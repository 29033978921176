import React, { useRef, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Spinner,
  Label,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import { leadGuida } from '../../../app/utility/QueryLanding'
import swal from 'sweetalert'
import { guidaFiscaleGA } from '../../../app/utility/GA'

const GuidaFiscaleMobile = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const refIniziaOra = useRef(null)
  const [consenso, setConsenso] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const scaricaGuida = async () => {
    if (email === '' || consenso === false || newsletter === false) {
      swal({
        title: 'Attenzione',
        text: "Compila tutti i campi, dai il tuo consenso al trattamento dei dati e all'isrizione alla newsletter",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    guidaFiscaleGA()
    await leadGuida({ nome, email }).then((res) => {
      setCaricamento(false)
      swal({
        title: 'Bene 😊',
        text: 'Entro pochi minuti riceverai una email da pill@fiscomed.it contenente il link per la guida fiscale. Se non la trovi, controlla nelle cartelle spam, aggiornamenti e promozioni.',
        icon: 'success',
        button: 'Ok',
      })
    })
  }

  return (
    <>
      <Helmet>
        <title>Guida fiscale</title>
        <meta property="title" content="Guida fiscale" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/guida-fiscale" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Guida fiscale" />
        <meta
          property="og:description"
          content="Scarica gratuitamente la guida fiscale per il giovane medico"
        />
        <meta
          property="description"
          content="Scarica gratuitamente la guida fiscale per il giovane medico"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }} className="mobile-container">
        <br />
        <br />
        <div className="header-landing-mobile-guida" />
        <span className="titolo1-mobile">La guida fiscale per giovani medici</span>
        <br />
        <br />
        <h4 className="font-bold">
          AGGIORNATA A MARZO <span className="testo-blu">2025</span>
        </h4>
        <br />
        <h6 className="font-regular interlinea-30">
          Durante gli studi di Medicina ti insegnano tante cose. Ce n’è una, però,
          che nessuno ti dice:{' '}
          <span className="font-bold">come gestire la fiscalità del tuo lavoro</span>
          . Per questo, abbiamo creato una{' '}
          <span className="testo-blu">guida fiscale gratuita</span> in cui ti
          spieghiamo, con parole semplici, tutto ciò che devi sapere.
        </h6>
        <br />
        <center>
          <Button
            className="bottone-blu px-4"
            onClick={() =>
              refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
            }
            style={{ paddingTop: '14px', paddingBottom: '14px', width: '190px' }}
          >
            OTTIENI LA GUIDA
          </Button>
          <br />
          <br />
          <br />
          <img
            src="/img_landing/ebook2025_header.webp"
            alt="fiscomed"
            style={{ width: '100%' }}
          />
        </center>
        <br />
        <br />
        <div className="div-celeste-guida py-5 px-4">
          <span className="titolo3-mobile">Cosa c'è nella guida?</span>
          <br />
          <br />
          <h6 className="h6-landing">
            In questa guida troverai tutte le informazioni che ti possono essere
            utili per gestire con serenità il tuo lavoro. Avrai a disposizione in un
            unico posto le <b>risposte alle tue domande</b> sul complicato mondo del
            fisco
          </h6>

          <Row>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/1.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />
              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                I primi
                <br />
                passi
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/2.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />
              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                I regimi
                <br />
                fiscali
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/3.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />

              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                Il regime
                <br />
                forfettario
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/4.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />

              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                L'ENPAM
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/5.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />

              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                La
                <br />
                fatturazione
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/6.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />

              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                Il Sistema
                <br />
                Tessera
                <br />
                Sanitaria
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/7.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />

              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                La scelta del
                <br />
                commercialista
              </h6>
            </Col>
            <Col xs={6} className="my-5" style={{ paddingLeft: '70px' }}>
              <img
                src="/img_landing/8.webp"
                alt="nuvola di colore"
                className="numero-guida-mobile"
              />

              <h6
                className="font-bold interlinea-20 testo-blu"
                style={{ marginLeft: '-20px' }}
              >
                Ti presentiamo
                <br />
                Fiscomed
              </h6>
            </Col>
            <Col xs={6} />
          </Row>
          <br />
          <center>
            <Button
              className="bottone-blu px-4"
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
              style={{ paddingTop: '14px', paddingBottom: '14px', width: '190px' }}
            >
              OTTIENI LA GUIDA
            </Button>
          </center>
        </div>
        <br />
        <br />
        <center>
          <span className="titolo2-mobile mb-5">Cosa imparerai?</span>

          <img
            src="/img_landing/ebook2025_desktop.webp"
            alt="immagine libro"
            style={{ width: '100%', maxWidth: '800px' }}
            className="my-5"
          />
        </center>
        <br />
        <div className="div-verde-guida py-5 px-4">
          <span className="titolo3-mobile">
            La nostra guida è stata utile a molte persone
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Questa guida nasce dall&apos;ascolto di tanti dottori e dottoresse in
            Medicina come te. Ed è stata scritta dopo aver capito che vorresti avere{' '}
            <b>informazioni sicure</b> e <b>facili</b> da capire riguardo il mondo
            fiscale
          </h6>
          <br />
          <br />
          <center>
            <div className="card-guida-mobile">
              <img
                src="/img_landing/cuore_sx.webp"
                alt="cuore"
                className="cuore-guida-mobile"
              />
              <br />
              <h3 className="font-bold testo-verde" style={{ fontSize: '4rem' }}>
                +10.000
              </h3>
              <h6 className="h6-landing">
                Medici hanno scaricato la nostra guida da questa pagina
              </h6>
            </div>
            <br />
            <br />
            <div className="card-guida-mobile">
              <img
                src="/img_landing/like.webp"
                alt="cuore"
                className="like-guida-mobile"
              />
              <h3 className="font-bold testo-verde" style={{ fontSize: '4rem' }}>
                ∞
              </h3>
              <h6 className="h6-landing">
                Medici l'hanno ricevuta su Telegram e WhatsApp grazie ai distributori
                seriali di dispense e guide fiscali 😏
              </h6>
            </div>
          </center>
          <br />
        </div>
        <br />
        <br />
        <span className="titolo2-mobile">Chi ha scritto la guida fiscale?</span>
        <br />
        <br />
        <h6 className="h6-landing">
          Questa guida è stata scritta da una Dottoressa Commercialista e un Dottore
          Commercialista del team di Fiscomed <b>esperti</b> nella fiscalità della{' '}
          <b>professione medica</b> e molto vicini ai bisogni, dubbi e difficoltà dei
          giovani medici come te
        </h6>
        <br />
        <center>
          <img
            src="/img_landing/cg.png"
            alt="foto di Cristina"
            style={{ maxWidth: '160px' }}
          />
          <br />
          <br />
          <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
            Maria Cristina
          </h5>
          <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
          <br />
          <br />
          <img
            src="/img_landing/ji.png"
            alt="foto di Jacopo"
            style={{ maxWidth: '160px' }}
          />
          <br />
          <br />
          <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
            Jacopo
          </h5>
          <h5 className="testo-landing-fiscomed" ref={refIniziaOra}>
            Dottore Commercialista
          </h5>
        </center>
        <br />
        <br />
        <div className="div-giallo-guida py-5 px-4">
          <span className="titolo3-mobile">
            Scrivi qui la tua email.
            <br />
            Riceverai subito la nostra guida
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Crediamo che con la giusta dose di informazioni non proverai più quel
            senso di spaesamento che si prova una volta entrati nel mondo del lavoro
          </h6>
          <br />
          <center>
            <FormGroup>
              <h6 className="font-medium mb-3">Come ti chiami?</h6>
              <Input
                type="text"
                placeholder="Ippocrate di Coo"
                style={{ borderColor: '#f2b705' }}
                autoComplete="name"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <h6 className="font-medium mb-3">Quale email usi di più?</h6>
              <Input
                type="text"
                placeholder="ippocrate@email.it"
                style={{ borderColor: '#f2b705' }}
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <br />
            <FormGroup check style={{ maxWidth: '300px' }}>
              <Input
                type="checkbox"
                name="privacy"
                id="privacy"
                style={{ marginTop: '2px' }}
                checked={consenso}
                onChange={(e) => setConsenso(e.target.checked)}
              />
              <Label for="privacy" className="consenso">
                Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
                <a
                  href="/documenti/termini_e_condizioni.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>privacy policy</u>
                </a>
              </Label>
            </FormGroup>
            <br />
            <FormGroup check style={{ maxWidth: '300px' }}>
              <Input
                type="checkbox"
                name="newsletter"
                id="newsletter"
                style={{ marginTop: '2px' }}
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
              />
              <Label for="newsletter" className="consenso">
                Scaricando la guida, accetto di iscrivermi alla newsletter
              </Label>
            </FormGroup>
            <br />
            {caricamento ? (
              <Button
                className="bottone-blu px-5 mt-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                disabled
              >
                <Spinner color="light" size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-blu px-5 mt-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                onClick={() => scaricaGuida()}
              >
                OTTIENI LA GUIDA
              </Button>
            )}
          </center>
        </div>
        <br />
        <br />
        <br />
        <div className="px-4">
          <div style={{ minHeight: '140px' }}>
            <img
              src="/img_landing/bookmarks.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Otterrai subito la nostra <b>guida</b> e poi <b>email educative</b>{' '}
              riguardanti la fiscalità della professione medica
            </h6>
            <br />
            <br />
            <br />
          </div>
          <div style={{ minHeight: '140px' }}>
            <img
              src="/img_landing/file-lock.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Teniamo alla tua <b>privacy</b>, quindi non daremo a nessuno il tuo
              indirizzo email
            </h6>
            <br />
            <br />
            <br />
          </div>
          <div style={{ minHeight: '140px' }}>
            <img
              src="/img_landing/stoplights.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Non ti bombarderemo mai di inutili email promozionali. Riceverai{' '}
              <b>al massimo</b> 3 email al mese per ricordarti le <b>scadenze</b>{' '}
              fiscali di quel periodo e spiegarti tutto ciò che devi sapere riguardo
              gli <b>adempimenti</b> più importanti
            </h6>
            <br />
            <br />
            <br />
          </div>
          <div style={{ minHeight: '140px' }}>
            <img
              src="/img_landing/chat-left-dots.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Naturalmente, puoi <b>smettere</b> di ricevere le nostre email quando
              vuoi
            </h6>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default GuidaFiscaleMobile
