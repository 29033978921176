import moment from 'moment'

export const emptyState = {
  loading: false,
  medico_id: null,
  anno: moment().year() - 1,
  anagrafica: {},
  certificato_attribuzione: null,
  file_condivisi: [],
  file_privati: [],
  lm: {},
  ravvedimenti: [],
  incassato: [],
  dichiarazione: {},
  cassetto: {},
  somma: 0,
  trattenuta_enpam: 0,
  limiti: {},
  report_tassazione: {},
}
