import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import {
  selectFileManager,
  selectNFatture,
  selectNBorseMmg,
  selectNBorseSpecializzazione,
  selectNCedolini,
  selectNFileCondivisi,
  selectNPrestazioniOccasionali,
} from '../../app/store/fileManager/selectors'
import { preposizioni } from '../../app/utility/Miscellanea'
import { Container, Row, Col, Card, Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import TabellaFatture from './component/TabellaFatture'
import TabellaCedolini from './component/TabellaCedolini'
import TabellaBorse from './component/TabellaBorse'
import TabellaDocumenti from './component/TabellaDocumenti'
import TabellaPrestazioniOccasionali from './component/TabellaPrestazioniOccasionali'
import { useDispatch } from 'react-redux'
import {
  aggiornaDocumento,
  setVisibleFilesType,
} from '../../app/store/fileManager/slice'
import { idrata } from '../../app/store/fileManager/actions'
import SelettoreAnno from './component/SelettoreAnno'
import classnames from 'classnames'
import ModalUpload from './component/ModalUpload'
import ModalUpdateBlocchetto from '../sharedComponent/ModalUpdateBlocchetto'
import { emptyDocumento } from '../../app/store/fileManager/costants'

const ImportaDocumento = () => {
  const user = useSelector(selectUser)
  const fileManager = useSelector(selectFileManager)
  const nFatture = useSelector(selectNFatture)
  const nBorseMmg = useSelector(selectNBorseMmg)
  const nBorseSpecializzazione = useSelector(selectNBorseSpecializzazione)
  const nCedolini = useSelector(selectNCedolini)
  const nFileCondivisi = useSelector(selectNFileCondivisi)
  const nPrestazioniOccasionali = useSelector(selectNPrestazioniOccasionali)
  const dispatch = useDispatch()
  const genere = preposizioni(user.commercialista_uomo)
  const [modalUpdateIsOpen, setModalUpdateIsOpen] = useState(false)
  const [documentToUpdate, setDocumentToUpdate] = useState(emptyDocumento)

  useEffect(() => {
    dispatch(idrata())
  }, [])

  return (
    <Container className="my-4">
      <Helmet>
        <title>Importa documento</title>
        <meta property="title" content="Importa documento" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ModalUpdateBlocchetto
        isOpen={modalUpdateIsOpen}
        close={() => setModalUpdateIsOpen(false)}
        inputDocument={documentToUpdate}
        returnFunction={(doc) =>
          dispatch(
            aggiornaDocumento({
              tipologiaDaRimuovere: documentToUpdate.tipologia,
              documento: doc,
            }),
          )
        }
      />
      <Row className="d-flex h-100">
        <Col xs={4} className="d-flex align-items-stretch">
          <Card
            body
            className="d-flex align-items-center justify-content-center h-100"
          >
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="img/filecondivisi.png"
                className="img-fluid"
                style={{ maxHeight: '250px', objectFit: 'contain' }}
                alt="File Condivisi"
              />
            </div>
          </Card>
        </Col>
        <Col xs={8} className="d-flex flex-column">
          <Card body className="flex-grow-1 d-flex flex-column h-100">
            <h4 className="font-medium mt-2">
              <i className="bi bi-cloud"></i> Documenti importati
              <div className="d-inline">
                <ModalUpload />
              </div>
            </h4>
            <h6 className="mt-3 font-regular interlinea-30 flex-grow-1">
              Qui puoi caricare tutti i documenti che vuoi condividere con{' '}
              {genere.ilTuo} commercialista e consultare quelli che {genere.lui} ha
              condiviso con te. Se importi un documento che influisce sul tuo reddito
              le previsioni delle tasse e dei contibuti si aggiorneranno.
            </h6>
          </Card>
        </Col>
      </Row>
      <Card body className="px-4 mt-3">
        <div className="d-flex justify-content-between">
          <SelettoreAnno />
          <Button
            className={classnames(
              'position-relativa',
              { 'bottone-blu': fileManager.visibleFilesType !== 'fatture' },
              {
                'bottone-blu-outline': fileManager.visibleFilesType === 'fatture',
              },
            )}
            color="primary"
            onClick={() => dispatch(setVisibleFilesType('fatture'))}
          >
            Fatture {nFatture}
          </Button>
          <Button
            className={classnames(
              'position-relativa',
              { 'bottone-blu': fileManager.visibleFilesType !== 'cedolini' },
              {
                'bottone-blu-outline': fileManager.visibleFilesType === 'cedolini',
              },
            )}
            color="primary"
            onClick={() => dispatch(setVisibleFilesType('cedolini'))}
          >
            Cedolini {nCedolini}
          </Button>
          <Button
            className={classnames(
              'position-relativa',
              { 'bottone-blu': fileManager.visibleFilesType !== 'borse' },
              {
                'bottone-blu-outline': fileManager.visibleFilesType === 'borse',
              },
            )}
            color="primary"
            onClick={() => dispatch(setVisibleFilesType('borse'))}
          >
            Borse {nBorseMmg + nBorseSpecializzazione}
          </Button>
          <Button
            className={classnames(
              'position-relativa',
              {
                'bottone-blu':
                  fileManager.visibleFilesType !== 'prestazioni_occasionali',
              },
              {
                'bottone-blu-outline':
                  fileManager.visibleFilesType === 'prestazioni_occasionali',
              },
            )}
            color="primary"
            onClick={() => dispatch(setVisibleFilesType('prestazioni_occasionali'))}
          >
            P. occasionali {nPrestazioniOccasionali}
          </Button>
          <Button
            className={classnames(
              'position-relativa',
              { 'bottone-blu': fileManager.visibleFilesType !== 'documenti' },
              {
                'bottone-blu-outline': fileManager.visibleFilesType === 'documenti',
              },
            )}
            color="primary"
            onClick={() => dispatch(setVisibleFilesType('documenti'))}
          >
            Altri documenti {nFileCondivisi}
          </Button>
        </div>
        <br />
        {fileManager.visibleFilesType === 'fatture' && (
          <TabellaFatture
            setDocumentToUpdate={(doc) => {
              setDocumentToUpdate(doc)
              setModalUpdateIsOpen(true)
            }}
          />
        )}
        {fileManager.visibleFilesType === 'cedolini' && (
          <TabellaCedolini
            setDocumentToUpdate={(doc) => {
              setDocumentToUpdate(doc)
              setModalUpdateIsOpen(true)
            }}
          />
        )}
        {fileManager.visibleFilesType === 'borse' && (
          <TabellaBorse
            setDocumentToUpdate={(doc) => {
              setDocumentToUpdate(doc)
              setModalUpdateIsOpen(true)
            }}
          />
        )}
        {fileManager.visibleFilesType === 'prestazioni_occasionali' && (
          <TabellaPrestazioniOccasionali
            setDocumentToUpdate={(doc) => {
              setDocumentToUpdate(doc)
              setModalUpdateIsOpen(true)
            }}
          />
        )}
        {fileManager.visibleFilesType === 'documenti' && <TabellaDocumenti />}
        <br />
      </Card>
    </Container>
  )
}

export default ImportaDocumento
