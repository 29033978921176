import React from 'react'

const Completato = ({ completato }) => {
  return (
    <span>
      {completato === true && <i className="bi bi-check text-success"></i>}
      {completato === false && <i className="bi bi-x text-danger"></i>}
      {completato === 'inProgress' && (
        <i className="bi bi-exclamation-diamond text-warning"></i>
      )}
    </span>
  )
}

export default Completato
