import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment/moment'
import { codiceFiscaleValid } from '../../../../app/utility/ValidatoreRegex'
import swal from 'sweetalert'
import { attivaMedico } from '../../../../app/utility/QueryCommercialista'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'

const AttivaMedico = ({ medico, rimuoviMedico }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const user = useSelector(selectUser)

  const attiva = () => {
    attivaMedico(user, medico.id)
    swal(
      'Profilo attivato',
      "Il medico ha ricevuto un'email di notifica. Da questo momento può utilizzare Fiscomed",
      'success',
    ).then(() => rimuoviMedico(medico.id))
  }

  const dataIsComplete = (medico) => {
    if (
      medico.data_invio_pdf_riepilogativo === undefined ||
      medico.data_invio_pdf_riepilogativo === null
    ) {
      return false
    }
    if (medico.email === '' || medico.email === null) {
      return false
    }
    if (medico.pec === '' || medico.pec === null) {
      return false
    }
    if (medico.telefono === '' || medico.telefono === null) {
      return false
    }
    if (medico.nome === '' || medico.nome === null) {
      return false
    }
    if (medico.cognome === '' || medico.cognome === null) {
      return false
    }
    if (
      medico.data_di_nascita === '' ||
      medico.data_di_nascita === null ||
      moment(medico.data_di_nascita).isBefore('1950-01-01') ||
      moment(medico.data_di_nascita).isAfter('2010-01-01')
    ) {
      return false
    }
    if (codiceFiscaleValid(medico.cf) === false) {
      return false
    }
    if (medico.indirizzo === '' || medico.indirizzo === null) {
      return false
    }
    if (medico.comune === '' || medico.comune === null) {
      return false
    }
    if (medico.provincia === '' || medico.provincia === null) {
      return false
    }
    if (medico.cap === '' || medico.cap === null) {
      return false
    }
    if (medico.ordine === '' || medico.ordine === null) {
      return false
    }
    if (
      medico.partita_iva === '' ||
      medico.partita_iva === null ||
      medico.partita_iva.length !== 11
    ) {
      return false
    }
    if (medico.ateco === '' || medico.ateco === null) {
      return false
    }
    if (
      medico.data_apertura_p_iva === '' ||
      medico.data_apertura_p_iva === null ||
      moment(medico.data_apertura_p_iva).isBefore('1950-01-01') ||
      moment(medico.data_apertura_p_iva).isAfter('2040-01-01')
    ) {
      return false
    }
    return true
  }

  const ok = <i className="bi bi-check text-success"></i>
  const ko = <i className="bi bi-x text-danger"></i>

  const modal = (
    <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
        Dati mancanti
      </ModalHeader>
      <ModalBody>
        <p>
          PDF inviato{' '}
          {medico.data_invio_pdf_riepilogativo === undefined ||
          medico.data_invio_pdf_riepilogativo === null
            ? ko
            : ok}
        </p>
        <p>Email {medico.email === '' || medico.email === null ? ko : ok}</p>
        <p>PEC {medico.pec === '' || medico.pec === null ? ko : ok}</p>
        <p>
          Telefono {medico.telefono === '' || medico.telefono === null ? ko : ok}
        </p>
        <p>Nome {medico.nome === '' || medico.nome === null ? ko : ok}</p>
        <p>Cognome {medico.cognome === '' || medico.cognome === null ? ko : ok}</p>
        <p>
          Data di nascita{' '}
          {medico.data_di_nascita === '' ||
          medico.data_di_nascita === null ||
          moment(medico.data_di_nascita).isBefore('1950-01-01') ||
          moment(medico.data_di_nascita).isAfter('2010-01-01')
            ? ko
            : ok}
        </p>
        <p>Codice fiscale {codiceFiscaleValid(medico.cf) === false ? ko : ok}</p>
        <p>
          Indirizzo {medico.indirizzo === '' || medico.indirizzo === null ? ko : ok}
        </p>
        <p>Comune {medico.comune === '' || medico.comune === null ? ko : ok}</p>
        <p>
          Provincia {medico.provincia === '' || medico.provincia === null ? ko : ok}
        </p>
        <p>CAP {medico.cap === '' || medico.cap === null ? ko : ok}</p>
        <p>Ordine {medico.ordine === '' || medico.ordine === null ? ko : ok}</p>
        <p>
          Partita IVA{' '}
          {medico.partita_iva === '' ||
          medico.partita_iva === null ||
          medico.partita_iva.length !== 11
            ? ko
            : ok}
        </p>
        <p>Codice ATECO {medico.ateco === '' || medico.ateco === null ? ko : ok}</p>
        <p>
          Data apertura partita IVA{' '}
          {medico.data_apertura_p_iva === '' ||
          medico.data_apertura_p_iva === null ||
          moment(medico.data_apertura_p_iva).isBefore('1950-01-01') ||
          moment(medico.data_apertura_p_iva).isAfter('2040-01-01')
            ? ko
            : ok}
        </p>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {modal}
      {dataIsComplete(medico) ? (
        <Button color="success" onClick={() => attiva()}>
          Attiva profilo
        </Button>
      ) : (
        <Button onClick={() => setModalIsOpen(true)} color="warning" outline>
          <i className="bi bi-eye"></i> Dati mancanti
        </Button>
      )}
    </>
  )
}

export default AttivaMedico
