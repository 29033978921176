import React, { useState, useEffect } from 'react'
import { Container, Table, Row, Col, Card } from 'reactstrap'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import { downloadInformazioniFiscali } from '../../app/utility/Miscellanea'
import { apriFileCondiviso } from '../../app/store/fileViewer/actions'
import { useDispatch } from 'react-redux'

const InformazioniFiscali = () => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const [info, setInfo] = useState([])
  const [certificato, setCertificato] = useState(null)

  useEffect(() => {
    downloadInformazioniFiscali(user).then((response) => {
      setInfo(response.info)
      setCertificato(response.certificato_attribuzione)
    })
  }, [])

  const certificatoComponent = (
    <h5
      onClick={() => dispatch(apriFileCondiviso(certificato?.id))}
      className="mb-3"
      style={{ cursor: 'pointer' }}
    >
      <u className="text-primary">
        <i className="bi bi-cloud-download"></i> {certificato?.nome_file}
      </u>
    </h5>
  )

  return (
    <Container className="mb-5 mt-4">
      <Helmet>
        <title>Informazioni fiscalil</title>
        <meta property="title" content="Informazioni fiscali" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Card body>
        <h4 className="font-medium">Informazioni fiscali</h4>
        <h6 className="font-regular interlinea-30 mb-4">
          Qui puoi vedere i tuoi dati, le tue informazioni fiscali e il documento che
          attesta l&apos;apertura della tua partita IVA
        </h6>
        <Row className="mt-3">
          <Col xs="6">
            <h5 className="font-medium">I tuoi dati</h5>
            <Table className="table-gialla table mt-3">
              <thead>
                <tr>
                  <th className="h4 font-medium"></th>
                  <th className="h4 font-medium"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nome</td>
                  <td>{info.nome}</td>
                </tr>
                <tr>
                  <td>Cognome</td>
                  <td>{info.cognome}</td>
                </tr>
                <tr>
                  <td>Comune</td>
                  <td>{info.comune}</td>
                </tr>
                <tr>
                  <td>Indirizzo</td>
                  <td>{info.indirizzo}</td>
                </tr>
                <tr>
                  <td>Nazione</td>
                  <td>{info.nazione}</td>
                </tr>
                <tr>
                  <td>Cap</td>
                  <td>{info.cap}</td>
                </tr>
                <tr>
                  <td>Codice fiscale</td>
                  <td>{info.codice_fiscale}</td>
                </tr>
                <tr>
                  <td>Partita IVA</td>
                  <td>{info.partita_iva}</td>
                </tr>
                <tr>
                  <td>Provincia</td>
                  <td>{info.provincia}</td>
                </tr>
                <tr>
                  <td>Codice ATECO</td>
                  <td>Controlla il certificato di attribuzione</td>
                </tr>
                <tr>
                  <td>Regime IVA</td>
                  <td>Forfettario</td>
                </tr>
                <tr>
                  <td>Data apertura</td>
                  <td>
                    {moment(info.data_apertura_partita_iva).format('DD/MM/yyyy')}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs="6 pl-5">
            <h5 className="font-medium">
              Certificato di attribuzione della partita IVA
            </h5>
            {certificato === null ? (
              <>Scrivici su WhatsApp per richiederlo</>
            ) : (
              certificatoComponent
            )}
            <br />
            <br />
            <center>
              <img src="/img/fisco/informazioni_fiscali.webp" className="mt-5" />
            </center>
          </Col>
        </Row>
      </Card>
    </Container>
  )
}
export default InformazioniFiscali
