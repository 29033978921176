import React, { useEffect } from 'react'
import { Container, Row, Col, Badge } from 'reactstrap'
import HeaderTools from './headerTools/HeaderTools'
import { useParams } from 'react-router'
import SelettoreAnno from './component/SelettoreAnno'
import ModelloD from './component/ModelloD'
import Cassetto from './cassetto/Cassetto'
import F24 from './component/F24'
import Unico from './component/Unico'
import CertificatoContributi from './component/CertificatoContributi'
import Quiz from './component/Quiz'
import { useDispatch, useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../app/store/gestionaleUtente/selectors'
import { idrata } from '../../../app/store/gestionaleUtente/actions'
import Incassato from './component/Incassato'
import CentredLoading from '../../component/CentredLoading'
import StatoDichiarazione from './component/StatoDichiarazione'
import Fatto from './component/Fatto'
import CU from './cassetto/CU'
import ModelloUnico from './cassetto/ModelloUnico'
import SetteTrenta from './cassetto/SetteTrenta'
import F24Ade from './cassetto/F24Ade'
import FattureElettronicheRicevute from './cassetto/FattureElettronicheRicevute'
import Fabbricati from './cassetto/Fabbricati'
import Terreni from './cassetto/Terreni'
import Registro from './cassetto/Registro'
import MetaDati from './component/MetaDati'

const UserProfile = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const { medicoId } = useParams()

  useEffect(() => {
    const medico_id = parseInt(medicoId)
    const anno = new Date().getFullYear() - 1
    dispatch(idrata({ anno, medico_id }))
  }, [])

  return (
    <div className="mb-5">
      <HeaderTools />
      <div className="pb-3 pt-2" style={{ backgroundColor: 'white' }}>
        <Container fluid>
          <SelettoreAnno />
          <StatoDichiarazione />
          <Badge color="light" className="ms-2">
            <i className="bi bi-arrow-right-short font-regular"></i>
          </Badge>
          <CertificatoContributi />
          <Quiz />
          <Badge color="light" className="ms-2">
            <i className="bi bi-arrow-right-short font-regular"></i>
          </Badge>
          <Fatto />
          <Badge color="light" className="ms-2">
            <i className="bi bi-arrow-right-short font-regular"></i>
          </Badge>
          <ModelloD />
          <F24 />
          <Unico />
          <MetaDati />
        </Container>
      </div>
      {dati.loading ? (
        <CentredLoading />
      ) : (
        <Container fluid>
          <Row>
            <Col xs={9}>
              <Incassato />
            </Col>
            <Col xs={3}>
              <CU />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Cassetto />
            </Col>
            <Col xs={6}>
              <ModelloUnico />
            </Col>
            <Col xs={6}>
              <SetteTrenta />
            </Col>
            <Col xs={5}>
              <F24Ade />
            </Col>
            <Col xs={7}>
              <FattureElettronicheRicevute />
            </Col>
            <Col xs={12}>
              <Fabbricati />
            </Col>
            <Col xs={12}>
              <Terreni />
            </Col>
            <Col xs={12}>
              <Registro />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}
export default UserProfile
