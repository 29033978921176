import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'

const Delega = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <>
      <h1 className="font-bold mt-5">
        Prima di cominciare, c&apos;è una cosa che dovresti fare
      </h1>
      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Per consentire {context.genere.alTuo} commercialista di preparare al meglio
        la dichiarazione dei redditi, abbiamo bisogno che{' '}
        <span className="font-bold">deleghi Fiscomed</span> alla consultazione del
        tuo <i>cassetto fiscale</i> e della sezione <i>“Fatture e corrispettivi”</i>.
        <br />
        <br />
        Il <i>cassetto fiscale</i> è lo spazio dell'Agenzia delle Entrate dove ci
        sono tutti i tuoi dati e documenti fiscali, mentre la sezione{' '}
        <i>“Fatture e corrispettivi”</i> raccoglie tutte le fatture elettroniche che
        fai o ricevi.
        <br />
        <br />
        Fare la delega è davvero semplice.{' '}
        <Link to="/fiscodemia/video/cassettofiscale">Guarda il video</Link> che
        Giulia ha preparato pet te: ti guida passo dopo passo e ti mostra esattamente
        cosa fare.
        <br />
        <br />
        Quando hai fatto, non ti resta che aspettare. Potrebbero volerci fino a 24
        ore prima che tutto sia aggiornato, quindi ti consigliamo di{' '}
        <span className="font-bold">tornare domani</span> per controllare.
        <br />
        <br />
        Se è tutto okay,{' '}
        <span className="font-bold">potrai iniziare il percorso</span> verso la tua
        dichiarazione dei redditi 🤗
        <br />
        <br />
      </h6>
    </>
  )
}

export default Delega
