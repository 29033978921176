import React from 'react'
import SwitchToUser from './component/SwitchToUser'
import CertificatoDiAttribuzione from './component/CertificatoDiAttribuzione'
import Anagrafica from './component/Anagrafica'
import Appunti from './component/Appunti'
import OffcanvasFile from './component/OffcanvasFile'
import StatoPagamento from './component/StatoPagamento'
import { Container } from 'reactstrap'
import Nome from './component/Nome'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'
import Report from './component/Report'
import Altro from './component/Altro'

const HeaderTools = () => {
  const user = useSelector(selectUser)
  return (
    <div className="py-3" style={{ backgroundColor: '#e9ecef' }}>
      <Container fluid>
        <div className="d-flex justify-content-between">
          <div>
            <Nome />
            <StatoPagamento />
          </div>
          <div>
            <Anagrafica />
            <Appunti />
            <CertificatoDiAttribuzione />
            <OffcanvasFile tipologia="file_condiviso" />
            {user.role === 'commercialista' && (
              <OffcanvasFile tipologia="file_privato" />
            )}
            <Report />
            <Altro />
            <SwitchToUser />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeaderTools
