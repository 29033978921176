import React, { useState } from 'react'
import { Card, Input, Label, Row, Col, Button } from 'reactstrap'
import {
  rettificaFatturaQuery,
  fatturaGestitaQuery,
} from '../../../app/utility/QueryContabile'

const FatturaDaGestire = ({ fattura, rimuovi, user }) => {
  const [datiFattura, setDatiFattura] = useState({
    descrizione: fattura.parametriDecodificati.descrizione,
    nome: fattura.parametriDecodificati.nome,
    cognome: fattura.parametriDecodificati.cognome,
    codice_fiscale: fattura.parametriDecodificati.codice_fiscale,
    indirizzo: fattura.parametriDecodificati.indirizzo,
    cap: fattura.parametriDecodificati.cap,
    comune: fattura.parametriDecodificati.comune,
    nazione: fattura.parametriDecodificati.nazione,
    provincia: fattura.parametriDecodificati.provincia,
    pec: fattura.parametriDecodificati.pec,
    partita_iva: fattura.parametriDecodificati.partita_iva,
    netto: fattura.parametriDecodificati.netto,
    iva: fattura.parametriDecodificati.iva,
    totale: fattura.parametriDecodificati.totale,
  })
  const [loading, setLoading] = useState(false)

  const handleGestita = () => {
    rimuovi(fattura.id)
    setLoading(true)
    fatturaGestitaQuery(user, fattura.progressivo_unico_fattura)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleInvia = () => {
    rimuovi(fattura.id)
    setLoading(true)
    let dati = {
      netto: datiFattura.netto,
      iva: datiFattura.iva,
      totale: datiFattura.totale,
      nome: datiFattura.nome,
      cognome: datiFattura.cognome,
      codice_fiscale: datiFattura.codice_fiscale,
      pec: datiFattura.pec,
      partita_iva: datiFattura.partita_iva,
      indirizzo: datiFattura.indirizzo,
      nazione: datiFattura.nazione,
      cap: datiFattura.cap,
      comune: datiFattura.comune,
      provincia: datiFattura.provincia,
      numero_fattura: fattura.numero_fattura,
      descrizione: datiFattura.descrizione,
      sezionale: 'B',
      data_fattura: fattura.parametriDecodificati.data_fattura,
      tentativo_successivo: true,
      progressivo_unico_fattura_gestita: fattura.progressivo_unico_fattura,
    }
    rettificaFatturaQuery(user, dati)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <Card body className="mb-3">
      <div className="d-flex justify-content-between">
        <span className="text-muted">
          Fattura numero: {fattura.numero_fattura}B/{fattura.anno} (Data fattura:{' '}
          {fattura.parametriDecodificati.data_fattura})
        </span>

        <div className="d-flex justify-content-end">
          <Button color="success" size="sm" onClick={() => handleGestita()}>
            <i className="bi bi-check"></i> Gestita con Fatture in Cloud
          </Button>
          <Button
            color="primary"
            className="ms-2"
            size="sm"
            onClick={() => handleInvia()}
            disabled={loading}
          >
            <i className="bi bi-send"></i> Invia
          </Button>
        </div>
      </div>
      <br />
      {fattura.esito === 'scarto' && (
        <span className="text-muted">Errore: {fattura.suggerimento}</span>
      )}
      <br />
      <div className="mb-3">
        <Label>Causale</Label>
        <Input
          type="text"
          value={datiFattura.descrizione}
          onChange={(e) =>
            setDatiFattura({ ...datiFattura, descrizion: e.target.value })
          }
        />
      </div>
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label>Nome</Label>
                <Input
                  type="text"
                  value={datiFattura.nome}
                  onChange={(e) =>
                    setDatiFattura({ ...datiFattura, nome: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label>Cognome</Label>
                <Input
                  type="text"
                  value={datiFattura.cognome}
                  onChange={(e) =>
                    setDatiFattura({ ...datiFattura, cognome: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <Label>Codice fiscale</Label>
            <Input
              type="text"
              value={datiFattura.codice_fiscale}
              onChange={(e) =>
                setDatiFattura({ ...datiFattura, codice_fiscale: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <Label>Indirizzo</Label>
            <Input
              type="text"
              value={datiFattura.indirizzo}
              onChange={(e) =>
                setDatiFattura({ ...datiFattura, indirizzo: e.target.value })
              }
            />
          </div>
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label>CAP</Label>
                <Input
                  type="text"
                  value={datiFattura.cap}
                  onChange={(e) =>
                    setDatiFattura({ ...datiFattura, cap: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <Label>Comune</Label>
                <Input
                  type="text"
                  value={datiFattura.comune}
                  onChange={(e) =>
                    setDatiFattura({ ...datiFattura, comune: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label>Nazione</Label>
                <Input
                  type="text"
                  value={datiFattura.nazione}
                  onChange={(e) =>
                    setDatiFattura({ ...datiFattura, nazione: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <Label>Provincia</Label>
                <Input
                  type="text"
                  value={datiFattura.provincia}
                  onChange={(e) =>
                    setDatiFattura({ ...datiFattura, provincia: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <div className="mb-3">
            <Label>PEC</Label>
            <Input
              type="text"
              value={datiFattura.pec}
              onChange={(e) =>
                setDatiFattura({ ...datiFattura, pec: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <Label>Partita IVA</Label>
            <Input
              type="text"
              value={datiFattura.partita_iva}
              onChange={(e) =>
                setDatiFattura({ ...datiFattura, partita_iva: e.target.value })
              }
            />
          </div>
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label>Netto</Label>
                <Input type="text" value={datiFattura.netto / 100} disabled />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label>IVA</Label>
                <Input type="text" value={datiFattura.iva / 100} disabled />
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <Label>Totale</Label>
            <Input type="text" value={datiFattura.totale / 100} disabled />
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default FatturaDaGestire
