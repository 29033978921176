import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Navbar,
  Collapse,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Container,
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import { logout } from '../../../app/store/user/slice'

const OperationNav = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const logoutAction = () => {
    dispatch(logout())
    history.push('/')
  }

  return (
    <Navbar
      light
      style={{
        zIndex: '1000',
      }}
      expand="md"
      fixed="none"
      className="navbar navbar-expand-md navbar-dark bg-dark"
    >
      <Container
        fluid
        style={{
          display: 'flex',
        }}
      >
        <NavbarBrand tag={Link} to="/admin">
          <img
            src="/img_landing/logo_bianco.png"
            alt="Fiscomed"
            style={{ height: '42px' }}
          />
        </NavbarBrand>
        <NavbarToggler style={{ backgroundColor: 'white' }} />
        <Collapse isOpen={true} navbar>
          <Nav navbar>
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/admin">
                  <i className="bi bi-person"></i> Clienti
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pending">
                  <i className="bi bi-signpost-split"></i> Pending
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/lead">
                  <i className="bi bi-headset"></i> Lead
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/commercialisti">
                  <i className="bi bi-person-video"></i> Commercialisti
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/checker">
                  <i className="bi bi-file-check"></i> Checker
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="#"
                >
                  <i className="bi bi-tools"></i> Strumenti
                </a>
                <div
                  className="dropdown-menu dropdown-menu-start"
                  aria-labelledby="navbarDropdown"
                >
                  <Link
                    className="dropdown-item"
                    to="/operation/tappe-dichiarazione"
                  >
                    Tappe dichiarazione
                  </Link>
                  <div className="dropdown-divider"></div>

                  <Link className="dropdown-item" to="/operation/limiti-incasso">
                    Limiti d'incasso
                  </Link>
                  <Link className="dropdown-item" to="/operation/mailchimp">
                    Mailchimp
                  </Link>
                  <Link className="dropdown-item" to="/operation/abbonamenti-stripe">
                    Abbonamenti
                  </Link>
                </div>
              </li>
            </ul>
          </Nav>
          <Nav navbar className="ms-auto">
            <ul className="navbar-nav">
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => logoutAction()}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="bi bi-door-open"></i> Logout
                </span>
              </li>
            </ul>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default OperationNav
