import React from 'react'
import { Route, Switch } from 'react-router-dom'
import FiscoMed from '../../pagineSingole/Home'
import Scadenze from '../../pagineSingole/Scadenze'
import TesseraSanitaria from '../../sistemaTS/MainContainer'
import Statistiche from '../../pagineSingole/Statistiche'
import Abbonamento from '../../pagineSingole/Abbonamento'
import InformazioniFiscali from '../../pagineSingole/InformazioniFiscali'
import Rubrica from '../../pagineSingole/Rubrica'
import VuoiUnaMano from '../../pagineSingole/VuoiUnaMano'
import FiscoDemiaHome from '../../fiscodemia/FiscoDemiaHome'
import FiscoDemiaVideo from '../../fiscodemia/FiscoDemiaVideo'
import FatturaContainer from '../../fattura/FatturaContainer'
import EsitoProforma from '../../fattura/esiti/EsitoProforma'
import ProformaContainer from '../../pagineSingole/ProformaContainer'
import EsitoFatturaCartacea from '../../fattura/esiti/EsitoFatturaCartacea'
import EsitoFatturaElettronica from '../../fattura/esiti/EsitoFatturaElettronica'
import EsitoAutofattura from '../../fattura/esiti/EsitoAutofattura'
import MetodoDiPagamento from '../../pagineSingole/MetodoDiPagamento'
import RiepilogoContainer from '../../riepilogoFatture/RiepilogoContainer'
import CodiceCollega from '../../codiceCollega/CodiceCollega'
import DichiarazioneProvider from '../../../app/context/Dichiarazione'
import DichiarazioneContainer from '../../dichiarazione/DichiarazioneContainer'
import Archivio from '../../dichiarazione/Archivio'
import EnpamContainer from '../../enpam/EnpamContainer'
import ImportaDocumento from '../../importaDocumento/ImportaDocumento'

const MedicoRoutes = () => (
  <Switch>
    <Route exact path="/fiscomed">
      <FiscoMed />
    </Route>
    <Route
      exact
      path="/fiscomed/nuova-fattura/esito-fattura-elettronica/:esito/:idFattura"
    >
      <EsitoFatturaElettronica />
    </Route>
    <Route exact path="/fiscomed/nuova-fattura/esito-proforma/:idProforma">
      <EsitoProforma />
    </Route>
    <Route exact path="/fiscomed/nuova-fattura/esito-fattura-cartacea/:idFattura">
      <EsitoFatturaCartacea />
    </Route>
    <Route
      exact
      path="/fiscomed/nuova-fattura/esito-autofattura/:esito/:iva/:idFattura"
    >
      <EsitoAutofattura />
    </Route>
    <Route exact path="/fiscomed/nuova-fattura/:tipologia/:idFatturaDiRiferimento">
      <FatturaContainer />
    </Route>
    <Route exact path="/fiscomed/proforma">
      <ProformaContainer />
    </Route>
    <Route exact path="/codice-collega">
      <CodiceCollega />
    </Route>
    <Route exact path="/riepilogo-fatture">
      <RiepilogoContainer />
    </Route>
    <Route exact path="/enpam">
      <EnpamContainer />
    </Route>
    <Route exact path="/dichiarazione">
      <DichiarazioneProvider>
        <DichiarazioneContainer />
      </DichiarazioneProvider>
    </Route>
    <Route exact path="/dichiarazione/archivio">
      <DichiarazioneProvider>
        <Archivio />
      </DichiarazioneProvider>
    </Route>
    <Route exact path="/scadenze">
      <Scadenze />
    </Route>
    <Route exact path="/tessera-sanitaria">
      <TesseraSanitaria />
    </Route>
    <Route exact path="/statistiche">
      <Statistiche />
    </Route>
    <Route exact path="/metodo-di-pagamento">
      <MetodoDiPagamento />
    </Route>
    <Route exact path="/abbonamento">
      <Abbonamento />
    </Route>
    <Route exact path="/informazioni-fiscali">
      <InformazioniFiscali />
    </Route>
    <Route exact path="/rubrica">
      <Rubrica />
    </Route>
    <Route exact path="/chat-commercialista">
      <VuoiUnaMano />
    </Route>
    <Route exact path="/fiscodemia/home">
      <FiscoDemiaHome />
    </Route>
    <Route exact path="/fiscodemia/video/:nomeVideo">
      <FiscoDemiaVideo />
    </Route>
    <Route exact path="/importa-documento">
      <ImportaDocumento />
    </Route>
    <Route>
      <FiscoMed />
    </Route>
  </Switch>
)

export default MedicoRoutes
