import React, { useState, useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import swal from 'sweetalert'
import moment from 'moment'

const Fatture = () => {
  const context = useContext(DichiarazioneContext)
  const [caricati, setCaricati] = useState(false)
  const [incassati, setIncassati] = useState(false)
  const [nonHoFatture, setNonHoFatture] = useState(false)

  const controllaEProsegui = () => {
    if (nonHoFatture || (caricati && incassati)) {
      context.completaStepFatture(nonHoFatture ? false : true)
    } else {
      swal({
        title: 'Manca qualcosa',
        text: 'Se hai delle fatture e/o dei cedolini, spunta le prime due caselle prima di andare avanti. Se non hai alcun documento, spunta la casella in basso',
        icon: 'warning',
        button: 'CHIUDI',
      })
    }
  }

  const dichiaroDiAverCaricatoIDocumenti = (rispostaAffermativa) => {
    setCaricati(rispostaAffermativa)
    if (!rispostaAffermativa) setIncassati(false)
    if (rispostaAffermativa) setNonHoFatture(false)
  }

  const dichiaroDiAverIncassatoIDocumenti = (rispostaAffermativa) => {
    setIncassati(rispostaAffermativa)
    if (rispostaAffermativa) setNonHoFatture(false)
  }

  const dichiaroDiNonAvereFattureOCedolini = (rispostaAffermativa) => {
    setNonHoFatture(rispostaAffermativa)
    if (rispostaAffermativa) setCaricati(false)
    if (rispostaAffermativa) setIncassati(false)
  }

  return (
    <div>
      <h4 className="testo-verde font-bold mb-4">
        1️⃣ PRIMA TAPPA: FATTURE, CEDOLINI, PRESTAZIONI OCCASIONALI E BORSE DI STUDIO
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Controlla se su Fiscomed ci sono{' '}
        <span className="font-bold">tutte le fatture</span>{' '}
        <i>(cartacee e elettroniche)</i>,{' '}
        <span className="font-bold">tutti i cedolini</span> e le eventuali
        prestazioni occasionali che hai incassato nel {moment().year() - 1}.
        <br />
        Se manca qualcosa, puoi importare i documenti cliccando su{' '}
        <span className="font-bold">"Strumenti" &gt; "Importa documento"</span>.
      </h6>
      <h6 className="font-regular mb-3 interlinea-30">
        A questo punto, verifica che tutte le fatture siano{' '}
        <span className="font-bold">segnate come incassate</span> nella sezione{' '}
        <span className="font-bold">"Riepilogo fatture"</span>. Solitamente lo si fa
        quando si riceve il pagamento, ma se non lo hai fatto puoi tranquillamente
        farlo ora inserendo la data in cui ti hanno pagato.
      </h6>
      <h6 className="font-regular mb-3 interlinea-30">
        Se frequenti la <span className="font-bold">scuola di specializzazione</span>{' '}
        o il <span className="font-bold">corso di medicina generale</span>, carica
        anche i cedolini delle borse di studio. Puoi farlo sempre da{' '}
        <span className="font-bold">"Strumenti" &gt; "Importa documento"</span>.
        Questi cedolini <i>non vanno incassati.</i>
      </h6>
      <FormGroup check className="mt-4">
        <Input
          type="checkbox"
          onChange={(e) => dichiaroDiAverCaricatoIDocumenti(e.target.checked)}
          valid={caricati}
          checked={caricati}
        />{' '}
        <Label check>
          Ci sono tutte le fatture, i cedolini e le prestazioni occasionali
        </Label>
      </FormGroup>
      <FormGroup check className="mb-4  mt-2">
        <Input
          type="checkbox"
          onChange={(e) => dichiaroDiAverIncassatoIDocumenti(e.target.checked)}
          valid={incassati}
          checked={incassati}
        />{' '}
        <Label check>Tutte le fatture sono incassate correttamente</Label>
      </FormGroup>
      <h6 className="font-regular mb-4 interlinea-30">
        ⚠️ Lo scorso anno non hai fatto fatture, prestazioni occasionali o ricevuto
        cedolini? Se sei qui, è perché devi comunque fare la dichiarazione dei
        redditi. Se non hai alcun documento, spunta la casella qui sotto prima di
        andare avanti.
      </h6>
      <FormGroup check className="mb-4">
        <Input
          type="checkbox"
          onChange={(e) => dichiaroDiNonAvereFattureOCedolini(e.target.checked)}
          valid={nonHoFatture}
          checked={nonHoFatture}
        />{' '}
        <Label check>
          Non ho fatture o cedolini relativi al {moment().year() - 1}
        </Label>
      </FormGroup>
      <br />
      <Button
        className="bottone-blu float-end mb-4"
        onClick={() => controllaEProsegui()}
      >
        PROSSIMA TAPPA
      </Button>
    </div>
  )
}

export default Fatture
