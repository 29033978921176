import React from 'react'
import { Card, Badge } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { apriFileCassetto } from '../../../../app/store/fileViewer/actions'

const F24Ade = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()

  return (
    <Card body className="mt-4">
      <span className="h5">F24 AdE</span>
      {dati.cassetto?.last_sync !== null && (
        <>
          {dati.cassetto?.f24?.length > 0 ? (
            <table className="table table-striped table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Data versamento</th>
                  <th scope="col">Saldo</th>
                  <th scope="col">Quietanza</th>
                  <th scope="col">Anno</th>
                  <th scope="col">F24</th>
                </tr>
              </thead>
              <tbody>
                {dati.cassetto?.f24?.map((f24, index) => {
                  return (
                    <tr key={index}>
                      <td>{f24.data_versamento}</td>
                      <td>{f24.saldo}</td>
                      <td>
                        {f24.nome_file_quietanza && (
                          <Badge
                            color="primary"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              dispatch(
                                apriFileCassetto('f24/' + f24.nome_file_quietanza),
                              )
                            }
                          >
                            <i className="bi bi-eye"></i>
                          </Badge>
                        )}
                      </td>
                      <td>{f24.anno}</td>
                      <td>
                        <Badge
                          color="primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            dispatch(apriFileCassetto('f24/' + f24.nome_file))
                          }
                        >
                          <i className="bi bi-eye"></i>
                        </Badge>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-secondary mt-3" role="alert">
              Non risultano F24 nei 3 anni precedenti
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default F24Ade
