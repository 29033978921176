import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'

const Registro = () => {
  const dati = useSelector(selectGestionaleUtente)
  const [scritturePresenti, setScritturePresenti] = useState(false)

  useEffect(() => {
    if (dati.cassetto?.registro?.length > 0) {
      const scritture = dati.cassetto?.registro?.some((anniRegistro) => {
        return anniRegistro.dichiarazioni.length > 0
      })
      setScritturePresenti(scritture)
    }
  }, [dati.cassetto])

  return (
    <Card body className="mt-4">
      <span className="h5">Registro</span>
      {dati.cassetto?.last_sync !== null && (
        <>
          {scritturePresenti ? (
            <table className="table table-striped table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Anno</th>
                  <th scope="col">Data</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Ruolo</th>
                  <th scope="col">Valore</th>
                  <th scope="col">Modello</th>
                  <th scope="col">Ufficio</th>
                </tr>
              </thead>
              <tbody>
                {dati.cassetto?.registro?.map((annoEDichiarazioni) => {
                  if (annoEDichiarazioni.dichiarazioni.length > 0) {
                    return annoEDichiarazioni.dichiarazioni.map(
                      (dichiarazione, index) => {
                        return (
                          <tr key={index}>
                            <td>{annoEDichiarazioni.anno}</td>
                            <td>{dichiarazione.data_presentazione}</td>
                            <td>{dichiarazione.tipo}</td>
                            <td>{dichiarazione.ruolo}</td>
                            <td>{dichiarazione.valore}</td>
                            <td>{dichiarazione.modello}</td>
                            <td>{dichiarazione.ufficio_registrazione}</td>
                          </tr>
                        )
                      },
                    )
                  }
                })}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-warning mt-3" role="alert">
              Non risultano scritture nel registro negli ultimi 5 anni
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default Registro
