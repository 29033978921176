import React from 'react'
import { Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { selectLoadingFile } from '../../../../app/store/fileViewer/selectors'
import classNames from 'classnames'

const Deleghe = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingFile)

  return (
    <Button size="sm" color="secondary" outline disabled={true} className="ms-2">
      <span
        className={classNames(
          'badge rounded-pill',
          { 'text-bg-danger': !dati.cassetto?.delega_cassetto },
          { 'text-bg-success': dati.cassetto?.delega_cassetto },
        )}
      >
        Cassetto
      </span>{' '}
      <span
        className={classNames(
          'badge rounded-pill',
          { 'text-bg-danger': !dati.cassetto?.delega_fatturazione },
          { 'text-bg-success': dati.cassetto?.delega_fatturazione },
        )}
      >
        Fatture
      </span>{' '}
    </Button>
  )
}

export default Deleghe
