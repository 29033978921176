import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsMedico } from '../../app/store/user/selectors'
import ModaleContratti from './ModaleContratti'

const ContrattoOnlyForMedico = () => {
  const isMedico = useSelector(selectIsMedico)

  return <>{isMedico && <ModaleContratti />}</>
}

export default ContrattoOnlyForMedico
