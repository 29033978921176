import request from 'superagent'

export const listLead = async (user, operatore_uuid) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/list`)
      .send({ token: user.auth_token, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const salvaInfo = async (user, dati) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/salva-info`)
      .send({ token: user.auth_token, dati })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const assegnaLead = async (
  user,
  lead_id,
  uuid_for_assignment,
  operatore_uuid,
) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/assegna`)
      .send({ token: user.auth_token, lead_id, uuid_for_assignment, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const scartaLead = async (user, lead_id, operatore_uuid) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/scarta`)
      .send({ token: user.auth_token, lead_id, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const chiamatoLead = async (user, lead_id, operatore_uuid) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/chiamato`)
      .send({ token: user.auth_token, lead_id, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const queryToggleCantina = async (user, lead_id, operatore_uuid) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/toggle-cantina`)
      .send({ token: user.auth_token, lead_id, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const queryMoveUp = async (user, lead_id, operatore_uuid) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/move-up`)
      .send({ token: user.auth_token, lead_id, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const queryMoveDown = async (user, lead_id, operatore_uuid) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/move-down`)
      .send({ token: user.auth_token, lead_id, operatore_uuid })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}

export const leadChiusi = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/lead/chiusi`)
      .send({ token: user.auth_token })

    return response.body
  } catch (e) {
    console.error(e.message)
    return []
  }
}
