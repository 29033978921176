import swal from 'sweetalert'

const emptyTester = {
  errorePresente: false,
  messageErrore: '',
  consiglioPresente: false,
  messageConsiglio: '',
}

export const controlloFattura = async (datiFattura, formato) => {
  let tester = { ...emptyTester }
  tester = bloccoNumeroFattura(datiFattura, tester)
  tester = bloccaSeSenzaPrestazione(datiFattura, tester)
  tester = bloccoSeSenzaCliente(datiFattura, tester)
  tester = bloccoElettronicaAPaziente(datiFattura, formato, tester)
  tester = bloccoCartaceaAAzienda(datiFattura, formato, tester)
  tester = bloccoPAaNonPA(datiFattura, formato, tester)
  tester = consiglioMarcaBollo(datiFattura, tester, formato)
  tester = consiglioPagamento(datiFattura, tester)
  if (tester.errorePresente) {
    mostraErrore(tester.messageErrore)
    return tester
  }
  if (tester.consiglioPresente) {
    const testerConScelta = await mostraConsiglio(tester)
    return testerConScelta
  }
  return tester
}

const bloccoNumeroFattura = (datiFattura, tester) => {
  if (datiFattura.numeroFattura <= 0) {
    tester.errorePresente = true
    tester.messageErrore = componiStringa(
      tester.messageErrore,
      'Hai selezionato un numero di fattura non valido',
    )
  }
  return tester
}

const bloccaSeSenzaPrestazione = (datiFattura, tester) => {
  if (datiFattura.prestazioni.length === 0) {
    tester.errorePresente = true
    tester.messageErrore = componiStringa(
      tester.messageErrore,
      "Non c'è neanche una prestazione",
    )
  }
  return tester
}

const bloccoSeSenzaCliente = (datiFattura, tester) => {
  if (Object.keys(datiFattura.clienteAttivo).length === 0) {
    tester.errorePresente = true
    tester.messageErrore = componiStringa(
      tester.messageErrore,
      "Non c'è un cliente selezionato",
    )
  }
  return tester
}

const bloccoElettronicaAPaziente = (datiFattura, formato, tester) => {
  if (
    formato !== 'cartacea' &&
    datiFattura.clienteAttivo?.idoneo_elettronica === false
  ) {
    tester.errorePresente = true
    tester.messageErrore = componiStringa(
      tester.messageErrore,
      'Il cliente selezionato non va bene per la fattura elettronica',
    )
  }
  return tester
}

const bloccoCartaceaAAzienda = (datiFattura, formato, tester) => {
  if (
    formato === 'cartacea' &&
    datiFattura.clienteAttivo?.idoneo_elettronica === true
  ) {
    tester.errorePresente = true
    tester.messageErrore = componiStringa(
      tester.messageErrore,
      'Il cliente selezionato non può ricevere le fatture cartacee',
    )
  }
  return tester
}

const bloccoPAaNonPA = (datiFattura, formato, tester) => {
  if (formato !== 'PA' && datiFattura.clienteAttivo?.ente_pubblic) {
    tester.errorePresente = true
    tester.messageErrore = componiStringa(
      tester.messageErrore,
      'Dovresti selezionare la fattura PA per un ente pubblico',
    )
  }
  return tester
}

const consiglioMarcaBollo = (datiFattura, tester, formato) => {
  if (formato === 'cartacea') {
    if (datiFattura.totaleImporto >= 77.47) {
      if (datiFattura.identificativoMarcaDaBollo === '') {
        tester.consiglioPresente = true
        tester.messageConsiglio = componiStringa(
          tester.messageConsiglio,
          "La fattura è di un importo superiore ai 77,47€, ma non hai inserito l'identificativo della marca da bollo",
        )
      }
    } else {
      if (datiFattura.addebitoMarcaDaBollo) {
        tester.consiglioPresente = true
        tester.messageConsiglio = componiStringa(
          tester.messageConsiglio,
          'La fattura è di un importo inferiore ai 77,47€, ma hai addebitato il costo della marca da bollo',
        )
      }
      if (datiFattura.identificativoMarcaDaBollo !== '') {
        tester.consiglioPresente = true
        tester.messageConsiglio = componiStringa(
          tester.messageConsiglio,
          'La fattura è di un importo inferiore ai 77,47€, ma hai inserito un identificativo della marca da bollo',
        )
      }
    }
  }
  return tester
}

const consiglioPagamento = (datiFattura, tester) => {
  if (datiFattura.metodoDiPagamentoAttivo === 'Contante') {
    tester.consiglioPresente = true
    tester.messageConsiglio = componiStringa(
      tester.messageConsiglio,
      'La fattura verra pagata in contanti? Se è giusto clicca su CONTINUA COMUNQUE',
    )
  }
  return tester
}

const mostraErrore = (messaggio) => {
  swal({
    title: 'OOOPS...',
    text: messaggio,
    icon: 'error',
    button: 'OK',
  })
}

const mostraConsiglio = async (tester) =>
  swal({
    title: 'Da controllare...',
    text: tester.messageConsiglio,
    icon: 'info',
    buttons: ['CORREGGI', 'CONTINUA COMUNQUE'],
  }).then((isConfirm) => {
    if (isConfirm) {
      return tester
    } else {
      tester.errorePresente = true
      return tester
    }
  })

const componiStringa = (stringaAttuale, stringaDaAppendere) => {
  if (stringaAttuale === '') {
    return stringaDaAppendere
  }
  return stringaAttuale + '\n\n' + stringaDaAppendere
}

export const dataIncassoSezionaleBlocchettoValida = (dataIncasso) => {
  if (dataIncasso === null) {
    return false
  }
  if (dataIncasso === undefined) {
    return false
  }
  if (dataIncasso === '') {
    return false
  }
  if (
    new Date(dataIncasso) <
    new Date(new Date().setFullYear(new Date().getFullYear() - 5))
  ) {
    return false
  }
  if (
    new Date(dataIncasso) > new Date(new Date().setMonth(new Date().getMonth() + 6))
  ) {
    return false
  }
  return true
}
