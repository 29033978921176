import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../../sharedView/home/Home'
import Lead from '../../sharedView/lead/Lead'
import FatturePA from '../../admin/fatturePA/FatturePA'
import UserProfile from '../../sharedView/userProfile/UserProfile'
import Pending from '../../operation/pending/Pending'
import ControlloDocumenti from '../../sharedView/controlloDocumentiImportati/ControlloDocumenti'

const AdminRoutes = () => (
  <Switch>
    <Route exact path="/pending">
      <Pending />
    </Route>
    <Route exact path="/lead">
      <Lead />
    </Route>
    <Route exact path="/fatture-pa">
      <FatturePA />
    </Route>
    <Route path="/shared/user-profile/:medicoId">
      <UserProfile />
    </Route>
    <Route exact path="/checker">
      <ControlloDocumenti />
    </Route>
    <Route>
      <Home />
    </Route>
  </Switch>
)

export default AdminRoutes
