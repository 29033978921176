import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectIsMedico,
  selectIsMedicoMoroso,
  selectIsCommercialista,
  selectIsAdmin,
  selectIsContabile,
  selectIsOperation,
  selectIsTecnico,
} from '../../../app/store/user/selectors'
import MedicoNav from '../navBars/MedicoNav'
import MedicoMorosoNav from '../navBars/MedicoMorosoNav'
import CommercialistaNav from '../navBars/CommercialistaNav'
import AdminNav from '../navBars/AdminNav'
import ContabileNav from '../navBars/ContabileNav'
import OperationNav from '../navBars/OperationNav'
import TecnicoNav from '../navBars/TecnicoNav'

const PrivateNavContainer = () => {
  const isMedico = useSelector(selectIsMedico)
  const isMedicoMoroso = useSelector(selectIsMedicoMoroso)
  const isCommercialista = useSelector(selectIsCommercialista)
  const isAdmin = useSelector(selectIsAdmin)
  const isContabile = useSelector(selectIsContabile)
  const isOperation = useSelector(selectIsOperation)
  const isTecnico = useSelector(selectIsTecnico)

  return (
    <>
      {isMedico && <>{isMedicoMoroso ? <MedicoMorosoNav /> : <MedicoNav />}</>}
      {isCommercialista && <CommercialistaNav />}
      {isAdmin && <AdminNav />}
      {isContabile && <ContabileNav />}
      {isOperation && <OperationNav />}
      {isTecnico && <TecnicoNav />}
    </>
  )
}

export default PrivateNavContainer
