import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'
import {
  chiudiModalIncasso,
  aggiornaDataIncasso,
} from '../../../app/store/riepilogoFatture/slice'
import { incassaFattura } from '../../../app/store/riepilogoFatture/actions'

const ModalIncassoRiepilogo = () => {
  const dati = useSelector(selectRiepilogoFattureData)
  const dispatch = useDispatch()

  return (
    <Modal
      isOpen={dati.modalIncassoOpen}
      toggle={() => dispatch(chiudiModalIncasso())}
    >
      <ModalHeader
        className="font-medium"
        toggle={() => dispatch(chiudiModalIncasso())}
      >
        Incassa fattura
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Hai ricevuto il pagamento di questa fattura? Se sì, incassala per
          aggiornare la previsione delle tasse da pagare, per condividere le
          informazioni con il tuo commercialista e per tenere il conto dei compensi
          ricevuti.
        </h6>
        <br />
        <center>
          <h4>
            {dati.fatturaSelezionata?.cliente}{' '}
            {dati.fatturaSelezionata?.fattura?.totale} €
          </h4>
        </center>
        <br />
        <Form>
          <FormGroup>
            <Label>Data incasso</Label>
            <Input
              type="date"
              value={dati.fatturaSelezionata?.dataIncasso}
              onChange={(e) => dispatch(aggiornaDataIncasso(e.target.value))}
            />
          </FormGroup>
        </Form>
        <br />
        <Button
          className="float-end bottone-blu"
          onClick={() => {
            dispatch(incassaFattura())
            dispatch(chiudiModalIncasso())
          }}
        >
          INCASSA
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalIncassoRiepilogo
