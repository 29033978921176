import { createSlice } from '@reduxjs/toolkit'
import { emptyState, emptyDocumento } from './costants'
import * as actions from './actions'
import { groupDocuments } from '../../utility/Miscellanea'
import { documentToUploadIsValid } from '../../utility/ValidatoreDocumenti'

const initialState = {
  ...emptyState,
}

const fileManager = createSlice({
  name: 'fileManager',
  initialState,
  reducers: {
    setVisibleFilesType: (state, action) => {
      state.visibleFilesType = action.payload
      state.anno = new Date().getFullYear()
    },
    cambiaAnno: (state, action) => {
      state.anno = action.payload
    },
    setUserUploadModalIsOpen: (state, action) => {
      state.userUploadModalIsOpen = action.payload
      state.documento = emptyDocumento
    },
    changeDocumento: (state, action) => {
      state.documento = action.payload
      state.uploadButtonDisabled = !documentToUploadIsValid(action.payload)
    },
    changeDataDocumento: (state, action) => {
      const nuovoDocumento = { ...state.documento, data_incasso: action.payload }
      state.documento = nuovoDocumento
      state.uploadButtonDisabled = !documentToUploadIsValid(nuovoDocumento)
    },
    aggiornaDocumento: (state, action) => {
      state[action.payload.tipologiaDaRimuovere] = state[
        action.payload.tipologiaDaRimuovere
      ].filter((doc) => doc.id !== action.payload.documento.id)
      state[action.payload.documento.tipologia].push(action.payload.documento)
      state.grouped_documents = groupDocuments({
        fattura: state.fattura,
        cedolino: state.cedolino,
        borsa_mmg: state.borsa_mmg,
        borsa_specializzazione: state.borsa_specializzazione,
        prestazione_occasionale: state.prestazione_occasionale,
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.idrata.pending, (state) => {
      state.fattura = []
      state.cedolino = []
      state.borsa_mmg = []
      state.borsa_specializzazione = []
      state.prestazione_occasionale = []
      state.file_condiviso = []
      state.grouped_documents = {
        fattura: [],
        cedolino: [],
        borsa_mmg: [],
        borsa_specializzazione: [],
        prestazione_occasionale: [],
      }
    })
    builder.addCase(actions.idrata.fulfilled, (state, action) => {
      const {
        fattura,
        cedolino,
        borsa_mmg,
        borsa_specializzazione,
        prestazione_occasionale,
        file_condiviso,
      } = action.payload
      if (fattura) state.fattura = fattura
      if (cedolino) state.cedolino = cedolino
      if (borsa_mmg) state.borsa_mmg = borsa_mmg
      if (borsa_specializzazione)
        state.borsa_specializzazione = borsa_specializzazione
      if (prestazione_occasionale)
        state.prestazione_occasionale = prestazione_occasionale
      if (file_condiviso) state.file_condiviso = file_condiviso
      state.grouped_documents = groupDocuments(action.payload)
    })
    builder.addCase(actions.uploadFileCondiviso.pending, (state) => {
      state.documento.uploading = true
    })
    builder.addCase(actions.uploadFileCondiviso.fulfilled, (state, action) => {
      state.documento.uploading = false
      state.userUploadModalIsOpen = false
      state.file_condiviso.push(action.payload)
    })
    builder.addCase(actions.uploadFileBlocchetto.pending, (state) => {
      state.documento.uploading = true
    })
    builder.addCase(actions.uploadFileBlocchetto.fulfilled, (state, action) => {
      state.documento.uploading = false
      state.userUploadModalIsOpen = false
      state[action.payload.documento.tipologia].push(action.payload.documento)
      state.grouped_documents = groupDocuments({
        fattura: state.fattura,
        cedolino: state.cedolino,
        borsa_mmg: state.borsa_mmg,
        borsa_specializzazione: state.borsa_specializzazione,
        prestazione_occasionale: state.prestazione_occasionale,
      })
    })
    builder.addCase(actions.deleteFileBlocchetto.pending, (state) => {
      state.documento.uploading = true
    })
    builder.addCase(actions.deleteFileBlocchetto.fulfilled, (state, action) => {
      if (action.payload.id === null) return
      state[action.payload.tipologia] = state[action.payload.tipologia].filter(
        (doc) => doc.id !== action.payload.id,
      )
      state.grouped_documents = groupDocuments({
        fattura: state.fattura,
        cedolino: state.cedolino,
        borsa_mmg: state.borsa_mmg,
        borsa_specializzazione: state.borsa_specializzazione,
        prestazione_occasionale: state.prestazione_occasionale,
      })
    })
    builder.addCase(actions.deleteFileCondiviso.pending, (state) => {
      state.documento.uploading = true
    })
    builder.addCase(actions.deleteFileCondiviso.fulfilled, (state, action) => {
      if (action.payload.id === null) return
      state.file_condiviso = state.file_condiviso.filter(
        (doc) => doc.id !== action.payload.id,
      )
    })
  },
})

export const {
  setVisibleFilesType,
  cambiaAnno,
  setUserUploadModalIsOpen,
  changeDocumento,
  changeDataDocumento,
  aggiornaDocumento,
} = fileManager.actions

export default fileManager.reducer
