import React, { useEffect, useState } from 'react'
import {
  Card,
  Button,
  FormGroup,
  Input,
  Spinner,
  Row,
  Col,
  Progress,
  Form,
} from 'reactstrap'
import { emailCliente } from '../../../../app/utility/QueryFattura'
import { queryInviaEmail } from '../../../../app/utility/QueryRiepilogoFatture'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'

const InviaEmail = (props) => {
  const emailInviata = props.emailInviata
  const idFattura = props.idFattura
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useSelector(selectUser)

  useEffect(() => {
    emailCliente(user, idFattura).then((res) => {
      setEmail(res.email_cliente === null ? '' : res.email_cliente)
    })
  }, [])

  const inviaFattura = async () => {
    if (email === null || email === '') {
      swal({
        title: 'Attenzione',
        text: "Inserisci l'email del destinatario",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setLoading(true)
    const esito = await queryInviaEmail(user, idFattura, email)
    if (esito.email_inviata) {
      emailInviata()
    } else {
      setLoading(false)
    }
  }

  return (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={40} className="progress-gialla" />
          <h3 className="font-medium mt-4">
            Fattura elettronica trasmessa al Sistema di Interscambio 🎉
          </h3>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Ora lo SdI <span className="font-bold">controllerà</span> se la fattura
            contiene tutti i dati obbligatori ai fini fiscali e se la Partita IVA o
            il Codice Fiscale del cliente è davvero esistente.
            <br />
            Non appena lo SdI ci comunicherà l&apos;esito della tua fattura,
            riceverai una email di notifica. Ricorda che potrebbe metterci anche{' '}
            <span className="font-bold">5 giorni</span> per verificare la tua
            fattura.
          </h6>
        </div>
      </Card>
      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25">
            Se ti va, intanto puoi{' '}
            <span className="font-bold">inviare la fattura di cortesia</span> al tuo
            cliente. Per farlo ti basta scrivere la sua email qui accanto e cliccare
            sul tasto &quot;Invia fattura per email&quot;.
          </h6>
          <br />
          <div className="d-flex float-end">
            <FormGroup>
              <Input
                type="text"
                placeholder="Inserisci l'email del cliente"
                value={email === null ? '' : email}
                onChange={(e) => setEmail(e.target.value)}
                className="me-3"
                style={{ width: '300px' }}
              />
            </FormGroup>
            <div>
              <Button onClick={() => inviaFattura()} className="bottone-blu ms-3">
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  <>
                    <i className="bi bi-envelope"></i> INVIA FATTURA PER EMAIL
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            <span className="font-bold">Non vuoi farlo ora?</span> Nessun problema.
            Puoi sempre inviare la fattura in un secondo momento, dalla sezione
            &quot;Riepilogo fatture&quot;🤗
          </h6>
          <div className="float-end">
            <Button
              className="bottone-blu-outline mt-3 mb-3"
              onClick={() => emailInviata()}
            >
              SALTA E VAI AVANTI
            </Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default InviaEmail
