import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Input, Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { selectUser } from '../../../../app/store/user/selectors'
import SalvatoSulServer from '../../../sharedComponent/SalvatoSulServer'
import {
  salvaNoteDichiarazione,
  salvaResponsabile,
  salvaDifficolta,
} from '../../../../app/utility/QueryShared'
import { useDispatch } from 'react-redux'
import {
  modificaNoteDichiarazione,
  modificaResponsabile,
  modificaFacile,
} from '../../../../app/store/gestionaleUtente/slice'
import classNames from 'classnames'

const MetaDati = () => {
  const dati = useSelector(selectGestionaleUtente)
  const user = useSelector(selectUser)
  const [appuntiSalvati, setAppuntiSalvati] = useState(true)
  const [responsabileSalvato, setResponsabileSalvato] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [debounceId, setDebounceId] = useState(null)

  const aggiornaNote = (note) => {
    setAppuntiSalvati(false)
    dispatch(modificaNoteDichiarazione(note))
    clearTimeout(debounceId)
    setDebounceId(setTimeout(() => salvaAppuntiSulServer(note), 750))
  }

  const salvaAppuntiSulServer = (note) => {
    salvaNoteDichiarazione(user, dati.dichiarazione.id, note)
    setAppuntiSalvati(true)
  }

  const aggiornaResponsabile = (responsabile) => {
    setResponsabileSalvato(false)
    dispatch(modificaResponsabile(responsabile))
    clearTimeout(debounceId)
    setDebounceId(setTimeout(() => salvaResponsabileSulServer(responsabile), 750))
  }

  const salvaResponsabileSulServer = (note) => {
    salvaResponsabile(user, dati.dichiarazione.id, note)
    setResponsabileSalvato(true)
  }

  const salvaEAggiornaDifficolta = () => {
    dispatch(modificaFacile(!dati.dichiarazione?.facile))
    salvaDifficolta(user, dati.dichiarazione.id, !dati.dichiarazione.facile)
  }

  return (
    <>
      <Button
        size="sm"
        color="secondary"
        outline
        className="float-end mt-2"
        onClick={() => setModalIsOpen(true)}
      >
        <i
          className={classNames(
            'bi bi-circle-fill',
            { 'text-success': dati.dichiarazione?.facile },
            { 'text-warning': !dati.dichiarazione?.facile },
          )}
        ></i>{' '}
        {dati.dichiarazione?.responsabile?.substring(0, 9)}
      </Button>
      <Modal
        isOpen={modalIsOpen}
        size="xl"
        toggle={() => setModalIsOpen(!modalIsOpen)}
      >
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Info generali
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="4">
              <h5 className="font-regular d-inline-block">Responsabile</h5>{' '}
              <SalvatoSulServer
                className="d-inline-block"
                salvato={responsabileSalvato}
              />
            </Col>
            <Col xs="8"></Col>
            <Col xs="4">
              <Input
                type="text"
                bsSize="sm"
                name="responsabile"
                id="responsabile"
                value={dati.dichiarazione?.responsabile}
                onChange={(e) => aggiornaResponsabile(e.target.value)}
              />
            </Col>
            <Col xs="8">
              <Button
                size="sm"
                color="secondary"
                className="float-end"
                outline
                onClick={() => salvaEAggiornaDifficolta()}
              >
                <i
                  className={classNames(
                    'bi bi-circle-fill',
                    { 'text-success': dati.dichiarazione?.facile },
                    { 'text-warning': !dati.dichiarazione?.facile },
                  )}
                ></i>{' '}
                Dichiarazione {dati.dichiarazione?.facile ? 'facile' : 'difficile'}
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <h5 className="font-regular d-inline-block">Appunti dichiarazione</h5>{' '}
          <SalvatoSulServer className="d-inline-block" salvato={appuntiSalvati} />
          <Input
            type="textarea"
            name="note_admin"
            value={dati.dichiarazione?.note}
            onChange={(e) => aggiornaNote(e.target.value)}
            rows={10}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default MetaDati
