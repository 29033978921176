import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container, CardTitle } from 'reactstrap'
import { BloccoF24Modal } from './component/BloccoF24Modal'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { apriF24, apriUnico } from '../../app/store/fileViewer/actions'
import { selectUser } from '../../app/store/user/selectors'
import { queryIdrataArchivio } from '../../app/utility/QueryDichiarazione'

const Archivio = () => {
  const user = useSelector(selectUser)
  const [archivio, setArchivio] = useState([])
  const [bloccoF24ModalOpen, setBloccoF24ModalOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    queryIdrataArchivio(user).then((archivio) => setArchivio(archivio))
  }, [])

  const handleDownload = (f24) => {
    if (
      moment(f24.data_scadenza, 'DD/MM/YYYY').add(1000, 'day').isBefore(moment())
    ) {
      setBloccoF24ModalOpen(true)
    } else {
      dispatch(apriF24(f24.id))
    }
  }

  const documenti = () =>
    archivio?.map((dichiarazione, index) => (
      <div key={index} className="mt-4">
        <h4 className="testo-verde" style={{ fontWeight: 600 }}>
          {dichiarazione.anno} - Documenti della dichiarazione relativa al{' '}
          {dichiarazione.anno - 1}
        </h4>
        {dichiarazione.f24s?.map((f24, index) => (
          <div
            key={index}
            className="interlinea-35 testo-blu"
            style={{ cursor: 'pointer' }}
            onClick={() => handleDownload(f24)}
          >
            F24 con scadenza {f24.data_scadenza}
          </div>
        ))}
        {dichiarazione.f24s?.length === 0 &&
          'Non ci sono F24 per questa dichiarazione'}
        {dichiarazione.modello_redditi !== null && (
          <div
            className="interlinea-35 testo-blu"
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(apriUnico(dichiarazione.modello_redditi.id))}
          >
            Modello Redditi
          </div>
        )}
      </div>
    ))

  return (
    <Container>
      <div className="mt-4 mb-5">
        <Row>
          <Col xs={5}>
            <Card body style={{ height: '100%' }}>
              <center>
                <img
                  src="/img/dichiarazione/archivio.webp"
                  alt="archivio"
                  style={{ width: '60%' }}
                />
              </center>
            </Card>
          </Col>
          <Col xs={7}>
            <Card body>
              <CardTitle className="h3 font-grigio-scuro">
                <i className="bi bi-archive"></i> Archivio delle dichiarazioni
              </CardTitle>
              <h6 className="font-regular interlinea-30">
                In questa sezione sono conservati gli F24 e i Modelli Redditi degli
                anni passati. Se vuoi dargli un&apos;occhiata, li puoi scaricare
                facilmente.
              </h6>
            </Card>
            <Card
              body
              className="mt-4 px-5"
              style={{ color: '#fff', backgroundColor: '#66d98e' }}
            >
              <center>
                <h6
                  className="interlinea-30"
                  style={{ fontWeight: 600, fontSize: '1.1rem' }}
                >
                  <i>
                    Il Modello Redditi è la dichiarazione dei redditi delle persone
                    che hanno la Partita IVA
                  </i>
                </h6>
              </center>
            </Card>
          </Col>
        </Row>
        <Card body className="mt-4">
          {archivio?.length === 0 ? (
            <div className="alert alert-warning mt-5" role="alert">
              Non ci sono documenti nell&apos;archivio perché non hai mai fatto una
              dichiarazione con Fiscomed 😊
            </div>
          ) : (
            documenti()
          )}
          <br />
          <br />
        </Card>
      </div>
      <BloccoF24Modal
        isOpen={bloccoF24ModalOpen}
        onClose={() => setBloccoF24ModalOpen(false)}
      />
    </Container>
  )
}

export default Archivio
