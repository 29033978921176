export const emptyErrorState = {
  error: undefined,
  message_error: undefined,
}

export const emptyUserObject = {
  public_uuid: undefined,
  role: undefined,
  role_in_app: undefined,
  ...emptyErrorState,
}
