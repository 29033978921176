import React from 'react'
import { Container, Card, CardBody } from 'reactstrap'
import { Helmet } from 'react-helmet'

const ConfermaPrenotazione = () => {
  const smallScreen = window.innerWidth <= 992
  return (
    <>
      <Helmet>
        <title>Grazie</title>
        <meta property="title" content="Grazie" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container style={{ marginTop: '56px' }} className="mobile-container">
        <br />
        <Card className="my-5">
          <CardBody className={smallScreen ? 'p-4' : 'p-5'}>
            <span className="titolo2-mobile mb-5">
              Hai prenotato la videoconsulenza ✅
            </span>
            <br />
            <br />
            <h6 className="h6-landing">
              È tutto pronto: tra poco riceverai un’email con il{' '}
              <span className="font-bold">link di Google Meet</span> per accedere
              alla videoconsulenza il giorno dell'appuntamento.
            </h6>
            <br />
            <h6 className="h6-landing">
              📌 Tre consigli per sfruttare al meglio l’incontro:
            </h6>
            <h6 className="h6-landing">
              <ul>
                <li>
                  Assicurati di trovarti in un luogo con una{' '}
                  <i>buona connessione Internet</i>, così eviti problemi di linea.
                </li>
                <li>
                  Se non lo hai ancora fatto, dai un’occhiata alla{' '}
                  <i>
                    <a
                      href="https://fiscomed.it/documenti/guida_fiscale.pdf"
                      target="_blank"
                    >
                      guida fiscale
                    </a>
                  </i>{' '}
                  prima dell’appuntamento. Ti aiuterà ad arrivare con qualche
                  conoscenza in più e a rendere l’incontro ancora più utile.
                </li>
                <li>
                  Se c’è qualcosa che non ti è chiaro, segnati le domande da fare al
                  commercialista: questo è il momento giusto per risolvere i tuoi
                  primi dubbi.
                </li>
              </ul>
            </h6>
            <br />
            <h6 className="h6-landing">Buona videoconsulenza 🤗</h6>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default ConfermaPrenotazione
