import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  idrataFattura,
  idrataNotaDiVariazionePR,
  idrataNotaDiVariazionePA,
  idrataFatturaRettifica,
  finalizzaProforma,
  finalizzaFatturaCartacea,
  inviaFattureElettronica,
  inviaNotaDiCreditoPr,
  inviaNotaDiCreditoPa,
  inviaNotaDiDebitoPr,
  inviaFatturaPA,
  inviaAutofattura,
  queryAggiornaMetodoDiPagamento,
} from '../../utility/QueryFattura'
import {
  syncMetodiDiPagamento,
  cancellaMetodoDiPagamento,
} from '../../utility/QueryMiscellanea'
import { controlloFattura } from '../../utility/controllerApi'
import {
  idrataFatturaObject,
  idrataNDVariazioneObject,
  idrataFatturaDiRettificaObject,
  proformaObject,
  fatturaCartaceaObject,
  fatturaElettronicaObject,
  fatturaPAObject,
  rettificaPrObject,
  notaDiCreditoPrObject,
  notaDiCreditoPAObject,
  notaDiDebitoPrObject,
  autofatturaObject,
} from '../../utility/FiscomedDataObjects'
import { resetState } from './slice'

export const idrata = createAsyncThunk(
  'fiscomed/idrata',
  async (_, { getState }) => {
    const state = getState()
    const responseBody = await idrataFattura(state.user.user)
    return idrataFatturaObject(responseBody)
  },
)

export const idrataNDVariazionePr = createAsyncThunk(
  'fiscomed/idrataNDVariazionePr',
  async (idFattura, { getState }) => {
    const state = getState()
    const responseBody = await idrataNotaDiVariazionePR(state.user.user, idFattura)
    return idrataNDVariazioneObject(responseBody)
  },
)

export const idrataNDVariazionePa = createAsyncThunk(
  'fiscomed/idrataNDVariazionePa',
  async (idFattura, { getState }) => {
    const state = getState()
    const responseBody = await idrataNotaDiVariazionePA(state.user.user, idFattura)
    return idrataNDVariazioneObject(responseBody)
  },
)

export const idrataFatturaDiRettifica = createAsyncThunk(
  'fiscomed/idrataFatturaDiRettifica',
  async (idFattura, { getState }) => {
    const state = getState()
    const responseBody = await idrataFatturaRettifica(state.user.user, idFattura)
    return idrataFatturaDiRettificaObject(responseBody)
  },
)

export const inizializzaMetodoDiPagamento = createAsyncThunk(
  'fiscomed/inizializzaMetodoDiPagamento',
  async (_, { getState }) => {
    const state = getState()
    const mDiPagamento = await syncMetodiDiPagamento(state.user.user)
    return mDiPagamento
  },
)

export const rimuoviMetodoDiPagamento = createAsyncThunk(
  'fiscomed/rimuoviMetodoDiPagamento',
  async (idPagamento, { getState }) => {
    const state = getState()
    const pagamentiResponse = await cancellaMetodoDiPagamento(
      state.user.user,
      idPagamento,
    )
    return pagamentiResponse
  },
)

export const modificaMetodoDiPagamento = createAsyncThunk(
  'fiscomed/modificaMetodoDiPagamento',
  async (metodoDiPagamento, { getState }) => {
    const state = getState()
    const pagamentiResponse = await queryAggiornaMetodoDiPagamento(
      state.user.user,
      metodoDiPagamento,
    )
    return pagamentiResponse
  },
)

export const creaProforma = createAsyncThunk(
  'fiscomed/creaProforma',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const datiDaTrasmettere = proformaObject(state.fiscomed)
    const valore = await finalizzaProforma(state.user.user, datiDaTrasmettere)
    if (valore) {
      dispatch(resetState())
    }
    return valore
  },
)

export const creaFatturaCartacea = createAsyncThunk(
  'fiscomed/creaFatturaCartacea',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const datiDaTrasmettere = fatturaCartaceaObject(state.fiscomed)
    const tester = await controlloFattura(state.fiscomed, 'cartacea')

    if (tester.errorePresente) {
      return rejectWithValue('Errore nella fattura cartacea')
    } else {
      const idFattura = await finalizzaFatturaCartacea(
        state.user.user,
        datiDaTrasmettere,
      )
      if (idFattura) {
        dispatch(resetState())
        return idFattura
      } else {
        return rejectWithValue('Failed to create invoice')
      }
    }
  },
)

export const creaFatturaElettronica = createAsyncThunk(
  'fiscomed/creaFatturaElettronica',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const datiDaTrasmettere = fatturaElettronicaObject(state.fiscomed)
    const tester = await controlloFattura(state.fiscomed, 'elettronica')
    if (tester.errorePresente) {
      return rejectWithValue('Errore nella fattura elettronica')
    } else {
      const valore = await inviaFattureElettronica(
        state.user.user,
        datiDaTrasmettere,
      )
      if (valore) {
        dispatch(resetState())
      }
      return valore
    }
  },
)

export const creaFatturaPA = createAsyncThunk(
  'fiscomed/creaFatturaPA',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const datiDaTrasmettere = fatturaPAObject(state.fiscomed)
    const tester = await controlloFattura(state.fiscomed, 'PA')
    if (tester.errorePresente) {
      return rejectWithValue('Errore nella fattura PA')
    } else {
      const valore = await inviaFatturaPA(state.user.user, datiDaTrasmettere)
      if (valore) {
        dispatch(resetState())
      }
      return valore
    }
  },
)

export const creaRettificaPr = createAsyncThunk(
  'fiscomed/creaRettificaPr',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const datiDaTrasmettere = rettificaPrObject(state.fiscomed)
    const tester = await controlloFattura(state.fiscomed, 'elettronica')
    if (tester.errorePresente) {
      return rejectWithValue('Errore nella rettifica della fattura privata')
    } else {
      const valore = await inviaFattureElettronica(
        state.user.user,
        datiDaTrasmettere,
      )
      if (valore) {
        dispatch(resetState())
      }
      return valore
    }
  },
)

export const creaNotaDiCreditoPR = createAsyncThunk(
  'fiscomed/creaNotaDiCreditoPR',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const datiDaTrasmettere = notaDiCreditoPrObject(state.fiscomed)
    const valore = await inviaNotaDiCreditoPr(state.user.user, datiDaTrasmettere)
    if (valore) {
      dispatch(resetState())
    }
    return valore
  },
)

export const creaNotaDiCreditoPA = createAsyncThunk(
  'fiscomed/creaNotaDiCreditoPA',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const datiDaTrasmettere = notaDiCreditoPAObject(state.fiscomed)
    const valore = await inviaNotaDiCreditoPa(state.user.user, datiDaTrasmettere)
    if (valore) {
      dispatch(resetState())
    }
    return valore
  },
)

export const creaNotaDiDebitoPR = createAsyncThunk(
  'fiscomed/creaNotaDiDebitoPR',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const datiDaTrasmettere = notaDiDebitoPrObject(state.fiscomed)
    const valore = await inviaNotaDiDebitoPr(state.user.user, datiDaTrasmettere)
    if (valore) {
      dispatch(resetState())
    }
    return valore
  },
)

export const creaAutofattura = createAsyncThunk(
  'fiscomed/creaAutofattura',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const datiDaTrasmettere = autofatturaObject(state.fiscomed)
    const valore = await inviaAutofattura(state.user.user, datiDaTrasmettere)
    if (valore) {
      dispatch(resetState())
    }
    return valore
  },
)
