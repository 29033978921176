export const tipologiaAdapterFromTipologiaToText = (tipologia) => {
  if (tipologia === 'fattura') return 'Fattura'
  if (tipologia === 'cedolino') return 'Cedolino'
  if (tipologia === 'borsa_mmg') return 'Borsa MMG'
  if (tipologia === 'borsa_specializzazione') return 'Borsa specializzazione'
  if (tipologia === 'prestazione_occasionale') return 'Prestazione occasionale'
  if (tipologia === 'altro') return 'Altro'
}

export const tipologiaAdapterFromTextToTipologia = (text) => {
  if (text === 'Fattura') return 'fattura'
  if (text === 'Cedolino') return 'cedolino'
  if (text === 'Borsa MMG') return 'borsa_mmg'
  if (text === 'Borsa specializzazione') return 'borsa_specializzazione'
  if (text === 'Prestazione occasionale') return 'prestazione_occasionale'
  if (text === 'Altro') return 'altro'
}

export const documentFromBlocchettoToGestionaleAdapter = (document) => {
  return {
    id: document.id,
    numero: document.numero,
    data_emissione: document.data_incasso,
    data_incasso: document.data_incasso,
    trattenuta_enpam: document.trattenuta_enpam,
    trattenuta_irpef: document.trattenuta_irpef,
    importo: document.importo,
    cliente_id: null,
    nome: 'Esterno',
    p_iva: document.partita_iva,
    cf: document.codice_fiscale,
    tipologia: document.tipologia,
    nome_originale_file: document.nome_originale_file,
    inviata_ts: document.inviata_ts,
    fattura_elettronica: false,
    controllato: document.controllato,
    appunti_verifica: document.appunti_verifica,
  }
}
