import React, { useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import classNames from 'classnames'
import { cambiaStatoDichiarazione } from '../../../../app/store/gestionaleUtente/actions'

const StatoDichiarazione = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const cambiaStato = (nuovoStato) => {
    if (
      confirm(
        "Sei sicuro di voler cambiare lo stato della dichiarazione? Il cliente riceve un'email solo quando diventa dichiarante",
      )
    ) {
      dispatch(cambiaStatoDichiarazione(nuovoStato))
      setModalIsOpen(false)
    }
  }

  return (
    <>
      <Button
        color={classNames(
          { success: dati.dichiarazione?.attivo },
          { danger: dati.dichiarazione?.attivo === false },
          { warning: dati.dichiarazione?.attivo === null },
        )}
        size="sm"
        className="ms-2 mt-2"
        onClick={() => setModalIsOpen(true)}
      >
        {dati.dichiarazione?.attivo === true && 'Dichiarante'}
        {dati.dichiarazione?.attivo === false && 'Non dichiarante'}
        {dati.dichiarazione?.attivo === null && 'Da capire'}
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} size="sm">
        <ModalBody>
          <Button color="warning" onClick={() => cambiaStato(null)} block>
            Da capire
          </Button>
          <br />
          <Button
            color="danger"
            onClick={() => cambiaStato(false)}
            block
            className="mt-2"
          >
            Non dichiarante
          </Button>
          <br />
          <Button
            color="success"
            onClick={() => cambiaStato(true)}
            block
            className="mt-2"
          >
            Dichiarante
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default StatoDichiarazione
