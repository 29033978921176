import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ConfermaPassword from '../../login/ConfermaPassword'
import Login from '../../login/Login'
import Pagamento from '../../login/Pagamento'
import PagamentoMobile from '../../login/PagamentoMobile'
import ThankYouPage from '../../login/ThankYouPage'
import ScreenSelector from '../../landing/ScreenSelector'
import FiscomedDesktop from '../../landing/desktop/FiscomedDesktop'
import FiscomedMobile from '../../landing/mobile/FiscomedMobile'
import PrezziDesktop from '../../landing/desktop/PrezziDesktop'
import PrezziMobile from '../../landing/mobile/PrezziMobile'
import GuidaFiscaleMobile from '../../landing/mobile/GuidaFiscaleMobile '
import GuidaFiscaleDesktop from '../../landing/desktop/GuidaFiscaleDesktop'
import NewsLetterDesktop from '../../landing/desktop/NewsLetterDesktop'
import NewsLetterMobile from '../../landing/mobile/NewsLetterMobile'
import LoginMobile from '../../login/LoginMobile'
import ConfermaPasswordMobile from '../../login/ConfermaPasswordMobile'
import ConfermaPrenotazione from '../../login/ConfermaPrenotazione'
import LoginCommercialista from '../../login/LoginCommercialista'
import BlogHomeDesktop from '../../blog/BlogHomeDesktop'
import BlogHomeMobile from '../../blog/BlogHomeMobile'
import ArticoloDesktop from '../../blog/ArticoloDesktop'
import ArticoloMobile from '../../blog/ArticoloMobile'
import DoctorsaDesktop from '../../landing/desktop/DoctorsaDesktop'
import DoctorsaMobile from '../../landing/mobile/DoctorsaMobile'
import PrimaConsulenza from '../../pagineSingole/PrimaConsulenza'
import VideoIntroduzione from '../../landing/VideoIntroduzione'

const NotLoggedRoutes = () => (
  <Switch>
    <Route exact path="/blog/:categoriaPrimaria">
      <ScreenSelector>
        <BlogHomeMobile />
        <BlogHomeDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/blog/articolo/:url">
      <ScreenSelector>
        <ArticoloMobile />
        <ArticoloDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/auth-commercialista/:uuidUtente/:token">
      <LoginCommercialista />
    </Route>
    <Route exact path="/conferma-prenotazione">
      <ConfermaPrenotazione />
    </Route>
    <Route exact path="/cambio_password/:codice">
      <ScreenSelector>
        <ConfermaPasswordMobile />
        <ConfermaPassword />
      </ScreenSelector>
    </Route>
    <Route exact path="/pagamento/:periodo">
      <ScreenSelector>
        <PagamentoMobile />
        <Pagamento />
      </ScreenSelector>
    </Route>
    <Route exact path="/accesso">
      <ScreenSelector>
        <LoginMobile />
        <Login />
      </ScreenSelector>
    </Route>
    <Route exact path="/pagamento-riuscito">
      <ThankYouPage />
    </Route>
    <Route exact path="/">
      <ScreenSelector>
        <FiscomedMobile />
        <FiscomedDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/newsletter">
      <ScreenSelector>
        <NewsLetterMobile />
        <NewsLetterDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/prezzi">
      <ScreenSelector>
        <PrezziMobile />
        <PrezziDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/guida-fiscale">
      <ScreenSelector>
        <GuidaFiscaleMobile />
        <GuidaFiscaleDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/doctorsa">
      <ScreenSelector>
        <DoctorsaMobile />
        <DoctorsaDesktop />
      </ScreenSelector>
    </Route>
    <Route exact path="/video-introduttivo">
      <VideoIntroduzione />
    </Route>
    <Route exact path="/prima-consulenza/:publicUuid/:commercialista?">
      <PrimaConsulenza />
    </Route>
    <Route>
      <ScreenSelector>
        <FiscomedMobile />
        <FiscomedDesktop />
      </ScreenSelector>
    </Route>
  </Switch>
)

export default NotLoggedRoutes
