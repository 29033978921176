import React from 'react'
import { Button } from 'reactstrap'

const PositionController = ({ position, moveUp, moveDown }) => {
  return (
    <div className="font-regular">
      <Button
        color="secondary"
        outline
        disabled={position === 1}
        size="sm"
        onClick={() => moveUp()}
        className="me-2"
      >
        <i className="bi bi-arrow-up"></i>
      </Button>{' '}
      {position}{' '}
      <Button
        color="secondary"
        outline
        size="sm"
        className="ms-2"
        onClick={() => moveDown()}
      >
        <i className="bi bi-arrow-down"></i>
      </Button>
    </div>
  )
}

export default PositionController
