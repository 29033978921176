import React from 'react'
import { Container } from 'reactstrap'

const Chiuso = () => (
  <Container fluid>
    <div className="my-5">
      <h1 className="font-bold">Non è ancora il momento ⏱️ </h1>
      <br />
      <h6 className="font-regular interlinea-30 mb-3">
        Qui troverai un percorso a step per la dichiarazione dei redditi, ma per te{' '}
        <i>il processo non è ancora iniziato.</i> Per questo, la sezione è chiusa da
        una copertina.
      </h6>
      <h6 className="font-regular interlinea-30 mb-3">
        <span className="font-bold">Se dovrai farla, riceverai un'email</span> con
        tutte le istruzioni e ti guideremo passo passo. Così non devi preoccuparti di
        nulla.
      </h6>
      <h6 className="font-regular interlinea-30">
        Se hai aperto la Partita IVA quest&apos;anno e{' '}
        <i>non c'è alcun motivo per cui tu debba fare la dichiarazione,</i>{' '}
        <span className="font-bold">
          lo scoprirai direttamente in questa sezione
        </span>{' '}
        dopo qualche controllo 🤗
      </h6>
    </div>
  </Container>
)

export default Chiuso
