import React from 'react'
import { Spinner } from 'reactstrap'

const CentredLoading = () => (
  <div className="d-flex justify-content-center">
    <Spinner
      type="grow"
      className="mt-5"
      style={{
        height: '3rem',
        width: '3rem',
        color: '#0583f2',
      }}
    />
  </div>
)

export default CentredLoading
