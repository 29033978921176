import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../../sharedView/home/Home'
import Pending from '../../operation/pending/Pending'
import Commercialisti from '../../operation/commercialisti/Commercialisti'
import UserProfile from '../../sharedView/userProfile/UserProfile'
import Tappe from '../../sharedView/tappe/Tappe'
import LimitiIncasso from '../../operation/LimitiIncasso'
import Stripe from '../../operation/Stripe'
import MailChimp from '../../operation/MailChimp'
import Lead from '../../sharedView/lead/Lead'
import ControlloDocumenti from '../../sharedView/controlloDocumentiImportati/ControlloDocumenti'

const OperationRoutes = () => (
  <Switch>
    <Route exact path="/pending">
      <Pending />
    </Route>
    <Route exact path="/commercialisti">
      <Commercialisti />
    </Route>
    <Route path="/shared/user-profile/:medicoId">
      <UserProfile />
    </Route>
    <Route exact path="/operation/tappe-dichiarazione">
      <Tappe />
    </Route>
    <Route exact path="/operation/limiti-incasso">
      <LimitiIncasso />
    </Route>
    <Route exact path="/operation/abbonamenti-stripe">
      <Stripe />
    </Route>
    <Route exact path="/operation/mailchimp">
      <MailChimp />
    </Route>
    <Route exact path="/lead">
      <Lead />
    </Route>
    <Route exact path="/checker">
      <ControlloDocumenti />
    </Route>
    <Route>
      <Home />
    </Route>
  </Switch>
)

export default OperationRoutes
