import React, { useEffect, useCallback } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { saveAs } from 'file-saver'
import { useSelector, useDispatch } from 'react-redux'
import { selectFileViewer } from '../../app/store/fileViewer/selectors'
import { close } from '../../app/store/fileViewer/slice'

const FileViewer = () => {
  const data = useSelector(selectFileViewer)
  const dispatch = useDispatch()

  const acceptedFiles = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif',
    'image/bmp',
    'image/x-icon',
    'image/tiff',
    'image/heic',
    'image/heif',
  ]

  const downloadFileFromUrl = useCallback(() => {
    if (!data?.fileObject?.url || !data?.fileObject?.nome_file) return
    fetch(data.fileObject.url)
      .then((response) => {
        if (!response.ok) throw new Error('Errore durante il download del file')
        return response.blob()
      })
      .then((blobFile) => {
        const file = new File([blobFile], data.fileObject.nome_file)
        saveAs(file)
      })
      .catch((error) => {
        console.error('Errore durante il download del file:', error)
        alert('Si è verificato un errore durante il download del file.')
      })
  }, [data])

  useEffect(() => {
    if (
      data?.modalIsOpen &&
      !acceptedFiles.includes(data?.fileObject?.content_type)
    ) {
      downloadFileFromUrl()
      dispatch(close())
    }
  }, [data, acceptedFiles, downloadFileFromUrl, dispatch])

  const isFileViewable =
    acceptedFiles.includes(data?.fileObject?.content_type) &&
    (data?.isS3URL || data?.isBASE64)

  return (
    <>
      {isFileViewable && (
        <Modal
          isOpen={data?.modalIsOpen}
          fullscreen
          toggle={() => dispatch(close())}
          scrollable
        >
          <ModalHeader toggle={() => dispatch(close())}>File</ModalHeader>
          <ModalBody>
            <object
              data={data?.isBASE64 ? data.base64string : data?.fileObject?.url}
              type={data?.fileObject?.content_type}
              width="100%"
              height="100%"
              name={data?.fileObject?.nome_file}
            >
              <p>
                Il tuo browser non supporta la visualizzazione di questo file. Puoi
                scaricarlo utilizzando il link o il pulsante sottostante.
              </p>
              <ul>
                <li>
                  Scarica tramite il link{' '}
                  <a href={data?.fileObject?.url} target="_blank" rel="noreferrer">
                    {data?.fileObject?.nome_file}
                  </a>
                </li>
                <br />
                <li>
                  Oppure scarica con il bottone
                  <Button
                    className="ms-3"
                    color="primary"
                    onClick={downloadFileFromUrl}
                  >
                    <i className="bi bi-cloud-arrow-down"></i> Scarica
                  </Button>
                </li>
              </ul>
            </object>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default FileViewer
