import React, { useState, useEffect } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'
import moment from 'moment'
import ModificaEmail from './ModificaEmail'
import { salvaDatiMedicoDaAttivare } from '../../../../../app/utility/QueryShared'
import { modificaAnagrafica } from '../../../../../app/store/gestionaleUtente/slice'
import { selectUser } from '../../../../../app/store/user/selectors'

const Anagrafica = () => {
  const dati = useSelector(selectGestionaleUtente)
  const user = useSelector(selectUser)
  const [offcanvasOpen, setOffcanvasOpen] = useState(false)
  const [medico, setMedico] = useState(dati.anagrafica)
  const [email, setEmail] = useState(dati.anagrafica.email)
  const dispatch = useDispatch()

  const annullaModifiche = () => setMedico(dati.anagrafica)

  useEffect(() => {
    setMedico(dati.anagrafica)
    setEmail(dati.anagrafica.email)
  }, [dati.anagrafica])

  const salvaModifiche = () => {
    salvaDatiMedicoDaAttivare(user, medico)
    dispatch(modificaAnagrafica(medico))
  }

  return (
    <>
      <Button
        color="secondary"
        outline
        className="me-2"
        size="sm"
        onClick={() => setOffcanvasOpen(true)}
        disabled={dati.loading}
      >
        <i className="bi bi-person-lines-fill"></i> Anagrafica
      </Button>
      <Offcanvas
        direction="start"
        scrollable
        isOpen={offcanvasOpen}
        className="w-50"
        toggle={() => setOffcanvasOpen(!offcanvasOpen)}
      >
        <OffcanvasHeader toggle={() => setOffcanvasOpen(!offcanvasOpen)}>
          Anagrafica
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={user.role !== 'admin' && user.role !== 'operation'}
                />
              </FormGroup>
            </Col>
            <Col xs={8}>
              <ModificaEmail email={email} />
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="pec">
                  PEC{' '}
                  <small className="text-muted">
                    (inserire pec@pec.it se mancante)
                  </small>
                </Label>
                <Input
                  type="text"
                  name="pec"
                  id="pec"
                  value={medico.pec}
                  onChange={(e) => setMedico({ ...medico, pec: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="telefono">Telefono</Label>
                <Input
                  type="text"
                  name="telefono"
                  id="telefono"
                  value={medico.telefono}
                  onChange={(e) =>
                    setMedico({ ...medico, telefono: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Label for="nome">Nome</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  value={medico.nome}
                  onChange={(e) => setMedico({ ...medico, nome: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="cognome">Cognome</Label>
                <Input
                  type="text"
                  name="cognome"
                  id="cognome"
                  value={medico.cognome}
                  onChange={(e) => setMedico({ ...medico, cognome: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="data_di_nascita">Data di nascita</Label>
                <Input
                  type="date"
                  name="data_di_nascita"
                  id="data_di_nascita"
                  value={moment(medico.data_di_nascita).format('YYYY-MM-DD')}
                  onChange={(e) =>
                    setMedico({ ...medico, data_di_nascita: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="cf">Codice fiscale</Label>
                <Input
                  type="text"
                  name="cf"
                  id="cf"
                  value={medico.cf}
                  onChange={(e) =>
                    setMedico({ ...medico, cf: e.target.value.toUpperCase() })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Label for="indirizzo">Indirizzo</Label>
                <Input
                  type="text"
                  name="indirizzo"
                  id="indirizzo"
                  value={medico.indirizzo}
                  onChange={(e) =>
                    setMedico({ ...medico, indirizzo: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="comune">Comune</Label>
                <Input
                  type="text"
                  name="comune"
                  id="comune"
                  value={medico.comune}
                  onChange={(e) => setMedico({ ...medico, comune: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="provincia">Provincia</Label>
                <Input
                  type="text"
                  name="provincia"
                  id="provincia"
                  value={medico.provincia}
                  onChange={(e) =>
                    setMedico({
                      ...medico,
                      provincia: e.target.value.toUpperCase().substring(0, 2),
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="cap">CAP</Label>
                <Input
                  type="text"
                  name="cap"
                  id="cap"
                  value={medico.cap}
                  onChange={(e) => setMedico({ ...medico, cap: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="cap">Ordine di riferimento</Label>
                <Input
                  type="text"
                  name="ordine"
                  id="ordine"
                  value={medico.ordine}
                  onChange={(e) => setMedico({ ...medico, ordine: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Label for="partita_iva">Numero partita IVA</Label>
                <Input
                  type="text"
                  name="partita_iva"
                  id="partita_iva"
                  value={medico.partita_iva}
                  onChange={(e) =>
                    setMedico({ ...medico, partita_iva: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="codice_ateco">Codice ATECO</Label>
                <Input
                  type="text"
                  name="codice_ateco"
                  id="codice_ateco"
                  value={medico.ateco}
                  onChange={(e) => setMedico({ ...medico, ateco: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="data_apertuta_p_iva">Data apertura partita IVA</Label>
                <Input
                  type="date"
                  name="data_apertura_p_iva"
                  id="data_apertura_p_iva"
                  value={moment(medico.data_apertura_p_iva).format('YYYY-MM-DD')}
                  onChange={(e) =>
                    setMedico({ ...medico, data_apertura_p_iva: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="cap">Partita IVA aperta con il 5%?</Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  defaultValue={medico.aliquota_ridotta ? 'si' : 'no'}
                  onChange={(e) =>
                    setMedico({
                      ...medico,
                      aliquota_ridotta: e.target.value === 'si',
                    })
                  }
                >
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          {medico !== dati.anagrafica && (
            <div className="alert alert-secondary d-flex justify-content-between">
              <p className="font-regular">
                <i className="bi bi-x text-danger"></i> Modifche non salvate
              </p>
              <div>
                <Button color="warning" onClick={annullaModifiche}>
                  <i className="bi bi-arrow-counterclockwise"></i> Annulla modifiche
                </Button>
                <Button
                  color="success"
                  className="ms-2"
                  onClick={() => salvaModifiche()}
                >
                  <i className="bi bi-floppy"></i> Salva modifiche
                </Button>
              </div>
            </div>
          )}
        </OffcanvasBody>
      </Offcanvas>
    </>
  )
}

export default Anagrafica
