import React from 'react'
import { Card, Badge } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { apriFatturaAdE } from '../../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../../app/store/fileViewer/selectors'
import classNames from 'classnames'

const FattureElettronicheRicevute = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingFile)

  return (
    <Card body className="mt-4">
      <span className="h5">Fatture elettroniche ricevute {dati.anno}</span>
      {dati.cassetto?.last_sync !== null && (
        <>
          {dati.cassetto?.fatture_elettroniche?.length > 0 ? (
            <table className="table table-striped table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Data</th>
                  <th scope="col">Tipo</th>
                  <th scope="col"></th>
                  <th scope="col">Numero</th>
                  <th scope="col">Importo</th>
                  <th scope="col">Denominazione</th>
                  <th scope="col">CF</th>
                  <th scope="col">PIVA</th>
                </tr>
              </thead>
              <tbody>
                {dati.cassetto?.fatture_elettroniche?.map((fattura, index) => {
                  return (
                    <tr key={index}>
                      <td>{fattura.data}</td>
                      <td>{fattura.tipo}</td>
                      <td>
                        {fattura.nome_file && (
                          <Badge
                            color={classNames({
                              primary: !loading,
                              secondary: loading,
                            })}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (loading) return
                              dispatch(
                                apriFatturaAdE('fatture/' + fattura.nome_file),
                              )
                            }}
                          >
                            <i className="bi bi-eye"></i>
                          </Badge>
                        )}
                      </td>
                      <td>{fattura.numero}</td>
                      <td>{fattura.importo}</td>
                      <td>{fattura.denominazione}</td>
                      <td>{fattura.codice_fiscale}</td>
                      <td>{fattura.partita_iva}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-secondary mt-3" role="alert">
              Non risultano fatture ricevute nel {dati.anno}
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default FattureElettronicheRicevute
