import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import request from 'superagent'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import Footer from '../landing/Footer'
import ChiamataDesktop from './componenti/ChiamataDesktop'
import NewsLetterDesktop from './componenti/NewsletterDesktop'
import GuidaDesktop from './componenti/GuidaDesktop'
import VerificatoDesktop from './componenti/VerificatoDesktop'

const ArticoloDesktop = () => {
  const [dati, setDati] = useState({})
  const { url } = useParams()
  const history = useHistory()
  const arrayLead = [
    <ChiamataDesktop key="1" />,
    <NewsLetterDesktop key="2" />,
    <GuidaDesktop key="3" />,
  ]
  const lead = arrayLead[Math.floor(Math.random() * arrayLead.length)]

  useEffect(() => {
    request
      .get(`${process.env.REACT_APP_API_URL}/blog/show-articolo/${url}`)
      .then((response) => setDati(response.body))
      .catch((e) => {
        console.error(e.message)
      })
  }, [url])

  return (
    <>
      <Helmet>
        <title>{dati.articolo?.titolo}</title>
        <meta property="title" content={dati.articolo?.titolo} />
        <meta property="og:site_name" content="fiscomed.it" />
        <meta
          property="og:url"
          content={'https://fiscomed.it/blog/articolo/' + dati.articolo?.url}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={dati.articolo?.titolo} />
        <meta property="og:description" content={dati.articolo?.descrizione} />
        <meta property="og:image" content={dati.url_immagine} />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }}>
        <br />
        <br />
        <center>
          <h6 className="testo-landing mt-5">
            {moment(dati.articolo?.data_aggiornamento)
              .locale('it')
              .format('D MMMM YYYY')}
          </h6>
          <h1 className="titolo-landing-fiscomed font-medium">
            {dati.articolo?.titolo}
          </h1>
          <br />
          <h6 className="testo-landing mb-5 interlinea-30">
            {dati.articolo?.descrizione}
          </h6>
        </center>
        <br />
        <img
          src={dati.url_immagine}
          alt="Immagine articolo"
          style={{ width: '100%' }}
        />
        <div className="d-flex justify-content-between my-4">
          <h6 className="testo-landing testo-blu">
            <i className="bi bi-stopwatch"></i>{' '}
            {parseInt(dati.articolo?.testo.length / 1260) + 1}{' '}
            {parseInt(dati.articolo?.testo.length / 1260) === 0
              ? 'minuto'
              : 'minuti'}{' '}
            di lettura
          </h6>
          <div>
            {dati.tag_associati && dati.tag_associati[0]?.nome && (
              <div
                type="button"
                onClick={() => history.push('/blog/' + dati.tag_associati[0]?.nome)}
                style={{
                  backgroundColor: '#66d98e',
                  color: 'white',
                  padding: '6px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '7px',
                  display: 'inline-block',
                  marginRight: '15px',
                  fontWeight: 500,
                }}
              >
                {dati.tag_associati[0]?.nome}
              </div>
            )}
            {dati.tag_associati && dati.tag_associati[1]?.nome && (
              <div
                type="button"
                onClick={() => history.push('/blog/' + dati.tag_associati[1]?.nome)}
                style={{
                  backgroundColor: '#66d98e',
                  color: 'white',
                  padding: '6px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '7px',
                  display: 'inline-block',
                  fontWeight: 500,
                }}
              >
                {dati.tag_associati[1]?.nome}
              </div>
            )}
          </div>
        </div>
        <div className="articolo-blog-desktop my-5">
          {dati.articolo && parse(dati.articolo?.testo_html)}
        </div>
        <br />
        <VerificatoDesktop commercialista={dati.articolo?.commercialista} />
        <br />
        <br />
        <br />
      </Container>
      {lead}
      <Footer />
    </>
  )
}

export default ArticoloDesktop
