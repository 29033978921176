import React, { useEffect, useState } from 'react'
import { Card, Container, Button, Input } from 'reactstrap'
import { queryIdrataLimitiIncasso } from '../../app/utility/QueryOperation'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import CentredLoading from '../component/CentredLoading'
import { CSVLink } from 'react-csv'

const LimitiIncasso = () => {
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const [anno, setAnno] = useState('anno_corrente')
  const [datiGrezzi, setDatiGrezzi] = useState([])
  const [datiFiltrati, setDatiFiltrati] = useState([])
  const [limite, setLimite] = useState(15000)

  useEffect(() => {
    queryIdrataLimitiIncasso(user).then((response) => {
      setDatiGrezzi(response.dati)
      filtraDati(response.dati)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    filtraDati(datiGrezzi)
  }, [limite, anno])

  const filtraDati = (data) => {
    if (anno === 'anno_corrente') {
      setDatiFiltrati(
        data.filter(
          (d) => d.anno_corrente.limite - d.anno_corrente.incassato < limite,
        ),
      )
    } else {
      setDatiFiltrati(
        data.filter(
          (d) => d.anno_precedente.limite - d.anno_precedente.incassato < limite,
        ),
      )
    }
  }

  const tableDataAnnoCorrente = datiFiltrati.map((d) => (
    <tr key={d.id}>
      <td>{d.id}</td>
      <td>{d.email}</td>
      <td>{d.nome}</td>
      <td>{d.anno_corrente.limite}</td>
      <td>{d.anno_corrente.incassato}</td>
    </tr>
  ))

  const tableDataAnnoPrecedente = datiFiltrati.map((d) => (
    <tr key={d.id}>
      <td>{d.id}</td>
      <td>{d.email}</td>
      <td>{d.nome}</td>
      <td>{d.anno_precedente.limite}</td>
      <td>{d.anno_precedente.incassato}</td>
    </tr>
  ))

  const csvDataAdapter = (data) =>
    data.map((d) => ({
      Nome: d.nome,
      Email: d.email,
      Id: d.id,
      'Incassato anno corrente': d.anno_corrente.incassato,
      'Limite anno corrente': d.anno_corrente.limite,
      'Incassato anno precedente': d.anno_precedente.incassato,
      'Limite anno precedente': d.anno_precedente.limite,
    }))

  return (
    <Container fluid className="mt-4">
      {loading ? (
        <CentredLoading />
      ) : (
        <>
          <Card body className="mb-3">
            <div className="row g-3 align-items-center">
              <div className="col-auto">
                <Input
                  className="form-control"
                  bsSize="sm"
                  type="number"
                  value={limite}
                  onChange={(e) => setLimite(e.target.value)}
                />
              </div>
              <div className="col-auto">
                <label className="col-form-label">Limite incasso</label>
              </div>
              <div className="col-auto">
                <CSVLink
                  data={csvDataAdapter(datiFiltrati)}
                  filename="limiti_incasso.csv"
                >
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  className="me-2"
                  disabled={anno === 'anno_corrente'}
                  onClick={() => setAnno('anno_corrente')}
                >
                  Anno corrente
                </Button>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  disabled={anno === 'anno_precedente'}
                  onClick={() => setAnno('anno_precedente')}
                >
                  Anno precedente
                </Button>
              </div>
            </div>
            <br />
            {datiFiltrati.length === 0 ? (
              <div className="alert alert-info mt-3" role="alert">
                Non ci sono medici che si sono avvicinati così tanto al limite di
                incasso
              </div>
            ) : (
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Email</th>
                    <th>Nome</th>
                    <th>Limite</th>
                    <th>Incassato</th>
                  </tr>
                </thead>
                <tbody>
                  {anno === 'anno_corrente'
                    ? tableDataAnnoCorrente
                    : tableDataAnnoPrecedente}
                </tbody>
              </table>
            )}
          </Card>
        </>
      )}
    </Container>
  )
}

export default LimitiIncasso
