export const selectGestionaleUtente = (state) => state.gestionaleUtente
export const useMedicoId = (state) => state.gestionaleUtente.medico_id
export const useAnagrafica = (state) => state.gestionaleUtente.anagrafica
export const numeroDocLP = (state) =>
  state.gestionaleUtente.incassato?.fattura_fiscomed?.length +
  state.gestionaleUtente.incassato?.fattura?.length +
  state.gestionaleUtente.incassato?.cedolino?.length
export const numeroBorse = (state) =>
  state.gestionaleUtente.incassato?.borsa_mmg?.length +
  state.gestionaleUtente.incassato?.borsa_specializzazione?.length
export const selectDichiarazione = (state) => state.gestionaleUtente.dichiarazione
