import { createSlice } from '@reduxjs/toolkit'
import {
  emptyState,
  emptyStateIdratato,
  aggiornaTipologiaFatturaObject,
} from './constants'
import * as actions from './actions'
import { totaleImporto, totaleImportoAutofattura } from '../../utility/Miscellanea'
// controllare la ritenuta enpam se funziona bene o meno
// fare il check delle funzioni su file precedente

const initialState = {
  ...emptyState,
}

const fiscomedSlice = createSlice({
  name: 'fiscomed',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    aggiornaNumeroFattura(state, action) {
      state.numeroFattura = action.payload
    },
    aggiornaNumeroAutofattura(state, action) {
      state.numeroAutofattura = action.payload
    },
    aggiornaDataFattura(state, action) {
      state.dataFattura = action.payload
    },
    aggiornaMetodoDiPagamento(state, action) {
      state.metodoDiPagamento = action.payload.metodidipagamento
      state.metodoDiPagamentoAttivo = action.payload.metodo_aggiunto
    },
    aggiornaClienti(state, action) {
      state.clienti = action.payload.clienti
      const clienteAttivo = state.clienti.find(
        (cliente) => cliente.id === action.payload.clienteAttivo,
      )
      state.clienteAttivo = clienteAttivo
    },
    aggiornaClienteAttivo(state, action) {
      state.clienteAttivo = state.clienti[action.payload]
    },
    aggiornaClienteAttivoDaId(state, action) {
      state.clienteAttivo = state.clienti.find(
        (cliente) => cliente.id === action.payload,
      )
    },
    aggiungiPrestazioni(state, action) {
      // sembra che non ci siano problemi di esecuzione, comunque non funziona con assegnazione di variabili
      // esempio: newPrestazioni = state.prestazioni.push(action.payload)
      // viene usato Immer per la gestione dello stato
      // indagare su come funziona immer
      state.prestazioni.push(action.payload)
      state.totaleImporto = totaleImporto(
        state.prestazioni,
        state.addebitoMarcaDaBollo,
      )
    },
    aggiungiPrestazioneAutofattura(state, action) {
      state.prestazioniAutofattura.push(action.payload)
      const importo = totaleImportoAutofattura(state.prestazioniAutofattura)
      state.totaleImportoAutofattura = importo.somma
      state.IVATotale = importo.sommaIva
    },
    rimuoviPrestazione(state, action) {
      state.prestazioni = state.prestazioni.filter(
        (_, index) => index !== action.payload,
      )
      state.totaleImporto = totaleImporto(
        state.prestazioni,
        state.addebitoMarcaDaBollo,
      )
    },
    rimuoviPrestazioneAutofattura(state, action) {
      state.prestazioniAutofattura = state.prestazioniAutofattura.filter(
        (_, index) => index !== action.payload,
      )
      const importo = totaleImportoAutofattura(state.prestazioniAutofattura)
      state.totaleImporto = importo.somma
      state.totaleImportoIVA = importo.sommaIva
    },
    aggiornaTipologiaFattura(state, action) {
      Object.assign(state, aggiornaTipologiaFatturaObject(action.payload))
    },
    aggiornaMetodoDiPagamentoAttivo(state, action) {
      state.metodoDiPagamentoAttivo = state.metodoDiPagamento[action.payload]
    },
    aggiornaMetodoDiPagamentoAttivoDaID(state, action) {
      if (action.payload === 'Contante') {
        state.metodoDiPagamentoAttivo = 'Contante'
      } else {
        state.metodoDiPagamentoAttivo = state.metodoDiPagamento.find(
          (metodo) => metodo.id === action.payload,
        )
      }
    },
    aggiornaNota(state, action) {
      // vedere se c'è lo strip
      state.nota = action.payload
      state.notaPresente = action.payload.length > 0
    },
    aggiornaAddebitoMarcaDaBollo(state, action) {
      state.addebitoMarcaDaBollo = action.payload
      state.totaleImporto = totaleImporto(
        state.prestazioni,
        state.addebitoMarcaDaBollo,
      )
    },
    aggiornaIdentificativoMarcaDaBollo(state, action) {
      state.identificativoMarcaDaBollo = action.payload
    },
    aggiornaDatiAggiuntivi(state, action) {
      // vedere se viene passato l'object intero
      state.datiAggiuntivi = action.payload
    },
    aggiornaDescrzioneNotaDiVariazione(state, action) {
      state.descrizioneNotaDiVariazione = action.payload
    },
    aggiornaImportoNotaDiVariazione(state, action) {
      state.importoNotaDiVariazione = action.payload
    },
    aggiornaTipologiaAutofattura(state, action) {
      // cosa è la tipologia autofattura?
      state.tipologiaAutofattura = action.payload
    },
    aggiornaIdDocumentoAutofattura(state, action) {
      state.idDocumentoAutofattura = action.payload
    },
    aggiornaDataDocumentoAutofattura(state, action) {
      state.dataDocumentoAutofattura = action.payload
    },
    resetState(state) {
      Object.assign(state, emptyStateIdratato(state))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.idrata.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(actions.idrataNDVariazionePr.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(actions.idrataNDVariazionePa.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(actions.idrataFatturaDiRettifica.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(actions.inizializzaMetodoDiPagamento.fulfilled, (state, action) => {
        state.metodoDiPagamento = action.payload
      })
      .addCase(actions.rimuoviMetodoDiPagamento.fulfilled, (state, action) => {
        state.metodoDiPagamento = action.payload.metodidipagamento
      })
      .addCase(actions.modificaMetodoDiPagamento.fulfilled, (state, action) => {
        state.metodoDiPagamento = action.payload.metodidipagamento
      })
      .addCase(actions.creaProforma.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaFatturaCartacea.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaFatturaElettronica.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaFatturaPA.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaRettificaPr.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaNotaDiCreditoPR.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaNotaDiCreditoPA.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaNotaDiDebitoPR.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
      .addCase(actions.creaAutofattura.fulfilled, (state) => {
        Object.assign(state, emptyStateIdratato(state))
      })
  },
})

export const {
  setLoading,
  aggiornaNumeroFattura,
  aggiornaNumeroAutofattura,
  aggiornaDataFattura,
  aggiornaMetodoDiPagamento,
  aggiornaClienti,
  aggiornaClienteAttivo,
  aggiornaClienteAttivoDaId,
  aggiungiPrestazioni,
  aggiungiPrestazioneAutofattura,
  rimuoviPrestazione,
  rimuoviPrestazioneAutofattura,
  aggiornaTipologiaFattura,
  aggiornaMetodoDiPagamentoAttivo,
  aggiornaMetodoDiPagamentoAttivoDaID,
  aggiornaNota,
  aggiornaAddebitoMarcaDaBollo,
  aggiornaIdentificativoMarcaDaBollo,
  aggiornaDatiAggiuntivi,
  aggiornaDescrzioneNotaDiVariazione,
  aggiornaImportoNotaDiVariazione,
  aggiornaTipologiaAutofattura,
  aggiornaIdDocumentoAutofattura,
  aggiornaDataDocumentoAutofattura,
  resetState,
} = fiscomedSlice.actions

export default fiscomedSlice.reducer
