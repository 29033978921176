import React, { useEffect, useState } from 'react'
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import { selectUser } from '../../../../../app/store/user/selectors'
import classNames from 'classnames'
import {
  uploadDocumentoCondiviso,
  deleteDocumentoCondiviso,
} from '../../../../../app/utility/QueryShared'
import { caricaCertificatoAttribuzione } from '../../../../../app/store/gestionaleUtente/slice'
import { apriFileCondiviso } from '../../../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../../../app/store/fileViewer/selectors'
import moment from 'moment'

const CertificatoDiAttribuzione = () => {
  const dati = useSelector(selectGestionaleUtente)
  const user = useSelector(selectUser)
  const loadingFile = useSelector(selectLoadingFile)
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [loading, setLoading] = useState(false)

  const uploadFile = () => {
    setLoading(true)
    uploadDocumentoCondiviso(user, {
      file: fileToUpload,
      medico_id: dati.medico_id,
      nota: null,
      file_type: 'certificato_attribuzione',
    }).then((bodyResponse) => {
      dispatch(
        caricaCertificatoAttribuzione({
          nome_file: bodyResponse.nome_file,
          id: bodyResponse.id,
          created_at: bodyResponse.created_at,
        }),
      )
      setLoading(false)
    })
  }

  const deleteFile = () => {
    if (confirm('Sei sicuro di voler eliminare il file?')) {
      deleteDocumentoCondiviso(user, dati.certificato_attribuzione.id).then(() =>
        dispatch(caricaCertificatoAttribuzione(null)),
      )
    }
  }

  return (
    <>
      <Button
        color="secondary"
        outline
        className="me-2"
        size="sm"
        onClick={() => setModalIsOpen(true)}
        disabled={dati.loading}
      >
        <i
          className={classNames(
            'bi bi-patch-check',
            { 'text-success': dati.certificato_attribuzione !== null },
            { 'text-danger': dati.certificato_attribuzione === null },
          )}
        ></i>{' '}
        Cer attribuzione
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Certificato di attribuzione
        </ModalHeader>
        <ModalBody>
          {dati.certificato_attribuzione ? (
            <div className="font-regular">
              Data di caricamento:{' '}
              <span className="text-muted">
                {moment(dati.certificato_attribuzione.created_at).format(
                  'DD/MM/YYYY',
                )}
              </span>
              <br />
              Nome file:{' '}
              <span className="text-muted">
                {dati.certificato_attribuzione.nome_file}
              </span>
              <br />
              <br />
              <Button
                color="primary"
                className="float-end"
                onClick={() =>
                  dispatch(apriFileCondiviso(dati.certificato_attribuzione.id))
                }
                disabled={loadingFile}
              >
                <i className="bi bi-eye"></i> Visualizza
              </Button>
              <Button
                color="danger"
                onClick={() => deleteFile()}
                className="float-end me-2"
              >
                <i className="bi bi-trash3"></i>
              </Button>
            </div>
          ) : (
            <>
              <FormGroup className="d-flex">
                <Input
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) => setFileToUpload(e.target.files[0])}
                  accept=".pdf"
                />
                <Button
                  color="primary"
                  className="ms-2"
                  onClick={() => uploadFile()}
                  disabled={fileToUpload === null || loading}
                >
                  {loading ? <Spinner size="sm" /> : 'Upload'}
                </Button>
              </FormGroup>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default CertificatoDiAttribuzione
