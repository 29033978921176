import React from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectFileManager } from '../../../app/store/fileManager/selectors'
import { cambiaAnno } from '../../../app/store/fileManager/slice'

const SelettoreAnno = () => {
  const fileManager = useSelector(selectFileManager)
  const dispatch = useDispatch()

  return (
    <ButtonGroup>
      <Button
        onClick={() => dispatch(cambiaAnno(fileManager.anno - 1))}
        color="warning"
        disabled={
          fileManager.anno === 2015 || fileManager.visibleFilesType === 'documenti'
        }
      >
        <i className="bi bi-arrow-left-square"></i>
      </Button>
      <Button disabled color="warning">
        {fileManager.anno}
      </Button>
      <Button
        onClick={() => dispatch(cambiaAnno(fileManager.anno + 1))}
        color="warning"
        disabled={
          fileManager.anno === new Date().getFullYear() ||
          fileManager.visibleFilesType === 'documenti'
        }
      >
        <i className="bi bi-arrow-right-square"></i>
      </Button>
    </ButtonGroup>
  )
}

export default SelettoreAnno
