import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Container,
  Card,
} from 'reactstrap'
import DataIncassoBlocchetto from '../../sharedComponent/DataIncassoBlocchetto'
import { Tooltip } from '../../sharedComponent/Tooltip'
import currency from 'currency.js'
import {
  tipologiaAdapterFromTextToTipologia,
  tipologiaAdapterFromTipologiaToText,
} from '../../../app/utility/Adapter'
import { selectUser } from '../../../app/store/user/selectors'
import { useSelector } from 'react-redux'
import {
  updateDocumentoBlocchetto,
  queryDocumentoDaControllare,
} from '../../../app/utility/QueryDocumentiImportati'
import { fileBlocchettoPresignedURL } from '../../../app/utility/QueryFileViewer'
import CentredLoading from '../../component/CentredLoading'

export const ControlloDocumenti = () => {
  const user = useSelector(selectUser)
  const [uploading, setUploading] = useState(false)
  const [printableFile, setPrintableFile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [outputDocument, setOutputDocument] = useState({})
  const [mancanti, setMancanti] = useState(0)
  const [medico, setMedico] = useState(null)

  const fetchDocumento = useCallback(() => {
    queryDocumentoDaControllare(user).then((response) => {
      setOutputDocument(response.documento)
      setMancanti(response.numero_mancanti)
      setMedico(response.medico)
      fileBlocchettoPresignedURL(user, response.documento.id).then((response) => {
        setPrintableFile(response)
        setLoading(false)
      })
    })
  }, [user])

  useEffect(() => {
    fetchDocumento()
  }, [fetchDocumento])

  const upload = async (controllato) => {
    setUploading(true)
    const responseBody = await updateDocumentoBlocchetto(user, {
      data_incasso: outputDocument.data_incasso,
      numero: outputDocument.numero,
      importo: outputDocument.importo,
      trattenuta_enpam: outputDocument.trattenuta_enpam,
      trattenuta_irpef: outputDocument.trattenuta_irpef,
      partita_iva: outputDocument.partita_iva,
      codice_fiscale: outputDocument.codice_fiscale,
      inviata_ts: outputDocument.inviata_ts,
      file_type: outputDocument.tipologia,
      id_documento: outputDocument.id,
      controllato: controllato,
      appunti_verifica: outputDocument.appunti_verifica,
    })
    if (responseBody) {
      setUploading(false)
      fetchDocumento()
    }
    return responseBody
  }

  const downloadFileFromUrl = () => {
    if (!printableFile?.url || !printableFile?.nome_file) return
    fetch(printableFile.url)
      .then((response) => {
        if (!response.ok) throw new Error('Errore durante il download del file')
        return response.blob()
      })
      .then((blobFile) => {
        const file = new File([blobFile], printableFile.nome_file)
        saveAs(file)
      })
      .catch((error) => {
        console.error('Errore durante il download del file:', error)
        alert('Si è verificato un errore durante il download del file.')
      })
  }

  return (
    <Container fluid className="mt-4" style={{ height: '90vh' }}>
      <Row style={{ height: '100%' }}>
        <Col xs={6}>
          {loading ? (
            <CentredLoading />
          ) : (
            <object
              data={printableFile?.url}
              type={printableFile?.content_type}
              width="100%"
              height="100%"
              name={printableFile?.nome_file}
            >
              <p>
                Il tuo browser non supporta la visualizzazione di questo file. Puoi
                scaricarlo utilizzando il link o il pulsante sottostante.
              </p>
              <ul>
                <li>
                  Scarica tramite il link{' '}
                  <a href={printableFile?.url} target="_blank" rel="noreferrer">
                    {printableFile?.nome_file}
                  </a>
                </li>
                <br />
                <li>
                  Oppure scarica con il bottone
                  <Button
                    className="ms-3"
                    color="primary"
                    onClick={() => downloadFileFromUrl()}
                  >
                    <i className="bi bi-cloud-arrow-down"></i> Scarica
                  </Button>
                </li>
              </ul>
            </object>
          )}
        </Col>
        <Col xs={6}>
          <Card body>
            <div>
              <small className="text-muted">Mancanti: {mancanti}</small>
              <br />
              <Button
                color="warning"
                onClick={() => upload(null)}
                disabled={uploading}
              >
                <i className="bi bi-exclamation-lg"></i> STRANO
              </Button>
              <Button
                className="float-end"
                color="success"
                onClick={() => upload(true)}
                disabled={uploading}
              >
                <i className="bi bi-check-lg"></i> GIUSTO
              </Button>
              <br />
              <br />
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="nota_verificatore">Nota verificatore</Label>
                    <Input
                      type="text"
                      placeholder=""
                      value={outputDocument.appunti_verifica || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          appunti_verifica: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <span className="text-muted">
                    Medico:{' '}
                    <a href={'/shared/user-profile/' + medico?.id} target="_blank">
                      {medico?.nome} {medico?.cognome}
                    </a>
                    <br />
                    Partita IVA: {medico?.partita_iva} | Codice Fiscale:{' '}
                    {medico?.codice_fiscale}
                  </span>
                  <br />
                  <br />
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Tipologia</Label>
                    <Input
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          tipologia: tipologiaAdapterFromTextToTipologia(
                            e.target.value,
                          ),
                        })
                      }
                      type="select"
                      value={tipologiaAdapterFromTipologiaToText(
                        outputDocument.tipologia,
                      )}
                    >
                      <option>Fattura</option>
                      <option>Cedolino</option>
                      <option>Borsa MMG</option>
                      <option>Borsa specializzazione</option>
                      <option>Prestazione occasionale</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Nome file</Label>
                    <Input
                      type="text"
                      disabled
                      defaultValue={outputDocument.nome_originale_file}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Partita IVA</Label>
                    <Input
                      type="text"
                      value={outputDocument.partita_iva || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          partita_iva: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Codice Fiscale</Label>
                    <Input
                      type="text"
                      value={outputDocument.codice_fiscale || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          codice_fiscale: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <DataIncassoBlocchetto
                    dataIncasso={outputDocument.data_incasso}
                    modificaData={(data) =>
                      setOutputDocument({
                        ...outputDocument,
                        data_incasso: data,
                      })
                    }
                  />
                </Col>

                {(outputDocument.tipologia === 'fattura' ||
                  outputDocument.tipologia === 'cedolino') && (
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Numero</Label>
                      <Input
                        type="text"
                        value={outputDocument.numero || ''}
                        onChange={(e) =>
                          setOutputDocument({
                            ...outputDocument,
                            numero: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
                {[
                  'fattura',
                  'cedolino',
                  'borsa_mmg',
                  'prestazione_occasionale',
                ].includes(outputDocument.tipologia) && (
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="importo">
                        Importo lordo{' '}
                        <Tooltip placement="top">
                          Se stai caricando una fattura, in questo campo devi
                          inserire l&apos;importo della fattura più la marca da
                          bollo. Esempio: l&apos;importo della tua fattura è 1200 € e
                          la marca da bollo che hai applicato è di 2€. In questo
                          campo scriverai 1202 €. Se, vuoi importare un cedolino,
                          sempre in questo campo devi riportare il numero che trovi
                          sotto la dicitura totale competenze. Se invece stai
                          importando una borsa MMG, in questo campo devi inserire
                          l&apos;importo IRPEF lordo
                        </Tooltip>
                      </Label>
                      <Input
                        type="number"
                        value={outputDocument.importo || ''}
                        onChange={(e) =>
                          setOutputDocument({
                            ...outputDocument,
                            importo: currency(e.target.value).value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
                {outputDocument.tipologia === 'cedolino' && (
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Trattenute ENPAM{' '}
                        <Tooltip placement="top">
                          In questo campo, inserisci il numero che trovi sotto la
                          voce &quot;oneri previdenziali&quot;. È importante per il
                          calcolo delle tasse
                        </Tooltip>
                      </Label>
                      <Input
                        type="number"
                        value={outputDocument.trattenuta_enpam || ''}
                        onChange={(e) =>
                          setOutputDocument({
                            ...outputDocument,
                            trattenuta_enpam: currency(e.target.value).value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
                {outputDocument.tipologia === 'prestazione_occasionale' && (
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Trattenute IRPEF{' '}
                        <Tooltip placement="top">
                          In questo campo, inserisci il numero che trovi sotto la
                          voce trattenuta IRPEF
                        </Tooltip>
                      </Label>
                      <Input
                        type="number"
                        value={outputDocument.trattenuta_irpef || ''}
                        onChange={(e) =>
                          setOutputDocument({
                            ...outputDocument,
                            trattenuta_irpef: currency(e.target.value).value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col xs={6}>
                  {outputDocument.tipologia === 'fattura' && (
                    <FormGroup>
                      <Label for="exampleEmail">
                        È stata trasmessa a Sistema Tessera Sanitaria
                      </Label>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="radio1"
                            checked={outputDocument.inviata_ts === true}
                            onChange={() =>
                              setOutputDocument({
                                ...outputDocument,
                                inviata_ts: true,
                              })
                            }
                            value="Si"
                          />
                          Sì
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="radio2"
                            checked={outputDocument.inviata_ts === false}
                            onChange={() =>
                              setOutputDocument({
                                ...outputDocument,
                                inviata_ts: false,
                              })
                            }
                            value="No"
                          />
                          No
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default ControlloDocumenti
