import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import {
  selectGestionaleUtente,
  selectDichiarazione,
} from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import Completato from './Completato'
import { uploadF24 } from '../../../../app/utility/QueryGestionaleUtente'
import { selectUser } from '../../../../app/store/user/selectors'
import { aggiornaF24 } from '../../../../app/store/gestionaleUtente/slice'
import TabellaF24 from './TabellaF24'
import {
  completaSaldoENotifica,
  completaAccontoENotifica,
} from '../../../../app/store/gestionaleUtente/actions'

const emptyF24 = {
  tipologia: 'Saldo e primo acconto',
  importo: 0,
  data_scadenza: '',
  file_to_upload: null,
  anno: '',
}

const F24 = () => {
  const user = useSelector(selectUser)
  const dati = useSelector(selectGestionaleUtente)
  const dichiarazione = useSelector(selectDichiarazione)
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [saldoCompletato, setSaldoCompletato] = useState(false)
  const [accontoCompletato, setAccontoCompletato] = useState(false)
  const [processoCompletato, setProcessoCompletato] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(emptyF24)
  const [loading, setLoading] = useState(false)
  const [resetInputValue, setResetInputValue] = useState(Date.now())

  useEffect(() => {
    if (dati.anno < new Date().getFullYear() - 1) {
      setSaldoCompletato(true)
      setAccontoCompletato(true)
      setProcessoCompletato(true)
    } else {
      if (dichiarazione.dati?.tappe[3].completata === true) {
        setProcessoCompletato(true)
        setSaldoCompletato(true)
        setAccontoCompletato(true)
        return
      }
      if (dichiarazione?.dati?.tappe[3].completata === false) {
        if (dichiarazione?.dati?.tappe[3].saldo_acconto_completata === true) {
          setProcessoCompletato('inProgress')
          setSaldoCompletato(true)
          setAccontoCompletato(false)
        } else {
          setProcessoCompletato(false)
          setSaldoCompletato(false)
          setAccontoCompletato(false)
        }
      }
    }
  }, [dati])

  useEffect(() => {
    setFileToUpload({ ...fileToUpload, anno: dati.anno })
  }, [dati.anno])

  const uploadFile = () => {
    setLoading(true)
    uploadF24(user, dati.medico_id, fileToUpload).then((bodyResponse) => {
      dispatch(aggiornaF24(bodyResponse))
      setFileToUpload({ ...emptyF24, anno: dati.anno })
      setResetInputValue(Date.now())
      setLoading(false)
    })
  }

  const completaSaldo = () => {
    dispatch(completaSaldoENotifica())
  }

  const completaAcconto = () => {
    dispatch(completaAccontoENotifica())
  }

  return (
    <>
      <Button
        className="mt-2 ms-2"
        color="secondary"
        outline
        size="sm"
        onClick={() => setModalIsOpen(true)}
      >
        F24 <Completato completato={processoCompletato} />
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} size="lg">
        <ModalBody>
          <Row>
            <Col xs={5}>
              <center>
                <>
                  Saldo e primo acconto <Completato completato={saldoCompletato} />
                </>
                <br />
                {dati.anno === new Date().getFullYear() - 1 && !saldoCompletato && (
                  <Button
                    color="primary"
                    onClick={() => completaSaldo()}
                    size="sm"
                    className="mt-2"
                    disabled={dati.loading}
                  >
                    {dati.loading ? <Spinner size="sm" /> : 'Completa e notifica'}
                  </Button>
                )}
              </center>
            </Col>
            <Col xs={2}>
              <center>
                <i className="bi bi-arrow-right-short"></i>
              </center>
            </Col>
            <Col xs={5}>
              <center>
                <>
                  Secondo acconto <Completato completato={accontoCompletato} />
                </>
                <br />
                {dati.anno === new Date().getFullYear() - 1 &&
                  saldoCompletato &&
                  !accontoCompletato && (
                    <Button
                      color="primary"
                      onClick={() => completaAcconto()}
                      size="sm"
                      className="mt-2"
                      disabled={dati.loading}
                    >
                      {dati.loading ? <Spinner size="sm" /> : 'Completa e notifica'}
                    </Button>
                  )}
              </center>
            </Col>
          </Row>
          <hr />
          <TabellaF24 />
          <br />
          <Card className="card-bordo-visibile">
            <CardHeader>Aggiungi F24</CardHeader>
            <CardBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Tipo</Label>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      value={fileToUpload.tipologia}
                      onChange={(e) =>
                        setFileToUpload({
                          ...fileToUpload,
                          tipologia: e.target.value,
                        })
                      }
                    >
                      <option key="cortesia">Saldo e primo acconto</option>
                      <option key="xml">Secondo acconto</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Importo</Label>
                    <Input
                      type="number"
                      name="number"
                      id="number"
                      placeholder="9,32"
                      value={fileToUpload.importo}
                      onChange={(e) =>
                        setFileToUpload({ ...fileToUpload, importo: e.target.value })
                      }
                      className="d-block"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Scadenza</Label>
                    <Input
                      type="date"
                      name="date"
                      id="date"
                      value={fileToUpload.data_scadenza}
                      onChange={(e) =>
                        setFileToUpload({
                          ...fileToUpload,
                          data_scadenza: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Il file in PDF</Label>
                    <Input
                      key={resetInputValue}
                      type="file"
                      name="file"
                      id="file"
                      onChange={(e) =>
                        setFileToUpload({
                          ...fileToUpload,
                          file_to_upload: e.target.files[0],
                        })
                      }
                      accept=".pdf"
                      className="d-block"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color="primary"
                block
                onClick={() => uploadFile()}
                disabled={
                  fileToUpload.file_to_upload === null ||
                  fileToUpload.importo < 0 ||
                  fileToUpload.data_scadenza === '' ||
                  loading
                }
              >
                {loading ? <Spinner size="sm" /> : 'Upload'}
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  )
}

export default F24
