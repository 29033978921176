import React from 'react'
import { ButtonGroup, Col, Row, Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { modificaAnno } from '../../../../app/store/gestionaleUtente/slice'
import { idrata } from '../../../../app/store/gestionaleUtente/actions'

const SelettoreAnno = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()

  const modificaAnnoEIdrata = (anno) => {
    if (anno > new Date().getFullYear()) return
    dispatch(modificaAnno(anno))
    dispatch(idrata({ anno, medico_id: dati.medico_id }))
  }

  return (
    <ButtonGroup className="mt-2" size="sm">
      <Button
        onClick={() => modificaAnnoEIdrata(dati.anno - 1)}
        color="primary"
        disabled={dati.loading}
      >
        <i className="bi bi-arrow-left-square"></i>
      </Button>
      <Button disabled color="light">
        Competenza {dati.anno}
      </Button>
      <Button
        onClick={() => modificaAnnoEIdrata(dati.anno + 1)}
        color="primary"
        disabled={dati.loading}
      >
        <i className="bi bi-arrow-right-square"></i>
      </Button>
    </ButtonGroup>
  )
}

export default SelettoreAnno
