import React, { useState, useEffect } from 'react'
import { Table, Container, Card, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { queryAdeInfoStats } from '../../../app/utility/QueryTecnico'
import { selectUser } from '../../../app/store/user/selectors'
import ModalSync from './ModalSync'

const AdeInfoStats = () => {
  const user = useSelector(selectUser)
  const [adeInfoStats, setAdeInfoStats] = useState()
  const [loading, setLoading] = useState(true)
  moment.locale('it')

  useEffect(() => {
    queryAdeInfoStats(user)
      .then((response) => {
        setAdeInfoStats(response.stats)
        setLoading(false)
      })
      .catch((e) => console.error(e.message))
  }, [])

  return (
    <Container fluid>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Card body className="my-3">
          <Row>
            <Col xs="8">
              Strumento di controllo delle sincronizzazioni con il sito AdE
            </Col>
            <Col xs="4"></Col>
          </Row>
          <br />
          <Table size="sm" hover className="mt-4 mb-5">
            <thead>
              <tr>
                <th className="h6 font-regular">Ultimo utente sincronizzato</th>
                <th className="h6 font-regular">Utenti con almeno 1 sync</th>
                <th className="h6 font-regular">Deleghe cassetto</th>
                <th className="h6 font-regular">Deleghe fatturazione</th>
                <th className="h6 font-regular">Deleghe complete</th>
                <th className="h6 font-regular">In Coda</th>
                <th className="h6 font-regular">Errori</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moment(adeInfoStats.ultimo_sync).fromNow()}</td>
                <td>{adeInfoStats.sync_effettuato}</td>
                <td>{adeInfoStats.delega_cassetto}</td>
                <td>{adeInfoStats.delega_fatturazione}</td>
                <td>{adeInfoStats.doppia_delega}</td>
                <td>{adeInfoStats.in_coda}</td>
                <td>{adeInfoStats.errore}</td>
              </tr>
            </tbody>
          </Table>
          <center>
            <ModalSync />
          </center>
        </Card>
      )}
    </Container>
  )
}

export default AdeInfoStats
