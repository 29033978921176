import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalConsegnata } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalRicevutaDiConsegna = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalConsegnataOpen}
      toggle={() => dispatch(chiudiModalConsegnata())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalConsegnata())}
        className="font-regular font-grigio-scuro"
      >
        Fattura consegnata
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata consegnata al cliente, puoi tirare un sospiro di
          sollievo 😊
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Anche se il tuo cliente ha ricevuto la fattura, è sempre buona norma
          inviargli un&lsquo;email o, comunque, avvertire il cliente che la fattura è
          stata emessa
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalRicevutaDiConsegna
