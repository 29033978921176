import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Navbar, Collapse, Nav, NavbarBrand, Container } from 'reactstrap'
import classNames from 'classnames'

const PublicLargeNav = () => {
  const [opacity, setOpacity] = useState(0)
  const location = useLocation()

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    if (scrollPosition > 20) {
      setOpacity(1)
    } else {
      setOpacity(scrollPosition * 0.05)
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Navbar
      light
      style={{
        zIndex: '1000',
        backgroundColor: 'rgba(255, 255, 255,' + opacity + ')',
      }}
      expand="md"
      fixed={'top'}
    >
      <Container
        className="container-landing"
        style={{
          display: 'flex',
        }}
      >
        <NavbarBrand tag={Link} to="/">
          <img
            src="/img_landing/logo.webp"
            alt="Fiscomed"
            style={{ height: '42px' }}
          />
        </NavbarBrand>
        <Collapse isOpen={false} navbar>
          <Nav navbar>
            <ul className="navbar-nav me-auto">
              <li
                className={classNames('nav-item mx-2 ', {
                  'link-nav-attivo': location.pathname === '/',
                  active: location.pathname !== '/',
                })}
              >
                <Link className="nav-link" to="/">
                  Fiscomed
                </Link>
              </li>
              <li
                className={classNames('nav-item mx-2 ', {
                  'link-nav-attivo': location.pathname === '/prezzi',
                  active: location.pathname !== '/prezzi',
                })}
              >
                <Link className="nav-link" to="/prezzi">
                  Prezzi
                </Link>
              </li>
              <li
                className={classNames('nav-item mx-2 ', {
                  'link-nav-attivo': location.pathname === '/guida-fiscale',
                  active: location.pathname !== '/guida-fiscale',
                })}
              >
                <Link className="nav-link" to="/guida-fiscale">
                  Guida fiscale
                </Link>
              </li>
              <li
                className={classNames('nav-item mx-2 ', {
                  'link-nav-attivo': location.pathname === '/newsletter',
                  active: location.pathname !== '/newsletter',
                })}
              >
                <Link className="nav-link" to="/newsletter">
                  Newsletter
                </Link>
              </li>
            </ul>
          </Nav>
          <Nav navbar className="ms-auto">
            <div className="navbar-nav">
              <form className="form-inline">
                <Button className="bottone-blu" tag={Link} to="/accesso">
                  ACCEDI
                </Button>
              </form>
            </div>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default PublicLargeNav
