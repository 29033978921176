import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
} from 'reactstrap'
import DataIncassoBlocchetto from './DataIncassoBlocchetto'
import { Tooltip } from './Tooltip'
import currency from 'currency.js'
import {
  tipologiaAdapterFromTextToTipologia,
  tipologiaAdapterFromTipologiaToText,
} from '../../app/utility/Adapter'
import { selectUser } from '../../app/store/user/selectors'
import { useSelector } from 'react-redux'
import { updateDocumentoBlocchetto } from '../../app/utility/QueryDocumentiImportati'
import { dataIncassoSezionaleBlocchettoValida } from '../../app/utility/controllerApi'
import swal from 'sweetalert'

export const ModalUpdateBlocchetto = ({
  isOpen,
  close,
  inputDocument,
  medicoId,
  returnFunction,
}) => {
  const user = useSelector(selectUser)
  const [outputDocument, setOutputDocument] = useState(inputDocument)
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    setOutputDocument(inputDocument)
  }, [inputDocument])

  const checkIfValidAndUpload = () => {
    if (outputDocument.tipologia !== 'altro') {
      if (dataIncassoSezionaleBlocchettoValida(outputDocument.data_incasso)) {
        upload()
      } else {
        swal({
          title: 'OOOPS...',
          text: 'La data di incasso sembra trovarsi in un tempo lontano o indefinito. Se viaggi nel tempo è tutto okay, ma se così non fosse prova a ricontrollarla 🕰️',
          icon: 'error',
          button: 'OK',
        })
      }
    }
  }

  const upload = async () => {
    setUploading(true)
    const responseBody = await updateDocumentoBlocchetto(user, {
      medico_id: medicoId,
      data_incasso: outputDocument.data_incasso,
      numero: outputDocument.numero,
      importo: outputDocument.importo,
      trattenuta_enpam: outputDocument.trattenuta_enpam,
      trattenuta_irpef: outputDocument.trattenuta_irpef,
      partita_iva: outputDocument.partita_iva,
      codice_fiscale: outputDocument.codice_fiscale,
      inviata_ts: outputDocument.inviata_ts,
      file_type: outputDocument.tipologia,
      id_documento: outputDocument.id,
    })
    if (responseBody) {
      setUploading(false)
      returnFunction(responseBody.documento)
      close()
    }
    return responseBody
  }

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader className="font-medium" toggle={close}>
        Modifica documento
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Tipologia</Label>
              <Input
                onChange={(e) =>
                  setOutputDocument({
                    ...outputDocument,
                    tipologia: tipologiaAdapterFromTextToTipologia(e.target.value),
                  })
                }
                type="select"
                value={tipologiaAdapterFromTipologiaToText(outputDocument.tipologia)}
              >
                <option>Fattura</option>
                <option>Cedolino</option>
                <option>Borsa MMG</option>
                <option>Borsa specializzazione</option>
                <option>Prestazione occasionale</option>
              </Input>
            </FormGroup>
          </Col>
          {outputDocument.tipologia !== 'altro' && (
            <Col xs={6}>
              <DataIncassoBlocchetto
                dataIncasso={outputDocument.data_incasso}
                modificaData={(data) =>
                  setOutputDocument({ ...outputDocument, data_incasso: data })
                }
              />
            </Col>
          )}
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Partita IVA</Label>
              <Input
                type="text"
                placeholder=""
                value={outputDocument.partita_iva || ''}
                onChange={(e) =>
                  setOutputDocument({
                    ...outputDocument,
                    partita_iva: e.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Codice fiscale</Label>
              <Input
                type="text"
                placeholder=""
                value={outputDocument.codice_fiscale || ''}
                onChange={(e) =>
                  setOutputDocument({
                    ...outputDocument,
                    codice_fiscale: e.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          {(outputDocument.tipologia === 'fattura' ||
            outputDocument.tipologia === 'cedolino') && (
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">Numero</Label>
                <Input
                  type="text"
                  placeholder="1s"
                  value={outputDocument.numero}
                  onChange={(e) =>
                    setOutputDocument({ ...outputDocument, numero: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
          )}
          {['fattura', 'cedolino', 'borsa_mmg', 'prestazione_occasionale'].includes(
            outputDocument.tipologia,
          ) && (
            <Col xs={6}>
              <FormGroup>
                <Label for="importo">
                  Importo lordo{' '}
                  <Tooltip placement="top">
                    Se stai caricando una fattura, in questo campo devi inserire
                    l&apos;importo della fattura più la marca da bollo. Esempio:
                    l&apos;importo della tua fattura è 1200 € e la marca da bollo che
                    hai applicato è di 2€. In questo campo scriverai 1202 €. Se, vuoi
                    importare un cedolino, sempre in questo campo devi riportare il
                    numero che trovi sotto la dicitura totale competenze. Se invece
                    stai importando una borsa MMG, in questo campo devi inserire
                    l&apos;importo IRPEF lordo
                  </Tooltip>
                </Label>
                <Input
                  type="number"
                  placeholder="120,23"
                  value={outputDocument.importo}
                  onChange={(e) =>
                    setOutputDocument({
                      ...outputDocument,
                      importo: currency(e.target.value).value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          )}
          {outputDocument.tipologia === 'cedolino' && (
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">
                  Trattenute ENPAM{' '}
                  <Tooltip placement="top">
                    In questo campo, inserisci il numero che trovi sotto la voce
                    &quot;oneri previdenziali&quot;. È importante per il calcolo
                    delle tasse
                  </Tooltip>
                </Label>
                <Input
                  type="number"
                  placeholder="12,23"
                  value={outputDocument.trattenuta_enpam}
                  onChange={(e) =>
                    setOutputDocument({
                      ...outputDocument,
                      trattenuta_enpam: currency(e.target.value).value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          )}
          {outputDocument.tipologia === 'prestazione_occasionale' && (
            <Col xs={6}>
              <FormGroup>
                <Label for="trattenutaIrpef">
                  Trattenute IRPEF{' '}
                  <Tooltip placement="top">
                    In questo campo, inserisci il numero che trovi sotto la voce
                    &quot;oneri previdenziali&quot;. È importante per il calcolo
                    delle tasse
                  </Tooltip>
                </Label>
                <Input
                  name="trattenutaIrpef"
                  type="number"
                  placeholder="12,23"
                  value={outputDocument.trattenuta_irpef}
                  onChange={(e) =>
                    setOutputDocument({
                      ...outputDocument,
                      trattenuta_enpam: currency(e.target.value).value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        {outputDocument.tipologia === 'fattura' && (
          <FormGroup>
            <Label for="exampleEmail">
              È stata trasmessa a Sistema Tessera Sanitaria
            </Label>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  checked={outputDocument.inviata_ts === true}
                  onChange={() =>
                    setOutputDocument({ ...outputDocument, inviata_ts: true })
                  }
                  value="Si"
                />
                Sì
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio2"
                  checked={outputDocument.inviata_ts === false}
                  onChange={() =>
                    setOutputDocument({ ...outputDocument, inviata_ts: false })
                  }
                  value="No"
                />
                No
              </Label>
            </FormGroup>
          </FormGroup>
        )}
        {outputDocument.tipologia === 'altro' && (
          <Col xs={12}>
            <FormGroup>
              <Label for="nota" className="font-regular">
                Aggiungi una nota per spiegarci che tipo di file stai caricando
              </Label>
              <Input
                type="text"
                name="nota"
                id="nota"
                placeholder="Questo è la ricevuta dell'f24 del 06/2022"
                value={outputDocument.nota}
                onChange={(e) =>
                  setOutputDocument({ ...outputDocument, nota: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        )}
        <FormGroup>
          <Label for="exampleEmail">Foto o pdf del documento</Label>
          <Input
            type="text"
            placeholder={outputDocument.nome_originale_file}
            disabled
            defaultValue={outputDocument.nome_originale_file}
          />
        </FormGroup>
        <br />
        {uploading ? (
          <Button className="float-end bottone-blu" disabled>
            <Spinner size="sm" />
          </Button>
        ) : (
          <Button
            className="bottone-blu float-end"
            onClick={() => checkIfValidAndUpload()}
            disabled={uploading}
          >
            SALVA MODIFICA
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdateBlocchetto
