import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { sincronizzaCassetto } from '../../../../app/store/gestionaleUtente/actions'

const ModalSync = () => {
  const dati = useSelector(selectGestionaleUtente)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [sezioniDaSincronizzare, setSezioniDaSincronizzare] = useState([])

  const toggleSezione = (sezione) => {
    if (sezioniDaSincronizzare.includes(sezione)) {
      setSezioniDaSincronizzare(sezioniDaSincronizzare.filter((s) => s !== sezione))
    } else {
      setSezioniDaSincronizzare([...sezioniDaSincronizzare, sezione])
    }
  }

  const sincronizza = () => {
    dispatch(
      sincronizzaCassetto({
        cf: dati.cassetto.cf,
        sections: sezioniDaSincronizzare,
      }),
    )
  }

  return (
    <>
      <Button
        size="sm"
        color="secondary"
        outline
        className="ms-2"
        onClick={() => setModalIsOpen(true)}
        disabled={
          !dati.cassetto?.delega_cassetto || !dati.cassetto?.delega_fatturazione
        }
      >
        <i className="bi bi-arrow-clockwise"></i> Sincronizza
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Sincronizzazione documenti AdE
        </ModalHeader>
        <ModalBody>
          <FormGroup check className="mt-3">
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('dichiarazioni')}
              onChange={() => toggleSezione('dichiarazioni')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>Dichiarazioni</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('sette_trenta')}
              onChange={() => toggleSezione('sette_trenta')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>730</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('cu')}
              onChange={() => toggleSezione('cu')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>CU</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('registro')}
              onChange={() => toggleSezione('registro')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>Registro</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('f24')}
              onChange={() => toggleSezione('f24')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>F24</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('fatture_elettroniche')}
              onChange={() => toggleSezione('fatture_elettroniche')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>Fatture elettroniche</Label>
          </FormGroup>
          <FormGroup check className="mb-3">
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('catasto')}
              onChange={() => toggleSezione('catasto')}
              disabled={dati.cassetto?.to_sync}
            />
            <Label>Catasto</Label>
          </FormGroup>

          {dati.cassetto?.to_sync ? (
            <div className="alert alert-warning mt-3">
              <i className="bi bi-exclamation-triangle"></i> Il cliente è già in
              attesa di una sincronizzazione per queste sezioni:
              <br />
              <br />
              {dati.cassetto?.sections_to_sync?.length === 0
                ? 'dichiarazioni, sette_trenta, cu, registro, f24, fatture_elettroniche, catasto'
                : dati.cassetto?.sections_to_sync?.join(', ')}
              <br />
              <br />
              Sarà possibile sincronizzare nuovamente il cassetto solo dopo che la
              precedente sincronizzazione sarà completata.
            </div>
          ) : (
            <Button
              color="primary"
              onClick={() => sincronizza()}
              className="float-end"
              disabled={dati.loading}
            >
              <i className="bi bi-arrow-clockwise"></i> Sincronizza
            </Button>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalSync
