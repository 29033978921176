import React, { useEffect, useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, Input } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { leadChiusi } from '../../../app/utility/QueryLead'
import CentredLoading from '../../component/CentredLoading'
import PrintLeads from './component/PrintLeads'
import { searchLead } from './utility'
import classNames from 'classnames'

const LeadChiusi = ({ floatEnd, canvas }) => {
  const [offcanvas, setOffcanvas] = useState(false)
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [leads, setLeads] = useState([])
  const [corrispondenze, setCorrispondenze] = useState([])

  useEffect(() => {
    if (!canvas) {
      setLoading(true)
      leadChiusi(user).then((leadChiusi) => {
        setLoading(false)
        setLeads(leadChiusi)
        setCorrispondenze(searchLead('', leadChiusi))
      })
    }
  }, [])

  const openCanvas = () => {
    setLoading(true)
    leadChiusi(user).then((leadChiusi) => {
      setLoading(false)
      setLeads(leadChiusi)
      setCorrispondenze(searchLead('', leadChiusi))
    })
    setOffcanvas(true)
  }

  const search = (query) => {
    setCorrispondenze(searchLead(query, leads))
  }

  return (
    <>
      {canvas ? (
        <div className="d-inline ms-3">
          <Button
            color="secondary"
            outline
            size="sm"
            onClick={() => openCanvas()}
            className={classNames('position-relativ', { 'float-end': floatEnd })}
          >
            <i className="bi bi-bookmark-check"></i> Lead chiusi
          </Button>
          <Offcanvas
            direction="bottom"
            scrollable
            toggle={() => setOffcanvas(false)}
            isOpen={offcanvas}
            className="h-75"
          >
            <OffcanvasBody>
              {loading ? (
                <CentredLoading />
              ) : (
                <>
                  <Input
                    type="text"
                    onChange={(e) => search(e.target.value)}
                    placeholder="🔍 Cerca per nome, telefono o email"
                    disabled={loading}
                    style={{ maxWidth: '400px' }}
                  />

                  <PrintLeads leads={corrispondenze} />
                </>
              )}
            </OffcanvasBody>
          </Offcanvas>
        </div>
      ) : (
        <>
          {loading ? (
            <CentredLoading />
          ) : (
            <>
              <Input
                type="text"
                onChange={(e) => search(e.target.value)}
                placeholder="🔍 Cerca per nome, telefono o email"
                disabled={loading}
                style={{ maxWidth: '400px' }}
              />

              <PrintLeads leads={corrispondenze} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default LeadChiusi
