// simple react component function with name PrimaConsulenza
// and default export

import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { listMediciPrimaConsulenza } from '../../../app/utility/QueryCommercialista'
import CentredLoading from '../../component/CentredLoading'
import ListaEmail from './component/ListaEmail'
import SchedaMedico from './component/SchedaMedico'
import { queryCambiaStatoDichiarazione } from '../../../app/utility/QueryGestionaleUtente'

const PrimaConsulenza = () => {
  const user = useSelector(selectUser)
  const [medici, setMedici] = useState([])
  const [selectedMedico, setSelectedMedico] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    listMediciPrimaConsulenza(user).then((mediciFromServer) => {
      setMedici(mediciFromServer)
      setSelectedMedico(mediciFromServer[0])
      setIsLoading(false)
    })
  }, [])

  const inviaPDF = (medico_id) => {
    setMedici(
      medici.map((medico) => {
        if (medico.id === medico_id) {
          return { ...medico, data_invio_pdf_riepilogativo: new Date() }
        }
        return medico
      }),
    )
    setSelectedMedico({
      ...selectedMedico,
      data_invio_pdf_riepilogativo: new Date(),
    })
  }

  const aggiornaDatiMedico = (medico) => {
    setMedici(
      medici.map((m) => {
        if (m.id === medico.id) {
          return medico
        }
        return m
      }),
    )
    setSelectedMedico({ ...selectedMedico, ...medico })
  }

  const rimuoviMedico = (medico_id) => {
    const mediciFiltered = medici.filter((medico) => medico.id !== medico_id)
    setMedici(mediciFiltered)
    setSelectedMedico(mediciFiltered[0])
  }

  const cambiaStatoDichiarazione = (medico_id, stato) => {
    setMedici(
      medici.map((m) => {
        if (m.id === medico_id) {
          return { ...m, dichiarazione: { ...m.dichiarazione, attivo: stato } }
        }
        return m
      }),
    )
    setSelectedMedico({
      ...selectedMedico,
      dichiarazione: { ...selectedMedico.dichiarazione, attivo: stato },
    })
    queryCambiaStatoDichiarazione(
      user,
      medico_id,
      new Date().getFullYear() - 1,
      stato,
    )
  }

  return (
    <Container fluid>
      {isLoading ? (
        <CentredLoading />
      ) : (
        <>
          {medici.length === 0 ? (
            <center>
              <h2 className="font-regular mt-5">
                Non hai medici in attesa, ottimo lavoro! 😎
              </h2>
            </center>
          ) : (
            <Row className="mt-5">
              <Col xs="3">
                <ListaEmail
                  medici={medici}
                  selectedMedico={selectedMedico}
                  changeSelected={(medico) => setSelectedMedico(medico)}
                />
              </Col>
              <Col xs="9">
                {selectedMedico && (
                  <SchedaMedico
                    selectedMedico={selectedMedico}
                    inviaPDF={(medico_id) => inviaPDF(medico_id)}
                    aggiornaDatiMedico={(medico) => aggiornaDatiMedico(medico)}
                    rimuoviMedico={(medico_id) => rimuoviMedico(medico_id)}
                    cambiaStatoDichiarazione={(medico_id, stato_dichiarazione) =>
                      cambiaStatoDichiarazione(medico_id, stato_dichiarazione)
                    }
                  />
                )}
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  )
}

export default PrimaConsulenza
