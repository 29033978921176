import request from 'superagent'
import swal from 'sweetalert'
export const salvaDatiMedicoDaAttivare = (user, medico) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/shared/medico-data/salva-dati-medico`)
    .send({ token: user.auth_token, medico })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

export const salvaNote = (user, medico_id, note) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/shared/medico-data/salva-note`)
    .send({ token: user.auth_token, medico_id, note_admin: note })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

// i dati da passare a questa funzione sono strutturati come segue:
// {
//   file: File,
//   medico_id: integer || null,
//   nota: string || null,
//   file_type: string ['file_condiviso', 'file_privato', 'certificato_attribuzione'],
//   role: string ['cliente', 'commercialista'],
// }
export const uploadDocumentoCondiviso = async (user, dati) => {
  if (dati.file === '') {
    swal({
      title: 'Oops...',
      text: 'Non capiamo che file stai cercando di caricare, ne hai selezionato uno vuoto?',
      icon: 'warning',
    })
    return
  }
  if (dati.file.size / 1073741824 > 0.02) {
    swal({
      title: 'Oops...',
      text: 'Il file che stai cercando di caricare è veramente gigante, ti va di provare con un file più piccolo? Possiamo gestire file fino a 20MB',
      icon: 'warning',
    })
    return
  }
  const formData = new FormData()
  formData.append('file_to_upload', dati.file)
  formData.append('medico_id', dati.medico_id)
  formData.append('nota', dati.nota)
  formData.append('file_type', dati.file_type)
  formData.append('token', user.auth_token)
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/file-manager/upload`)
      .send(formData)
    return response.body
  } catch (e) {
    return null
  }
}

export const deleteDocumentoCondiviso = async (user, file_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/file-manager/delete`)
      .send({ token: user.auth_token, file_id })
    return response.body
  } catch (e) {
    return null
  }
}

export const salvaNoteDichiarazione = async (user, dichiarazione_id, note) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/commercialista/tappe/modifica-note-dichiarazione`,
      )
      .send({ token: user.auth_token, dichiarazione_id, note })
    return response.body
  } catch (e) {
    return null
  }
}

export const salvaResponsabile = async (user, dichiarazione_id, responsabile) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/commercialista/tappe/modifica-responsabile-dichiarazione`,
      )
      .send({ token: user.auth_token, dichiarazione_id, responsabile })
    return response.body
  } catch (e) {
    return null
  }
}

export const salvaDifficolta = async (user, dichiarazione_id, facile) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/commercialista/tappe/modifica-difficolta`,
      )
      .send({ token: user.auth_token, dichiarazione_id, facile })
    return response.body
  } catch (e) {
    return null
  }
}

export const queryCambiaStatoFatto = async (user, dichiarazione_id) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/commercialista/tappe/cambia-stato-fatto`,
      )
      .send({ token: user.auth_token, dichiarazione_id })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryToggleControllato = async (user, id_documento) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/sezionale-blocchetto/toggle-controllato`,
      )
      .send({ token: user.auth_token, id_documento })
    return response.body
  } catch (e) {
    return null
  }
}
