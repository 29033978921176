import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const SelezionaOperatore = ({ operatori, selectedOperatore, selectOperatore }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <div className="d-inline">
      <Button
        color="secondary"
        outline
        onClick={() => setModalIsOpen(true)}
        size="sm"
        className="me-3"
      >
        <i className="bi bi-arrow-repeat"></i> {selectedOperatore?.email}
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)}>
        <ModalHeader>Seleziona utente</ModalHeader>
        <ModalBody>
          {operatori.map((operatore) => (
            <Button
              key={operatore.public_uuid}
              block
              className="mb-2"
              onClick={() => {
                selectOperatore(operatore)
                setModalIsOpen(false)
              }}
              outline
              disabled={selectedOperatore?.public_uuid === operatore.public_uuid}
              color="secondary"
            >
              {selectedOperatore?.public_uuid !== operatore.public_uuid && (
                <i className="bi bi-arrow-repeat"></i>
              )}{' '}
              {operatore.email}
            </Button>
          ))}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default SelezionaOperatore
