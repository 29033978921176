import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../../app/store/user/selectors'
import PublicNavContainer from './PublicNavContainer'
import LargeScreenOnly from './LargeScreenOnly'

const RootNavContainer = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    <>
      <>{isLoggedIn === true ? <LargeScreenOnly /> : <PublicNavContainer />}</>
    </>
  )
}

export default RootNavContainer
