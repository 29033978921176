import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Badge } from 'reactstrap'
import classNames from 'classnames'

const StatoDichiarazione = ({ medico, cambiaStatoDichiarazione }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const prettyPrint = (risposte) => {
    if (risposte.length === 0)
      return 'Il cliente ha saltato questa fase prima di prenotare la consulenza'
    return risposte.map((elemento, index) => {
      return (
        <Row key={index} className="mb-4">
          <Col xs={9}>
            <span className="text-muted">{elemento.domanda}</span>:
          </Col>
          <Col xs={3}>
            <span>
              {elemento.risposta === true && <Badge color="success">Sì</Badge>}
              {elemento.risposta === false && <Badge color="danger">No</Badge>}
              {elemento.risposta === null && (
                <Badge color="secondary">Non risposto</Badge>
              )}
            </span>
          </Col>
        </Row>
      )
    })
  }

  const modal = (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => setModalIsOpen(!modalIsOpen)}
      size="lg"
    >
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
        Il cliente deve dichiarare nel {new Date().getFullYear()}?
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular">Storico fiscale utente</h6>
        {prettyPrint(medico.storico?.risposte)}
        <br />
        <br />
        <Button
          color="warning"
          onClick={() => cambiaStatoDichiarazione(medico.id, null)}
          block
        >
          Da capire
        </Button>
        <Button
          color="danger"
          onClick={() => cambiaStatoDichiarazione(medico.id, false)}
          block
          className="mt-2"
        >
          Non dichiarante
        </Button>
        <Button
          color="success"
          onClick={() => cambiaStatoDichiarazione(medico.id, true)}
          block
          className="mt-2"
        >
          Dichiarante
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {modal}

      <Button
        color={classNames(
          { success: medico.dichiarazione?.attivo },
          { danger: medico.dichiarazione?.attivo === false },
          { warning: medico.dichiarazione?.attivo === null },
        )}
        className="me-2"
        onClick={() => setModalIsOpen(true)}
      >
        {medico.dichiarazione?.attivo === true && 'Dichiarante'}
        {medico.dichiarazione?.attivo === false && 'Non dichiarante'}
        {medico.dichiarazione?.attivo === null && 'Da capire'}
      </Button>
    </>
  )
}

export default StatoDichiarazione
