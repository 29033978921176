import React, { useState, useEffect } from 'react'
import {
  queryStatus,
  queryCancellaFiscoStatus,
} from '../../app/utility/QueryTecnico'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import moment from 'moment'
import { Table, Button, Container, Card, Row, Col } from 'reactstrap'
import { CSVLink } from 'react-csv'
import classNames from 'classnames'

const Status = () => {
  const user = useSelector(selectUser)
  const [dati, setDati] = useState([])
  const [loading, setLoading] = useState(true)
  const [visualizzati, setVisualizzati] = useState(300)

  useEffect(() => {
    queryStatus(user)
      .then((response) => {
        setDati(response.user)
        setLoading(false)
      })
      .catch((e) => console.error(e.message))
  }, [])

  const cancellaUser = (id) => {
    if (confirm('Sei sicuro di voler modificare il fiscostatus?')) {
      queryCancellaFiscoStatus(user, id)
      setDati(
        dati.map((d) =>
          d.id === id ? { ...d, fisco_status: null, active: null, moroso: null } : d,
        ),
      )
    }
  }

  const csvDataAdapter = (data) =>
    dati?.map((d) => ({
      status: d.fisco_status,
      commercialista: d.commercialista_id,
      created_at: moment(d.created_at).format('DD/MM/YYYY'),
      anno_apertura: d.anno_apertura_p_iva,
      active: toString(d.active),
      moroso: toString(d.moroso),
      email: d.email,
      medico_id: d.medico_id,
      nome: d.nome,
    }))

  const toString = (value) => {
    if (value === null) {
      return 'null'
    }
    if (value === true) {
      return 'true'
    }
    if (value === false) {
      return 'false'
    }
    return value
  }

  const vaoriDaVisualizzare = dati?.slice(0, visualizzati).map((dato, index) => {
    return (
      <tr
        key={index}
        className={classNames(
          { 'table-danger': dato.fisco_status === 'inactive' },
          { 'table-success': dato.fisco_status === 'active' },
          { 'table-warning': dato.fisco_status === 'pending' },
        )}
      >
        <td>{dato.fisco_status}</td>
        <td>{dato.commercialista_id}</td>
        <td>{moment(dato.created_at).format('DD/MM/YYYY')}</td>
        <td>{dato.anno_apertura_p_iva}</td>
        <td>{toString(dato.active)}</td>
        <td>{toString(dato.moroso)}</td>
        <td>{dato.email}</td>
        <td>{dato.medico_id}</td>
        <td>{dato.nome}</td>
        <td>{dato.note?.slice(0, 50)}</td>
        <td>
          <i
            className="bi bi-trash3"
            style={{ cursor: 'pointer' }}
            onClick={() => cancellaUser(dato.id)}
          ></i>
        </td>
      </tr>
    )
  })

  return (
    <Container fluid>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Card body className="my-3">
          <Row>
            <Col xs="8">
              Cliccando sul cestino si cambia fisco_status, active e moroso a null su
              user
            </Col>
            <Col xs="4">
              <CSVLink data={csvDataAdapter(dati)}>
                <Button color="secondary" className="float-end" outline size="sm">
                  <i className="bi bi-filetype-csv"></i> Scarica
                </Button>
              </CSVLink>
            </Col>
          </Row>
          <br />
          <Table size="sm" hover className="mt-2">
            <thead>
              <tr>
                <th className="h6 font-regular">Status</th>
                <th className="h6 font-regular">Commercialista</th>
                <th className="h6 font-regular">Creato il</th>
                <th className="h6 font-regular">Anno apertura</th>
                <th className="h6 font-regular">Active</th>
                <th className="h6 font-regular">Moroso</th>
                <th className="h6 font-regular">Email</th>
                <th className="h6 font-regular">Medico id</th>
                <th className="h6 font-regular">Nome</th>
                <th className="h6 font-regular">Note</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{vaoriDaVisualizzare}</tbody>
          </Table>
          <center>
            <Button
              color="secondary"
              className="my-3"
              outline
              onClick={() => setVisualizzati(visualizzati + 300)}
            >
              Carica altri
            </Button>
          </center>
        </Card>
      )}
    </Container>
  )
}

export default Status
