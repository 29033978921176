import React from 'react'
import { Badge, Row, Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { apriFileDichiarazioneAllegatoDaUtente } from '../../../../app/store/fileViewer/actions'

const Domanda = ({ domanda }) => {
  const dispatch = useDispatch()

  const visualizzaFile = (files) =>
    files.map((file, index) => (
      <div key={index}>
        <br />
        <span
          className="testo-blu"
          onClick={() =>
            dispatch(
              apriFileDichiarazioneAllegatoDaUtente({
                nome: file.nome,
                uuid: file.uuid,
              }),
            )
          }
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {file.nome}
        </span>
        <br />
      </div>
    ))

  return (
    <Row>
      <Col xs={8}>
        <span className="text-muted">{domanda.domanda}</span>:{' '}
      </Col>
      <Col xs={4}>
        <span>
          {domanda.risposta === true && <Badge color="success">Sì</Badge>}
          {domanda.risposta === false && <Badge color="danger">No</Badge>}
          {domanda.risposta === null ||
            (domanda.risposta === '' && (
              <Badge color="secondary">Non risposto</Badge>
            ))}
          {domanda.risposta !== true &&
            domanda.risposta !== false &&
            domanda.risposta !== null && (
              <span className="testo-grigio">{domanda.risposta}</span>
            )}
        </span>
      </Col>
      <Col xs={12}>
        {visualizzaFile(domanda.files)}
        <hr />
      </Col>
    </Row>
  )
}

export default Domanda
