import React from 'react'
import { Card, Badge, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'

import { apriFileCassetto } from '../../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../../app/store/fileViewer/selectors'
import classNames from 'classnames'
import { toggleCu } from '../../../../app/store/gestionaleUtente/actions'

const CU = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingFile)

  const cuAnnoCorrente = dati?.cassetto?.cu?.filter(
    (cu) => cu.anno === parseInt(dati.anno),
  )

  return (
    <Card body className="mt-4">
      <span className="h5">Certificazioni Uniche</span>
      {cuAnnoCorrente?.length > 0 ? (
        <>
          <br />
          {cuAnnoCorrente.map((cu, index) => {
            return (
              <div key={index}>
                {index > 0 && <hr />}
                <Row>
                  <Col xs="9">
                    {cu.ragione_sociale_azienda}{' '}
                    <span className="text-muted">{cu.partita_iva_azienda}</span>
                  </Col>

                  <Col xs="3">
                    <Badge
                      color={classNames(
                        { primary: !loading },
                        { secondary: loading },
                      )}
                      onClick={(e) =>
                        dispatch(apriFileCassetto('cu/' + cu.nome_file))
                      }
                      style={{ cursor: 'pointer' }}
                      disabled={loading}
                    >
                      <i className="bi bi-eye"></i>
                    </Badge>
                    <Badge
                      color={cu.controllato ? 'success' : 'danger'}
                      className="ms-2"
                      onClick={() => dispatch(toggleCu(cu.nome_file))}
                    >
                      <i className={cu.controllato ? 'bi bi-check' : 'bi bi-x'}></i>
                    </Badge>
                  </Col>
                </Row>
              </div>
            )
          })}
        </>
      ) : (
        <div className="alert alert-secondary mt-3" role="alert">
          Non ci sono CU per il {dati.anno}
        </div>
      )}
    </Card>
  )
}

export default CU
