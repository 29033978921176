// latin-1 regex validator function
export const latin1Valid = (value) => {
  if (value === undefined || value === null || value === '') {
    return false
  }
  return !/[^\u0000-\u00ff]/g.test(value)
}

export const latin1Invalid = (value) => {
  if (value === undefined || value === null || value === '') {
    return false
  }
  return /[^\u0000-\u00ff]/g.test(value)
}

export const codiceFiscaleValid = (value) => {
  if (value === undefined || value === null || value === '' || value.length !== 16) {
    return false
  }
  const regex =
    /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  if (value.match(regex)) {
    return true
  } else {
    return false
  }
}
