import React, { useRef, useState } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Input,
  Spinner,
  FormGroup,
  Label,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import { leadChiamata } from '../../../app/utility/QueryLanding'
import swal from '@sweetalert/with-react'
import { leadChiamataGA } from '../../../app/utility/GA'
import { lead } from '../../../app/utility/SwalContent'

const FiscomedMobile = () => {
  const refComeFunziona = useRef(null)
  const refIniziaOra = useRef(null)
  const [teamAperto, setTeamAperto] = useState(false)
  const [caricamento, setCaricamento] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [message, setMessage] = useState('')
  const [consenso, setConsenso] = useState(false)

  const prenotaChiamata = async () => {
    if (
      nome === '' ||
      email === '' ||
      telefono === '' ||
      consenso === false ||
      message === ''
    ) {
      swal({
        title: 'Attenzione',
        text: 'Compila tutti i campi',
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    leadChiamataGA()
    await leadChiamata({ nome, email, telefono, message }).then((res) => {
      setCaricamento(false)
      swal(lead)
      setNome('')
      setEmail('')
      setTelefono('')
      setMessage('')
      setConsenso(false)
    })
  }

  return (
    <>
      <Helmet>
        <title>Fiscomed.it</title>
        <meta property="title" content="Fiscomed.it" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fiscomed.it" />
        <meta
          property="og:description"
          content="Gestiamo la Partita IVA dei giovani medici con un team di commercialisti specializzati. Richiedi una consulenza"
        />
        <meta
          property="description"
          content="Gestiamo la Partita IVA dei giovani medici con un team di commercialisti specializzati. Richiedi una consulenza"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }} className="mobile-container">
        <br />
        <br />
        <div className="header-landing-mobile-fiscomed" />
        <div>
          <h1 className="titolo1-mobile">
            La consulenza fiscale dedicata ai medici
          </h1>
          <br />
          <h6 className="font-regular interlinea-30">
            Con <span className="font-bold">Fiscomed</span> puoi affidare la tua
            gestione fiscale a un commercialista specializzato e monitorare la tua
            situazione fiscale su un&apos;unica piattaforma
          </h6>
          <br />
          <center>
            <Button
              className="bottone-verde-outline mb-3"
              block
              onClick={() =>
                refComeFunziona.current.scrollIntoView({ behavior: 'smooth' })
              }
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              COME FUNZIONA
            </Button>
            <Button
              className="bottone-verde"
              block
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              INIZIA ORA
            </Button>
          </center>
          <br />
          <img
            src="/img_landing/immagine_fiscomed.webp"
            alt="fiscomed"
            style={{ width: '100%' }}
          />
          <br />
          <br />
          <br />
          <h2 className="titolo2-mobile">Perché Fiscomed</h2>
          <br />
          <h6 className="font-regular interlinea-30">
            Dopo aver ascoltato tanti giovani dottori e dottoresse in Medicina
            abbiamo capito che Partita IVA, tasse e fatturazione sono fonte di{' '}
            <span className="font-bold">ansia</span> e{' '}
            <span className="font-bold">tanti dubbi.</span>
            <br />
            <br />
            <span className="font-bold">Di addio alle tue preoccupazioni:</span> con
            Fiscomed la gestione fiscale non sarà più un problema.
          </h6>
          <br />
          <br />
          <Row>
            <Col xs="12" className="mb-5">
              <div className="ms-5">
                <img
                  src="/img_landing/nuvola.webp"
                  alt="nuvola di colore"
                  className="nuvola-mobile"
                />
                <h1 className="testo-blu" style={{ fontWeight: 600 }}>
                  600 +
                </h1>
                <h6 className="font-regular interlinea-25">
                  Medici hanno aperto la
                  <br /> Partita IVA con Fiscomed
                </h6>
              </div>
            </Col>
            <Col xs={12} className="mb-5">
              <div className="float-end me-5">
                <div>
                  <img
                    src="/img_landing/nuvola.webp"
                    alt="nuvola di colore"
                    className="nuvola-mobile"
                  />
                  <h1 className="push-left testo-blu" style={{ fontWeight: 600 }}>
                    9300 +
                  </h1>
                  <h6 className="font-regular interlinea-25 push-left">
                    Fatture emesse dalla
                    <br /> nostra piattaforma
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={12} className="mb-4">
              <div className="ms-5">
                <img
                  src="/img_landing/nuvola.webp"
                  alt="nuvola di colore"
                  className="nuvola-mobile"
                />
                <h1 className="testo-blu" style={{ fontWeight: 600 }}>
                  900 +
                </h1>
                <h6 className="font-regular interlinea-25">
                  Medici hanno scelto i<br /> nostri commercialisti
                </h6>
              </div>
            </Col>
          </Row>
          <br ref={refComeFunziona} />
          <br />
          <br />
          <center>
            <span className="etichetta-mobile">Come funziona</span>
            <br />
            <br />

            <br />
            <span className="titolo2-mobile">
              Ti semplifichiamo la gestione fiscale
              <br />
              <span className="testo-verde">con un supporto umano e digitale</span>
            </span>
          </center>
          <br />
          <br />
          <img
            src="/img_landing/elisse_verde.webp"
            alt="nuvola di colore"
            className="cerchio-verde-mobile"
          />
          <span className="titolo3-mobile">
            Il Dottore Commercialista dedicato alla tua Partita IVA
          </span>
          <br />
          <br />
          <h6 className="font-regular interlinea-30">
            Un professionista <span className="font-bold">specializzato</span> nella
            consulenza fiscale per giovani medici si occuperà di tutta la tua
            gestione fiscale e sarà lieto di risolvere i tuoi dubbi e domande.
          </h6>
          <br />
          <h6 className="font-regular interlinea-30">
            Sarai affidato/a a un Dottore Commercialista che ti aprirà la{' '}
            <span className="font-bold">Partita IVA</span>, si occuperà della tua
            dichiarazione dei redditi e ti farà dei resoconti della tua situazione
            fiscale
          </h6>
          <br />
          <br />
          <Row>
            <Col xs={3} className="mb-5">
              <i
                className="bi bi-compass float-end testo-blu"
                style={{ fontSize: '3.5rem' }}
              ></i>
            </Col>
            <Col xs={9} className="mb-5">
              <h6 className="font-light interlinea-30">
                Affidati a un commercialista di{' '}
                <span className="testo-blu">fiducia</span> che ti guiderà nella
                gestione fiscale
              </h6>
            </Col>
            <Col xs={3} className="mb-5">
              <i
                className="bi bi-heart float-end testo-blu"
                style={{ fontSize: '3.5rem' }}
              ></i>
            </Col>
            <Col xs={9} className="mb-5">
              <h6 className="font-light interlinea-30">
                Ricevi <span className="testo-blu">supporto</span> in tutti gli
                adempimenti della professione medica
              </h6>
            </Col>
            <Col xs={3} className="mb-5">
              <i
                className="bi bi-shield-lock float-end testo-blu"
                style={{ fontSize: '3.5rem' }}
              ></i>
            </Col>
            <Col xs={9} className="mb-5">
              <h6 className="font-light interlinea-30">
                L&apos;unico servizio chiaro nei prezzi: ottieni assistenza completa
                a un <span className="testo-blu">prezzo fisso</span>
              </h6>
            </Col>
          </Row>
          <center>
            <Button
              className="bottone-verde"
              style={{ paddingTop: '14px', paddingBottom: '14px', width: '170px' }}
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              INIZIA ORA
            </Button>
          </center>
          <br />
        </div>
      </Container>
      <br />
      <div className="outer-mobile-image">
        <div className="img-fiscomed-mobile-top" />
        <div className="img-fiscomed-mobile-bottom" />
      </div>
      <br />
      <br />
      <br />
      <Container className="mobile-container">
        <img
          src="/img_landing/elisse_giallo.webp"
          alt="nuvola di colore"
          className="cerchio-verde-mobile"
        />
        <span className="titolo3-mobile">
          La gestione digitale della tua situazione fiscale
        </span>
        <br />
        <br />
        <h6 className="font-regular interlinea-30">
          Nella tua area riservata puoi tenere sotto controllo gli{' '}
          <b>adempimenti fiscali</b> a cui dovrai far fronte, visualizzare le{' '}
          <b>statistiche</b> e condividere i documenti con il tuo commercialista.
        </h6>
        <br />
        <h6 className="font-regular interlinea-30">
          Non solo: puoi creare le <b>fatture</b> sia cartacee che elettroniche e
          vedere in maniera facile e immediata le <b>tasse</b> da pagare e le
          relative scadenze
        </h6>
        <br />
        <br />
        <Row>
          <Col xs={3} className="mb-5">
            <i
              className="bi bi-calendar-week float-end testo-blu"
              style={{ fontSize: '3.5rem' }}
            ></i>
          </Col>
          <Col xs={9} className="mb-5">
            <h6 className="font-light interlinea-30">
              Ci pensiamo noi a ricordarti le{' '}
              <span className="testo-blu">scadenze</span> nella tua area personale
            </h6>
          </Col>

          <Col xs={3} className="mb-5">
            <i
              className="bi bi-bar-chart float-end testo-blu"
              style={{ fontSize: '3.5rem' }}
            ></i>
          </Col>
          <Col xs={9} className="mb-5">
            <h6 className="font-light interlinea-30">
              Visualizza la previsione delle <span className="testo-blu">tasse</span>{' '}
              che dovrai pagare l&apos;anno successivo, è sempre aggiornata
            </h6>
          </Col>
          <Col xs={3} className="mb-5">
            <i
              className="bi bi-file-earmark float-end testo-blu"
              style={{ fontSize: '3.5rem' }}
            ></i>
          </Col>
          <Col xs={9} className="mb-5">
            <h6 className="font-light interlinea-30">
              Crea le tue <span className="testo-blu">fatture</span> grazie a un
              modulo precompilato ed evita così gli errori più comuni
            </h6>
          </Col>
        </Row>
        <center>
          <Button
            className="bottone-verde"
            style={{ paddingTop: '14px', paddingBottom: '14px', width: '170px' }}
            onClick={() =>
              refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
            }
          >
            INIZIA ORA
          </Button>
        </center>
        <br ref={refIniziaOra} />
        <br />
        <center>
          <span className="etichetta-mobile">Inizia ora</span>
          <br />
          <br />
          <br />
          <span className="titolo2-mobile">
            Parla con noi e scopri perché Fiscomed è la scelta giusta per un dottore
            o dottoressa <span className="testo-verde">come te</span>
          </span>
        </center>
        <br />
        <h6 className="font-regular interlinea-30">
          Scrivi qui i tuoi contatti e il momento in cui preferisci ricevere la
          chiamata. Non vediamo l&apos;ora di rispondere alle tue domande.
          Naturalmente non cederemo a nessuno i tuoi dati.
        </h6>
        <br />
        <div className="outer-center">
          <div className="card-contatto-mobile px-4 py-5">
            <FormGroup>
              <h6 className="font-medium mb-3">Come ti chiami?</h6>
              <Input
                type="text"
                placeholder="Ippocrate di Coo"
                style={{ border: 'none' }}
                autoComplete="name"
                onChange={(e) => {
                  setNome(e.target.value)
                }}
                value={nome}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <h6 className="font-medium mb-3">Qual è la tua email?</h6>
              <Input
                type="text"
                placeholder="ippocrate@email.com"
                style={{ border: 'none' }}
                autoComplete="email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                value={email}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <h6 className="font-medium mb-3">
                A quale numero possiamo chiamarti?
              </h6>
              <Input
                type="text"
                placeholder="3399957060"
                style={{ border: 'none' }}
                autoComplete="tel"
                onChange={(e) => {
                  setTelefono(e.target.value)
                }}
                value={telefono}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <h6 className="font-medium mb-3">Quando vuoi che ti chiamiamo?</h6>
              <Input
                type="textarea"
                placeholder="Indica un giorno compreso tra lunedì e venerdì e un orario tra le 9 e le 18. Per favore, dai più disponibilità in modo che possiamo accontentare tutte le persone che desiderano essere chiamate"
                rows="6"
                style={{ border: 'none' }}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
                value={message}
              />
            </FormGroup>
            <br />
            <FormGroup check>
              <Input
                type="checkbox"
                name="privacy"
                id="privacy"
                style={{ marginTop: '2px' }}
                checked={consenso}
                onChange={(e) => setConsenso(e.target.checked)}
              />
              <Label for="privacy" className="consenso">
                Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
                <a
                  href="/documenti/termini_e_condizioni.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>privacy policy</u>
                </a>
              </Label>
            </FormGroup>
            <br />
            <center>
              {caricamento ? (
                <Button
                  className="bottone-verde mt-4"
                  disabled
                  style={{
                    paddingTop: '14px',
                    paddingBottom: '14px',
                    width: '170px',
                  }}
                >
                  <Spinner color="light" size="sm" />
                </Button>
              ) : (
                <Button
                  className="bottone-verde mt-4"
                  style={{
                    paddingTop: '14px',
                    paddingBottom: '14px',
                    width: '170px',
                  }}
                  onClick={() => prenotaChiamata()}
                >
                  INIZIA ORA
                </Button>
              )}
            </center>
          </div>
        </div>
        <br />
        <br />
        <center>
          <span className="titolo2-mobile">Chi siamo</span>
        </center>
        <br />
        <br />
        <center>
          <img
            src="/img_landing/kp.png"
            alt="foto di Kirill"
            style={{ maxWidth: '200px' }}
          />
          <br />
          <br />
          <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
            Kirill
          </h5>
          <h5 className="testo-landing-fiscomed">Medico, CTO e founder</h5>
          <br />
          <br />
          <img
            src="/img_landing/es.png"
            alt="foto di Elisa"
            style={{ maxWidth: '200px' }}
          />
          <br />
          <br />
          <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
            Elisa
          </h5>
          <h5 className="testo-landing-fiscomed">CMO e co-founder</h5>
          <br />
          <br />
          <img
            src="/img_landing/ico.png"
            alt="foto di Ilenia"
            style={{ maxWidth: '200px' }}
          />
          <br />
          <br />
          <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
            Ilenia
          </h5>
          <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
          <br />

          {teamAperto ? (
            <>
              <br />
              <img
                src="/img_landing/gr.png"
                alt="foto di Giulia"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Giulia
              </h5>
              <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
              <br />
              <br />
              <img
                src="/img_landing/ji.png"
                alt="foto di Jacopo"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Jacopo
              </h5>
              <h5 className="testo-landing-fiscomed">Dottore Commercialista</h5>
              <br />
              <br />
              <img
                src="/img_landing/cg.png"
                alt="foto di Cristina"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Maria Cristina
              </h5>
              <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
            </>
          ) : (
            <Button
              className="bottone-verde px-2 mt-4"
              onClick={() => setTeamAperto(true)}
              block
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              INCONTRA GLI ALTRI COMMERCIALISTI DEL TEAM
            </Button>
          )}
        </center>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  )
}

export default FiscomedMobile
