import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardText, CardTitle, Button } from 'reactstrap'

const CardArchivio = () => (
  <Card body className="p-4">
    <CardTitle className="h4 font-grigio-scuro">Un tuffo nel passato</CardTitle>
    <h6 className="font-regular mb-3 interlinea-30">
      Nell&apos;archivio puoi trovare gli F24 e il Modello Redditi degli anni
      passati. Se li vuoi consultare, clicca sul bottone qui sotto
    </h6>
    <center>
      <CardText>
        <Button
          className="bottone-giallo my-2"
          tag={Link}
          to="/dichiarazione/archivio"
        >
          <i className="bi bi-archive"></i>&nbsp; ARCHIVIO
        </Button>
      </CardText>
    </center>
  </Card>
)

export default CardArchivio
