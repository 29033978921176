import React, { useState } from 'react'
import {
  createCommercialista,
  updateCommercialista,
} from '../../../app/utility/QueryOperation'
import { Container, Row, Col, FormGroup, Card, Input, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'

const Commercialista = ({ commercialista, isNew, setShowNewCommercialista }) => {
  const user = useSelector(selectUser)
  const [editing, setEditing] = useState(!!isNew)
  const [updatedCommercialista, setUpdatedCommercialista] = useState(
    getEditableFields({ commercialista, isNew }),
  )

  const onSave = async () => {
    if (isNew) {
      if (
        updatedCommercialista.nome?.length > 0 &&
        updatedCommercialista.cognome?.length > 0 &&
        updatedCommercialista.email?.length > 0 &&
        updatedCommercialista.password?.length > 0 &&
        updatedCommercialista.link_consulenza?.length > 0 &&
        updatedCommercialista.mandrill_template?.length > 0
      ) {
        const data = {
          ...updatedCommercialista,
          password_confirmation: updatedCommercialista.password,
        }
        await createCommercialista(user, data)
        window.location.reload()
      } else {
        alert('Ci sono delle informazioni mancanti')
      }
    } else {
      const { id, ...updateFields } = updatedCommercialista
      await updateCommercialista(user, id, updateFields)
      setEditing(false)
    }
  }

  const onInputChange = (key, newValue) => {
    setUpdatedCommercialista({
      ...updatedCommercialista,
      [key]: newValue,
    })
  }

  const handleEdit = (newEditingState) => {
    setEditing(newEditingState)
    if (!newEditingState) {
      if (isNew) {
        setShowNewCommercialista(false)
      } else {
        setUpdatedCommercialista(getEditableFields({ commercialista }))
      }
    }
  }

  return (
    <Card className="p-4 mb-5">
      <Row>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Nome</h6>
            <Input
              type="text"
              value={updatedCommercialista.nome || ''}
              disabled={!editing}
              onChange={(e) => onInputChange('nome', e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Cognome</h6>
            <Input
              type="text"
              value={updatedCommercialista.cognome || ''}
              disabled={!editing}
              onChange={(e) => onInputChange('cognome', e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Email</h6>
            <Input
              type="text"
              value={updatedCommercialista.email || ''}
              disabled={!editing}
              onChange={(e) => onInputChange('email', e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      {isNew && (
        <CreateFields
          updatedCommercialista={updatedCommercialista}
          onInputChange={onInputChange}
        />
      )}
      <Row>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Fiscobisca</h6>
            <Input
              type="select"
              value={updatedCommercialista.partecipante_fiscobisca ?? true}
              disabled={!editing}
              onChange={(e) =>
                onInputChange('partecipante_fiscobisca', e.target.value)
              }
            >
              <option>true</option>
              <option>false</option>
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Tag Mailchimp</h6>
            <Input
              type="text"
              value={updatedCommercialista.tag_mailchimp || ''}
              disabled={!editing}
              onChange={(e) => onInputChange('tag_mailchimp', e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Account di test</h6>
            <Input
              type="select"
              value={updatedCommercialista.test_account ?? false}
              disabled={!editing}
              onChange={(e) => onInputChange('test_account', e.target.value)}
            >
              <option>true</option>
              <option>false</option>
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <h6 className="font-medium mb-3">Riceve nuovi clienti</h6>
            <Input
              type="select"
              value={updatedCommercialista.riceve_nuovi_clienti ?? true}
              disabled={!editing}
              onChange={(e) => onInputChange('riceve_nuovi_clienti', e.target.value)}
            >
              <option>true</option>
              <option>false</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup>
          <h6 className="font-medium mb-3">Link calendly</h6>
          <Input
            type="text"
            value={updatedCommercialista.link_consulenza || ''}
            disabled={!editing}
            onChange={(e) => onInputChange('link_consulenza', e.target.value)}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <h6 className="font-medium mb-3">Mandrill template</h6>
          <Input
            type="text"
            value={updatedCommercialista.mandrill_template || ''}
            disabled={!editing}
            onChange={(e) => onInputChange('mandrill_template', e.target.value)}
          />
        </FormGroup>
      </Row>
      <Container className="d-flex justify-content-end px-0">
        {editing && (
          <Button className="bottone-verde me-2" onClick={onSave}>
            Salva
          </Button>
        )}
        <Button
          className={editing ? 'bottone-rosso' : 'bottone-giallo'}
          onClick={() => handleEdit(!editing)}
        >
          {editing ? 'Annulla' : 'Modifica'}
        </Button>
      </Container>
    </Card>
  )
}

const CreateFields = ({ updatedCommercialista, onInputChange }) => (
  <Row>
    <Col>
      <FormGroup>
        <h6 className="font-medium mb-3">Password</h6>
        <Input
          type="text"
          value={updatedCommercialista.password || ''}
          onChange={(e) => onInputChange('password', e.target.value)}
        />
      </FormGroup>
    </Col>
    <Col>
      <FormGroup>
        <h6 className="font-medium mb-3">Genere</h6>
        <Input
          type="select"
          value={updatedCommercialista.uomo ?? true}
          onChange={(e) => onInputChange('uomo', e.target.value)}
        >
          <option value={true}>Uomo</option>
          <option value={false}>Donna</option>
        </Input>
      </FormGroup>
    </Col>
  </Row>
)

const getEditableFields = ({ commercialista, isNew }) => {
  const fields = [
    'id',
    'nome',
    'cognome',
    'email',
    'partecipante_fiscobisca',
    'test_account',
    'riceve_nuovi_clienti',
    'mandrill_template',
    'tag_mailchimp',
    'link_consulenza',
  ]

  const data = isNew
    ? {}
    : fields.reduce((acc, key) => ({ ...acc, [key]: commercialista[key] }), {})

  return {
    ...data,
    ...(isNew ? { password: '', uomo: true } : {}),
  }
}

export default Commercialista
