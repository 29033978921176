import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import moment from 'moment'

const Terreni = () => {
  const dati = useSelector(selectGestionaleUtente)
  const [dataDiNascitaIsCorrect, setDataDiNascitaIsCorrect] = useState(true)

  useEffect(() => {
    if (dati.cassetto?.catasto?.data_di_nascita_terreni === null) return
    if (
      dati.cassetto?.catasto?.data_di_nascita_terreni !==
      moment(dati.anagrafica.data_di_nascita).format('DD/MM/YYYY')
    )
      setDataDiNascitaIsCorrect(false)
  }, [dati.cassetto])

  return (
    <Card body className="mt-4">
      <span className="h5">Terreni</span>
      {dati.cassetto?.last_sync !== null && (
        <>
          {dati.cassetto?.catasto?.anomalia_terreni === false &&
          dataDiNascitaIsCorrect ? (
            <>
              {dati.cassetto?.catasto?.terreni.length > 0 ? (
                <table className="table table-striped table-bordered mb-5">
                  <thead>
                    <tr>
                      <th scope="col">Titolarità</th>
                      <th scope="col">Comune</th>
                      <th scope="col">Foglio</th>
                      <th scope="col">Particella</th>
                      <th scope="col">Subalterno</th>
                      <th scope="col">Qualità</th>
                      <th scope="col">Classe</th>
                      <th scope="col">Ha are ca</th>
                      <th scope="col">Reddito dominicale</th>
                      <th scope="col">Reddito agrario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dati.cassetto?.catasto?.terreni.map((terreno, index) => {
                      return (
                        <tr key={index}>
                          <td>{terreno.titolarita}</td>
                          <td>{terreno.comune}</td>
                          <td>{terreno.foglio}</td>
                          <td>{terreno.particella}</td>
                          <td>{terreno.subalterno}</td>
                          <td>{terreno.qualita}</td>
                          <td>{terreno.classe}</td>
                          <td>{terreno.ha_are_ca}</td>
                          <td>{terreno.reddito_dominicale}</td>
                          <td>{terreno.reddito_agrario}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-secondary mt-3" role="alert">
                  Non risultano terreni
                </div>
              )}
            </>
          ) : (
            <div className="alert alert-danger mt-3" role="alert">
              Anamolia sul catasto terreni, contattare l'assistenza tecnlica
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default Terreni
