import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Button,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Spinner,
} from 'reactstrap'
import {
  listProforma,
  scaricaProformaPdf,
  cancellaProforma,
  inviaEmailProforma,
} from '../../app/utility/QueryFattura'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { selectIsLoadingUser, selectUser } from '../../app/store/user/selectors'

const ProformaContainer = () => {
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectIsLoadingUser)
  const [infoFetched, setInfoFetched] = useState(false)
  const [proforma, setProforma] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [proformaSelezionata, setProformaSelezionata] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        listProforma(user).then((list) => {
          setProforma(list)
        })
      }
    }
  }, [isLoading])

  const apriModale = (datiProforma) => {
    setModalOpen(true)
    setProformaSelezionata(datiProforma)
  }

  const inviaEmail = () => {
    setLoading(true)
    if (
      proformaSelezionata.cliente_email === null ||
      proformaSelezionata.cliente_email === ''
    ) {
      swal({
        title: 'Attenzione',
        text: "Non hai inserito l'email del cliente. Compila il campo e riprova",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    inviaEmailProforma(
      user,
      proformaSelezionata.id,
      proformaSelezionata.cliente_email,
    ).then((response) => {
      if (response.email_inviata === true) {
        swal({
          title: 'Fattura inviata',
          text: 'Il tuo cliente riceverà la fattura a breve 😊',
          icon: 'success',
          button: 'Ok',
        })
        setModalOpen(false)
        setLoading(false)
      } else {
        swal({
          title: 'Errore',
          text: "Non siamo riusciti ad inviare la tua fattura al cliente. Riprova tra un'ora oppure scrivici su WhatsApp",
          icon: 'error',
          button: 'Ok',
        })
        setModalOpen(false)
        setLoading(false)
      }
    })
  }

  const madaleEmail = () => (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
      <ModalHeader
        toggle={() => setModalOpen(!modalOpen)}
        className="font-regular font-grigio-scuro"
      >
        Invia la fattura proforma per email
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Scrivi l&apos;indirizzo email del cliente e clicca il tasto &quot;Invia
          email&quot; per inviare la fattura proforma 😊
        </h6>
        <br />
        <FormGroup>
          <Input
            type="text"
            placeholder="Inserisci l'email del cliente"
            value={
              proformaSelezionata.cliente_email === null
                ? ''
                : proformaSelezionata.cliente_email
            }
            onChange={(e) =>
              setProformaSelezionata({
                ...proformaSelezionata,
                cliente_email: e.target.value,
              })
            }
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => inviaEmail()} className="bottone-blu">
          {loading ? <Spinner size="sm" /> : 'Invia email'}
        </Button>
      </ModalFooter>
    </Modal>
  )

  const cancellaFile = (idProforma) => {
    if (window.confirm('Cancellare definitavemente il file ?')) {
      cancellaProforma(user, idProforma).then((list) => setProforma(list))
    }
  }

  const riepilogoProforma = proforma.map((objProforma, i) => (
    <tr key={i}>
      <td>
        <span className="testo-verde">
          {moment(objProforma.data_emissione).format('DD/MM/YYYY')}
        </span>
      </td>
      <td>{objProforma.cliente_ragione_sociale}</td>
      <td>{objProforma.totale / 100} €</td>
      <td
        className="text-primary"
        style={{ cursor: 'pointer' }}
        onClick={() => scaricaProformaPdf(user, objProforma.id)}
      >
        <center>
          <i className="bi bi-cloud-arrow-down"></i>
        </center>
      </td>
      <td
        className="text-danger"
        style={{ cursor: 'pointer' }}
        onClick={() => cancellaFile(objProforma.id)}
      >
        <center>
          <i className="bi bi-trash"></i>
        </center>
      </td>
      <td
        className="text-primary"
        style={{ cursor: 'pointer' }}
        onClick={() => apriModale(objProforma)}
      >
        <center>
          <i className="bi bi-envelope"></i>
        </center>
      </td>
    </tr>
  ))

  return (
    <Container className="mt-4 mb-5">
      <Helmet>
        <title>Proforma</title>
        <meta property="title" content="Proforma" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {madaleEmail()}
      <Card body>
        <div className="d-flex justify-content-between">
          <h4 className="font-medium">
            <i className="bi bi-file-post"></i> Proforma
          </h4>
          {proforma.length !== 0 && (
            <div>
              <Button
                tag={Link}
                to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
                className="bottone-blu float-end"
              >
                CREA PROFORMA
              </Button>
            </div>
          )}
        </div>
        <h6 className="mt-3 font-regular interlinea-30">
          Lo sai che prima di inviare una fattura vera e propria puoi inviare un
          documento senza valore fiscale? Si tratta della fattura proforma. È un
          documento molto utile sia per il cliente che per chi emette la fattura.
          <br />
          <ul>
            <li>
              Da un lato, infatti, il cliente può verificare che tutti i dati
              inseriti siano corretti.
            </li>
            <li>
              Dall&apos;altro lato, tu che emetti la fattura, puoi avere la certezza
              di emettere un documento fiscale corretto.
            </li>
          </ul>
        </h6>

        {proforma.length !== 0 ? (
          <>
            <Table className="mt-5 table-gialla-bianca">
              <thead>
                <tr>
                  <th className="h5 font-medium">Data emissione</th>
                  <th className="h5 font-medium">Cliente</th>
                  <th className="h5 font-medium">Importo</th>
                  <th className="h5 font-medium">Scarica</th>
                  <th className="h5 font-medium">Cancella</th>
                  <th className="h5 font-medium">Invia email</th>
                </tr>
              </thead>
              <tbody>{riepilogoProforma}</tbody>
            </Table>
          </>
        ) : (
          <center>
            <h4 className="font-medium">Non hai ancora creato un proforma</h4>
            <h6 className="font-regular">
              Clicca sul tasto qui sotto per fare la tua prima fattura proforma
            </h6>
            <br />
            <Button
              tag={Link}
              to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
              className="bottone-blu"
            >
              CREA PROFORMA
            </Button>
          </center>
        )}
      </Card>
    </Container>
  )
}

export default ProformaContainer
