import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Abbonamento from '../../pagineSingole/Abbonamento'
import InformazioniFiscali from '../../pagineSingole/InformazioniFiscali'
import Rubrica from '../../pagineSingole/Rubrica'
import VuoiUnaMano from '../../pagineSingole/VuoiUnaMano'
import MetodoDiPagamento from '../../pagineSingole/MetodoDiPagamento'
import RiepilogoContainer from '../../riepilogoFatture/RiepilogoContainer'
import DichiarazioneProvider from '../../../app/context/Dichiarazione'
import DichiarazioneContainer from '../../dichiarazione/DichiarazioneContainer'
import Archivio from '../../dichiarazione/Archivio'
import EnpamContainer from '../../enpam/EnpamContainer'
import ImportaDocumento from '../../importaDocumento/ImportaDocumento'

const MedicoMorosoRoutes = () => (
  <Switch>
    <Route exact path="/riepilogo-fatture">
      <RiepilogoContainer />
    </Route>
    <Route exact path="/enpam">
      <EnpamContainer />
    </Route>
    <Route exact path="/dichiarazione">
      <DichiarazioneProvider>
        <DichiarazioneContainer />
      </DichiarazioneProvider>
    </Route>
    <Route exact path="/dichiarazione/archivio">
      <DichiarazioneProvider>
        <Archivio />
      </DichiarazioneProvider>
    </Route>
    <Route exact path="/metodo-di-pagamento">
      <MetodoDiPagamento />
    </Route>
    <Route exact path="/abbonamento">
      <Abbonamento />
    </Route>
    <Route exact path="/informazioni-fiscali">
      <InformazioniFiscali />
    </Route>
    <Route exact path="/rubrica">
      <Rubrica />
    </Route>
    <Route exact path="/chat-commercialista">
      <VuoiUnaMano />
    </Route>
    <Route exact path="/importa-documento">
      <ImportaDocumento />
    </Route>
    <Route>
      <RiepilogoContainer />
    </Route>
  </Switch>
)

export default MedicoMorosoRoutes
