import React from 'react'
import { Card, CardTitle, Progress } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const GraficoLimiteRicavi = (props) => {
  const user = useSelector(selectUser)
  const { report } = props

  const limiteRicavi = () => {
    const dataApertura = moment(user.data_apertura_partita_iva, 'YYYY-MM-DD')
    if (dataApertura.isSame(moment(), 'year')) {
      const inizioAnno = moment().startOf('year')
      const giorniDaSottrarre = dataApertura.diff(inizioAnno, 'days')
      const giorniTotaliAnno = dataApertura.isLeapYear() ? 366 : 365
      const giorniRimanenti = giorniTotaliAnno - giorniDaSottrarre
      return ((85000 / giorniTotaliAnno) * giorniRimanenti).toFixed(2)
    } else {
      return 85000
    }
  }

  return (
    <Card body className="my-4">
      <CardTitle className="h4 font-medium interlinea-30">
        <i className="bi bi-speedometer2"></i> Limite di ricavi
      </CardTitle>
      <h6 className="font-regular mb-3 interlinea-30">
        Questa barra di progresso indica quanto hai incassato e quanto ancora puoi
        incassare quest&apos;anno per rimanere nel limite del regime forfettario (
        {limiteRicavi()} €)
      </h6>
      <Progress
        value={parseInt(
          (report?.anno_corrente?.fatturato?.fatturato_libera_professione +
            report?.anno_corrente?.fatturato?.lordo_ca) /
            (limiteRicavi() / 100),
        )}
        barClassName="barra-verde"
      />
      <h6 className="font-regular mt-3 interlinea-30">
        Hai incassato{' '}
        {(
          report?.anno_corrente?.fatturato?.fatturato_libera_professione +
          report?.anno_corrente?.fatturato?.lordo_ca
        )?.toFixed(2)}{' '}
        € sui {limiteRicavi()} € incassabili
      </h6>
    </Card>
  )
}

export default GraficoLimiteRicavi
