import React from 'react'
import { Button, Card } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import Anagrafica from './Anagrafica'
import Deleghe from './Deleghe'
import ModalSync from './ModalSync'

const Cassetto = () => {
  const dati = useSelector(selectGestionaleUtente)

  return (
    <Card body className="mt-4">
      <div className="d-flex justify-content-end">
        <Anagrafica />
        <ModalSync />
        <Deleghe />
      </div>
      {dati.cassetto?.last_sync === null && (
        <div className="alert alert-secondary mt-3" role="alert">
          In attesa della prima sincronizzazione, verrà eseguita automaticamente
          appena il cliente attiva le deleghe
        </div>
      )}
    </Card>
  )
}

export default Cassetto
