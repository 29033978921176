import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Input } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import { selectUser } from '../../../../../app/store/user/selectors'
import SalvatoSulServer from '../../../../sharedComponent/SalvatoSulServer'
import { salvaNote } from '../../../../../app/utility/QueryShared'
import { useDispatch } from 'react-redux'
import { modificaNote } from '../../../../../app/store/gestionaleUtente/slice'

const Appunti = () => {
  const dati = useSelector(selectGestionaleUtente)
  const user = useSelector(selectUser)
  const [datiSalvati, setDatiSalvati] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [debounceId, setDebounceId] = useState(null)

  const aggiornaNote = (note) => {
    setDatiSalvati(false)
    dispatch(modificaNote(note))
    clearTimeout(debounceId)
    setDebounceId(setTimeout(() => salvaSulServer(note), 750))
  }

  const salvaSulServer = (note) => {
    salvaNote(user, dati.medico_id, note)
    setDatiSalvati(true)
  }

  return (
    <>
      <Button
        color="secondary"
        outline
        size="sm"
        className="me-2 position-relative"
        onClick={() => setModalIsOpen(true)}
        disabled={dati.loading}
      >
        <i className="bi bi-blockquote-right"></i> Appunti
      </Button>
      <Modal
        isOpen={modalIsOpen}
        size="xl"
        toggle={() => setModalIsOpen(!modalIsOpen)}
      >
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Appunti
        </ModalHeader>
        <ModalBody>
          <SalvatoSulServer salvato={datiSalvati} floatEnd />
          <Input
            type="textarea"
            name="note_admin"
            id="note_admin"
            value={dati.anagrafica.note_admin}
            onChange={(e) => aggiornaNote(e.target.value)}
            rows={30}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default Appunti
