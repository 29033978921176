import React, { useState, useEffect } from 'react'
import { Card, Col, Input, Row, Button, FormGroup, Label } from 'reactstrap'
import SalvatoSulServer from '../../../sharedComponent/SalvatoSulServer'
import { salvaInfo } from '../../../../app/utility/QueryLead'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'
import ModalAssegna from './ModalAssegna'
import {
  assegnaLead,
  scartaLead,
  chiamatoLead,
  queryToggleCantina,
  queryMoveUp,
  queryMoveDown,
} from '../../../../app/utility/QueryLead'
import PositionController from './PositionController'
import moment from 'moment'

const PrintLead = ({
  lead,
  operatori,
  ricaricaDati,
  assegnato,
  selectedOperatore,
  aspetto,
}) => {
  const user = useSelector(selectUser)
  const [datiSalvati, setDatiSalvati] = useState(true)
  const [firtsRun, setFirstRun] = useState(true)
  const [dati, setDati] = useState({
    operatore_uuid: selectedOperatore.public_uuid,
    lead_id: lead.id,
    ebook_letto: lead.ebook_letto === null ? '' : lead.ebook_letto,
    canale_di_acquisizione: lead.canale_di_acquisizione || '',
    nota: lead.nota || '',
    messaggio: lead.messaggio || '',
  })

  useEffect(() => {
    if (firtsRun) {
      setFirstRun(false)
      return
    }
    setDatiSalvati(false)
    const debounceId = setTimeout(() => salvaSulServer(), 500)
    return () => {
      return clearTimeout(debounceId)
    }
  }, [dati])

  const salvaSulServer = () => {
    salvaInfo(user, dati).then((risposta) => {
      setDatiSalvati(true)
      ricaricaDati(risposta)
    })
  }

  const assegna = (uuid_for_assignment) => {
    assegnaLead(user, dati.lead_id, uuid_for_assignment, dati.operatore_uuid).then(
      (risposta) => {
        ricaricaDati(risposta)
      },
    )
  }

  const scarta = () => {
    if (
      confirm(
        'Sei sicuro di voler scartare il lead? Questa scelta è irreversibile e i dati andranno persi',
      )
    ) {
      scartaLead(user, dati.lead_id, dati.operatore_uuid).then((risposta) => {
        ricaricaDati(risposta)
      })
    }
  }

  const chiamato = () => {
    chiamatoLead(user, dati.lead_id, dati.operatore_uuid).then((risposta) => {
      ricaricaDati(risposta)
    })
  }

  const toggleCantina = () => {
    queryToggleCantina(user, dati.lead_id, dati.operatore_uuid).then((risposta) => {
      ricaricaDati(risposta)
    })
  }

  const moveUp = () => {
    queryMoveUp(user, dati.lead_id, dati.operatore_uuid).then((risposta) => {
      ricaricaDati(risposta)
    })
  }

  const moveDown = () => {
    queryMoveDown(user, dati.lead_id, dati.operatore_uuid).then((risposta) => {
      ricaricaDati(risposta)
    })
  }

  return (
    <Card body className="mb-2">
      <div className="justify-content-between d-flex">
        <div>
          {aspetto === 'lead' && (
            <PositionController
              position={lead.position}
              moveUp={() => moveUp()}
              moveDown={() => moveDown()}
            />
          )}
        </div>
        <div className="float-end">
          <SalvatoSulServer salvato={datiSalvati} />
          <ModalAssegna
            assegna={(uuid_for_assignment) => assegna(uuid_for_assignment)}
            operatori={operatori}
          />
          <Button
            color="danger"
            outline
            size="sm"
            className="mx-3"
            onClick={() => scarta()}
          >
            <i className="bi bi-trash3"></i>
          </Button>
          <Button color="success" size="sm" onClick={() => chiamato()}>
            <i className="bi bi-check"></i> Chiamato
          </Button>
          {aspetto === 'cantina' && (
            <Button
              color="secondary"
              outline
              size="sm"
              className="mx-3"
              onClick={() => toggleCantina()}
            >
              <i className="bi bi-telephone"></i> Da chiamare oggi
            </Button>
          )}
          {aspetto === 'lead' && (
            <Button
              color="secondary"
              size="sm"
              outline
              className="mx-3"
              onClick={() => toggleCantina()}
            >
              <i className="bi bi-moon-stars"></i> In cantina
            </Button>
          )}
        </div>
      </div>
      <br />
      <Row>
        <Col xs={4} className="font-regular">
          <i className="bi bi-person"></i> {lead.nome}{' '}
          <small className="text-muted">
            {moment(lead.created_at).format('DD/MM/YYYY')}
          </small>
          <br />
          <i className="bi bi-envelope"></i> {lead.email}
          <br />
          <i className="bi bi-telephone"></i> {lead.telefono}
          <br />
        </Col>
        <Col xs={8} className="font-regular">
          <i className="bi bi-calendar-event"></i> {dati.messaggio}
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={8}>
          <Label className="font-regular">Note</Label>
          <Input
            type="textarea"
            value={dati.nota}
            onChange={(e) => setDati({ ...dati, nota: e.target.value })}
          />
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label className="font-regular">Ebook letto</Label>
            <Input
              type="select"
              defaultValue={dati.ebook_letto}
              onChange={(e) => setDati({ ...dati, ebook_letto: e.target.value })}
            >
              <option value={true}>Si</option>
              <option value={false}>No</option>
              <option value="">Non si sa</option>
            </Input>
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label className="font-regular">Come ci conosce?</Label>
            <Input
              type="text"
              value={dati.canale_di_acquisizione}
              onChange={(e) =>
                setDati({ ...dati, canale_di_acquisizione: e.target.value })
              }
            />
          </FormGroup>
        </Col>
      </Row>
    </Card>
  )
}

export default PrintLead
