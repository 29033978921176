import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

const TablePreviewMedico = ({ medici, user, handleSort }) => {
  moment.updateLocale('en', { invalidDate: '' })
  const history = useHistory()
  const [sortedMedici, setSortedMedici] = useState(medici)

  useEffect(() => {
    setSortedMedici(medici)
  }, [medici])

  return (
    <div>
      <Table bordered hover responsive className="my-3">
        <thead>
          <tr>
            <th onClick={() => handleSort('cognome')}>
              Cognome <i className="bi bi-sort-alpha-down"></i>
            </th>
            <th onClick={() => handleSort('nome')} style={{ cursor: 'pointer' }}>
              Nome <i className="bi bi-sort-alpha-down"></i>
            </th>
            <th onClick={() => handleSort('email')}>
              Email <i className="bi bi-sort-alpha-down"></i>
            </th>
            {user.role !== 'commercialista' && (
              <th onClick={() => handleSort('active')}>
                Attivo <i className="bi bi-sort-alpha-down"></i>
              </th>
            )}
            <th onClick={() => handleSort('telefono')}>
              Telefono <i className="bi bi-sort-alpha-down"></i>
            </th>
            <th onClick={() => handleSort('created_at')}>
              Iscritto da <i className="bi bi-sort-alpha-down"></i>
            </th>
            <th onClick={() => handleSort('data_apertura_p_iva')}>
              Apertura Partita IVA <i className="bi bi-sort-alpha-down"></i>
            </th>
            {user.role !== 'commercialista' && (
              <th onClick={() => handleSort('commercialista')}>
                Commercialista <i className="bi bi-sort-alpha-down"></i>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedMedici.map((medico) => (
            <tr
              key={medico.id}
              className={classNames(
                { 'table-danger': medico.stato === 'inactive' },
                { 'table-success': medico.stato === 'active' },
                { 'table-warning': medico.stato === 'pending' },
              )}
              onClick={() => history.push('/shared/user-profile/' + medico.id)}
            >
              <td>{medico.cognome}</td>
              <td>{medico.nome}</td>
              <td>{medico.email}</td>
              {user.role !== 'commercialista' && (
                <td>
                  {
                    <i
                      className={classNames(
                        'bi bi-person-fill',
                        { 'text-warning': medico.active && medico.moroso },
                        { 'text-success': medico.active && !medico.moroso },
                        { 'text-danger': !medico.active },
                      )}
                    ></i>
                  }
                </td>
              )}
              <td>{medico.telefono}</td>
              <td>{moment(medico.created_at).format('DD-MM-YYYY')}</td>
              <td>{moment(medico.data_apertura_p_iva).format('DD-MM-YYYY')}</td>
              {user.role !== 'commercialista' && <td>{medico.commercialista}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
      {sortedMedici.length === 0 && <center>Nessuna corrispondenza</center>}
    </div>
  )
}

export default TablePreviewMedico
