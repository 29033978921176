import React, { useEffect, useState } from 'react'
import {
  Card,
  Container,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  FormGroup,
} from 'reactstrap'
import {
  queryIdrataStripe,
  queryModificaSubscription,
  queryModificaStripeId,
  querySyncSubscription,
} from '../../app/utility/QueryOperation'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import CentredLoading from '../component/CentredLoading'
import classNames from 'classnames'

const updateModalEmpty = {
  isOpen: false,
  subscriptionId: null,
  note: '',
  visible: false,
}

const stripeIdModalEmpty = {
  isOpen: false,
  stripe_customer_id: '',
}

const Stripe = () => {
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const [datiFiltrati, setDatiFiltrati] = useState({
    daSincronizzare: [],
    nascosti: [],
    abbonamentoNonTrovato: [],
    abbonamentoScaduto: [],
    attivi: [],
    tutti: [],
    risultatiRicerca: [],
    senzaCustomerId: [],
  })
  const [printedData, setPrintedData] = useState(100)
  const [currentView, setCurrentView] = useState('abbonamentoScaduto')
  const [searchValue, setSearchValue] = useState('')
  const [updateModal, setUpdateModal] = useState(updateModalEmpty)
  const [stripeIdModal, setStripeIdModal] = useState(stripeIdModalEmpty)
  const [loadingAggiornamento, setLoadingAggiornamento] = useState(false)

  useEffect(() => {
    queryIdrataStripe(user).then((response) => {
      setDatiFiltrati({ ...datiFiltrati, tutti: response })
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (datiFiltrati.tutti === undefined || datiFiltrati.tutti.length === 0) return
    setCurrentView('risultatiRicerca')
    ricercaValori()
  }, [searchValue])

  const ricercaValori = () => {
    const risultatiRicerca = datiFiltrati.tutti.filter(
      (d) =>
        d.nome?.toLowerCase().includes(searchValue.toLowerCase()) ||
        d.cognome?.toLowerCase().includes(searchValue.toLowerCase()) ||
        d.email?.toLowerCase().includes(searchValue.toLowerCase()),
    )
    setDatiFiltrati({ ...datiFiltrati, risultatiRicerca })
  }

  useEffect(() => {
    if (datiFiltrati.tutti === undefined || datiFiltrati.tutti.length === 0) return
    filtraDati(datiFiltrati.tutti)
  }, [datiFiltrati.tutti])

  const filtraDati = (data) => {
    const daSincronizzare = data.filter(
      (d) =>
        d.subscription === null &&
        d.stripe_customer_id !== null &&
        d.stripe_customer_id !== '',
    )
    const nascosti = data.filter(
      (d) => d.subscription !== null && d.subscription.visible === false,
    )
    const abbonamentoNonTrovato = data.filter(
      (d) =>
        d.subscription !== null &&
        d.stripe_customer_id !== null &&
        d.stripe_customer_id !== '' &&
        d.subscription.visible &&
        d.subscription.status === 'vuoto',
    )
    const abbonamentoScaduto = data.filter(
      (d) =>
        d.subscription !== null &&
        d.stripe_customer_id !== null &&
        d.stripe_customer_id !== '' &&
        d.subscription.visible &&
        d.subscription.status === 'disattivato',
    )
    const attivi = data.filter(
      (d) =>
        d.subscription !== null &&
        d.stripe_customer_id !== null &&
        d.stripe_customer_id !== '' &&
        d.subscription.visible &&
        d.subscription.status === 'attivo',
    )
    const senzaCustomerId = data.filter(
      (d) => d.stripe_customer_id === null || d.stripe_customer_id === '',
    )
    const risultatiRicerca = data.filter(
      (d) =>
        d.nome?.toLowerCase().includes(searchValue.toLowerCase()) ||
        d.cognome?.toLowerCase().includes(searchValue.toLowerCase()) ||
        d.email?.toLowerCase().includes(searchValue.toLowerCase()),
    )
    setDatiFiltrati({
      ...datiFiltrati,
      daSincronizzare,
      nascosti,
      abbonamentoNonTrovato,
      abbonamentoScaduto,
      attivi,
      risultatiRicerca,
      senzaCustomerId,
    })
  }

  const salvaDati = () => {
    queryModificaSubscription(
      user,
      updateModal.subscriptionId,
      updateModal.note,
      updateModal.visible,
    ).then((response) => {
      setDatiFiltrati({ ...datiFiltrati, tutti: response })
      setLoading(false)
      setUpdateModal({ ...updateModal, isOpen: false })
    })
  }

  const salvaStripeId = () => {
    queryModificaStripeId(
      user,
      stripeIdModal.medico_id,
      stripeIdModal.stripe_customer_id,
    ).then((response) => {
      setDatiFiltrati({ ...datiFiltrati, tutti: response })
      setLoading(false)
      setStripeIdModal({ ...stripeIdModal, isOpen: false })
    })
  }

  const aggiorna = (medico_id) => {
    if (loadingAggiornamento) return
    setLoadingAggiornamento(true)
    querySyncSubscription(user, medico_id).then((response) => {
      setLoading(false)
      setLoadingAggiornamento(false)
      if (response === null) return
      setDatiFiltrati({ ...datiFiltrati, tutti: response })
    })
  }

  const tableData = datiFiltrati[currentView].slice(0, printedData).map((d) => (
    <tr key={d.medico_id}>
      <td>
        <span
          className="badge bg-warning"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            setStripeIdModal({
              ...stripeIdModal,
              isOpen: true,
              medico_id: d.medico_id,
              stripe_customer_id: d.stripe_customer_id,
            })
          }
        >
          <i className="bi bi-pencil"></i>
        </span>{' '}
        {d.stripe_customer_id}
      </td>
      <td>{d.email}</td>
      <td>{d.nome}</td>
      <td>{d.cognome}</td>
      <td>
        <i
          className={classNames(
            'bi bi-circle-fill',
            { 'text-warning': d.fisco_status === 'pending' },
            { 'text-success': d.fisco_status === 'active' },
            { 'text-danger': d.fisco_status === 'inactive' },
          )}
        ></i>{' '}
        <i
          className={classNames(
            'bi bi-person-fill',
            { 'text-warning': d.active && d.moroso },
            { 'text-success': d.active && !d.moroso },
            { 'text-danger': !d.active },
          )}
        ></i>
      </td>
      <td>{d.subscription ? d.subscription.status : 'N/A'}</td>
      <td>{d.subscription ? d.subscription.note : 'N/A'}</td>
      <td>
        {d.subscription && (
          <span
            className="badge bg-warning me-1"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setUpdateModal({
                ...updateModal,
                isOpen: true,
                subscriptionId: d.subscription.id,
                note: d.subscription.note || '',
                visible: d.subscription.visible,
              })
            }}
          >
            <i className="bi bi-pencil"></i>
          </span>
        )}
        <span
          className={classNames(
            'badge',
            { 'bg-primary': !loadingAggiornamento },
            { 'bg-secondary': loadingAggiornamento },
          )}
          style={{ cursor: 'pointer' }}
          onClick={() => aggiorna(d.medico_id)}
        >
          <i className="bi bi-arrow-counterclockwise"></i>
        </span>
      </td>
    </tr>
  ))

  const stripeModal = () => (
    <Modal
      isOpen={stripeIdModal.isOpen}
      toggle={() => setStripeIdModal({ ...stripeIdModal, isOpen: false })}
    >
      <ModalHeader
        className="font-regular"
        toggle={() => setStripeIdModal({ ...stripeIdModal, isOpen: false })}
      >
        Stripe Id
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Stripe customer id</Label>
          <Input
            type="text"
            value={stripeIdModal.stripe_customer_id}
            onChange={(e) =>
              setStripeIdModal({
                ...stripeIdModal,
                stripe_customer_id: e.target.value,
              })
            }
          />
        </FormGroup>
        <br />
        <Button
          color="primary"
          className="float-end"
          onClick={() => salvaStripeId()}
        >
          Salva
        </Button>
      </ModalBody>
    </Modal>
  )

  const modal = () => (
    <Modal
      isOpen={updateModal.isOpen}
      toggle={() => setUpdateModal({ ...updateModal, isOpen: false })}
    >
      <ModalHeader
        className="font-regular"
        toggle={() => setUpdateModal({ ...updateModal, isOpen: false })}
      >
        Modifica
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Nota</Label>
          <Input
            type="text"
            value={updateModal.note}
            onChange={(e) =>
              setUpdateModal({ ...updateModal, note: e.target.value })
            }
          />
        </FormGroup>

        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={!updateModal.visible}
              onChange={(e) =>
                setUpdateModal({ ...updateModal, visible: !e.target.checked })
              }
            />
            Nascosto
          </Label>
        </FormGroup>

        <br />
        <Button color="primary" className="float-end" onClick={() => salvaDati()}>
          Salva
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <Container fluid className="mt-4">
      {loading ? (
        <CentredLoading />
      ) : (
        <>
          <Card body className="mb-3">
            <div>
              <center>
                <Input
                  className="form-control"
                  bsSize="lg"
                  placeholder="🔍 Cerca per nome, cognome o email"
                  style={{ maxWidth: '550px' }}
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </center>
              <br />
              <br />
              <Button
                color="danger"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'abbonamentoScaduto'}
                onClick={() => setCurrentView('abbonamentoScaduto')}
              >
                Non paganti {datiFiltrati.abbonamentoScaduto.length}
              </Button>

              <Button
                color="danger"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'abbonamentoNonTrovato'}
                onClick={() => setCurrentView('abbonamentoNonTrovato')}
              >
                Senza abbonamento {datiFiltrati.abbonamentoNonTrovato.length}
              </Button>
              <Button
                color="danger"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'senzaCustomerId'}
                onClick={() => setCurrentView('senzaCustomerId')}
              >
                Senza id {datiFiltrati.senzaCustomerId.length}
              </Button>
              <Button
                color="warning"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'daSincronizzare'}
                onClick={() => setCurrentView('daSincronizzare')}
              >
                Da sincronizzare {datiFiltrati.daSincronizzare.length}
              </Button>

              <Button
                color="success"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'attivi'}
                onClick={() => setCurrentView('attivi')}
              >
                Paganti {datiFiltrati.attivi.length}
              </Button>
              <Button
                color="secondary"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'nascosti'}
                onClick={() => setCurrentView('nascosti')}
              >
                Nascosti {datiFiltrati.nascosti.length}
              </Button>
              <Button
                color="secondary"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'tutti'}
                onClick={() => setCurrentView('tutti')}
              >
                Tutti {datiFiltrati.tutti.length}
              </Button>
              <Button
                color="secondary"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'risultatiRicerca'}
                onClick={() => setCurrentView('risultatiRicerca')}
              >
                Risultati ricerca {datiFiltrati.risultatiRicerca.length}
              </Button>
            </div>
            <br />
            {datiFiltrati[currentView].length === 0 ? (
              <div className="alert alert-info mt-3" role="alert">
                Non ci sono medici in questa categoria
              </div>
            ) : (
              <>
                <table className="table table-striped table-hover my-4">
                  <thead>
                    <tr>
                      <th>Stripe Id</th>
                      <th>Email</th>
                      <th>Nome</th>
                      <th>Cognome</th>
                      <th>Status</th>
                      <th>Abbonamento</th>
                      <th>Appunti</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </table>
                {datiFiltrati[currentView].length > printedData && (
                  <div>
                    <center>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setPrintedData(printedData + 100)}
                      >
                        Mostra altri
                      </Button>
                    </center>
                  </div>
                )}
                {modal()}
                {stripeModal()}
              </>
            )}
          </Card>
        </>
      )}
    </Container>
  )
}

export default Stripe
