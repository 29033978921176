import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Input } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectDichiarazione } from '../../../../app/store/gestionaleUtente/selectors'
import Completato from './Completato'
import {
  uploadUnico,
  deleteUnico,
} from '../../../../app/store/gestionaleUtente/actions'
import { apriUnico } from '../../../../app/store/fileViewer/actions'

const Unico = () => {
  const dichiarazione = useSelector(selectDichiarazione)
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(null)

  const uploadFile = () => {
    dispatch(uploadUnico(fileToUpload))
  }

  const deleteFile = () => {
    if (confirm('Sei sicuro di voler eliminare il file?')) {
      dispatch(deleteUnico())
      setModalIsOpen(false)
    }
  }

  return (
    <>
      <Button
        className="mt-2 ms-2"
        color="secondary"
        outline
        size="sm"
        onClick={() => setModalIsOpen(true)}
      >
        Unico <Completato completato={dichiarazione?.modello_redditi !== null} />
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Modello Unico
        </ModalHeader>
        <ModalBody>
          {dichiarazione?.modello_redditi?.uudi ? (
            <div className="font-regular">
              Nome file:{' '}
              <span className="text-muted">
                {dichiarazione.modello_redditi.uudi}
              </span>
              <br />
              <br />
              <Button
                color="primary"
                className="float-end"
                onClick={() => dispatch(apriUnico(dichiarazione.modello_redditi.id))}
                disabled={false}
              >
                <i className="bi bi-eye"></i> Visualizza
              </Button>
              <Button
                color="danger"
                onClick={() => deleteFile()}
                className="float-end me-2"
                disabled={dichiarazione.loading}
              >
                {dichiarazione.loading ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bi bi-trash3"></i>
                )}
              </Button>
            </div>
          ) : (
            <>
              <FormGroup className="d-flex">
                <Input
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) => setFileToUpload(e.target.files[0])}
                  accept=".pdf"
                />
                <Button
                  color="primary"
                  className="ms-2"
                  onClick={() => uploadFile()}
                  disabled={fileToUpload === null || dichiarazione.loading}
                >
                  {dichiarazione.loading ? <Spinner size="sm" /> : 'Upload'}
                </Button>
              </FormGroup>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default Unico
