import React, { useEffect, useState } from 'react'
import PublicSmallNav from '../navBars/PublicSmallNav'
import PublicLargeNav from '../navBars/PublicLargeNav'

const PublicNavContainer = () => {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 767)

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 767) {
        setSmallScreen(true)
      } else {
        setSmallScreen(false)
      }
    })
  }, [])

  return <>{smallScreen ? <PublicSmallNav /> : <PublicLargeNav />}</>
}

export default PublicNavContainer
