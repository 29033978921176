export const searchLead = (query, leads, numeroRisultati = 20) => {
  if (query === '') {
    return leads.slice(0, numeroRisultati)
  }
  const queryLower = query.toLowerCase()
  const corrispondenze = leads
    .filter((lead) => {
      return (
        lead.email?.toLowerCase().includes(queryLower) ||
        lead.nome?.toLowerCase().includes(queryLower) ||
        lead.telefono?.toLowerCase().includes(queryLower)
      )
    })
    .slice(0, numeroRisultati)

  return corrispondenze
}
