import React from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import moment from 'moment'
import { selectFileManager } from '../../../app/store/fileManager/selectors'
import { useDispatch } from 'react-redux'
import { deleteFileBlocchetto } from '../../../app/store/fileManager/actions'
import { apriFileBlocchetto } from '../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../app/store/fileViewer/selectors'
import classNames from 'classnames'

export const TabellaCedolini = ({ setDocumentToUpdate }) => {
  const fileManager = useSelector(selectFileManager)
  const dispatch = useDispatch()
  const loadingFile = useSelector(selectLoadingFile)

  const tabella = fileManager?.grouped_documents?.cedolino[fileManager.anno]?.map(
    (element, i) => (
      <tr key={i}>
        <td>{element.numero}</td>
        <td>{moment(element.data_incasso).format('DD/MM/YYYY')}</td>
        <td>{element.importo}€</td>
        <td>{element.trattenuta_enpam}€</td>
        <td>
          <span
            className={classNames({ 'testo-blu': loadingFile === false })}
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(apriFileBlocchetto(element.id))}
          >
            {element.nome_originale_file.substring(0, 40)}
          </span>
        </td>
        <td className="font-regular">
          <center
            style={{ cursor: 'pointer' }}
            onClick={() => setDocumentToUpdate(element)}
          >
            <i className="bi bi-pencil"></i>
          </center>
        </td>
        <td className="text-danger">
          <center
            style={{ cursor: 'pointer' }}
            onClick={() =>
              dispatch(
                deleteFileBlocchetto({
                  id_documento: element.id,
                  tipologia: 'cedolino',
                }),
              )
            }
          >
            <i className="bi bi-trash"></i>
          </center>
        </td>
      </tr>
    ),
  )

  return (
    <>
      {fileManager?.grouped_documents?.cedolino[fileManager.anno]?.length ===
      undefined ? (
        <div className="alert alert-info mt-5" role="alert">
          <i className="bi bi-info-circle"></i> Non hai importato nessun cedolino con
          questa data d'incasso
        </div>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca mt-5">
          <thead>
            <tr>
              <th className="h6 font-regular">Numero</th>
              <th className="h6 font-regular">Data incasso</th>
              <th className="h6 font-regular">Importo</th>
              <th className="h6 font-regular">Trattenuta ENPAM</th>
              <th className="h6 font-regular">File</th>
              <th className="h6 font-regular"></th>
              <th className="h6 font-regular"></th>
            </tr>
          </thead>
          <tbody>{tabella}</tbody>
        </Table>
      )}
    </>
  )
}

export default TabellaCedolini
