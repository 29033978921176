export const selectFileManager = (state) => state.fileManager
export const selectLoadingFile = (state) => state.fileManager.loading
export const selectNFatture = (state) => state.fileManager.fattura.length
export const selectNCedolini = (state) => state.fileManager.cedolino.length
export const selectNBorseMmg = (state) => state.fileManager.borsa_mmg.length
export const selectNBorseSpecializzazione = (state) =>
  state.fileManager.borsa_specializzazione.length
export const selectNPrestazioniOccasionali = (state) =>
  state.fileManager.prestazione_occasionale.length
export const selectNFileCondivisi = (state) =>
  state.fileManager.file_condiviso.length
