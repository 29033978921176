import { createSlice } from '@reduxjs/toolkit'
import { emptyState } from './costants'
import * as actions from './actions'

const initialState = {
  ...emptyState,
}

const fileViewer = createSlice({
  name: 'fileViewer',
  initialState,
  reducers: {
    close: (state) => {
      state.modalIsOpen = false
      state.fileObject = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.apriFileCondiviso.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriFileCondiviso.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = action.payload
      state.isS3URL = true
      state.isBASE64 = false
    })
    builder.addCase(actions.apriFileCondiviso.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(actions.apriFileBlocchetto.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriFileBlocchetto.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = action.payload
      state.isS3URL = true
      state.isBASE64 = false
    })
    builder.addCase(actions.apriFileBlocchetto.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(actions.apriFatturaCartacea.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriFatturaCartacea.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = {
        url: action.payload.url,
        content_type: 'application/pdf',
        nome_file: 'fattura_cartacea.pdf',
      }
      state.isS3URL = true
      state.isBASE64 = false
    })
    builder.addCase(actions.apriFatturaCartacea.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(actions.apriFatturaElettronica.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriFatturaElettronica.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = {
        url: null,
        content_type: 'application/pdf',
        nome_file: 'fattura_elettronica.pdf',
      }
      state.isS3URL = false
      state.isBASE64 = true
      state.base64string = 'data:application/pdf;base64,' + action.payload.base64
    })
    builder.addCase(actions.apriFatturaElettronica.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(
      actions.apriFileDichiarazioneAllegatoDaUtente.pending,
      (state) => {
        state.loading = true
      },
    )
    builder.addCase(
      actions.apriFileDichiarazioneAllegatoDaUtente.fulfilled,
      (state, action) => {
        state.loading = false
        state.modalIsOpen = true
        state.fileObject = action.payload
        state.isS3URL = true
        state.isBASE64 = false
      },
    )
    builder.addCase(
      actions.apriFileDichiarazioneAllegatoDaUtente.rejected,
      (state) => {
        state.loading = false
      },
    )
    builder.addCase(actions.apriF24.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriF24.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = {
        url: action.payload.url,
        content_type: 'application/pdf',
        nome_file: action.payload.nome_file,
      }
      state.isS3URL = true
      state.isBASE64 = false
    })
    builder.addCase(actions.apriF24.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(actions.apriUnico.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriUnico.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = {
        url: action.payload.url,
        content_type: 'application/pdf',
        nome_file: action.payload.nome_file,
      }
      state.isS3URL = true
      state.isBASE64 = false
    })
    builder.addCase(actions.apriUnico.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(actions.apriFileCassetto.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriFileCassetto.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = action.payload
      state.isS3URL = true
      state.isBASE64 = false
    })
    builder.addCase(actions.apriFatturaAdE.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriFatturaAdE.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = {
        url: null,
        content_type: 'application/pdf',
        nome_file: 'fattura_elettronica.pdf',
      }
      state.isS3URL = false
      state.isBASE64 = true
      state.base64string = 'data:application/pdf;base64,' + action.payload.base64
    })
    builder.addCase(actions.apriFatturaAdE.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(actions.apriXMLBusiness.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.apriXMLBusiness.fulfilled, (state, action) => {
      state.loading = false
      state.modalIsOpen = true
      state.fileObject = {
        url: null,
        content_type: 'application/pdf',
        nome_file: 'fattura_elettronica.pdf',
      }
      state.isS3URL = false
      state.isBASE64 = true
      state.base64string = 'data:application/pdf;base64,' + action.payload.base64
    })
    builder.addCase(actions.apriXMLBusiness.rejected, (state) => {
      state.loading = false
    })
  },
})

export const { close } = fileViewer.actions

export default fileViewer.reducer
