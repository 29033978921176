import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { Container, Card, Button, Row, Col, Input, Badge } from 'reactstrap'
import CentredLoading from '../../component/CentredLoading'
import { listFatturePA } from '../../../app/utility/QueryAdmin'
import moment from 'moment'
import { inviaFatturaPa, scaricaFatturaPA } from '../../../app/utility/QueryAdmin'

const FatturePA = () => {
  const user = useSelector(selectUser)
  const [fatture, setFatture] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    listFatturePA(user).then((fPA) => {
      setFatture(fPA)
      setLoading(false)
    })
  }, [])

  const scarica = (fattura) => {
    scaricaFatturaPA(user, fattura)
  }

  const invia = (fattura) => {
    inviaFatturaPa(user, fattura.file_firmato, fattura.id).then(() => {
      const fattureModificate = fatture.filter((f) => f.id !== fattura.id)
      setFatture(fattureModificate)
    })
  }

  const selezionaFile = (e, fatturaRiferimento) => {
    const fattureModificate = fatture.map((fattura) => {
      if (fattura.id === fatturaRiferimento.id) {
        return {
          ...fattura,
          file_firmato: e.target.files[0],
        }
      } else {
        return fattura
      }
    })
    setFatture(fattureModificate)
  }

  return (
    <Container>
      {loading ? (
        <CentredLoading />
      ) : (
        <div className="my-5">
          {fatture.length === 0 ? (
            <>
              <h4 className="font-regular">Fatture PA</h4>
              <div className="alert alert-info mt-3" role="alert">
                Non ci sono fatture da firmare
              </div>
            </>
          ) : (
            <>
              <h4 className="font-regular">Fatture PA</h4>
              {fatture.map((fattura, index) => (
                <Card body className="mt-3" key={index}>
                  <div>
                    <Badge color="warning" className="float-end" pill>
                      {index + 1}
                    </Badge>{' '}
                    <span className="text-muted">
                      {fattura.user} (
                      {moment(fattura.created_at).format('DD/MM/YYYY')})
                    </span>
                  </div>
                  <br />
                  <Row>
                    <Col xs={5}>
                      <div className="input-group">
                        <Input
                          type="text"
                          disabled
                          value={fattura.xml_non_firmato}
                        />
                        <Button
                          color="primary"
                          className="float-end"
                          onClick={() => scarica(fattura)}
                        >
                          <i className="bi bi-file-earmark-arrow-down"></i> Scarica
                        </Button>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <div className="input-group">
                        <Input
                          type="file"
                          className="float-end"
                          onChange={(e) => selezionaFile(e, fattura)}
                          accept=".xml"
                          data-testid="seleziona-file"
                          valid={
                            fattura.file_firmato?.name ===
                            fattura.xml_non_firmato.split('.')[0] + '-signed.xml'
                          }
                        />
                        <Button
                          color="success"
                          onClick={() => invia(fattura)}
                          data-testid="invia-fattura"
                          disabled={
                            fattura.file_firmato?.name !==
                            fattura.xml_non_firmato.split('.')[0] + '-signed.xml'
                          }
                        >
                          <i className="bi bi-file-earmark-arrow-up"></i> Invia
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          )}
        </div>
      )}
    </Container>
  )
}

export default FatturePA
