import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Navbar,
  Collapse,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Container,
} from 'reactstrap'
import classNames from 'classnames'

const PublicSmallNav = () => {
  const [collapsed, setCollapsed] = useState(false)
  const history = useHistory()
  const [opacity, setOpacity] = useState(0)
  const location = useLocation()

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    if (scrollPosition > 20) {
      setOpacity(1)
    } else {
      setOpacity(scrollPosition * 0.05)
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const toggleNavbar = () => {
    if (collapsed === false) {
      setOpacity(1)
    } else {
      handleScroll()
    }
    setCollapsed(!collapsed)
  }

  const loginAction = () => {
    toggleNavbar()
    history.push('/accesso')
  }

  return (
    <Navbar
      light
      style={{
        zIndex: '1000',
        backgroundColor: 'rgba(255, 255, 255,' + opacity + ')',
      }}
      expand="md"
      fixed={'top'}
    >
      <NavbarBrand tag={Link} to="/">
        <img
          src="/img_landing/logo.webp"
          alt="Fiscomed"
          style={{ height: '42px' }}
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} style={{ backgroundColor: 'white' }} />
      <Collapse isOpen={collapsed} navbar>
        <Nav navbar>
          <ul className="navbar-nav me-auto">
            <li
              className={classNames('nav-item mx-2 ', {
                active: location.pathname !== '/',
              })}
            >
              <Link className="nav-link" to="/" onClick={toggleNavbar}>
                Fiscomed
              </Link>
            </li>
            <li
              className={classNames('nav-item mx-2 ', {
                active: location.pathname !== '/prezzi',
              })}
            >
              <Link className="nav-link" to="/prezzi" onClick={toggleNavbar}>
                Prezzi
              </Link>
            </li>
            <li
              className={classNames('nav-item mx-2 ', {
                active: location.pathname !== '/guida-fiscale',
              })}
            >
              <Link className="nav-link" to="/guida-fiscale" onClick={toggleNavbar}>
                Guida fiscale
              </Link>
            </li>
            <li
              className={classNames('nav-item mx-2 ', {
                active: location.pathname !== '/newsletter',
              })}
            >
              <Link className="nav-link" to="/newsletter" onClick={toggleNavbar}>
                Newsletter
              </Link>
            </li>
          </ul>
        </Nav>
        <Nav navbar className="ms-auto">
          <div className="navbar-nav">
            <form className="form-inline">
              <Button className="bottone-blu" onClick={() => loginAction()}>
                ACCEDI
              </Button>
            </form>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default PublicSmallNav
