import React from 'react'
import { Button, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../../app/store/user/selectors'
import { cambiaEmail } from '../../../../../app/store/gestionaleUtente/actions'

const ModificaEmail = ({ email }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const salvaEmail = () => {
    if (
      confirm(
        "Prima di cliccare su 'Ok' aggiorna l'email anche su Stripe, Mailchimp e la rubrica di Front",
      )
    ) {
      dispatch(cambiaEmail(email))
    }
  }

  return (
    <div>
      <>
        <Label for="cf">Modifica email</Label>
        <br />

        <Button
          color="warning"
          disabled={user.role !== 'admin' && user.role !== 'operation'}
          onClick={() => salvaEmail()}
        >
          <i className="bi bi-pencil"></i>
        </Button>
      </>
    </div>
  )
}

export default ModificaEmail
