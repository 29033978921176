import React, { useState, useContext, useEffect } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import {
  Button,
  FormGroup,
  Input,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import swal from 'sweetalert'
import classNames from 'classnames'
import moment from 'moment'

const Quiz = () => {
  const context = useContext(DichiarazioneContext)
  const [domande, setDomande] = useState([])
  const [rispTestuali, setRispTestuali] = useState({
    duePerMille: '',
    cinquePerMille: '',
    ottoPerMille: '',
    cittadinanza: '',
    cambioStatoCivile: '',
    coniugi: '',
  })
  const lastYear = moment().year() - 1
  const [modalAltriRedditiOpen, setModalAltriRedditiOpen] = useState(false)
  const [modalTSOpen, setModalTSOpen] = useState(false)

  const controllaEProsegui = () => {
    swal({
      title: "L'accendiamo? 📺",
      text: 'Proprio come in un quiz televisivo, è meglio avere la certezza delle risposte date prima di andare avanti. Se hai risposto con attenzione, clicca su "Vai avanti". Altrimenti, rivedi le tue risposte',
      icon: 'warning',
      buttons: ['VOGLIO RIVEDERE LE RISPOSTE', 'VAI AVANTI'],
    }).then((isConfirm) => {
      if (isConfirm) {
        context.completaStepQuiz(rispTestuali)
      }
    })
  }

  const bottoniSiNo = (numeroDomanda) => (
    <Col xs={5}>
      <div className="mb-5">
        <Button
          className={classNames(
            {
              'bottone-verde': domande.find((d) => d.numero === numeroDomanda)
                ?.risposta,
            },
            {
              'bottone-verde-outline': !domande.find(
                (d) => d.numero === numeroDomanda,
              )?.risposta,
            },
            'me-5',
          )}
          style={{ marginTop: '-8px' }}
          onClick={() => context.salvaRisposta('quiz', numeroDomanda, true)}
        >
          &nbsp;Sì&nbsp;
        </Button>
        <Button
          className={classNames(
            {
              'bottone-verde':
                !domande.find((d) => d.numero === numeroDomanda)?.risposta &&
                domande.find((d) => d.numero === numeroDomanda)?.risposta != null,
            },
            {
              'bottone-verde-outline':
                domande.find((d) => d.numero === numeroDomanda)?.risposta == null ||
                domande.find((d) => d.numero === numeroDomanda)?.risposta,
            },
            'me-5',
          )}
          style={{ marginTop: '-8px' }}
          onClick={() => context.salvaRisposta('quiz', numeroDomanda, false)}
        >
          No
        </Button>
      </div>
    </Col>
  )

  useEffect(() => {
    const tappa = context.dati?.tappe.find((t) => t.nome === 'quiz')
    setDomande(tappa?.domande)
  }, [context.dati])

  const listaFiles = (numeroDomanda) => {
    const domanda = domande.find((d) => d.numero === numeroDomanda)
    return domanda?.files?.map((f, index) => (
      <div key={index} className="mt-2">
        <i
          className="bi bi-trash3 testo-rosso"
          style={{ cursor: 'pointer' }}
          onClick={() => context.cancellaFile('quiz', numeroDomanda, f.uuid)}
        ></i>{' '}
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() => context.visualizzaFile(f.uuid)}
        >
          {f.nome}
        </span>
      </div>
    ))
  }

  const modaleAltriRedditi = () => (
    <Modal
      isOpen={modalAltriRedditiOpen}
      toggle={() => setModalAltriRedditiOpen(false)}
    >
      <ModalHeader
        className="font-regular"
        toggle={() => setModalAltriRedditiOpen(false)}
      >
        Come rispondere a questa domanda ✅
      </ModalHeader>
      <ModalBody>
        Questa domanda serve a capire <i>se puoi scaricare qualcosa,</i> oltre ai
        contributi ENPAM.
        <br />
        <br />
        Se nel {lastYear} hai avuto solo redditi da Partita IVA, puoi scaricare solo
        i contributi.
        <br />
        Se invece hai avuto anche altri redditi su cui paghi l'IRPEF, allora puoi
        detrarre altre spese.
        <br />
        <br />
        📌 Come rispondere:
        <br />
        <br />
        <ul>
          <li>
            Hai avuto la <span className="font-bold">borsa di specializzazione</span>
            ? → Rispondi "No", perché su questa non paghi IRPEF.
          </li>
          <li>
            Hai avuto la <span className="font-bold">borsa MMG</span>? → Rispondi
            "Sì", perché su questa paghi l’IRPEF.
          </li>
          <li>
            Hai avuto un{' '}
            <span className="font-bold">
              reddito da lavoro dipendente o assimilato?
            </span>{' '}
            → Rispondi "Sì".
          </li>
          <li>
            Hai fatturato per <span className="font-bold">sostituzioni MMG</span>, ma
            non hai la borsa? → Rispondi "No", perché queste fatture rientrano nei
            redditi da Partita IVA forfettaria.
          </li>
          <li>
            Hai percepito <span className="font-bold">cedole di investimenti</span>{' '}
            (es. azioni, obbligazioni)? → Rispondi "No", perché non generano IRPEF
            detraibile.
          </li>
          <li>
            Hai ricevuto una{' '}
            <span className="font-bold">
              borsa di studio universitaria esente da tasse
            </span>
            ? → Rispondi "No".
          </li>
          <li>
            Hai fatto più di{' '}
            <span className="font-bold">5000 euro di prestazioni occasionali</span>{' '}
            nel 2024? → Rispondi "Sì", perché su questi redditi paghi l’IRPEF.
          </li>
          <li>
            Hai ricevuto soldi per una{' '}
            <span className="font-bold">casa in affitto senza cedolare secca</span>?
            → Rispondi “Sì”.
          </li>
        </ul>
      </ModalBody>
    </Modal>
  )

  const modaleTS = () => (
    <Modal isOpen={modalTSOpen} toggle={() => setModalTSOpen(false)}>
      <ModalHeader className="font-regular" toggle={() => setModalTSOpen(false)}>
        Come scaricare le spese sanitarie dal Sistema TS 💳
      </ModalHeader>
      <ModalBody>
        1️⃣{' '}
        <a
          href="https://sistemats1.sanita.finanze.it/portale/home"
          target="_blank"
          rel="noreferrer"
        >
          <u>Vai al sito</u>
        </a>{' '}
        del Sistema Tessera Sanitaria
        <br />
        <br />
        2️⃣ Clicca su <span className="font-bold">“Cittadini”</span> nella barra
        grigia
        <br /> <br />
        3️⃣ Nel riquadro “Spese sanitarie”, clicca su{' '}
        <span className="font-bold">“Accedi al servizio”</span>
        <br /> <br />
        4️⃣ Entra con <span className="font-bold">SPID</span>
        <br /> <br />
        5️⃣ Nel riquadro “Consultazione spese sanitarie”, clicca su{' '}
        <span className="font-bold">“Vai al servizio”</span>
        <br /> <br />
        6️⃣ Seleziona l’anno di pagamento il 2024 e clicca su{' '}
        <span className="font-bold">“Cerca”</span>
        <br /> <br />
        7️⃣ Scarica il file cliccando su{' '}
        <span className="font-bold">“Scarica tutte le spese”</span>
        <br /> <br />
        Ora chiudi questo specchietto e carica il documento 😊
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      {modaleAltriRedditi()}
      {modaleTS()}
      <h4 className="testo-verde font-bold mb-4">
        3️⃣ TERZA TAPPA: RISPONDI ALLE DOMANDE
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Ora abbiamo bisogno di raccogliere alcune informazioni che{' '}
        {context?.genere?.ilTuo} commercialista userà per preparare la tua
        dichiarazione dei redditi. Cominciamo 🏁
      </h6>
      <Row>
        <Col xs={12}>
          <h5 className="font-bold mt-4 mb-4">Prima parte: redditi esteri</h5>
        </Col>
        <Col xs={12} className="mb-5">
          <div
            style={{
              border: '1px solid #f2421b',
              borderRadius: '15px',
              backgroundColor: '#f2431b34',
            }}
          >
            <h6
              className="font-regular interlinea-20 mx-4 my-3"
              style={{ fontSize: '12px' }}
            >
              Quando si hanno dei <span className="font-bold">redditi esteri</span>{' '}
              bisogna compilare dei quadri della dichiarazione molto complicati. Come
              specificato nella{' '}
              <a
                href="https://fiscomed.it/documenti/lettera_incarico_professionale.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>lettera di incarico</u>
              </a>
              , se ci dai i quadri compilati entro il 30 maggio li inseriamo
              gratuitamente in dichiarazione. Se preferisci che li prepariamo noi per
              te potrebbe esserci un costo extra, ma sapremo dirti di più solo dopo
              che {context.genere.ilTuo} commercialista avrà analizzato con cura le
              tue esigenze. Il tuo compito ora? Rispondere al questionario nel modo
              più preciso possibile. Se ci saranno delle procedure speciali da
              seguire, ti contatteremo noi e ti spiegheremo tutto 😊
            </h6>
          </div>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular interlinea-30">
            Nel {lastYear} hai avuto delle <span className="font-bold">crypto</span>?
          </h6>
        </Col>
        {bottoniSiNo(0)}
        {domande.find((d) => d.numero === 0)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Se gestisci le tue crypto con delle applicazioni che ti danno il{' '}
                <i>quadro RW compilato</i>, ricordati di caricarlo nella sezione{' '}
                <span className="font-bold">
                  "Strumenti" &gt; “Importa documento”
                </span>{' '}
                non appena lo avrai{' '}
                <i>(solitamente è disponibile verso fine maggio)</i>. Se, invece, sai
                già che non riceverai alcun quadro RW,{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 0)}
                >
                  <u>clicca qui</u>
                </span>{' '}
                per caricare un <i>prospetto</i> o un foglio con il{' '}
                <span className="font-bold">valore delle crypto</span> al giorno
                01/01/{lastYear} fino al 31/12/{lastYear}
                <br />
                {listaFiles(0)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto uno o più <b>immobili esteri</b>?
          </h6>
        </Col>
        {bottoniSiNo(1)}
        {domande.find((d) => d.numero === 1)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai avuto uno o più immobili esteri, ci serve l&apos;
                <i>atto di acquisto</i> o la <i>visura catastale</i> di ciascuno.{' '}
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 1)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questi documenti
                <br />
                {listaFiles(1)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto <span className="font-bold">altri beni</span>{' '}
            registrati all&apos;estero? <i>(auto, imbarcazioni...)</i>
          </h6>
        </Col>
        {bottoniSiNo(2)}
        {domande.find((d) => d.numero === 2)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai avuto uno o più beni esteri, ci serve l&apos;
                <i>atto di acquisto</i> o la <i>visura catastale</i> di ciascuno.{' '}
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 2)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questi documenti
                <br />
                {listaFiles(2)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto{' '}
            <span className="font-bold">partecipazioni societarie</span>{' '}
            <i>(quote)</i> in società estere?
          </h6>
        </Col>
        {bottoniSiNo(3)}
        {domande.find((d) => d.numero === 3)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai avuto delle quote in società estere, abbiamo bisogno
                della <i>visura della società</i>.
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 3)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricarla
                <br />
                {listaFiles(3)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto uno o più{' '}
            <span className="font-bold">conti correnti esteri</span> con giacenza
            media superiore a 5.000 euro o su cui hai avuto più di 15.000 euro in una
            volta sola?
          </h6>
        </Col>
        {bottoniSiNo(4)}
        {domande.find((d) => d.numero === 4)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai avuto uno o più conti correnti esteri con questi
                importi, ci serve <i>l'estratto conto del {lastYear}.</i> <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 4)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricarlo
                <br />
                {listaFiles(4)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto un{' '}
            <span className="font-bold">deposito titoli all'estero</span>?{' '}
            <i>
              (È un conto che ti permette di avere e gestire investimenti in società
              estere)
            </i>
          </h6>
        </Col>
        {bottoniSiNo(5)}
        {domande.find((d) => d.numero === 5)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai avuto un deposito titoli all'estero, ci serve il{' '}
                <i>report dell'anno {lastYear}</i> rilasciato dal tuo intermediario
                finanziario.
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 5)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricarlo
                <br />
                {listaFiles(5)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto{' '}
            <span className="font-bold">
              redditi da lavoro dipendente all'estero?
            </span>
          </h6>
        </Col>
        {bottoniSiNo(6)}
        <Col xs={12}>
          <h5 className="font-bold mt-3 mb-4">Seconda parte: immobili e terreni</h5>
        </Col>

        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel 2024 hai{' '}
            <span className="font-bold">
              ricevuto soldi per l’affitto standard (più di 30 giorni)
            </span>{' '}
            di una casa?
            <br />
            <i>
              Attenzione: questa domanda non ha a che vedere con il fatto che tu sia
              o meno in affitto. Per noi è importante sapere se hai affittato una o
              più case o stanze a qualcun altro e hai ricevuto pagamenti per questo.
            </i>
          </h6>
        </Col>
        {bottoniSiNo(7)}
        {domande.find((d) => d.numero === 7)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai ricevuto dei soldi per l’affitto standard di una casa,
                ci serve il{' '}
                <i>
                  contratto di affitto e della registrazione all’Agenzia delle
                  Entrate.
                </i>
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 7)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questi documenti
                <br />
                {listaFiles(7)}
              </h6>
            </div>
          </Col>
        )}

        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel 2024 hai{' '}
            <span className="font-bold">
              ricevuto soldi per l’affitto breve (meno di 30 giorni)
            </span>{' '}
            di una casa?
            <br />
            <i>
              Attenzione: neanche questa domanda ha a che vedere con il fatto che tu
              sia o meno in affitto. Per noi è importante sapere se hai affittato una
              o più case o stanze a qualcun altro e hai ricevuto pagamenti per
              questo.
            </i>
          </h6>
        </Col>
        {bottoniSiNo(8)}
        {domande.find((d) => d.numero === 8)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai ricevuto dei soldi per l’affitto breve di una casa, ci
                serve un documento per la dichiarazione.
                <br />
                <br />
                Carica la{' '}
                <span className="font-bold">Certificazione Unica (CU)</span> se:
                <ul>
                  <li>
                    Hai usato Airbnb, Booking o altre <i>piattaforme</i> che fanno da
                    intermediari
                  </li>
                  <li>
                    Hai scelto di affidarti a un <i>property manager</i>, ma sei tu a
                    incassare i pagamenti
                  </li>
                </ul>
                Carica un semplice{' '}
                <span className="font-bold">prospetto degli incassi</span> se:
                <ul>
                  <li>
                    Ti affidi a un <i>property manager</i> che incassa al posto tuo
                  </li>
                  <li>
                    Hai gestito tutto in <i>autonomia</i>, senza piattaforme o
                    intermediari
                  </li>
                </ul>
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 8)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare la CU o il prospetto
                <br />
                {listaFiles(8)}
              </h6>
            </div>
          </Col>
        )}

        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto{' '}
            <span className="font-bold">terreni o fabbricati</span> di tua proprietà
            nella provincia autonoma di Trento e Bolzano?
          </h6>
        </Col>
        {bottoniSiNo(9)}
        {domande.find((d) => d.numero === 9)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Dato che hai avuto terreni o fabbricati di tua proprietà nella
                provincia autonoma di Trento e Bolzano, abbiamo bisogno della{' '}
                <i>visura catastale.</i> Questo perché non abbiamo modo di vederla
                dal tuo cassetto fiscale.{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 9)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricarla
                <br />
                {listaFiles(9)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={12}>
          <h5 className="font-bold mt-3 mb-4">Terza parte: di tutto e di più</h5>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel {lastYear} hai avuto <span className="font-bold">altri redditi</span>{' '}
            oltre a quelli della libera professione?
            <br />
            Prima di rispondere,{' '}
            <span
              className="testo-blu"
              style={{ cursor: 'pointer' }}
              onClick={() => setModalAltriRedditiOpen(true)}
            >
              <u>controlla la lista che abbiamo preparato per te</u>
            </span>
          </h6>
        </Col>
        {bottoniSiNo(10)}
        {domande.find((d) => d.numero === 10)?.risposta && (
          <>
            <Col xs={12} className="mb-5">
              <div
                style={{
                  border: '1px solid #0583f2',
                  borderRadius: '15px',
                }}
              >
                <h6 className="font-regular interlinea-30 mx-4 my-3">
                  Dato che hai avuto altri redditi oltre alla Partita IVA,{' '}
                  <i>puoi scaricare alcune tipologie di spese</i>. Per capire quali,{' '}
                  <a
                    href="https://www.cafacli.it/it/servizi-fiscali/modello-730/spese-detraibili-deducibili-730/"
                    target="blank"
                    className="testo-blu"
                  >
                    <u>consulta la lista delle spese detraibili e deducibili.</u>
                  </a>{' '}
                  Se hai <span className="font-bold">spese sanitarie</span>, puoi
                  caricare un file Excel che trovi nella tua area riservata del
                  Sistema Tessera Sanitaria.{' '}
                  <span
                    className="testo-blu"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setModalTSOpen(true)}
                  >
                    <u>Ecco le istruzioni per ottenerlo. </u>
                  </span>{' '}
                  Raccogli anche gli scontrini e, quando hai tutto pronto,{' '}
                  <span
                    className="testo-blu"
                    style={{ cursor: 'pointer' }}
                    onClick={() => context.apriFileUpload('quiz', 10)}
                  >
                    <u>clicca qui</u>
                  </span>{' '}
                  per caricare i tuoi documenti.
                  <br />
                  {listaFiles(10)}
                </h6>
              </div>
            </Col>
            <Col xs={12}>
              <h6 className="font-regular mb-3 interlinea-30 mb-5">
                Ora scegli a chi destinare il tuo{' '}
                <span className="font-bold">2,5 e 8x1000</span>.<br />
                Fare una scelta non è obbligatorio: se non indichi un destinatario,
                le tue imposte finiranno comunque nelle casse dello Stato. In questo
                caso, sarà lo Stato a decidere come usarle.
              </h6>
            </Col>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Il <span className="font-bold">2x1000</span> è il finanziamento ai
                partiti politici. A chi vuoi destinare il tuo 2x1000?
              </h6>
            </Col>
            <Col xs={5}>
              <FormGroup className="mb-5">
                <Input
                  type="text"
                  name="duePerMille"
                  placeholder="Il mio 2x1000 va al partito 'X'"
                  value={rispTestuali.duePerMille}
                  onChange={(e) =>
                    setRispTestuali({ ...rispTestuali, duePerMille: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Il <span className="font-bold">5x1000</span> va a enti che si
                occupano di ambiti come la ricerca o la cultura. A chi vuoi
                destinarlo?
              </h6>
            </Col>
            <Col xs={5}>
              <FormGroup className="mb-5">
                <Input
                  type="text"
                  name="cinquePerMille"
                  placeholder="Il mio 5x1000 va all'ente di ricerca"
                  value={rispTestuali.cinquePerMille}
                  onChange={(e) =>
                    setRispTestuali({
                      ...rispTestuali,
                      cinquePerMille: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                L'<span className="font-bold">8x1000</span> può andare a una
                confessione religiosa (
                <a
                  href="https://www.agenziaentrate.gov.it/portale/web/guest/scelte-8-5-e-2-per-mille-irpef-2023/8-per-mille"
                  target="blank"
                  className="testo-blu"
                >
                  <u>qui</u>
                </a>{' '}
                trovi la lista completa) oppure allo Stato. Se scegli lo Stato, puoi
                indicare anche uno degli scopi sociali previsti
              </h6>
            </Col>
            <Col xs={5}>
              <FormGroup className="mb-5">
                <Input
                  type="text"
                  name="ottoPerMille"
                  placeholder="Il mio 8x1000 va allo Stato"
                  value={rispTestuali.ottoPerMille}
                  onChange={(e) =>
                    setRispTestuali({
                      ...rispTestuali,
                      ottoPerMille: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          </>
        )}
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Nel {lastYear} qual era la tua{' '}
            <span className="font-bold">cittadinanza?</span>
          </h6>
        </Col>
        <Col xs={5}>
          <FormGroup className="mb-5">
            <Input
              type="text"
              name="cittadinanza"
              placeholder="Italiana"
              value={rispTestuali.cittadinanza}
              onChange={(e) =>
                setRispTestuali({
                  ...rispTestuali,
                  cittadinanza: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Nel {lastYear} il tuo <span className="font-bold">stato civile</span> è
            cambiato? <i>(Es. ti sei sposato/a, separato/a…)</i>. Se sì, indica
            quando. Se non è cambiato, non rispondere
          </h6>
        </Col>
        <Col xs={5}>
          <FormGroup className="mb-5">
            <Input
              type="text"
              name="cambioStatoCivile"
              placeholder="gg/mm/aaaa"
              value={rispTestuali.cambioStatoCivile}
              onChange={(e) =>
                setRispTestuali({
                  ...rispTestuali,
                  cambioStatoCivile: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Hai un/a <span className="font-bold">coniuge</span> o dei{' '}
            <span className="font-bold">figli/e</span>? Se sì, scrivi i loro nomi,
            cognomi, codici fiscali e indica il grado di parentela e la percentuale
            di carico
          </h6>
        </Col>
        <Col xs={5}>
          <Input
            className="mb-5"
            type="textarea"
            rows="5"
            name="coniugi"
            value={rispTestuali.coniugi}
            onChange={(e) =>
              setRispTestuali({ ...rispTestuali, coniugi: e.target.value })
            }
          />
        </Col>
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Dal 1° gennaio {lastYear} a oggi hai trasferito la{' '}
            <span className="font-bold">
              residenza dall’
              <span className="text-danger">ESTERO</span> all’Italia
            </span>
            ?
          </h6>
        </Col>
        {bottoniSiNo(17)}

        {context.tasse.anno_precedente.imposta_unica.saldo +
          context.tasse.anno_precedente.imposta_unica.acconto >
          500 && (
          <>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Sembra che tu debba pagare tra i{' '}
                <span className="font-bold">
                  {parseInt(
                    (context.tasse.anno_precedente.imposta_unica.saldo +
                      context.tasse.anno_precedente.imposta_unica.acconto) *
                      1.1,
                  )}
                </span>
                € e i{' '}
                <span className="font-bold">
                  {parseInt(
                    (context.tasse.anno_precedente.imposta_unica.saldo +
                      context.tasse.anno_precedente.imposta_unica.acconto) *
                      0.9,
                  )}
                </span>
                € di imposte. Preferisci farlo <i>a rate</i> o in{' '}
                <i>un’unica soluzione</i>? Te lo chiediamo perché questa scelta
                influisce sulla preparazione dei tuoi F24. <br />
                Se scegli il <span className="font-bold">pagamento a rate</span>,
                sappi che verranno applicati degli interessi{' '}
                <i>(scoprili cliccando su “Voglio pagare in 5 rate”)</i>
              </h6>
            </Col>
            <Col xs={5}>
              <div className="mb-5">
                <Button
                  className={classNames(
                    {
                      'bottone-verde': domande.find((d) => d.numero === 18)
                        ?.risposta,
                    },
                    {
                      'bottone-verde-outline': !domande.find((d) => d.numero === 18)
                        ?.risposta,
                    },
                    'me-5',
                  )}
                  block
                  onClick={() => context.salvaRisposta('quiz', 18, true)}
                >
                  Voglio pagare in 5 rate
                </Button>
                <Button
                  className={classNames(
                    {
                      'bottone-verde': !domande.find((d) => d.numero === 18)
                        ?.risposta,
                    },
                    {
                      'bottone-verde-outline':
                        domande.find((d) => d.numero === 18)?.risposta == null ||
                        domande.find((d) => d.numero === 18)?.risposta,
                    },
                    'mt-2',
                  )}
                  block
                  onClick={() => context.salvaRisposta('quiz', 18, false)}
                >
                  Voglio pagare in un&apos;unica soluzione
                </Button>
              </div>
            </Col>
            {domande.find((d) => d.numero === 18)?.risposta && (
              <Col xs={12} className="mb-5">
                <div
                  style={{
                    border: '1px solid #0583f2',
                    borderRadius: '15px',
                  }}
                >
                  <h6 className="font-regular interlinea-30 mx-4 my-3">
                    Dato che hai scelto di pagare a rate, è giusto che tu conosca i
                    tassi di interesse.
                    <br />
                    <ul>
                      <li>Prima rata: 0%</li>
                      <li>Seconda rata: 0,18%</li>
                      <li>Terza rata: 0,51%</li>
                      <li>Quarta rata: 0,84%</li>
                      <li>Quinta rata: 1,17%</li>
                    </ul>
                  </h6>
                </div>
              </Col>
            )}
          </>
        )}
      </Row>

      <br />
      <div>
        <Button
          className="bottone-blu float-end mb-4"
          onClick={() => controllaEProsegui()}
        >
          INVIA IL QUESTIONARIO 🥳
        </Button>
      </div>
    </div>
  )
}

export default Quiz
