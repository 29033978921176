import React, { useState, useEffect } from 'react'
import {
  idrataDichiarazione,
  chiudiStepFatture,
  chiudiStepContributi,
  chiudiStepQuiz,
  inviaFile,
  eliminaFile,
  salvaRisp,
} from '../utility/QueryDichiarazione'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/user/selectors'
import { emptyFileUpload, emptyState } from './utility/DichiarazioneDataObject'
import { preposizioni } from '../utility/Miscellanea'

const DichiarazioneContext = React.createContext()

const DichiarazioneProvider = ({ children }) => {
  const [state, setState] = useState(emptyState)
  const user = useSelector(selectUser)

  useEffect(() => {
    idrata()
  }, [])

  const idrata = async () => {
    try {
      const responseBody = await idrataDichiarazione(user)
      setState({
        ...state,
        ...responseBody.dichiarazione,
        tasse: responseBody.tasse,
        delega: responseBody.delega,
        percorsoDichiarativoAperto: responseBody.percorso_dichiarativo_aperto,
        genere: preposizioni(user.commercialista_uomo),
        caricamento: false,
      })
    } catch (e) {
      console.error(e.message)
      setState({
        ...state,
        caricamento: false,
      })
    }
  }

  const aggiornaDatiDichiarazioneEStep = (dichiarazione) => {
    setState({
      ...state,
      ...dichiarazione,
      modalFileUpload: emptyFileUpload,
      caricamento: false,
    })
  }

  const completaStepFatture = (caricatiEIncassati) => {
    chiudiStepFatture(user, caricatiEIncassati).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const completaStepContributi = (contributiVersati) => {
    chiudiStepContributi(user, contributiVersati).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const completaStepQuiz = (risposte) => {
    chiudiStepQuiz(user, risposte).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const apriFileUpload = (tappa, domanda) => {
    setState({
      ...state,
      modalFileUpload: {
        aperto: true,
        caricamento: false,
        file: undefined,
        tappa,
        domanda,
      },
    })
  }

  const chiudiFileUpload = () => {
    setState({
      ...state,
      modalFileUpload: {
        aperto: false,
        caricamento: false,
        file: undefined,
        tappa: undefined,
        domanda: undefined,
      },
    })
  }

  const impostaFile = (file) => {
    setState({
      ...state,
      modalFileUpload: {
        ...state.modalFileUpload,
        file,
      },
    })
  }

  const uploadFile = async () => {
    setState({
      ...state,
      modalFileUpload: {
        ...state.modalFileUpload,
        caricamento: true,
      },
    })
    try {
      const responseBody = await inviaFile(user, state.modalFileUpload)
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    } catch (e) {
      setState({
        ...state,
        caricamento: false,
      })
    }
  }

  const cancellaFile = (tappa, domanda, uuid) => {
    swal({
      title: 'Vuoi eliminare questo documento?',
      icon: 'warning',
      buttons: ['NO, CHIUDI', 'SÌ, CANCELLA'],
    }).then((isConfirm) => {
      if (isConfirm) {
        eliminaFile(user, tappa, domanda, uuid).then((responseBody) => {
          aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
        })
      }
    })
  }

  const salvaRisposta = (tappa, domanda, risposta) => {
    salvaRisp(user, tappa, domanda, risposta).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const context = {
    ...state,
    completaStepFatture,
    completaStepContributi,
    completaStepQuiz,
    apriFileUpload,
    chiudiFileUpload,
    impostaFile,
    uploadFile,
    cancellaFile,
    salvaRisposta,
  }

  return (
    <DichiarazioneContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </DichiarazioneContext.Provider>
  )
}

export { DichiarazioneProvider as default, DichiarazioneContext }
