import moment from 'moment'
import 'moment/locale/it'

export const currentYear = moment().year()
const nextYear = currentYear + 1
const lastYear = currentYear - 1

export const arrayScadenze = [
  [
    moment('15/01/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a dicembre ' +
      lastYear,
  ],
  [
    moment('31/01/' + currentYear, 'DD/MM/YYYY'),
    'Invio dei dati al Sistema TS delle fatture cartacee fatte da luglio a dicembre ' +
      lastYear,
  ],
  [
    moment('15/02/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a gennaio ' +
      currentYear,
  ],
  [
    moment('28/02/' + currentYear, 'DD/MM/YYYY'),
    "Pagamento dell'imposta di bollo delle fatture elettroniche consegnate a ottobre, novembre e dicembre " +
      lastYear,
  ],
  [
    moment('15/03/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a febbraio ' +
      currentYear,
  ],
  [
    moment('31/03/' + currentYear, 'DD/MM/YYYY'),
    'Richiesta domiciliazione bancaria ENPAM per pagare la Quota A a rate',
  ],
  [
    moment('15/04/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a marzo ' +
      currentYear,
  ],

  [
    moment('30/04/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento Quota A ENPAM ' +
      currentYear +
      " (in un'unica soluzione o prima rata)",
  ],
  [
    moment('15/05/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a aprile ' +
      currentYear,
  ],
  [
    moment('31/05/' + currentYear, 'DD/MM/YYYY'),
    "Pagamento dell'imposta di bollo delle fatture elettroniche consegnate a gennaio, febbraio e marzo " +
      currentYear,
  ],
  [
    moment('15/06/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a maggio ' +
      currentYear,
  ],
  [
    moment('30/06/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento del saldo imposta sostitutiva ' +
      lastYear +
      ' e del primo acconto ' +
      currentYear +
      '. Questa scadenza riguarda chi deve fare la dichiarazione ' +
      currentYear +
      ' relativa al ' +
      lastYear,
  ],
  [
    moment('15/07/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a giugno ' +
      currentYear,
  ],
  [
    moment('31/07/' + currentYear, 'DD/MM/YYYY'),
    'Invio Modello D relativo al ' + lastYear,
  ],
  [
    moment('15/08/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a luglio ' +
      currentYear,
  ],
  [
    moment('15/09/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a agosto ' +
      currentYear,
  ],
  [
    moment('30/09/' + currentYear, 'DD/MM/YYYY'),
    'Invio dei dati al Sistema TS delle fatture cartacee fatte da gennaio a giugno ' +
      currentYear,
  ],
  [
    moment('30/09/' + currentYear, 'DD/MM/YYYY'),
    "Pagamento dell'imposta di bollo delle fatture elettroniche consegnate ad aprile, maggio e giugno " +
      currentYear,
  ],
  [
    moment('30/09/' + currentYear, 'DD/MM/YYYY'),
    "Richiesta domiciliazione bancaria ENPAM per pagare la Quota B a rate (se non l'hai fatta per la Quota A)",
  ],
  [
    moment('15/10/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a settembre ' +
      currentYear,
  ],

  [
    moment('31/10/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento della Quota B ENPAM relativa al ' +
      lastYear +
      " (in un'unica soluzione o prima rata)",
  ],
  [
    moment('15/11/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a ottobre ' +
      currentYear,
  ],

  [
    moment('30/11/' + currentYear, 'DD/MM/YYYY'),
    "Pagamento dell'imposta di bollo delle fatture elettroniche consegnate nel terzo trimestre dell'anno (e precedenti, se hai scelto di rimandare il versamento)",
  ],
  [
    moment('30/11/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento secondo acconto imposta sostitutiva relativa al ' + currentYear,
  ],
  [
    moment('15/12/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a novembre ' +
      currentYear,
  ],
  [
    moment('15/01/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a dicembre ' +
      currentYear,
  ],
  [
    moment('31/01/' + nextYear, 'DD/MM/YYYY'),
    'Invio dei dati al Sistema TS delle fatture cartacee fatte da luglio a dicembre ' +
      currentYear,
  ],
  [
    moment('15/02/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a gennaio ' +
      nextYear,
  ],
  [
    moment('28/02/' + nextYear, 'DD/MM/YYYY'),
    "Pagamento dell'imposta di bollo delle fatture elettroniche consegnate a ottobre, novembre e dicembre " +
      currentYear,
  ],
  [
    moment('15/03/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a febbraio ' +
      nextYear,
  ],
  [
    moment('31/03/' + nextYear, 'DD/MM/YYYY'),
    'Richiesta domiciliazione bancaria ENPAM per pagare la Quota A a rate',
  ],
  [
    moment('15/04/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a marzo ' +
      nextYear,
  ],
  [
    moment('30/04/' + nextYear, 'DD/MM/YYYY'),
    'Pagamento Quota A ENPAM ' + nextYear + " (in un'unica soluzione o prima rata)",
  ],
]
