import React from 'react'
import { Badge } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { toggleControllato } from '../../../../app/store/gestionaleUtente/actions'

const BadgeControllato = ({ fattura }) => {
  const dispatch = useDispatch()
  let badgeColor, iconClass
  if (fattura.controllato === null) {
    badgeColor = 'warning'
    iconClass = 'bi bi-exclamation'
  } else if (fattura.tipologia === 'fattura_fiscomed' || fattura.controllato) {
    badgeColor = 'success'
    iconClass = 'bi bi-check'
  } else {
    badgeColor = 'danger'
    iconClass = 'bi bi-x'
  }

  const cambiaStato = (e) => {
    e.stopPropagation()
    if (fattura.tipologia === 'fattura_fiscomed') return
    dispatch(
      toggleControllato({
        id: fattura.id,
        tipologia: fattura.tipologia,
      }),
    )
  }

  return (
    <Badge color={badgeColor} onClick={(e) => cambiaStato(e)}>
      <i className={iconClass}></i>
    </Badge>
  )
}

export default BadgeControllato
