import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import moment from 'moment'

const Fabbricati = () => {
  const dati = useSelector(selectGestionaleUtente)
  const [dataDiNascitaIsCorrect, setDataDiNascitaIsCorrect] = useState(true)

  useEffect(() => {
    if (dati.cassetto?.catasto?.data_di_nascita_fabbricati === null) return
    if (
      dati.cassetto?.catasto?.data_di_nascita_fabbricati !==
      moment(dati.anagrafica.data_di_nascita).format('DD/MM/YYYY')
    )
      setDataDiNascitaIsCorrect(false)
  }, [dati.cassetto])

  return (
    <Card body className="mt-4">
      <span className="h5">Fabbricati</span>
      {dati.cassetto?.last_sync !== null && (
        <>
          {dati.cassetto?.catasto?.anomalia_fabbricati === false &&
          dataDiNascitaIsCorrect ? (
            <>
              {dati.cassetto?.catasto?.fabbricati.length > 0 ? (
                <table className="table table-striped table-bordered mb-5">
                  <thead>
                    <tr>
                      <th scope="col">Zona</th>
                      <th scope="col">Classe</th>
                      <th scope="col">Comune</th>
                      <th scope="col">Foglio</th>
                      <th scope="col">Rendita</th>
                      <th scope="col">Indirizzo</th>
                      <th scope="col">Particella</th>
                      <th scope="col">Subalterno</th>
                      <th scope="col">Titolarità</th>
                      <th scope="col">Consistenza</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dati.cassetto?.catasto?.fabbricati.map((fabbricato, index) => {
                      return (
                        <tr key={index}>
                          <td>{fabbricato.zona}</td>
                          <td>{fabbricato.classe}</td>
                          <td>{fabbricato.comune}</td>
                          <td>{fabbricato.foglio}</td>
                          <td>{fabbricato.rendita}</td>
                          <td>{fabbricato.indirizzo}</td>
                          <td>{fabbricato.particella}</td>
                          <td>{fabbricato.subalterno}</td>
                          <td>{fabbricato.titolarita}</td>
                          <td>{fabbricato.consistenza}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-secondary mt-3" role="alert">
                  Non risultano fabbricati
                </div>
              )}
            </>
          ) : (
            <div className="alert alert-danger mt-3" role="alert">
              Anamolia sul catasto fabbricati, contattare l'assistenza tecnlica
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default Fabbricati
