import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'

const PrintLeads = ({ leads }) => {
  const [visualResponse, setVisualResponse] = useState({
    note: false,
    telefono: false,
  })

  const copiaNote = (testo) => {
    navigator.clipboard.writeText(testo).then(() => {
      setVisualResponse({ note: true, telefono: false })
    })
  }

  const copiaTelefono = (testo) => {
    navigator.clipboard.writeText(testo).then(() => {
      setVisualResponse({ note: false, telefono: true })
    })
  }

  useEffect(() => {
    if (visualResponse.note || visualResponse.telefono) {
      setTimeout(() => {
        setVisualResponse({ note: false, telefono: false })
      }, 500)
    }
  }, [visualResponse.note, visualResponse.telefono])

  return (
    <div>
      <Table bordered hover responsive striped className="mt-3 font-regular">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>
              {visualResponse.telefono ? (
                <i className="bi bi-clipboard2-check"></i>
              ) : (
                <i className="bi bi-clipboard2"></i>
              )}
              Telefono
            </th>
            <th>Data richiesta</th>
            <th>
              {visualResponse.note ? (
                <i className="bi bi-clipboard2-check"></i>
              ) : (
                <i className="bi bi-clipboard2"></i>
              )}
              Nota
            </th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead, index) => (
            <tr key={index}>
              <td>{lead.nome}</td>
              <td>{lead.email}</td>
              <td onClick={() => copiaTelefono(lead.telefono)}>{lead.telefono}</td>
              <td>{lead.created_at}</td>
              <td onClick={() => copiaNote(lead.nota)}>{lead.nota}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {leads.length === 0 && <center>Nessuna corrispondenza</center>}
    </div>
  )
}

export default PrintLeads
