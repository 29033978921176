import { preposizioni } from '../../utility/Miscellanea'

export const emptyFileUpload = {
  aperto: false,
  caricamento: false,
  file: undefined,
  tappa: undefined,
  domanda: undefined,
}

export const emptyState = {
  attivo: false,
  caricamento: true,
  archivio: [],
  genere: preposizioni(true),
  tasse: {},
  dati: undefined,
  delega: false,
  percorsoDichiarativoAperto: false,
  modalFileUpload: emptyFileUpload,
}
