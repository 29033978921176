import React, { useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import PrintLead from './PrintLead'

const Cantina = ({ leadCantina, operatori, ricaricaDati, selectedOperatore }) => {
  const [offcanvas, setOffcanvas] = useState(false)

  return (
    <div>
      <Button
        color="secondary"
        size="sm"
        outline
        onClick={() => setOffcanvas(!offcanvas)}
        className="position-relative"
      >
        <i className="bi bi-moon-stars"></i> Cantina
        {leadCantina.length > 0 && (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {leadCantina.length}
          </span>
        )}
      </Button>
      <Offcanvas
        direction="start"
        scrollable
        toggle={() => setOffcanvas(false)}
        isOpen={offcanvas}
        className="w-75"
      >
        <OffcanvasHeader toggle={() => setOffcanvas(false)}>
          <i className="bi bi-moon-stars"></i> Cantina
        </OffcanvasHeader>
        <OffcanvasBody className="sfondo-azzurro">
          {leadCantina.map((lead) => (
            <PrintLead
              key={lead.id}
              lead={lead}
              operatori={operatori}
              ricaricaDati={(nuoviDati) => ricaricaDati(nuoviDati)}
              selectedOperatore={selectedOperatore}
              aspetto="cantina"
            />
          ))}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
}

export default Cantina
