import React, { useState } from 'react'
import { Card, FormGroup, Input, Label, Row, Col, Button } from 'reactstrap'
import { findCommercialistaIdByNomeEsteso } from '../utility'

const CardMedicoDaAssegnare = ({
  medico,
  commercialisti,
  assignMedico,
  deleteMedico,
}) => {
  const [email, setEmail] = useState(medico.email)
  const [telefono, setTelefono] = useState(
    medico.telefono == null ? '' : medico.telefono,
  )
  const [nome, setNome] = useState(medico.nome == null ? '' : medico.nome)
  const [cognome, setCognome] = useState(
    medico.cognome == null ? '' : medico.cognome,
  )
  const [appunti, setAppunti] = useState(
    medico.note_admin == null ? '' : medico.note_admin,
  )
  const [idCommercialistaSelezionato, setIdCommercialistaSelezionato] = useState(
    commercialisti[0].id,
  )

  const assegna = () => {
    assignMedico({
      medico_id: medico.id,
      email: email,
      telefono: telefono,
      nome: nome,
      cognome: cognome,
      note_admin: appunti,
      commercialista_id: idCommercialistaSelezionato,
    })
  }

  return (
    <Card body className="mb-4">
      <Row className="mt-2">
        <Col>
          <FormGroup>
            <Input
              type="select"
              onChange={(e) => {
                const idCommercialista = findCommercialistaIdByNomeEsteso(
                  e.target.value,
                  commercialisti,
                )
                setIdCommercialistaSelezionato(idCommercialista)
              }}
            >
              {commercialisti.map((commercialista) => (
                <option key={commercialista.id} value={commercialista.nome_esteso}>
                  {commercialista.nome_esteso}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <Button color="success" className="float-end" onClick={() => assegna()}>
            <i className="bi bi-send-check"></i> Assegna
          </Button>
          <Button
            color="danger"
            className="float-end me-2"
            onClick={() => deleteMedico(medico.id)}
          >
            <i className="bi bi-trash"></i>
          </Button>
        </Col>
      </Row>
      <FormGroup className="mt-5">
        <Label>Email</Label>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Telefono</Label>
        <Input
          type="text"
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <Label>Nome</Label>
            <Input
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Cognome</Label>
            <Input
              type="text"
              value={cognome}
              onChange={(e) => setCognome(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label>Appunti</Label>
        <Input
          type="textarea"
          value={appunti}
          rows="5"
          onChange={(e) => setAppunti(e.target.value)}
        />
      </FormGroup>
    </Card>
  )
}

export default CardMedicoDaAssegnare
