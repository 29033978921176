import React, { useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import PrintLead from './PrintLead'

const NonAssegnati = ({
  leadNonAssegnati,
  operatori,
  ricaricaDati,
  selectedOperatore,
}) => {
  const [offcanvas, setOffcanvas] = useState(false)

  return (
    <div className="d-inline ms-3">
      <Button
        color="secondary"
        outline
        size="sm"
        onClick={() => setOffcanvas(!offcanvas)}
        className="position-relative"
      >
        <i className="bi bi-person-plus"></i> Nuovi lead
        {leadNonAssegnati.length > 0 && (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {leadNonAssegnati.length}
          </span>
        )}
      </Button>
      <Offcanvas
        direction="end"
        scrollable
        toggle={() => setOffcanvas(false)}
        isOpen={offcanvas}
        className="w-75"
      >
        <OffcanvasHeader toggle={() => setOffcanvas(false)}>
          <i className="bi bi-person-plus"></i> Nuovi lead
        </OffcanvasHeader>
        <OffcanvasBody className="sfondo-azzurro">
          {leadNonAssegnati.map((lead) => (
            <PrintLead
              key={lead.id}
              lead={lead}
              operatori={operatori}
              ricaricaDati={(nuoviDati) => ricaricaDati(nuoviDati)}
              selectedOperatore={selectedOperatore}
              aspetto="non_assegnati"
            />
          ))}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
}

export default NonAssegnati
