import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../../tecnico/Home'
import Status from '../../tecnico/Status'
import DataIncasso from '../../tecnico/DataIncasso'
import AdeInfoStats from '../../tecnico/ade/AdeInfoStats'

const TecnicoRoutes = () => (
  <Switch>
    <Route exact path="/tecnico/status">
      <Status />
    </Route>
    <Route exact path="/tecnico/date-incasso">
      <DataIncasso />
    </Route>
    <Route exact path="/tecnico/ade-info-stats">
      <AdeInfoStats />
    </Route>
    <Route index>
      <Home />
    </Route>
  </Switch>
)

export default TecnicoRoutes
