import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../../app/store/user/selectors'
import NotLoggedRoutes from '../group/NotLoggedRoutes'
import SynchronizedUserDataComponent from './SynchronizedUserDataComponent'

const AuthStatusDetector = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    <>
      {isLoggedIn === true ? <SynchronizedUserDataComponent /> : <NotLoggedRoutes />}
    </>
  )
}

export default AuthStatusDetector
