import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Modal, ModalBody, ModalHeader } from 'reactstrap'
import moment from 'moment'
import { selectFileManager } from '../../../app/store/fileManager/selectors'
import { deleteFileCondiviso } from '../../../app/store/fileManager/actions'
import { useDispatch } from 'react-redux'
import { apriFileCondiviso } from '../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../app/store/fileViewer/selectors'
import classNames from 'classnames'

export const TabellaDocumenti = () => {
  const fileManager = useSelector(selectFileManager)
  const dispatch = useDispatch()
  const [nota, setNota] = useState('')
  const [modalNotaIsOpen, setModalNotaIsOpen] = useState(false)
  const loadingFile = useSelector(selectLoadingFile)

  const visualizzaNota = (nota) => {
    setNota(nota)
    setModalNotaIsOpen(true)
  }

  const modalNota = (
    <Modal isOpen={modalNotaIsOpen} toggle={() => setModalNotaIsOpen(false)}>
      <ModalHeader toggle={() => setModalNotaIsOpen(false)}>Nota</ModalHeader>
      <ModalBody>{nota}</ModalBody>
    </Modal>
  )

  const tabella = fileManager?.file_condiviso?.map((element, i) => (
    <tr key={i}>
      <td>
        {element.nota.length > 0 && (
          <span
            className="testo-blu"
            onClick={() => visualizzaNota(element.nota)}
            style={{ cursor: 'pointer' }}
          >
            Leggi
          </span>
        )}
      </td>
      <td>{moment(element.created_at).format('DD/MM/YYYY')}</td>
      <td>
        <span
          className={classNames({ 'testo-blu': loadingFile === false })}
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(apriFileCondiviso(element.id))}
        >
          {element.nome_file}
        </span>
      </td>
      <td>{element.owner === 'medico' ? 'Te' : 'Commercialista'}</td>
      <td className="text-danger">
        {element.owner === 'medico' && (
          <center
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(deleteFileCondiviso(element.id))}
          >
            <i className="bi bi-trash"></i>{' '}
          </center>
        )}
      </td>
    </tr>
  ))

  return (
    <>
      {modalNota}
      {fileManager?.file_condiviso?.length === undefined ||
      fileManager?.file_condiviso?.length === 0 ? (
        <div className="alert alert-info mt-5" role="alert">
          <i className="bi bi-info-circle"></i> Non ci sono documenti importati
        </div>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca mt-5">
          <thead>
            <tr>
              <th className="h6 font-regular">Nota</th>
              <th className="h6 font-regular">Data</th>
              <th className="h6 font-regular">File</th>
              <th className="h6 font-regular">Condiviso da</th>
              <th className="h6 font-regular"></th>
            </tr>
          </thead>
          <tbody>{tabella}</tbody>
        </Table>
      )}
    </>
  )
}

export default TabellaDocumenti
