import request from 'superagent'
import { saveAs } from 'file-saver'

export const listEsiti = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/contabile/gestore-fatture/list-esiti`)
      .send({ token: user.auth_token })

    return response.body
  } catch (e) {
    return []
  }
}

export const downloadZip = async (user, data_inizio, data_fine) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/contabile/gestore-fatture/download-zip`)
    .send({ token: user.auth_token, data_inizio, data_fine })
    .responseType('blob')
    .then((res) => {
      const file = new File([res.body], 'fatture.zip', {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(file)
      return true
    })
    .catch((err) => {
      return true
    })

export const rettificaFatturaQuery = async (user, data) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/contabile/gestore-fatture/rettifica-fattura`,
    )
    .send({ token: user.auth_token, data })

export const fatturaGestitaQuery = async (user, progressivo_unico_fattura_gestita) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/contabile/gestore-fatture/imposta-come-gestita`,
    )
    .send({ token: user.auth_token, progressivo_unico_fattura_gestita })

export const listImporti = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/contabile/numero-clienti/list`)
      .send({ token: user.auth_token })
    console.log(response.body)
    return response.body
  } catch (e) {
    return []
  }
}
