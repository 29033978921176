import React, { useEffect, useState } from 'react'
import { Card, Container, Button } from 'reactstrap'
import { queryMailChimpData } from '../../app/utility/QueryOperation'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import CentredLoading from '../component/CentredLoading'
import { Link } from 'react-router-dom'

const MailChimp = () => {
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const [datiFiltrati, setDatiFiltrati] = useState({
    disiscritti: [],
    fiscomed: [],
    commercialista: [],
    anno_apertura: [],
  })
  const [currentView, setCurrentView] = useState('disiscritti')

  useEffect(() => {
    queryMailChimpData(user).then((response) => {
      filtraDati(response.dati)
      setLoading(false)
    })
  }, [])

  const filtraDati = (data) => {
    setDatiFiltrati({
      disiscritti: data.filter((d) => d.status_mailchimp !== 'subscribed'),
      fiscomed: data.filter(
        (d) =>
          d.tag_fiscomed_presente === false && d.status_mailchimp === 'subscribed',
      ),
      commercialista: data.filter(
        (d) =>
          d.tag_commercialista_combaciante === false &&
          d.status_mailchimp === 'subscribed',
      ),
      anno_apertura: data.filter(
        (d) =>
          d.tag_piva_combaciante === false && d.status_mailchimp === 'subscribed',
      ),
    })
  }

  const tableData = datiFiltrati[currentView].map((d, i) => (
    <tr key={i}>
      <td>{d.email}</td>
      <td>
        {d.nome} {d.cognome}
      </td>
      <td>{d.tag_mailchimp}</td>
      <td>
        <Link to={'/shared/user-profile/' + d.medico_id}>Profilo</Link>
      </td>
    </tr>
  ))

  return (
    <Container fluid className="mt-4">
      {loading ? (
        <CentredLoading />
      ) : (
        <>
          <Card body className="mb-3">
            <div>
              <Button
                color="secondary"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'disiscritti'}
                onClick={() => setCurrentView('disiscritti')}
              >
                Disiscritti {datiFiltrati.disiscritti.length}
              </Button>
              <Button
                color="secondary"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'fiscomed'}
                onClick={() => setCurrentView('fiscomed')}
              >
                Fiscomed {datiFiltrati.fiscomed.length}
              </Button>
              <Button
                color="secondary"
                outline
                size="sm"
                className="me-2"
                disabled={currentView === 'commercialista'}
                onClick={() => setCurrentView('commercialista')}
              >
                Commercialista {datiFiltrati.commercialista.length}
              </Button>
              <Button
                color="secondary"
                outline
                size="sm"
                disabled={currentView === 'anno_apertura'}
                onClick={() => setCurrentView('anno_apertura')}
              >
                Anno apertura {datiFiltrati.anno_apertura.length}
              </Button>
            </div>
            <br />
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Nome</th>
                  <th>Tag</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          </Card>
        </>
      )}
    </Container>
  )
}

export default MailChimp
