import React, { useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectDichiarazione } from '../../../../app/store/gestionaleUtente/selectors'
import Completato from './Completato'
import Domanda from './Domanda'

const Quiz = () => {
  const dichiarazione = useSelector(selectDichiarazione)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Button
        className="mt-2 ms-2"
        color="secondary"
        outline
        size="sm"
        onClick={() => setModalIsOpen(true)}
      >
        Quiz <Completato completato={dichiarazione?.dati?.tappe[2]?.completata} />
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} size="lg">
        <ModalBody>
          {dichiarazione?.dati?.tappe[2]?.completata ? (
            dichiarazione?.dati?.tappe[2]?.domande.map((domanda, index) => (
              <Domanda key={index} domanda={domanda} />
            ))
          ) : (
            <span>Il cliente non ha completato questa sezione</span>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default Quiz
