import React, { useState } from 'react'
import request from 'superagent'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
  FormFeedback,
} from 'reactstrap'
import swal from '@sweetalert/with-react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const Variazione = () => {
  const user = useSelector(selectUser)
  const [loadingButtonInvio, setLoadingButtonInvio] = useState(false)
  const [dataEmissione, setDataEmissione] = useState(moment().format('YYYY-MM-DD'))
  const [numDocumentoFiscale, setNumDocumentoFiscale] = useState('')
  const [dataPagamento, setDataPagamento] = useState(moment().format('YYYY-MM-DD'))
  const [importo, setImporto] = useState(0.0)
  const [cfCittadino, setCfCittadino] = useState('')
  const [tipoSpesa, setTipoSpesa] = useState('SR')
  const [pagamentoTracciato, setPagamentoTracciato] = useState(false)
  const [flagOpposizione, setFlagOpposizione] = useState(false)
  const [tooltipDataEmissioneOpen, setTooltipDataEmissioneOpen] = useState(false)
  const [tooltipDataPagamentoOpen, setTooltipDataPagamentoOpen] = useState(false)
  const [tooltipImportoOpen, setTooltipImportoOpen] = useState(false)
  const [tooltipTipoSpesaOpen, setTooltipTipoSpesaOpen] = useState(false)
  const [tooltipPagamentoTracciatoOpen, setTooltipPagamentoTracciatoOpen] =
    useState(false)
  const [tooltipFlagOpposizioneOpen, setTooltipFlagOpposizioneOpen] = useState(false)
  const [tooltipNumDocumentoFiscaleOpen, setTooltipNumDocumentoFiscaleOpen] =
    useState(false)
  const [modalTipoSpesa, setModalTipoSpesa] = useState(false)
  const [erroreCF, setErroreCf] = useState(false)

  const inserisciDocumento = () => {
    if (loadingButtonInvio) return
    setLoadingButtonInvio(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/variazione-documento`)
      .send({
        token: user.auth_token,
        dataEmissione,
        numDocumentoFiscale,
        dataPagamento,
        importo,
        cfCittadino,
        tipoSpesa,
        pagamentoTracciato,
        flagOpposizione,
      })
      .then((response) => {
        setLoadingButtonInvio(false)
        mostraEsito(response.body)
      })
      .catch((e) => {
        console.error(e.message)
        setLoadingButtonInvio(false)
      })
  }

  const mostraEsito = (esito) => {
    let icon = 'success'
    let title = 'Esito verde'
    let eora =
      'Puoi rilassarti e tornare a fare quello che stavi facendo. Da questo momento, la persona interessata potrà consultare i dati corretti.'
    if (esito.error || esito.esito === '1') {
      icon = 'error'
      title = 'Esito rosso'
      eora =
        "Leggi la descrizione dell'errore e prova a fare un nuovo tentativo. Se incontri difficoltà scrivici su WhatsApp"
    }
    if (esito.esito === '2') {
      icon = 'warning'
      eora =
        'Se hai la certezza di aver scritto i dati corretti puoi ignorare questo messaggio e non fare nulla. Questo perché i dati sono stati comunque inviati.'
      title = 'Esito giallo'
    }

    let intestazione
    let messaggio
    intestazione =
      "L'esito rosso indica che i dati non sono stati inviati. Il Sistema Tessera Sanitaria dà questo errore:"
    messaggio = esito.error_message

    if (!esito.error) {
      if (esito.esito === '0') {
        intestazione = 'Tutto a posto: i dati sono stati inviati'
        messaggio = null
      }
      if (esito.esito === '1') {
        intestazione =
          "L'esito rosso indica che i dati non sono stati inviati. Il Sistema Tessera Sanitaria dà questo errore:"
        if (typeof esito.messaggi === 'string') {
          messaggio = esito.messaggi
        } else {
          messaggio = esito.messaggi.join(' ')
        }
      }
      if (esito.esito === '2') {
        intestazione =
          "L'esisto giallo indica che i dati sono stati inviati correttamente, ma che potresti aver sbagliato qualcosa. Il Sistema Tessera Sanitaria dà questo avviso:"
        if (typeof esito.messaggi === 'string') {
          messaggio = esito.messaggi
        } else {
          messaggio = esito.messaggi.join(' ')
        }
      }
    }
    swal({
      icon,
      title,
      content: (
        <div>
          {intestazione}
          <br />
          <br />
          {messaggio}
          <br />
          <br />
          <h5>E ora?</h5>
          {eora}
        </div>
      ),
      button: 'OK',
    })
  }

  const controlloCFPersona = (value) => {
    if (value.length > 16) {
      return
    }
    setCfCittadino(value)
    const regex =
      /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    if (value.length === 16 && value.match(regex)) {
      setErroreCf(false)
      return
    }
    setErroreCf(true)
  }

  return (
    <div>
      <Modal isOpen={modalTipoSpesa} toggle={() => setModalTipoSpesa(false)}>
        <ModalHeader
          className="font-regular"
          toggle={() => setModalTipoSpesa(false)}
        >
          Tipo Spesa
        </ModalHeader>
        <ModalBody>
          È un codice che indica la tipologia di spesa che la persona ha affrontato.
          <br />
          <br />
          Seleziona il codice <b>&quot;SR&quot;</b> se la persona ha pagato per:
          <br />
          - un&apos;assistenza specialistica ambulatoriale (tranne interventi di
          chirurgia estetica)
          <br />
          - una visita medica generica o specialistica
          <br />
          - una prestazione diagnostica e strumentale
          <br />
          - una prestazione chirurgica (tranne chirurgia estetica)
          <br />
          - un ricovero ospedaliero
          <br />
          - una certificazione medica.
          <br />
          <br />
          Scegli il codice <b>&quot;IC&quot;</b> se hai fatto un intervento di
          chirurgia estetica ambulatoriale o ospedaliero.
          <br />
          <br />
          Infine, seleziona il codice <b>&quot;AA&quot;</b> se la persona ha pagato
          per prestazioni che non rientrano in quelle elencate sopra.
          <br />
          <br />
          Considera che, molto probabilmente, il codice che dovrai selezionare sarà
          sempre &quot;SR&quot;.
        </ModalBody>
      </Modal>
      <br />
      <h6 className="font-regular interlinea-30">
        Se hai già inviato dei dati, ma pensi di aver sbagliato qualcosa non
        preoccuparti. Compila questi campi per inviare una &quot;variazione&quot;,
        cioè la versione corretta di un documento che hai già inviato.
      </h6>
      <br />
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Data di emissione{' '}
              <i className="bi bi-question-circle" id="tooltip-dataemissione"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipDataEmissioneOpen}
                target="tooltip-dataemissione"
                toggle={() => setTooltipDataEmissioneOpen(!tooltipDataEmissioneOpen)}
              >
                È il giorno in cui hai emesso la fattura
              </Tooltip>
            </Label>
            <Input
              type="date"
              value={dataEmissione}
              onChange={(e) => setDataEmissione(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Numero del documento fiscale{' '}
              <i className="bi bi-question-circle" id="tooltip-numdocfisc"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipNumDocumentoFiscaleOpen}
                target="tooltip-numdocfisc"
                toggle={() =>
                  setTooltipNumDocumentoFiscaleOpen(!tooltipNumDocumentoFiscaleOpen)
                }
              >
                È il numero della fattura
              </Tooltip>
            </Label>
            <Input
              type="text"
              value={numDocumentoFiscale}
              onChange={(e) => setNumDocumentoFiscale(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Data del pagamento{' '}
              <i className="bi bi-question-circle" id="tooltip-datapagamento"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipDataPagamentoOpen}
                target="tooltip-datapagamento"
                toggle={() => setTooltipDataPagamentoOpen(!tooltipDataPagamentoOpen)}
              >
                È il giorno in cui la persona ti ha pagato. Spesso coincide con la
                data di emissione
              </Tooltip>
            </Label>
            <Input
              type="date"
              value={dataPagamento}
              onChange={(e) => setDataPagamento(e.target.value)}
            />
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={pagamentoTracciato}
                onChange={(e) => setPagamentoTracciato(e.target.checked)}
              />
              Pagamento tracciato{' '}
              <i
                className="bi bi-question-circle"
                id="tooltip-pagamentotracciato"
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipPagamentoTracciatoOpen}
                target="tooltip-pagamentotracciato"
                toggle={() =>
                  setTooltipPagamentoTracciatoOpen(!tooltipPagamentoTracciatoOpen)
                }
              >
                Spunta questa casella se la persona ti ha pagato con un sistema
                tracciato (con carta, bancomat, bonifico...). Se la persona ha pagato
                anche solo una parte della prestazione con i contanti NON devi
                spuntare questa casella
              </Tooltip>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={flagOpposizione}
                onChange={(e) => setFlagOpposizione(e.target.checked)}
              />
              Flag di opposizione{' '}
              <i className="bi bi-question-circle" id="tooltip-flagopposizione"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipFlagOpposizioneOpen}
                target="tooltip-flagopposizione"
                toggle={() =>
                  setTooltipFlagOpposizioneOpen(!tooltipFlagOpposizioneOpen)
                }
              >
                Spunta questa casella solo se la persona NON ti ha dato il codice
                fiscale, quindi se ha dichiarato di NON volere &quot;scaricare&quot;
                la prestazione
              </Tooltip>
            </Label>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Importo <i className="bi bi-question-circle" id="tooltip-importo"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipImportoOpen}
                target="tooltip-importo"
                toggle={() => setTooltipImportoOpen(!tooltipImportoOpen)}
              >
                È la cifra indicata nella fattura. Deve essere comprensiva di marca
                da bollo. Per esempio se hai una fattura di quaranta euro e cinque
                centesimi inserisci 40.05
              </Tooltip>
            </Label>
            <Input
              type="text"
              value={importo}
              onChange={(e) => setImporto(e.target.value)}
            />
          </FormGroup>
          {!flagOpposizione && (
            <FormGroup>
              <Label>Codice fiscale del/della paziente</Label>
              <Input
                type="text"
                value={cfCittadino}
                onChange={(e) => controlloCFPersona(e.target.value)}
                valid={cfCittadino.length > 0 && !erroreCF}
                invalid={cfCittadino.length > 0 && erroreCF}
              />
              <FormFeedback valid>Sembra corretto</FormFeedback>
              <FormFeedback>Il codice fiscale non sembra essere giusto</FormFeedback>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Tipo di spesa{' '}
              <i
                className="bi bi-question-circle"
                id="tooltip-tipospesa"
                onClick={() => setModalTipoSpesa(!modalTipoSpesa)}
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipTipoSpesaOpen}
                target="tooltip-tipospesa"
                toggle={() => setTooltipTipoSpesaOpen(!tooltipTipoSpesaOpen)}
              >
                Clicca qui per la spiegazione
              </Tooltip>
            </Label>
            <Input
              type="select"
              value={tipoSpesa}
              onChange={(e) => setTipoSpesa(e.target.value)}
            >
              <option value="SR">SR</option>
              <option value="SS">SS</option>
              <option value="SP">SP</option>
            </Input>
          </FormGroup>

          <Button
            onClick={() => inserisciDocumento()}
            className="bottone-blu float-end mt-4"
          >
            {loadingButtonInvio ? (
              <Spinner size="sm" type="grow" />
            ) : (
              'INVIA LA VARIAZIONE'
            )}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default Variazione
