import request from 'superagent'

export const queryStatus = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/tecnico/status/list`)
    .send({ token: user.auth_token })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

export const queryCancellaFiscoStatus = (user, id) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/tecnico/status/delete-fiscostatus`)
    .send({ token: user.auth_token, user_id: id })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

export const queryListDocumenti = (user, data, tipologia) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/tecnico/date-incasso/list-documenti`)
    .send({ token: user.auth_token, data, tipologia })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

export const queryAdeInfoStats = (user) =>
  request
    .get(`${process.env.REACT_APP_API_URL}/tecnico/ade/stats`)
    .set({ token: user.auth_token })
    .then((response) => {
      return response.body
    })

export const querySincronizzaCassetto = ({ user, sections }) =>
  request
    .patch(`${process.env.REACT_APP_API_URL}/tecnico/ade/sync_all`)
    .set({ token: user.auth_token })
    .send({ sections })
    .then((response) => {
      return response.body
    })
