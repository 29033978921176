import React, { useEffect, useState } from 'react'
import PrivateNavContainer from './PrivateNavContainer'

const LargeScreenOnly = () => {
  const [largeScreen, setLargeScreen] = useState(window.innerWidth >= 1024)

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        setLargeScreen(true)
      } else {
        setLargeScreen(false)
      }
    })
  }, [])

  return <>{largeScreen && <PrivateNavContainer />}</>
}

export default LargeScreenOnly
