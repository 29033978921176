import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { listLead } from '../../../app/utility/QueryLead'
import { Button, Card, Col, Container, Row } from 'reactstrap'
import SelezionaOperatore from './component/SelezionaOperatore'
import NonAssegnati from './component/NonAssegnati'
import Cantina from './component/Cantina'
import LeadChiusi from '../leadChiusi/LeadChiusi'
import PrintLead from './component/PrintLead'

const Lead = () => {
  const [dati, setDati] = useState({
    lead: [],
    lead_cantina: [],
    lead_non_assegnati: [],
    operatori: [],
  })
  const user = useSelector(selectUser)
  const [selectedOperatore, setSelectedOperatore] = useState({
    email: user.email,
    public_uuid: user.public_uuid,
  })

  useEffect(() => {
    caricaDati(user.public_uuid)
  }, [])

  const caricaDati = (operatore_uuid) => {
    listLead(user, operatore_uuid).then((datiFromServer) => {
      setDati(datiFromServer)
    })
  }

  const cambiaOperatore = (operatore) => {
    setSelectedOperatore(operatore)
    caricaDati(operatore.public_uuid)
  }

  return (
    <Container>
      <Card body className="mt-4">
        <div className="my-3 d-flex">
          <SelezionaOperatore
            operatori={dati.operatori}
            selectedOperatore={selectedOperatore}
            selectOperatore={(operatore) => cambiaOperatore(operatore)}
          />
          <Cantina
            leadCantina={dati.lead_cantina}
            ricaricaDati={(nuoviDati) => setDati(nuoviDati)}
            selectedOperatore={selectedOperatore}
            operatori={dati.operatori}
          />
          <LeadChiusi canvas={true} />
          <a
            href="https://portale.fnomceo.it/cerca-prof/"
            target="_blank"
            className="ms-3"
          >
            <Button
              color="secondary"
              outline
              size="sm"
              className="position-relative"
            >
              <i className="bi bi-search"></i> Albo
            </Button>
          </a>
          <NonAssegnati
            leadNonAssegnati={dati.lead_non_assegnati}
            ricaricaDati={(nuoviDati) => setDati(nuoviDati)}
            selectedOperatore={selectedOperatore}
            operatori={dati.operatori}
          />
        </div>
      </Card>
      <br />
      {dati.lead.length === 0 && (
        <Card body className="py-5">
          <center>
            <h3 className="font-regular">
              🎊🎉🪇 Non ci sono chiamate per oggi 🪇🎉🎊
            </h3>
          </center>
        </Card>
      )}
      {dati.lead.map((lead) => (
        <PrintLead
          key={lead.id}
          lead={lead}
          operatori={dati.operatori}
          ricaricaDati={(nuoviDati) => setDati(nuoviDati)}
          selectedOperatore={selectedOperatore}
          aspetto="lead"
        />
      ))}
    </Container>
  )
}

export default Lead
