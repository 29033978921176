// serve per lo sviluppo
export const selectFullUser = (state) => state.user

export const selectIsLoggedIn = (state) => state.user.user.auth_token !== undefined
export const selectUser = (state) => state.user.user

export const selectIsLoadingUser = (state) => state.user.isLoading

export const selectUserError = (state) => ({
  error: state.user.user.error,
  message_error: state.user.user.message_error,
})

export const selectIsMedico = (state) => state.user.user.role === 'medico'

export const selectIsMedicoMoroso = (state) =>
  state.user.user.role === 'medico' && !state.user.user.active

export const selectIsCommercialista = (state) =>
  state.user.user.role === 'commercialista'

export const selectIsAdmin = (state) => state.user.user.role === 'admin'

export const selectIsContabile = (state) => state.user.user.role === 'contabile'

export const selectIsOperation = (state) => state.user.user.role === 'operation'

export const selectIsTecnico = (state) => state.user.user.role === 'tecnico'

export const selectIsyncWithServer = (state) => state.user.syncWithServer

export const selectIsSuperUser = (state) =>
  state.user.user.super_user_token !== undefined &&
  state.user.user.super_user_token !== null
