export const documentToUploadIsValid = (documento) => {
  if (documento.tipologia === 'fattura') {
    return (
      documento.data_incasso !== '' &&
      documento.numero !== '' &&
      documento.importo !== ''
    )
  }
  if (documento.tipologia === 'cedolino') {
    return (
      documento.data_incasso !== '' &&
      documento.numero !== '' &&
      documento.importo !== '' &&
      documento.trattenuta_enpam !== ''
    )
  }
  if (documento.tipologia === 'borsa_mmg') {
    return documento.data_incasso !== '' && documento.importo !== ''
  }
  if (documento.tipologia === 'borsa_specializzazione') {
    return documento.data_incasso !== ''
  }
  if (documento.tipologia === 'prestazione_occasionale') {
    return (
      documento.data_incasso !== '' &&
      documento.importo !== '' &&
      documento.trattenuta_irpef !== ''
    )
  }
  if (documento.tipologia === 'altro') {
    return documento.nota !== ''
  }
}
