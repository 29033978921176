import React, { useContext } from 'react'
import { Container } from 'reactstrap'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'

const NoDichiarazione = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <Container fluid>
      <div className="my-5">
        <h1 className="font-bold">Niente dichiarazione per quest'anno 🎉 </h1>
        <br />
        <h6 className="font-regular interlinea-30 mb-3">
          Abbiamo controllato tutto e, in base alle informazioni che abbiamo,{' '}
          <span className="font-bold">
            non devi fare la dichiarazione dei redditi
          </span>{' '}
          quest'anno.
        </h6>
        <h6 className="font-regular interlinea-30 mb-3">
          La dichiarazione va fatta solo in alcuni casi specifici e tu non rientri in
          nessuno di questi. Per questo motivo, in questa sezione non vedrai un
          percorso attivo.
        </h6>
        <h6 className="font-regular interlinea-30 mb-3">
          Tutto qui: nessuna scadenza da ricordare, nessun documento da caricare
        </h6>
        <h6 className="font-regular interlinea-30">
          Hai dubbi o c'è qualcosa che non ti torna? Puoi sempre chiedere{' '}
          {context.genere.ilTuo} commercialista ☺️
        </h6>
      </div>
    </Container>
  )
}

export default NoDichiarazione
