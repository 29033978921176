import React from 'react'
import { Card, CardTitle, CardText } from 'reactstrap'
import moment from 'moment'
import classnames from 'classnames'

const ListaEmail = ({ medici, selectedMedico, changeSelected }) => {
  return (
    <>
      {medici.map((medico) => (
        <Card
          data-testid="medico-card"
          body
          key={medico.id}
          className="mb-3"
          color={medico.id === selectedMedico.id ? 'primary' : undefined}
          inverse={medico.id === selectedMedico.id}
          onClick={() => changeSelected(medico)}
          style={{ cursor: 'pointer' }}
        >
          <CardTitle tag="h5">
            {medico.nome} {medico.cognome}
          </CardTitle>
          <CardText>
            <small
              className={classnames({
                'text-muted': medico.id !== selectedMedico.id,
              })}
            >
              Acquisto fatto {moment().diff(medico.created_at, 'days')} giorn
              {moment().diff(medico.created_at, 'days') === 1 ? 'o' : 'i'} fa
            </small>
          </CardText>
        </Card>
      ))}
    </>
  )
}

export default ListaEmail
