import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { Button, Card } from 'reactstrap'
import { queryTogglePercorsoDichiarativo } from '../../../app/utility/QueryOperation'

const IlBottoneDelPercorsoDichiarativo = ({ percorsoAperto }) => {
  const user = useSelector(selectUser)
  const [percorsoDichiarativoAperto, setPercorsoDichiarativoAperto] =
    useState(percorsoAperto)

  const togglePercorsoDichiarativo = () => {
    if (
      confirm('Sei sicuro di voler cambiare lo stato del percorso dichiarativo?')
    ) {
      setPercorsoDichiarativoAperto(!percorsoDichiarativoAperto)
      queryTogglePercorsoDichiarativo(user)
    }
  }

  return (
    <Card body className="mb-3">
      <center>
        <h1 className={percorsoDichiarativoAperto ? 'text-success' : 'text-danger'}>
          {percorsoDichiarativoAperto ? (
            <i className="bi bi-door-open-fill"></i>
          ) : (
            <i className="bi bi-door-closed-fill"></i>
          )}
        </h1>
        <h4>
          Percorso dichiarativo {percorsoDichiarativoAperto ? 'aperto' : 'chiuso'}
        </h4>
        {user.role === 'operation' && (
          <>
            <Button
              color={percorsoDichiarativoAperto ? 'danger' : 'success'}
              onClick={() => togglePercorsoDichiarativo()}
            >
              {percorsoDichiarativoAperto
                ? 'Chiudi il percorso dichiarativo'
                : 'Apri il percorso dichiarativo'}
            </Button>
            <br />
          </>
        )}
      </center>
    </Card>
  )
}

export default IlBottoneDelPercorsoDichiarativo
