import React, { useEffect, useState } from 'react'
import { Container, Row, Col, FormGroup, Input } from 'reactstrap'
import request from 'superagent'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Footer from '../landing/Footer'

const BlogHomeMobile = () => {
  const [articoli, setArticoli] = useState([])
  const [searchKey, setSearchKey] = useState('')
  const { categoriaPrimaria } = useParams()
  const [categoria, setCategoria] = useState(categoriaPrimaria)

  useEffect(() => {
    request
      .get(
        `${process.env.REACT_APP_API_URL}/blog/list-articoli-categoria/${categoria}`,
      )
      .then((response) => setArticoli(response.body.articoli))
      .catch((e) => {
        console.error(e.message)
      })
  }, [categoria])

  const articoliComponent = () =>
    articoli.map((elemento, i) => {
      if (elemento.articolo.titolo.toLowerCase().includes(searchKey.toLowerCase())) {
        return (
          <Col xs={12} key={i}>
            <div key={i} className="mt-5">
              <img
                style={{ width: '100%' }}
                src={elemento.articolo.miniatura_base64}
                alt={elemento.articolo.titolo}
              />
              <div className="mt-4">
                <h6 className="testo-landing me-3 d-inline">
                  {moment(elemento.articolo.data_aggiornamento)
                    .locale('it')
                    .format('D MMMM YYYY')}
                </h6>
                •
                <h6 className="testo-landing ms-3 d-inline">
                  {parseInt(elemento.articolo?.testo.length / 1260) + 1}{' '}
                  {parseInt(elemento.articolo?.testo.length / 1260) === 0
                    ? 'minuto'
                    : 'minuti'}{' '}
                  di lettura
                </h6>
              </div>

              <h5 className="font-bold mt-4">{elemento.articolo.titolo}</h5>
              <h6 className="testo-landing mt-3 interlinea-30">
                {elemento.articolo?.descrizione?.substring(0, 300)}...
              </h6>

              <div className="mt-4">
                {elemento.tag_associati[0]?.nome && (
                  <div
                    type="button"
                    onClick={() => setCategoria(elemento.tag_associati[0]?.nome)}
                    style={{
                      backgroundColor: '#66d98e',
                      color: 'white',
                      padding: '6px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      borderRadius: '7px',
                      marginRight: '15px',
                      display: 'inline-block',
                      fontWeight: 500,
                    }}
                  >
                    {elemento.tag_associati[0]?.nome}
                  </div>
                )}
                {elemento.tag_associati[1]?.nome && (
                  <div
                    type="button"
                    onClick={() => setCategoria(elemento.tag_associati[1]?.nome)}
                    style={{
                      backgroundColor: '#66d98e',
                      color: 'white',
                      padding: '6px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      borderRadius: '7px',
                      marginRight: '15px',
                      display: 'inline-block',
                      fontWeight: 500,
                    }}
                  >
                    {elemento.tag_associati[1]?.nome}
                  </div>
                )}
              </div>
              <br />
              <Link
                to={'/blog/articolo/' + elemento.articolo.url}
                className="float-end"
              >
                Leggi l&apos;articolo{' '}
                <i className="bi bi-arrow-right-short" type="button"></i>
              </Link>
              <br />
              <br />
            </div>
          </Col>
        )
      } else {
        return null
      }
    })

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta property="title" content="Blog" />
        <meta property="og:site_name" content="fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/blog/:categoria" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="Il blog di fiscomed.it" />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }} className="mobile-container">
        <br />
        <br />
        <div className="header-mobile-blog" />{' '}
        <h1 className="titolo1-mobile">Questo è il nostro blog</h1>
        <br />
        <h6 className="font-regular interlinea-30">
          Qui puoi trovare articoli sulla fiscalità della professione medica, scritti
          in parole semplici e approvati dai nostri commercialisti
        </h6>
        <br />
        <FormGroup className="mb-3">
          <Input
            type="text"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="🔎&nbsp;&nbsp; Digita una parola"
            bsSize="lg"
            style={{
              maxWidth: '900px',
              margin: 'auto',
              borderRadius: '10px',
              height: 'calc(2.5em + 1rem + 2px)',
              fontSize: '1rem',
            }}
          />
        </FormGroup>
        <br />
        <h6 className="font-regular text-muted">oppure cerca per categoria</h6>
        <div
          className="categorie-blog-mobile mt-4"
          style={{
            width: '100%',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            className="d-inline-block"
            type="button"
            onClick={() => setCategoria('home')}
            style={{
              backgroundColor: categoria === 'home' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              marginRight: '10px',
              fontWeight: 500,
            }}
          >
            In evidenza
          </div>
          <div
            type="button"
            className="d-inline-block"
            onClick={() => setCategoria('recenti')}
            style={{
              backgroundColor: categoria === 'recenti' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              marginRight: '10px',
              fontWeight: 500,
            }}
          >
            I più recenti
          </div>
          <div
            className="d-inline-block"
            type="button"
            onClick={() => setCategoria('Fatturazione')}
            style={{
              backgroundColor: categoria === 'Fatturazione' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              marginRight: '10px',
              fontWeight: 500,
            }}
          >
            Fatturazione
          </div>
          <div
            type="button"
            className="d-inline-block"
            onClick={() => setCategoria('ENPAM')}
            style={{
              backgroundColor: categoria === 'ENPAM' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              marginRight: '10px',
              fontWeight: 500,
            }}
          >
            ENPAM
          </div>
          <div
            type="button"
            className="d-inline-block"
            onClick={() => setCategoria('Sistema tessera sanitaria')}
            style={{
              backgroundColor:
                categoria === 'Sistema tessera sanitaria' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              marginRight: '10px',
              fontWeight: 500,
            }}
          >
            Sistema tessera sanitaria
          </div>
          <div
            type="button"
            className="d-inline-block"
            onClick={() => setCategoria('Regime forfettario')}
            style={{
              backgroundColor:
                categoria === 'Regime forfettario' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              marginRight: '10px',
              fontWeight: 500,
            }}
          >
            Regime forfettario
          </div>
        </div>
        <br />
        {articoli.length === 0 ? (
          <>
            <br />
            <br />
            <div
              className="alert alert-warning"
              style={{ maxWidth: '400px' }}
              role="alert"
            >
              Mi dispiace, ma ancora non ci sono articoli che rientrano in questa
              categoria 😢
            </div>
          </>
        ) : (
          <Row>{articoliComponent()}</Row>
        )}
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default BlogHomeMobile
