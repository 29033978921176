import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Navbar,
  Collapse,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import { logout } from '../../../app/store/user/slice'
import { selectIsSuperUser, selectFullUser } from '../../../app/store/user/selectors'
import { useSelector } from 'react-redux'
import { logoutSuperUserAction } from '../../../app/store/user/actions'

const MedicoMorosoNav = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSuperUser = useSelector(selectIsSuperUser)
  const user = useSelector(selectFullUser)
  const [modalMorosoIsOpen, setModalMorosoIsOpen] = useState(false)

  const logoutSuperUser = () => {
    dispatch(logoutSuperUserAction())
    history.push('/shared/user-profile/' + user.user.medico_id)
  }

  return (
    <>
      <Modal
        isOpen={modalMorosoIsOpen}
        toggle={() => setModalMorosoIsOpen(!modalMorosoIsOpen)}
      >
        <ModalHeader toggle={() => setModalMorosoIsOpen(!modalMorosoIsOpen)}>
          Stai usando Fiscomed in modalità limitata
        </ModalHeader>
        <ModalBody className="font-regular">
          Dato che il tuo abbonamento non è attivo, alcune funzioni come la
          fatturazione e la previsione delle tasse non sono disponibili.
          <br />
          <br /> Ma non preoccuparti: puoi sempre{' '}
          <span className="font-bold">consultare</span> e{' '}
          <span className="font-bold">scaricare</span> i documenti fiscali di cui hai
          bisogno, così hai sempre tutto a portata di mano.
          <br />
          <br />
          Se vuoi riattivare il tuo profilo, scrivici su WhatsApp al numero{' '}
          <span className="font-bold">3395601221</span>. Saremo felici di aiutarti 🤗
        </ModalBody>
      </Modal>

      <Navbar
        light
        style={{
          zIndex: '1000',
          backgroundColor: 'rgba(255, 255, 255,1)',
        }}
        expand="md"
        fixed="none"
      >
        <Container
          fluid
          style={{
            display: 'flex',
          }}
        >
          <NavbarBrand tag={Link} to="/fiscomed">
            <img
              src="/img_landing/logo.webp"
              alt="Fiscomed"
              style={{ height: '42px' }}
            />
          </NavbarBrand>
          <NavbarToggler style={{ backgroundColor: 'white' }} />
          <Collapse isOpen={true} navbar>
            <Nav navbar>
              <Button color="danger" onClick={() => setModalMorosoIsOpen(true)}>
                Modalità limitata
              </Button>
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/riepilogo-fatture">
                    <i className="bi bi-journals"></i> Riepilogo fatture
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    href="#"
                  >
                    <i className="bi bi-people"></i> Commercialista
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-start"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/chat-commercialista">
                      Vuoi una mano?
                    </Link>
                    <div className="dropdown-divider"></div>

                    <Link className="dropdown-item" to="/dichiarazione">
                      Dichiarazione
                    </Link>
                    <Link className="dropdown-item" to="/enpam">
                      L&apos;angolo dell&apos;ENPAM
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    href="#"
                  >
                    <i className="bi bi-tools"></i> Strumenti
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-start"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/importa-documento">
                      Importa documento
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/rubrica">
                      Rubrica clienti
                    </Link>
                    <Link className="dropdown-item" to="/metodo-di-pagamento">
                      Metodi di pagamento
                    </Link>
                  </div>
                </li>
              </ul>
            </Nav>

            <Nav navbar className="ms-auto">
              <ul className="navbar-nav">
                {isSuperUser && (
                  <Button color="danger" onClick={() => logoutSuperUser()}>
                    <i className="bi bi-tv"></i> Gestionale
                  </Button>
                )}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    href="#"
                  >
                    Account
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/informazioni-fiscali">
                      Informazioni sulla partita IVA
                    </Link>
                    <Link className="dropdown-item" to="/abbonamento">
                      Abbonamento
                    </Link>
                    <div className="dropdown-divider"></div>
                    <a
                      href="https://fiscomed.it/documenti/privacy_policy.pdf"
                      className="dropdown-item"
                      title="Privacy Policy"
                      target="blank"
                    >
                      Privacy policy
                    </a>

                    <a
                      href="https://fiscomed.it/documenti/termini_e_condizioni.pdf"
                      className="dropdown-item"
                      title="Termini e Condizioni"
                      target="blank"
                    >
                      Termini e condizioni
                    </a>
                    <a
                      href="https://fiscomed.it/documenti/cookie_policy.pdf"
                      className="dropdown-item"
                      title="Cookie Policy"
                      target="blank"
                    >
                      Cookie policy
                    </a>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/cambio-password">
                      Cambio password
                    </Link>
                    <button
                      className="dropdown-item"
                      onClick={() => dispatch(logout())}
                    >
                      Logout
                    </button>
                  </div>
                </li>
              </ul>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default MedicoMorosoNav
