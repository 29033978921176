import request from 'superagent'

export const pendingViewData = async (user) => {
  try {
    const response = await request
      .get(`${process.env.REACT_APP_API_URL}/operation/pending/view-data`)
      .set({ token: user.auth_token })
    return response.body
  } catch (e) {
    console.error(e.message)
  }
}

export const destroyMedico = async (user, medico_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/operation/pending/destroy-medico`)
      .send({ token: user.auth_token, medico_id })
    return response.status
  } catch (e) {
    console.error(e.message)
  }
}

export const assignMedicoToCommercialista = async (user, data) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/operation/pending/assign-medico-to-commercialista`,
      )
      .send({ token: user.auth_token, data })
    return response.body
  } catch (e) {
    console.error(e.message)
  }
}

export const getCommercialisti = async (user) => {
  try {
    const response = await request
      .get(`${process.env.REACT_APP_API_URL}/operation/commercialisti`)
      .set({ token: user.auth_token })
    return response.body
  } catch (e) {
    console.error(e.message)
  }
}

export const createCommercialista = async (user, commercialista) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/operation/commercialisti`)
      .set({ token: user.auth_token })
      .send({ commercialista })
    return response.body
  } catch (e) {
    console.error(e.message)
  }
}

export const updateCommercialista = async (
  user,
  idCommercialista,
  commercialista,
) => {
  try {
    const response = await request
      .patch(
        `${process.env.REACT_APP_API_URL}/operation/commercialisti/${idCommercialista}`,
      )
      .set({ token: user.auth_token })
      .send({ commercialista })
    return response.body
  } catch (e) {
    console.error(e.message)
  }
}

export const queryIdrataDatiTappeOperation = (user, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/operation/tappe/idrata-dati`)
    .send({ token: user.auth_token, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryIdrataLimitiIncasso = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/operation/strumenti/limiti-incasso`)
    .send({ token: user.auth_token })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryIdrataStripe = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/operation/subscription/list`)
    .send({ token: user.auth_token })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryModificaSubscription = (user, subscriptionId, note, visible) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/operation/subscription/update`)
    .send({ token: user.auth_token, id: subscriptionId, note, visible })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryModificaStripeId = (user, medico_id, stripe_customer_id) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/operation/subscription/upadate-stripe-customer-id`,
    )
    .send({ token: user.auth_token, medico_id, stripe_customer_id })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const querySyncSubscription = (user, medico_id) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/operation/subscription/sync-subscription`,
    )
    .send({ token: user.auth_token, medico_id })
    .then((response) => response.body)
    .catch((e) => {
      alert('Errore, non esiste un customer con questo id')
      return null
    })

export const queryMailChimpData = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/operation/strumenti/mailchimp-data`)
    .send({ token: user.auth_token })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryTogglePercorsoDichiarativo = (user) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/operation/strumenti/toggle-percorso-dichiarativo`,
    )
    .send({ token: user.auth_token })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))
