import request from 'superagent'

// al contrario di altre funzioni, questa funzione ritorna una lista non solo di file_blocchetto ma anche di file_condivisi
export const idrataDocumenti = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/list`)
      .send({ token: user.auth_token })
    return response.body
  } catch (e) {
    return null
  }
}

export const uploadDocumentoBlocchetto = async (user, dati) => {
  const formData = new FormData()
  formData.append('file_to_upload', dati.file_to_upload)
  formData.append('medico_id', dati.medico_id)
  formData.append('data_incasso', dati.data_incasso)
  formData.append('numero', dati.numero)
  formData.append('importo', dati.importo)
  formData.append('trattenuta_enpam', dati.trattenuta_enpam)
  formData.append('trattenuta_irpef', dati.trattenuta_irpef)
  formData.append('partita_iva', dati.partita_iva)
  formData.append('codice_fiscale', dati.codice_fiscale)
  formData.append('inviata_ts', dati.inviata_ts)
  formData.append('nota', dati.nota)
  formData.append('file_type', dati.file_type)
  formData.append('token', user.auth_token)
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/upload`)
      .send(formData)
    return response.body
  } catch (e) {
    return null
  }
}

// medico_id is the owner of file, pass null if this action is used directly by the medico.
export const updateDocumentoBlocchetto = async (user, dati) => {
  const formData = new FormData()
  formData.append('medico_id', dati.medico_id)
  formData.append('data_incasso', dati.data_incasso)
  formData.append('numero', dati.numero)
  formData.append('importo', dati.importo)
  formData.append('trattenuta_enpam', dati.trattenuta_enpam)
  formData.append('trattenuta_irpef', dati.trattenuta_irpef)
  formData.append('partita_iva', dati.partita_iva)
  formData.append('codice_fiscale', dati.codice_fiscale)
  formData.append('inviata_ts', dati.inviata_ts)
  formData.append('file_type', dati.file_type)
  formData.append('token', user.auth_token)
  formData.append('appunti_verifica', dati.appunti_verifica)
  formData.append('id_documento', dati.id_documento)
  formData.append('controllato', dati.controllato)
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/update`)
      .send(formData)
    return response.body
  } catch (e) {
    return null
  }
}

export const deleteDocumentoBlocchetto = async (user, id_documento) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/delete`)
      .send({ token: user.auth_token, id_documento })
    return response.body
  } catch (e) {
    return null
  }
}

export const queryDocumentoDaControllare = async (user) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/sezionale-blocchetto/documento-da-controllare`,
      )
      .send({ token: user.auth_token })
    return response.body
  } catch (e) {
    return null
  }
}
