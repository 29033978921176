import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  idrataDocumenti,
  uploadDocumentoBlocchetto,
  deleteDocumentoBlocchetto,
} from '../../utility/QueryDocumentiImportati'
import {
  uploadDocumentoCondiviso,
  deleteDocumentoCondiviso,
} from '../../utility/QueryShared'

export const idrata = createAsyncThunk(
  'fileManager/idrata',
  async (_, { getState }) => {
    const state = getState()
    return await idrataDocumenti(state.user.user)
  },
)

export const uploadFileCondiviso = createAsyncThunk(
  'fileManager/uploadFileCondiviso',
  async ({ selectedFile, medico_id }, { getState }) => {
    const state = getState()
    const responseBody = await uploadDocumentoCondiviso(state.user.user, {
      file: selectedFile,
      medico_id,
      nota: state.fileManager.documento.nota,
      file_type: 'file_condiviso',
    })
    return responseBody
  },
)

export const uploadFileBlocchetto = createAsyncThunk(
  'fileManager/uploadFileBlocchetto',
  async ({ selectedFile, medico_id }, { getState }) => {
    const state = getState()
    const responseBody = await uploadDocumentoBlocchetto(state.user.user, {
      file_to_upload: selectedFile,
      medico_id,
      data_incasso: state.fileManager.documento.data_incasso,
      numero: state.fileManager.documento.numero,
      importo: state.fileManager.documento.importo,
      trattenuta_enpam: state.fileManager.documento.trattenuta_enpam,
      trattenuta_irpef: state.fileManager.documento.trattenuta_irpef,
      partita_iva: state.fileManager.documento.partita_iva,
      codice_fiscale: state.fileManager.documento.codice_fiscale,
      inviata_ts: state.fileManager.documento.inviata_ts,
      nota: state.fileManager.documento.nota,
      file_type: state.fileManager.documento.tipologia,
    })
    return responseBody
  },
)

export const deleteFileBlocchetto = createAsyncThunk(
  'fileManager/deleteFileBlocchetto',
  async ({ id_documento, tipologia }, { getState }) => {
    const state = getState()
    if (confirm('Sei sicuro di voler cancellare il documento?')) {
      deleteDocumentoBlocchetto(state.user.user, id_documento)
      return { id: id_documento, tipologia }
    } else {
      return { id: null, tipologia: null }
    }
  },
)

export const deleteFileCondiviso = createAsyncThunk(
  'fileManager/deleteFileCondiviso',
  async (id_documento, { getState }) => {
    const state = getState()
    if (confirm('Sei sicuro di voler cancellare il documento?')) {
      deleteDocumentoCondiviso(state.user.user, id_documento)
      return { id: id_documento }
    } else {
      return { id: null }
    }
  },
)
