import React from 'react'
import { Table, Card } from 'reactstrap'

const TableCommercialisti = ({ commercialisti }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Commercialista</th>
          <th>Clienti</th>
        </tr>
      </thead>
      <tbody>
        {commercialisti.map((commercialista) => (
          <tr key={commercialista.id}>
            <td>
              <a href={commercialista.link_consulenza} target="_blank">
                {commercialista.nome_esteso}
              </a>
            </td>
            <td>{commercialista.numero_clienti}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default TableCommercialisti
