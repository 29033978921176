import { Link } from 'react-router-dom'
import request from 'superagent'
import React, { useEffect, useState } from 'react'
import {
  Container,
  Button,
  Card,
  CardText,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  CardTitle,
  Table,
  Label,
  Form,
  FormGroup,
  Progress,
  CardBody,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import 'moment/locale/it'
import GraficoIncassatoIncassabile from '../grafici/GraficoIncassatoIncassabile'
import GraficoFatturatoMensile from '../grafici/GraficoFatturatoMensile'
import CardCodiceCollega from '../codiceCollega/CardCodiceCollega'
import GraficoLimiteRicavi from '../grafici/GraficoLimiteRicavi'
import { arrayScadenze, currentYear } from '../../app/utility/ArrayScadenze'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const Home = () => {
  const user = useSelector(selectUser)
  const [fattureDaIncassare, setFattureDaIncassare] = useState([])
  const [modalIncasso, setModalIncasso] = useState(false)
  const [dataIncasso, setDataIncasso] = useState(moment().format('YYYY-MM-DD'))
  const [idFatturaDaIncassare, setIdFatturaDaIncassare] = useState(0)
  const [fatturaDaIncassare, setFatturaDaIncassare] = useState('')
  const [report, setReport] = useState({})

  useEffect(() => {
    request
      .post(`${process.env.REACT_APP_API_URL}/home/idrata`)
      .send({ token: user.auth_token })
      .then((response) => {
        setReport(response.body.report)
        setFattureDaIncassare(response.body.fatture_non_incassate)
      })
      .catch((e) => console.error(e.message))
  }, [])

  const apriModalIncasso = (idFattura) => {
    const fattura = fattureDaIncassare.filter(
      (fattura) => fattura.fattura.id === idFattura,
    )
    setFatturaDaIncassare(fattura[0])
    setIdFatturaDaIncassare(idFattura)
    setModalIncasso(true)
  }

  const tabScadenze = () => {
    const dateValide = arrayScadenze.filter((elemento) =>
      moment().isBefore(elemento[0]),
    )
    const primeTreValide = dateValide.slice(0, 4)
    const tabella = primeTreValide.map((elemento, indice) => (
      <tr key={indice}>
        <td className="h6 font-regular">{elemento[0].format('DD/MM/YYYY')}</td>
        <td className="h6 font-regular">{elemento[1]}</td>
      </tr>
    ))
    return tabella
  }

  const componentFatture = fattureDaIncassare.map((fattura, index) => (
    <tr key={index}>
      <td className="h6 font-regular">{fattura.cliente}</td>
      <td className="h6 font-regular">
        {moment(fattura.fattura.data_emissione).format('DD/MM/YYYY')}
      </td>
      <td className="h6 font-regular">{fattura.fattura.totale} €</td>
      <td>
        <h6
          onClick={() => apriModalIncasso(fattura.fattura.id)}
          className="testo-blu"
          style={{ cursor: 'pointer' }}
        >
          <i className="bi bi-piggy-bank"></i> <u>Incassa</u>
        </h6>
      </td>
    </tr>
  ))

  const incassaFattura = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/home/incassa-fattura`)
      .send({ token: user.auth_token, idFatturaDaIncassare, dataIncasso })
      .then((response) => {
        setFattureDaIncassare(response.body.fatture_non_incassate)
        setReport(response.body.report)
        setModalIncasso(false)
        setDataIncasso(moment().format('YYYY-MM-DD'))
      })
      .catch((e) => console.error(e.message))
  }

  const componentModalIncasso = (
    <Modal isOpen={modalIncasso} toggle={() => setModalIncasso(false)}>
      <ModalHeader toggle={() => setModalIncasso(false)} className="font-medium">
        Incassa fattura
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Hai ricevuto il pagamento di questa fattura? Se sì, incassala per
          aggiornare la previsione delle tasse da pagare, per condividere le
          informazioni con il tuo commercialista e per tenere il conto dei compensi
          ricevuti.
        </h6>
        <br />
        {fatturaDaIncassare !== '' && (
          <center>
            <span className="h4">
              {fatturaDaIncassare.cliente} {fatturaDaIncassare.fattura.totale} €
            </span>
          </center>
        )}
        <br />
        <Form>
          <FormGroup>
            <Label>Data incasso</Label>
            <Input
              type="date"
              value={dataIncasso}
              onChange={(e) => setDataIncasso(e.target.value)}
            />
          </FormGroup>
        </Form>
        <br />
        <Button className="bottone-blu float-end" onClick={() => incassaFattura()}>
          INCASSA
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      <Helmet>
        <title>Home Fiscomed</title>
        <meta property="title" content="Home Fiscomed" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container className="mt-4" fluid>
        <Row>
          <Col xs="8">
            <Card body className="mb-4">
              <CardTitle className="h4 interlinea-30 font-medium">
                <i className="bi bi-file-earmark-ruled"></i> Fatture da incassare
              </CardTitle>
              <h6 className="font-regular interlinea-30">
                In questa tabella ci sono le fatture da te create che, però, non hai
                ancora incassato. Tutte le volte che ricevi il pagamento di una
                fattura puoi incassarla per aggiornare le previsioni delle tasse da
                pagare, per condividere l&apos;informazione con il tuo commercialista
                e per tenere il conto dei compensi ricevuti.
              </h6>
              <br />
              <div>
                {componentFatture.length === 0 ? (
                  <h6 className="font-regular">
                    Al momento non hai fatture da incassare
                  </h6>
                ) : (
                  <Table size="sm" className="table-gialla-bianca mt-3">
                    <thead>
                      <tr>
                        <th>
                          <h5 className="font-medium">Cliente</h5>
                        </th>
                        <th>
                          <h5 className="font-medium">Data emissione</h5>
                        </th>
                        <th>
                          <h5 className="font-medium">Importo</h5>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{componentFatture}</tbody>
                  </Table>
                )}

                <br />
                <Button
                  size="sm"
                  tag={Link}
                  to="/riepilogo-fatture"
                  className="float-end bottone-blu"
                >
                  VEDI ALTRO...
                </Button>
              </div>
            </Card>
            <Card body className="mb-4">
              <CardTitle className="h4 font-grigio-scuro">
                <i className="bi bi-hourglass-split"></i> Scadenze
              </CardTitle>
              <Table borderless className="mt-3">
                <thead>
                  <tr>
                    <th className="h5 font-grigio-scuro font-medium">Quando</th>
                    <th className="h5 font-grigio-scuro font-medium">Cosa fare</th>
                  </tr>
                </thead>
                <tbody>{tabScadenze()}</tbody>
              </Table>
              <br />
              <CardText>
                <Button
                  size="sm"
                  tag={Link}
                  to="/scadenze"
                  className="float-end bottone-blu"
                >
                  VEDI ALTRO...
                </Button>
              </CardText>
            </Card>

            <Card body className="mb-4">
              <CardTitle className="h4 font-medium interlinea-30">
                <i className="bi bi-wallet2"></i> Incassato mensile
              </CardTitle>
              <h6 className="font-regular mb-3 interlinea-30">
                Con questo grafico puoi vedere quanto hai incassato ogni mese e fare
                un confronto con l&apos;anno precedente.
              </h6>
              <div style={{ height: '500px' }}>
                <GraficoFatturatoMensile
                  fatturatoAnnoCorrente={
                    report?.anno_corrente?.incasso_mensile === undefined
                      ? []
                      : Object.values(report.anno_corrente.incasso_mensile)
                  }
                  fatturatoAnnoPrecedente={
                    report?.anno_precedente?.incasso_mensile === undefined
                      ? []
                      : Object.values(report.anno_precedente.incasso_mensile)
                  }
                />
              </div>
            </Card>
            <Card body className="mb-4">
              <CardTitle className="h4 font-medium mb-3">
                <i className="bi bi-cash-coin interlinea-30"></i> Tasse e contributi{' '}
                <Link to="/statistiche" className="h6 testo-blu">
                  Scopri come sono calcolati
                </Link>
              </CardTitle>
              <h6 className="font-regular mb-3 interlinea-30">
                {parseInt(
                  report?.anno_precedente?.imposta_unica?.saldo +
                    report?.anno_precedente?.imposta_unica?.acconto +
                    report?.anno_precedente?.contributi?.quota_b +
                    report?.anno_corrente?.contributi?.quota_a,
                )}{' '}
                € è una stima approssimativa dei soldi che devi pagare nel{' '}
                {currentYear}
              </h6>
              <Progress
                value={parseInt(
                  report?.anno_precedente?.imposta_unica?.saldo +
                    report?.anno_precedente?.imposta_unica?.acconto +
                    report?.anno_precedente?.contributi?.quota_b +
                    report?.anno_corrente?.contributi?.quota_a,
                )}
                max={25000}
                barClassName="barra-verde"
              />
              <br />
              <h6 className="font-regular mb-3 interlinea-30">
                {parseInt(
                  report?.anno_corrente?.imposta_unica?.saldo +
                    report?.anno_corrente?.imposta_unica?.acconto +
                    report?.anno_corrente?.contributi?.quota_b +
                    report?.anno_successivo?.contributi?.quota_a,
                )}{' '}
                € è una stima approssimativa dei soldi che dovrai pagare nel{' '}
                {currentYear + 1}
              </h6>
              <Progress
                value={parseInt(
                  report?.anno_corrente?.imposta_unica?.saldo +
                    report?.anno_corrente?.imposta_unica?.acconto +
                    report?.anno_corrente?.contributi?.quota_b +
                    report?.anno_successivo?.contributi?.quota_a,
                )}
                max={25000}
                barClassName="barra-verde"
              />
            </Card>
            <GraficoLimiteRicavi report={report} />
          </Col>
          <Col md="4">
            <Card body className="mb-4">
              <center>
                <h3 className="font-grigio-scuro">
                  <i className="bi bi-person-square"></i> {user.nome} {user.cognome}
                </h3>
                <br />
                <div>
                  <Button
                    className="bottone-blu"
                    size="lg"
                    tag={Link}
                    to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
                  >
                    NUOVA FATTURA
                  </Button>
                </div>
              </center>
            </Card>
            <CardCodiceCollega />
            <Card body className="mb-4">
              <Row>
                <Col xs="4">
                  <center>
                    <a href="https://t.me/+LRUGFF-_C8gxYzg0">
                      <img
                        src="/img/telegram_logo.svg"
                        alt="Telegram"
                        width="80%"
                        className="mt-3"
                      />
                    </a>
                  </center>
                </Col>
                <Col xs="8">
                  <h4 className="font-medium interlinea-30 mt-3">
                    Sempre sul pezzo
                  </h4>
                  <h6 className="font-regular interlinea-30 mt-3">
                    Scadenze, aggiornamenti e novità: unisciti al nostro{' '}
                    <span className="font-bold">canale Telegram</span> per non
                    perderti nulla 🚀
                  </h6>
                  <br />
                  <div>
                    <a href="https://t.me/+LRUGFF-_C8gxYzg0">
                      <Button className="bottone-blu float-end">
                        UNISCITI A NOI
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Card>
            {user.moroso && (
              <Card className="mb-4">
                <CardBody>
                  <center>
                    <h1
                      className="font-bold testo-rosso interlinea-30"
                      style={{ fontSize: '50px' }}
                    >
                      <i className="bi bi-exclamation-triangle"></i>
                    </h1>
                    <h3 className="testo-rosso interlinea-30">Errore abbonamento</h3>
                    <br />
                    <h5 className="font-medium interlinea-30">
                      Non siamo riusciti a rinnovare il tuo abbonamento. Ricarica la
                      tua carta o aggiorna il metodo di pagamento
                    </h5>
                    <br />
                    <Button
                      className="bottone-blu"
                      size="lg"
                      tag={Link}
                      to="/abbonamento"
                    >
                      GESTISCI ABBONAMENTO
                    </Button>
                  </center>
                </CardBody>
              </Card>
            )}
            <Card body>
              <CardTitle className="h4 font-medium interlinea-30">
                <i className="bi bi-card-heading"></i> Informazioni fiscali{' '}
              </CardTitle>
              <h5 className="font-medium interlinea-30">Partita IVA</h5>
              <h6 className="text-muted mb-3  interlinea-30">{user.partita_iva}</h6>

              <h5 className="font-medium interlinea-30">Regime</h5>
              <h6 className="text-muted mb-3 interlinea-30">Forfettario</h6>

              <h5 className="font-medium interlinea-30">Coefficiente redditività</h5>
              <h6 className="text-muted mb-3 interlinea-30">78%</h6>

              <br />
              <div>
                <Button
                  size="sm"
                  tag={Link}
                  to="/informazioni-fiscali"
                  className="float-end bottone-blu"
                >
                  VEDI ALTRO...
                </Button>
              </div>
            </Card>
            <Card body className="my-4">
              <CardTitle className="h4 font-medium interlinea-30">
                <i className="bi bi-piggy-bank"></i> Incasso
              </CardTitle>
              <h6 className="font-regular interlinea-30">
                In questo grafico puoi vedere la somma delle fatture incassate e la
                somma delle fatture che devi ancora incassare
              </h6>
              <div style={{ maxWidth: '400px' }}>
                <GraficoIncassatoIncassabile
                  incassato={
                    report?.anno_corrente?.fatturato?.fatturato_libera_professione +
                    report?.anno_corrente?.fatturato?.lordo_ca
                  }
                  incassabile={report?.fatture_non_incassate}
                />
              </div>
              <h6 className="font-regular interlinea-30">
                Hai incassato{' '}
                {(
                  report?.anno_corrente?.fatturato?.fatturato_libera_professione +
                  report?.anno_corrente?.fatturato?.lordo_ca
                )?.toFixed(2)}{' '}
                € e devi ancora incassare {report?.fatture_non_incassate?.toFixed(2)}{' '}
                €
              </h6>
            </Card>
          </Col>
        </Row>
        {componentModalIncasso}
      </Container>
    </>
  )
}

export default Home
