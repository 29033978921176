export const emptyDocumento = {
  tipologia: 'fattura',
  data_incasso: '',
  numero: '',
  importo: '',
  trattenuta_enpam: '',
  trattenuta_irpef: '',
  partita_iva: '',
  codice_fiscale: '',
  inviata_ts: false,
  uploading: false,
  nota: '',
  id_documento: null,
  nome_originale_file: null,
}

export const emptyState = {
  fattura: [],
  cedolino: [],
  borsa_mmg: [],
  borsa_specializzazione: [],
  prestazione_occasionale: [],
  file_condiviso: [],
  grouped_documents: {
    fattura: [],
    cedolino: [],
    borsa_mmg: [],
    borsa_specializzazione: [],
  },
  visibleFilesType: 'fatture',
  anno: new Date().getFullYear(),
  userUploadModalIsOpen: false,
  uploadButtonDisabled: true,
  documento: emptyDocumento,
}
