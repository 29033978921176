import React, { useState } from 'react'
import { Button, Container, Input, Row, Col, Badge } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import { queryListDocumenti } from '../../app/utility/QueryTecnico'
import moment from 'moment'
import { apriFileBlocchetto } from '../../app/store/fileViewer/actions'
import { useDispatch } from 'react-redux'
import { deleteDocumentoBlocchetto } from '../../app/utility/QueryDocumentiImportati'

const DataIncasso = () => {
  const [tipologia, setTipologia] = useState(null)
  const user = useSelector(selectUser)
  const [documenti, setDocumenti] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')
  const [numeroDocumenti, setNumeroDocumenti] = useState(0)
  const dispatch = useDispatch()

  const cercaDocumenti = () => {
    setLoading(true)
    queryListDocumenti(user, data, tipologia)
      .then((response) => {
        setDocumenti(response.documenti)
        setNumeroDocumenti(response.numero_documenti)
      })
      .catch((e) => console.error(e.message))
      .finally(() => {
        setLoading(false)
      })
  }

  const cancellaDocumento = (id_documento) => {
    if (
      confirm('Sei sicuro di voler cancellare il documento?') &&
      loading === false
    ) {
      setLoading(true)
      deleteDocumentoBlocchetto(user, id_documento).then(() => cercaDocumenti())
    }
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col className="col-auto">
          <Button
            color="primary"
            className="mb-3 me-1"
            onClick={() => setTipologia(null)}
            disabled={tipologia === null || loading}
          >
            Senza data
          </Button>
        </Col>
        <Col className="col-auto">
          <Button
            color="primary"
            className="mb-3 me-1"
            onClick={() => setTipologia('minore')}
            disabled={tipologia === 'minore'}
          >
            Precedente a
          </Button>
        </Col>
        <Col className="col-auto">
          <Button
            color="primary"
            className="mb-3"
            onClick={() => setTipologia('maggiore')}
            disabled={tipologia === 'maggiore'}
          >
            Successivo a
          </Button>
        </Col>
        <Col className="col-auto">
          <Input
            className="mb-3 inline"
            type="date"
            name="date"
            id="exampleDate"
            placeholder="date placeholder"
            style={{ width: '200px' }}
            onChange={(e) => {
              setData(e.target.value)
            }}
            disabled={tipologia === null}
          />
        </Col>
        <Col className="col-auto">
          <Button
            color="primary"
            className="mb-3 me-1"
            disabled={loading}
            onClick={() => cercaDocumenti()}
          >
            <i className="bi bi-search"></i> Cerca
          </Button>
        </Col>
      </Row>
      <br />
      <span className="text-muted float-end">{numeroDocumenti}</span>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Caricato il</th>
            <th scope="col">Data incasso</th>
            <th scope="col">Tipologia</th>
            <th scope="col">Email</th>
            <th scope="col">Vedi</th>
            <th scope="col">Cencella</th>
          </tr>
        </thead>
        <tbody>
          {documenti.map((documento, index) => {
            return (
              <tr key={index}>
                <td>{documento.id}</td>
                <td>{moment(documento.created_at).format('DD/MM/YYYY')}</td>
                <td>{moment(documento.data_incasso).format('DD/MM/YYYY')}</td>
                <td>{documento.tipologia}</td>
                <td>{documento.email_utente}</td>
                <td>
                  <Badge
                    color="primary"
                    onClick={() => dispatch(apriFileBlocchetto(documento.id))}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="bi bi-eye"></i>
                  </Badge>
                </td>
                <td>
                  <Badge
                    color="danger"
                    onClick={() => cancellaDocumento(documento.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="bi bi-trash"></i>
                  </Badge>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Container>
  )
}

export default DataIncasso
