import React from 'react'
import './App.css'
import '../node_modules/video-react/dist/video-react.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CookieConsentBar from './features/component/CookieConsent'
import GAPageSentinel from './app/utility/AnalyticsPageSentinel'
import ScrollToTop from './features/routes/utility/ScrollToTop'
import AuthStatusDetector from './features/routes/utility/AuthStatusDetector'
import SoloDesktop from './features/pagineSingole/SoloDesktop'
import CambioPassword from './features/login/CambioPassword'
import CambioPasswordMobile from './features/login/CambioPasswordMobile'
import ScreenSelector from './features/landing/ScreenSelector'
import RootNavContainer from './features/navbar/container/RootNavContainer'
import ContrattoOnlyForMedico from './features/modaleContratti/ContrattoOnlyForMedico'
import FileViewer from './features/sharedComponent/FileViewer'

const App = () => (
  <>
    <CookieConsentBar />
    <Router>
      <RootNavContainer />
      <GAPageSentinel />
      <ContrattoOnlyForMedico />
      <ScrollToTop />
      <FileViewer />
      <Switch>
        <Route exact path="/cambio-password">
          <ScreenSelector>
            <CambioPasswordMobile />
            <CambioPassword />
          </ScreenSelector>
        </Route>
        <Route exact path="/disponibile-sul-desktop">
          <SoloDesktop />
        </Route>
        <Route>
          <AuthStatusDetector />
        </Route>
      </Switch>
    </Router>
  </>
)

export default App
