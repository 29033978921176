import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
} from 'reactstrap'
import DataIncassoBlocchetto from './DataIncassoBlocchetto'
import { Tooltip } from './Tooltip'
import currency from 'currency.js'
import {
  tipologiaAdapterFromTextToTipologia,
  tipologiaAdapterFromTipologiaToText,
} from '../../app/utility/Adapter'
import { selectUser } from '../../app/store/user/selectors'
import { useSelector } from 'react-redux'
import { updateDocumentoBlocchetto } from '../../app/utility/QueryDocumentiImportati'
import { fileBlocchettoPresignedURL } from '../../app/utility/QueryFileViewer'
import classNames from 'classnames'

export const ModalUpdateBlocchettoGestionale = ({
  isOpen,
  close,
  inputDocument,
  medicoId,
  returnFunction,
}) => {
  const user = useSelector(selectUser)
  const [outputDocument, setOutputDocument] = useState(inputDocument)
  const [uploading, setUploading] = useState(false)
  const [printableFile, setPrintableFile] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (Object.keys(inputDocument).length === 0) return
    setOutputDocument(inputDocument)
    fileBlocchettoPresignedURL(user, inputDocument.id).then((response) => {
      setPrintableFile(response)
      setLoading(false)
    })
  }, [inputDocument])

  const upload = async () => {
    setUploading(true)
    const responseBody = await updateDocumentoBlocchetto(user, {
      medico_id: medicoId,
      data_incasso: outputDocument.data_incasso,
      numero: outputDocument.numero,
      importo: outputDocument.importo,
      trattenuta_enpam: outputDocument.trattenuta_enpam,
      trattenuta_irpef: outputDocument.trattenuta_irpef,
      partita_iva: outputDocument.p_iva,
      codice_fiscale: outputDocument.cf,
      inviata_ts: outputDocument.inviata_ts,
      file_type: outputDocument.tipologia,
      id_documento: outputDocument.id,
      appunti_verifica: outputDocument.appunti_verifica,
    })
    if (responseBody) {
      setUploading(false)
      returnFunction(responseBody.documento)
      close()
    }
    return responseBody
  }

  const downloadFileFromUrl = () => {
    if (!printableFile?.url || !printableFile?.nome_file) return
    fetch(printableFile.url)
      .then((response) => {
        if (!response.ok) throw new Error('Errore durante il download del file')
        return response.blob()
      })
      .then((blobFile) => {
        const file = new File([blobFile], printableFile.nome_file)
        saveAs(file)
      })
      .catch((error) => {
        console.error('Errore durante il download del file:', error)
        alert('Si è verificato un errore durante il download del file.')
      })
  }

  return (
    <Modal isOpen={isOpen} toggle={close} fullscreen scrollable>
      <ModalHeader className="font-medium" toggle={close}>
        Modifica documento
      </ModalHeader>
      <ModalBody>
        <Row style={{ height: '100%' }}>
          <Col xs={6}>
            {loading ? (
              <Spinner />
            ) : (
              <object
                data={printableFile?.url}
                type={printableFile?.content_type}
                width="100%"
                height="100%"
                name={printableFile?.nome_file}
              >
                <p>
                  Il tuo browser non supporta la visualizzazione di questo file. Puoi
                  scaricarlo utilizzando il link o il pulsante sottostante.
                </p>
                <ul>
                  <li>
                    Scarica tramite il link{' '}
                    <a href={printableFile?.url} target="_blank" rel="noreferrer">
                      {printableFile?.nome_file}
                    </a>
                  </li>
                  <br />
                  <li>
                    Oppure scarica con il bottone
                    <Button
                      className="ms-3"
                      color="primary"
                      onClick={() => downloadFileFromUrl()}
                    >
                      <i className="bi bi-cloud-arrow-down"></i> Scarica
                    </Button>
                  </li>
                </ul>
              </object>
            )}
          </Col>
          <Col xs={6}>
            {uploading ? (
              <Button className="float-end bottone-blu" disabled>
                <Spinner size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-blu float-end"
                onClick={() => upload()}
                disabled={uploading}
                block
              >
                <i className="bi bi-floppy"></i> SALVA MODIFICA
              </Button>
            )}
            <br />
            <br />
            <br />
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleEmail">Tipologia</Label>
                  <Input
                    onChange={(e) =>
                      setOutputDocument({
                        ...outputDocument,
                        tipologia: tipologiaAdapterFromTextToTipologia(
                          e.target.value,
                        ),
                      })
                    }
                    type="select"
                    value={tipologiaAdapterFromTipologiaToText(
                      outputDocument.tipologia,
                    )}
                  >
                    <option>Fattura</option>
                    <option>Cedolino</option>
                    <option>Borsa MMG</option>
                    <option>Borsa specializzazione</option>
                    <option>Prestazione occasionale</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleEmail">Nome file</Label>
                  <Input
                    type="text"
                    placeholder={outputDocument.nome_originale_file}
                    disabled
                    defaultValue={outputDocument.nome_originale_file}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label
                    for="exampleEmail"
                    className={classNames(
                      { 'text-success': outputDocument.controllato },
                      { 'text-danger': outputDocument.controllato === false },
                      { 'text-warning': outputDocument.controllato === null },
                    )}
                  >
                    Nota verificatore
                  </Label>
                  <Input
                    type="text"
                    placeholder=""
                    value={outputDocument.appunti_verifica || ''}
                    onChange={(e) =>
                      setOutputDocument({
                        ...outputDocument,
                        appunti_verifica: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              {outputDocument.tipologia !== 'altro' && (
                <Col xs={6}>
                  <DataIncassoBlocchetto
                    dataIncasso={outputDocument.data_incasso}
                    modificaData={(data) =>
                      setOutputDocument({
                        ...outputDocument,
                        data_incasso: data,
                      })
                    }
                  />
                </Col>
              )}
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleEmail">Partita IVA</Label>
                  <Input
                    type="text"
                    placeholder=""
                    value={outputDocument.p_iva || ''}
                    onChange={(e) =>
                      setOutputDocument({
                        ...outputDocument,
                        p_iva: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleEmail">Codice Fiscale</Label>
                  <Input
                    type="text"
                    placeholder=""
                    value={outputDocument.cf || ''}
                    onChange={(e) =>
                      setOutputDocument({
                        ...outputDocument,
                        cf: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              {(outputDocument.tipologia === 'fattura' ||
                outputDocument.tipologia === 'cedolino') && (
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Numero</Label>
                    <Input
                      type="text"
                      placeholder="1s"
                      value={outputDocument.numero || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          numero: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              {[
                'fattura',
                'cedolino',
                'borsa_mmg',
                'prestazione_occasionale',
              ].includes(outputDocument.tipologia) && (
                <Col xs={6}>
                  <FormGroup>
                    <Label for="importo">
                      Importo lordo{' '}
                      <Tooltip placement="top">
                        Se stai caricando una fattura, in questo campo devi inserire
                        l&apos;importo della fattura più la marca da bollo. Esempio:
                        l&apos;importo della tua fattura è 1200 € e la marca da bollo
                        che hai applicato è di 2€. In questo campo scriverai 1202 €.
                        Se, vuoi importare un cedolino, sempre in questo campo devi
                        riportare il numero che trovi sotto la dicitura totale
                        competenze. Se invece stai importando una borsa MMG, in
                        questo campo devi inserire l&apos;importo IRPEF lordo
                      </Tooltip>
                    </Label>
                    <Input
                      type="number"
                      placeholder="120,23"
                      value={outputDocument.importo || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          importo: currency(e.target.value).value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              {outputDocument.tipologia === 'cedolino' && (
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Trattenute ENPAM{' '}
                      <Tooltip placement="top">
                        In questo campo, inserisci il numero che trovi sotto la voce
                        &quot;oneri previdenziali&quot;. È importante per il calcolo
                        delle tasse
                      </Tooltip>
                    </Label>
                    <Input
                      type="number"
                      placeholder="12,23"
                      value={outputDocument.trattenuta_enpam || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          trattenuta_enpam: currency(e.target.value).value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              {outputDocument.tipologia === 'prestazione_occasionale' && (
                <Col xs={6}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Trattenute IRPEF{' '}
                      <Tooltip placement="top">
                        In questo campo, inserisci il numero che trovi sotto la voce
                        trattenuta IRPEF
                      </Tooltip>
                    </Label>
                    <Input
                      type="number"
                      placeholder="12,23"
                      value={outputDocument.trattenuta_irpef || ''}
                      onChange={(e) =>
                        setOutputDocument({
                          ...outputDocument,
                          trattenuta_irpef: currency(e.target.value).value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={6}>
                {outputDocument.tipologia === 'fattura' && (
                  <FormGroup>
                    <Label for="exampleEmail">
                      È stata trasmessa a Sistema Tessera Sanitaria
                    </Label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          checked={outputDocument.inviata_ts === true}
                          onChange={() =>
                            setOutputDocument({
                              ...outputDocument,
                              inviata_ts: true,
                            })
                          }
                          value="Si"
                        />
                        Sì
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="radio2"
                          checked={outputDocument.inviata_ts === false}
                          onChange={() =>
                            setOutputDocument({
                              ...outputDocument,
                              inviata_ts: false,
                            })
                          }
                          value="No"
                        />
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdateBlocchettoGestionale
