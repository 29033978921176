import React, { useState, useEffect } from 'react'
import {
  FormGroup,
  Input,
  Label,
  Button,
  Container,
  Spinner,
  Card,
  CardBody,
} from 'reactstrap'
import {
  urlLetteraDaUUID,
  firmaLetteraDaUUID,
  salvaRisposteStorico,
} from '../../app/utility/QueryMiscellanea'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const PrimaConsulenza = () => {
  const [consenso, setConsenso] = useState(false)
  const [letteraFirmata, setLetteraFirmata] = useState(false)
  const [risposteQuiz, setRisposteQuiz] = useState([
    {
      numero: 0,
      domanda: "Avevi la partita IVA nell'anno precedente?",
      risposta: null,
    },
    {
      numero: 1,
      domanda:
        'Nel ' +
        (new Date().getFullYear() - 1) +
        ' hai guadagnato soldi da un affitto?',
      risposta: null,
    },
    {
      numero: 2,
      domanda:
        'Nel ' +
        (new Date().getFullYear() - 1) +
        " hai avuto conti o immobili all'estero? Hai fatto investimenti su piattaforme non italiane o hai avuto criptovalute?",
      risposta: null,
    },
    {
      numero: 3,
      domanda:
        'Nel ' +
        (new Date().getFullYear() - 1) +
        ' hai avuto uno o più redditi da lavoro dipendente o assimilato?',
      risposta: null,
    },
    {
      numero: 4,
      domanda:
        'Nel ' +
        (new Date().getFullYear() - 1) +
        ' hai avuto redditi da prestazione occasionale?',
      risposta: null,
    },
  ])
  const [urlLettera, setUrlLettera] = useState('')
  const { publicUuid } = useParams()
  const [urlCalendly, setUrlCalendly] = useState('')
  const [preposizioni, setPreposizioni] = useState({
    il: 'il',
    del: 'del',
  })
  const smallScreen = window.innerWidth <= 992
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    urlLetteraDaUUID(publicUuid).then((body) => {
      setUrlLettera(body.url_lettera)
      setUrlCalendly(body.url_calendly)
      console.log(body)
      if (body.commercialista_uomo === false) {
        setPreposizioni({ il: 'la', del: 'della' })
      }
    })
  }, [])

  const firmaLettera = () => {
    setLoading(true)
    firmaLetteraDaUUID(publicUuid).then(() => {
      setLetteraFirmata(true)
      setLoading(false)
    })
  }

  const rispondiAlQuiz = () => {
    setLoading(true)
    console.log(risposteQuiz)
    salvaRisposteStorico(publicUuid, risposteQuiz).then(() => {
      setLoading(false)
      window.open(urlCalendly, '_blank')
    })
  }

  const urlCliccabile = () => {
    return risposteQuiz.every((risposta) => risposta.risposta !== null)
  }

  return (
    <>
      <Helmet>
        <title>Firma lettera di incarico</title>
        <meta property="title" content="Fiscomed.it" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fiscomed.it" />
        <meta
          property="og:description"
          content="Qui è possibile firmare la lettera di incarico del tuo commercialista"
        />
        <meta
          property="description"
          content="Qui è possibile firmare la lettera di incarico del tuo commercialista"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
      </Helmet>

      <Container style={{ marginTop: '123px' }}>
        <Card className="mb-5">
          <CardBody className={smallScreen ? 'p-4' : 'p-5'}>
            {letteraFirmata ? (
              <div>
                <h1 className="font-bold">E ora, qualche domanda 🎤</h1>
                <br />
                <h6 className="font-regular interlinea-25 mb-3">
                  Prima di scegliere l'orario del nostro incontro, ho bisogno di
                  raccogliere alcune informazioni e ho pensato di farlo con{' '}
                  <span className="font-bold">cinque domande</span>.
                </h6>
                <h6 className="font-regular interlinea-25 mb-3">
                  Le risposte che darai mi aiuteranno a{' '}
                  <span className="font-bold">
                    conoscere meglio la tua situazione fiscale
                  </span>{' '}
                  e potranno essere utili anche per la futura dichiarazione dei
                  redditi.
                </h6>
                <h6 className="font-regular interlinea-25">
                  Ci vorranno <i>meno di due minuti</i>. Se non hai la certezza su
                  qualcosa, nessun problma: ne parleremo con calma durante la
                  videoconsulenza 🤗
                </h6>
                <br />
                <br />
                <h5 className="font-bold mb-4 interlinea-30">
                  1️⃣ Nel {new Date().getFullYear() - 1} avevi già la Partita IVA?
                </h5>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[0].risposta === false}
                      onChange={() => {
                        risposteQuiz[0].risposta = false
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    No, l'ho aperta quest'anno o la voglio aprire con voi
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[0].risposta === true}
                      onChange={() => {
                        risposteQuiz[0].risposta = true
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    Sì, avevo già la Partita IVA
                  </Label>
                </FormGroup>
                <br />
                <br />
                <h5 className="font-bold mb-4  interlinea-30">
                  2️⃣ Nel {new Date().getFullYear() - 1} hai ricevuto soldi per
                  l'affitto di una casa?
                </h5>
                <h6 className="font-regular interlinea-25 mb-3">
                  <i>
                    Attenzione: questa domanda non ha a che vedere con il fatto che
                    tu sia o meno in affitto. Per noi è importante sapere se hai
                    affittato una o più case o stanze a qualcun altro e hai ricevuto
                    pagamenti per questo.
                  </i>
                </h6>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[1].risposta === false}
                      onChange={() => {
                        risposteQuiz[1].risposta = false
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    No, non ho ricevuto soldi per un affitto
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[1].risposta === true}
                      onChange={() => {
                        risposteQuiz[1].risposta = true
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    Sì, ho affittato una casa, una stanza o un altro immobile
                  </Label>
                </FormGroup>
                <br />
                <br />
                <h5 className="font-bold mb-4 interlinea-30">
                  3️⃣ Nel {new Date().getFullYear() - 1} hai avuto conti esteri,
                  immobili all'estero, criptovalute o investimenti su piattaforme non
                  italiane?
                </h5>
                <h6 className="font-regular interlinea-25 mb-3">
                  <i>
                    Questa domanda serve a capire se hai avuto soldi o investimenti
                    fuori dall'Italia, anche se non hai guadagnato nulla.
                  </i>
                </h6>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[2].risposta === false}
                      onChange={() => {
                        risposteQuiz[2].risposta = false
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    No, non ho avuto criptovalute, investimenti, conti esteri o
                    immobili all'estero
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[2].risposta === true}
                      onChange={() => {
                        risposteQuiz[2].risposta = true
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    Sì, ho avuto conti esteri e/o immobili all'estero e/o
                    criptovalute e/o investimenti su piattaforme non italiane
                  </Label>
                </FormGroup>
                <br />
                <br />
                <h5 className="font-bold mb-4 interlinea-30">
                  4️⃣ Nel {new Date().getFullYear() - 1} hai avuto redditi da lavoro
                  dipendente o assimilato?
                </h5>
                <h6 className="font-regular interlinea-25 mb-3">
                  <i>
                    Questa domanda serve a capire se hai avuto uno o più contratti di
                    lavoro dipendente o assimilato, come i co.co.co.
                  </i>
                </h6>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[3].risposta === false}
                      onChange={() => {
                        risposteQuiz[3].risposta = false
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    No, non ho avuto redditi da lavoro dipendente o assimilato
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[3].risposta === true}
                      onChange={() => {
                        risposteQuiz[3].risposta = true
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    Sì, ho avuto almeno un reddito da lavoro dipendente o assimilato
                  </Label>
                </FormGroup>
                <br />
                <br />
                <h5 className="font-bold mb-4 interlinea-30">
                  5️⃣ Nel {new Date().getFullYear() - 1} hai avuto redditi da
                  prestazione occasionale?
                </h5>
                <h6 className="font-regular interlinea-25 mb-3">
                  <i>
                    Questa domanda serve a capire se hai ricevuto dei soldi per delle
                    prestazioni occasionali. Se sì, ne parlerai con il commercialista
                    durante la videoconsulenza
                  </i>
                </h6>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[4].risposta === false}
                      onChange={() => {
                        risposteQuiz[4].risposta = false
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    No, non ho avuto redditi da prestazione occasionale
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label>
                    <Input
                      type="radio"
                      checked={risposteQuiz[4].risposta === true}
                      onChange={() => {
                        risposteQuiz[4].risposta = true
                        setRisposteQuiz([...risposteQuiz])
                      }}
                    />
                    Sì, ho avuto almeno un reddito da prestazione occasionale
                  </Label>
                </FormGroup>
                <br />
                <br />
                <Button
                  className="bottone-blu"
                  block={smallScreen}
                  disabled={!urlCliccabile()}
                  onClick={() => rispondiAlQuiz()}
                >
                  VAI AL CALENDARIO
                </Button>
              </div>
            ) : (
              <>
                <h1 className="font-bold">
                  Ecco il primo passo per arrivare alla videoconsulenza 📄
                </h1>
                <br />
                <h6 className="font-regular interlinea-25 mb-3">
                  Come ti ho anticipato nell'email, ci sono un paio di cose da fare
                  per prepararci al nostro incontro.
                </h6>
                <h6 className="font-regular interlinea-25 mb-3">
                  La prima è accettare un documento particolare che si chiama{' '}
                  <span className="font-bold">"lettera di incarico"</span>.
                </h6>
                <h6 className="font-regular interlinea-25 mb-3">
                  Si tratta del contratto che regola il rapporto fra te e me, che ti
                  supporterò nella gestione degli adempimenti fiscali più complessi.{' '}
                  <i>
                    Non tiene conto del supporto che riceverai dall'assistenza di
                    Fiscomed,
                  </i>{' '}
                  ma solo del nostro rapporto professionale. Qui sono indicati i tuoi
                  diritti e le tue responsabilità, così come le mie.
                </h6>
                <h6 className="font-regular interlinea-25 mb-3">
                  Per favore,{' '}
                  <span className="font-bold">spunta la casella qui sotto.</span> In
                  questo modo, possiamo assicurarci che tu abbia dato un'occhiata a
                  questo documento legale.
                </h6>
                <h6 className="font-regular interlinea-25 mb-3">
                  Dopo questo passaggio, ci sarà un ultimo step prima di accedere al
                  celendario degli appuntamenti e fissare la nostra videoconsulenza.
                </h6>

                <br />
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="lettera"
                    id="lettera"
                    style={{ marginTop: smallScreen ? '4px' : '4px' }}
                    checked={consenso}
                    onChange={(e) => setConsenso(e.target.checked)}
                  />
                  <Label for="lettera" className="font-regular interlinea-25 mb-3">
                    Accetto la{' '}
                    <a href={urlLettera} target="_blank" rel="noreferrer">
                      <u>lettera di incarico</u>
                    </a>
                  </Label>
                </FormGroup>
                <br />
                {!letteraFirmata ? (
                  <Button
                    className="bottone-blu"
                    disabled={consenso === false || loading === true}
                    onClick={() => firmaLettera()}
                    block={smallScreen}
                  >
                    {loading ? <Spinner size="sm" /> : 'VAI AL PROSSIMO STEP'}
                  </Button>
                ) : (
                  <a
                    href={urlCalendly}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary bottone-blu float-end"
                    block={smallScreen}
                  >
                    VAI AL CALENDARIO
                  </a>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  )
}
export default PrimaConsulenza
