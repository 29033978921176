import React, { useRef, useState } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Input,
  Spinner,
  Label,
  FormGroup,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import { leadChiamata } from '../../../app/utility/QueryLanding'
import swal from '@sweetalert/with-react'
import { leadChiamataGA } from '../../../app/utility/GA'
import { lead } from '../../../app/utility/SwalContent'

const FiscomedDesktop = () => {
  const refComeFunziona = useRef(null)
  const refIniziaOra = useRef(null)
  const [caricamento, setCaricamento] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [message, setMessage] = useState('')
  const [consenso, setConsenso] = useState(false)

  const prenotaChiamata = async () => {
    if (
      nome === '' ||
      email === '' ||
      telefono === '' ||
      consenso === false ||
      message === ''
    ) {
      swal({
        title: 'Attenzione',
        text: 'Compila tutti i campi',
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    leadChiamataGA()
    await leadChiamata({ nome, email, telefono, message }).then((res) => {
      setCaricamento(false)
      swal(lead)
      setNome('')
      setEmail('')
      setTelefono('')
      setMessage('')
      setConsenso(false)
    })
  }

  return (
    <>
      <Helmet>
        <title>Fiscomed.it</title>
        <meta property="title" content="Fiscomed.it" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fiscomed.it" />
        <meta
          property="og:description"
          content="Gestiamo la Partita IVA dei giovani medici con un team di commercialisti specializzati. Richiedi una consulenza"
        />
        <meta
          property="description"
          content="Gestiamo la Partita IVA dei giovani medici con un team di commercialisti specializzati. Richiedi una consulenza"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container
        style={{ marginTop: '56px' }}
        className="container-landing landing-container-height"
      >
        <br />
        <br />
        <div className="header-landing-desktop-fiscomed" />
        <Row>
          <Col md={6}>
            <h1 className="titolo-landing-fiscomed font-medium mt-5">
              La consulenza
              <br /> fiscale dedicata
              <br /> ai medici
            </h1>
            <h5 className="testo-landing-fiscomed-margini">
              Con <span className="font-bold">Fiscomed</span> puoi affidare la tua
              gestione fiscale
              <br /> a un commercialista specializzato e monitorare
              <br /> la tua situazione fiscale su un&apos;unica piattaforma
            </h5>
            <Button
              className="bottone-verde-outline me-5 px-4"
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              onClick={() =>
                refComeFunziona.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              COME FUNZIONA
            </Button>
            <Button
              className="bottone-verde px-5"
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              INIZIA ORA
            </Button>
          </Col>
          <Col md={6}>
            <img
              src="/img_landing/immagine_fiscomed.webp"
              alt="fiscomed"
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <br />
        <center>
          <img
            src="/img_landing/freccia.webp"
            alt="fiscomed"
            className="my-5"
            style={{ zIndex: 1000 }}
          />
          <div className="titolo-2">Perché Fiscomed</div>
          <h5 className="testo-landing-fiscomed my-5">
            Dopo aver ascoltato tanti giovani dottori e dottoresse in Medicina
            abbiamo capito che Partita <br />
            IVA, tasse e fatturazione sono fonte di{' '}
            <span className="font-bold">ansia</span> e{' '}
            <span className="font-bold">tanti dubbi.</span>
            <br />
            <span className="font-bold">Di addio alle tue preoccupazioni:</span> con
            Fiscomed la gestione fiscale non sarà più un problema.
          </h5>
          <br />
        </center>
        <Row>
          <Col md={4}>
            <div className="float-end">
              <img
                src="/img_landing/nuvola.webp"
                alt="nuvola di colore"
                className="nuvola"
              />
              <h1 className="testo-blu" style={{ fontWeight: 600 }}>
                600 +
              </h1>
              <h5 className="interlinea-30">
                Medici hanno aperto la
                <br /> Partita IVA con Fiscomed
              </h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="outer-center">
              <div>
                <img
                  src="/img_landing/nuvola.webp"
                  alt="nuvola di colore"
                  className="nuvola"
                />
                <h1 className="push-left testo-blu" style={{ fontWeight: 600 }}>
                  9300 +
                </h1>
                <h5 className="push-left interlinea-30">
                  Fatture emesse dalla
                  <br /> nostra piattaforma
                </h5>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <img
              src="/img_landing/nuvola.webp"
              alt="nuvola di colore"
              className="nuvola"
            />
            <h1 className="testo-blu" style={{ fontWeight: 600 }}>
              900 +
            </h1>
            <h5 className="interlinea-30">
              Medici hanno scelto i<br /> nostri commercialisti
            </h5>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br ref={refComeFunziona} />
        <br />
        <br />
        <center>
          <span className="etichetta">Come funziona</span>

          <br />
          <br />
          <br />
          <div className="titolo-2">
            Ti semplifichiamo la gestione fiscale
            <br />
            <span className="testo-verde">con un supporto umano e digitale</span>
          </div>
        </center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md={7}>
            <img
              src="/img_landing/elisse_verde.webp"
              alt="nuvola di colore"
              className="cerchio-verde"
            />
            <span className="titolo-3">
              Il Dottore Commercialista
              <br />
              dedicato alla tua Partita IVA
            </span>
            <h5 className="testo-landing-fiscomed mt-5">
              Un professionista <span className="font-bold">specializzato</span>{' '}
              nella consulenza
              <br /> fiscale per giovani medici si occuperà di tutta la tua
              <br /> gestione fiscale e sarà lieto di risolvere i tuoi dubbi e<br />{' '}
              domande.
            </h5>
            <h5 className="testo-landing-fiscomed mt-5">
              Sarai affidato/a a un Dottore Commercialista che ti
              <br /> aprirà la <span className="font-bold">Partita IVA</span>, si
              occuperà della tua
              <br />
              dichiarazione dei redditi e ti farà dei resoconti della
              <br /> tua situazione fiscale
            </h5>
            <Button
              className="bottone-verde px-5 mt-5"
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              INIZIA ORA
            </Button>
          </Col>
          <Col md={5}>
            <Row>
              <Col md={3} className="mb-5">
                <i
                  className="bi bi-compass float-end testo-blu"
                  style={{ fontSize: '4rem', marginTop: '10px' }}
                ></i>
              </Col>
              <Col md={9} className="mb-5">
                <h5 className="testo-light-fiscomed mt-3">
                  Affidati a un commercialista di
                  <br /> <span className="testo-blu">fiducia</span> che ti guiderà
                  nella
                  <br />
                  gestione fiscale
                </h5>
              </Col>

              <Col md={3} className="mb-5">
                <i
                  className="bi bi-heart float-end testo-blu"
                  style={{ fontSize: '4rem', marginTop: '10px' }}
                ></i>
              </Col>
              <Col md={9} className="mb-5">
                <h5 className="testo-light-fiscomed mt-3">
                  Ricevi <span className="testo-blu">supporto</span> in tutti gli
                  <br /> adempimenti della
                  <br /> professione medica
                </h5>
              </Col>
              <Col md={3} className="mb-5">
                <i
                  className="bi bi-shield-lock float-end testo-blu"
                  style={{ fontSize: '4rem', marginTop: '10px' }}
                ></i>
              </Col>
              <Col md={9} className="mb-5">
                <h5 className="testo-light-fiscomed mt-3">
                  L&apos;unico servizio chiaro nei
                  <br /> prezzi: ottieni assistenza
                  <br /> completa a un{' '}
                  <span className="testo-blu">prezzo fisso</span>
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <img
          src="/img_landing/screen_fiscomed.webp"
          alt="Screen piattaforma digitale"
          style={{ width: '100%' }}
        />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md={6}>
            <Row>
              <Col md={3} className="mb-5">
                <i
                  className="bi bi-calendar-week float-end testo-blu"
                  style={{ fontSize: '4rem', marginTop: '10px' }}
                ></i>
              </Col>
              <Col md={9} className="mb-5">
                <h5 className="testo-light-fiscomed  mt-3">
                  Ci pensiamo noi a ricordarti le
                  <br /> <span className="testo-blu">scadenze</span> nella tua area
                  <br /> personale
                </h5>
              </Col>

              <Col md={3} className="mb-5">
                <i
                  className="bi bi-bar-chart float-end testo-blu"
                  style={{ fontSize: '4rem', marginTop: '10px' }}
                ></i>
              </Col>
              <Col md={9} className="mb-5">
                <h5 className="testo-light-fiscomed  mt-3">
                  Visualizza la previsione delle
                  <br /> <span className="testo-blu">tasse</span> che dovrai pagare
                  l&apos;anno
                  <br /> successivo, è sempre aggiornata
                </h5>
              </Col>
              <Col md={3} className="mb-5">
                <i
                  className="bi bi-file-earmark float-end testo-blu"
                  style={{ fontSize: '4rem', marginTop: '10px' }}
                ></i>
              </Col>
              <Col md={9} className="mb-5">
                <h5 className="testo-light-fiscomed  mt-3">
                  Crea le tue <span className="testo-blu">fatture</span> grazie a un
                  <br /> modulo precompilato ed evita
                  <br /> così gli errori più comuni
                </h5>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <img
              src="/img_landing/elisse_giallo.webp"
              alt="nuvola di colore"
              className="cerchio-verde"
            />
            <span className="titolo-3">
              La gestione digitale della
              <br />
              tua situazione fiscale
            </span>
            <h5 className="testo-landing-fiscomed mt-5">
              Nella tua area riservata puoi tenere sotto controllo gli
              <br />
              <b>adempimenti</b> fiscali a cui dovrai far fronte,
              <br />
              visualizzare le <b>statistiche</b> e condividere i documenti
              <br />
              con il tuo commercialista.
            </h5>
            <h5 className="testo-landing-fiscomed mt-5">
              Non solo: puoi creare le <b>fatture</b> sia cartacee che
              <br /> elettroniche e vedere in maniera facile e immediata
              <br /> le <b>tasse</b> da pagare e le relative scadenze
            </h5>
            <Button
              className="bottone-verde px-5 mt-5"
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              INIZIA ORA
            </Button>
          </Col>
        </Row>
        <br />
        <br ref={refIniziaOra} />
        <br />
        <br />
        <center>
          <br />
          <br />
          <span className="etichetta">Inizia ora</span>

          <br />
          <br />
          <br />
          <h1 className="titolo-2">
            Parla con noi e scopri perché Fiscomed è la <br />
            scelta giusta per un dottore o dottoressa
            <br />
            <span className="testo-verde">come te</span>
          </h1>
          <h5 className="testo-landing-fiscomed mt-5">
            Scrivi qui i tuoi contatti e il momento in cui preferisci ricevere la
            chiamata.
            <br />
            Non vediamo l&apos;ora di rispondere alle tue domande. Naturalmente non
            cederemo a nessuno i tuoi dati.
          </h5>
        </center>
        <br />
        <div className="outer-center mt-5">
          <div className="card-contatto p-5">
            <Row>
              <Col md={6} className="pr-5">
                <h6 className="font-medium mb-3">Come ti chiami?</h6>
                <Input
                  type="text"
                  placeholder="Ippocrate di Coo"
                  style={{ border: 'none' }}
                  autoComplete="name"
                  onChange={(event) => setNome(event.target.value)}
                  value={nome}
                />
                <br />
                <h6 className="font-medium mb-3">Qual è la tua email?</h6>
                <Input
                  type="text"
                  placeholder="ippocrate@email.com"
                  style={{ border: 'none' }}
                  autoComplete="email"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                />
                <br />
                <h6 className="font-medium mb-3">
                  A quale numero possiamo chiamarti?
                </h6>
                <Input
                  type="text"
                  placeholder="3399957060"
                  style={{ border: 'none' }}
                  autoComplete="tel"
                  onChange={(event) => setTelefono(event.target.value)}
                  value={telefono}
                />
              </Col>
              <Col md={6} className="pl-5">
                <h6 className="font-medium mb-3">Quando vuoi che ti chiamiamo?</h6>
                <Input
                  type="textarea"
                  placeholder="Indica un giorno compreso tra lunedì e venerdì e un orario tra le 9 e le 18. Per favore, dai più disponibilità in modo che possiamo accontentare tutte le persone che desiderano essere chiamate"
                  rows="6"
                  style={{ border: 'none' }}
                  onChange={(event) => setMessage(event.target.value)}
                  value={message}
                />
                <br />
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="privacy"
                    id="privacy"
                    style={{ marginTop: '2px' }}
                    checked={consenso}
                    onChange={(e) => setConsenso(e.target.checked)}
                  />
                  <Label for="privacy" className="consenso">
                    Acconsento al trattamento dei miei dati e dichiaro di aver letto
                    la{' '}
                    <a
                      href="/documenti/termini_e_condizioni.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <u>privacy policy</u>
                    </a>
                  </Label>
                </FormGroup>
                {caricamento ? (
                  <Button className="bottone-verde px-5 mt-4 float-end" disabled>
                    <Spinner color="light" size="sm" />
                  </Button>
                ) : (
                  <Button
                    className="bottone-verde px-5 mt-4 float-end"
                    onClick={() => prenotaChiamata()}
                    style={{ paddingTop: '14px', paddingBottom: '14px' }}
                  >
                    INIZIA ORA
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <center>
          <span className="titolo-2">Chi siamo</span>
        </center>
        <br />
        <br />
        <br />
        <Row>
          <Col md={6}>
            <center className="float-end me-5">
              <img
                src="/img_landing/kp.png"
                alt="foto di Kirill"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Kirill
              </h5>
              <h5 className="testo-landing-fiscomed">Medico, CTO e founder</h5>
            </center>
          </Col>
          <Col md={6}>
            <center className="float-start ms-5">
              <img
                src="/img_landing/es.png"
                alt="foto di Elisa"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Elisa
              </h5>
              <h5 className="testo-landing-fiscomed">CMO e co-founder</h5>
            </center>
          </Col>
          <Col md={4} className="mt-5">
            <center className="float-end me-5">
              <img
                src="/img_landing/ico.png"
                alt="foto di Ilenia"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Ilenia
              </h5>
              <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
            </center>
          </Col>
          <Col md={4} className="mt-5">
            <center>
              <img
                src="/img_landing/gr.png"
                alt="foto di Giulia"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Giulia
              </h5>
              <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
            </center>
          </Col>
          <Col md={4} className="mt-5">
            <center className="float-start ms-5">
              <img
                src="/img_landing/ji.png"
                alt="foto di Jacopo"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Jacopo
              </h5>
              <h5 className="testo-landing-fiscomed">Dottore Commercialista</h5>
            </center>
          </Col>
          <Col md={12} className="my-5">
            <center>
              <img
                src="/img_landing/cg.png"
                alt="foto di Cristina"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Maria Cristina
              </h5>
              <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
            </center>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <Footer />
    </>
  )
}
export default FiscomedDesktop
