import request from 'superagent'
import { emptyState } from '../store/gestionaleUtente/costants'

export const queryIdrata = async (user, anno, medico_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/gestionale-utente/idrata`)
      .send({ token: user.auth_token, anno, medico_id })

    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const querySalvaImportoModelloD = async (user, medico_id, importo, anno) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/salva-importo-modello-d`,
      )
      .send({ token: user.auth_token, medico_id, importo, anno })
    return response
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const uploadF24 = async (user, medico_id, f24) => {
  const formData = new FormData()
  formData.append('file_to_upload', f24.file_to_upload)
  formData.append('medico_id', medico_id)
  formData.append('tipologia', f24.tipologia)
  formData.append('token', user.auth_token)
  formData.append('importo', f24.importo)
  formData.append('data_scadenza', f24.data_scadenza)
  formData.append('anno', f24.anno)
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/gestionale-utente/upload-f24`)
      .send(formData)
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const deleteF24 = async (user, idF24, medico_id, anno) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/gestionale-utente/delete-f24`)
      .send({ token: user.auth_token, idF24, medico_id, anno })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryCompletaSaldoENotifica = async (user, medico_id, anno) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/completa-saldo-e-notifica`,
      )
      .send({ token: user.auth_token, medico_id, anno })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryCompletaAccontoENotifica = async (user, medico_id, anno) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/completa-acconto-e-notifica`,
      )
      .send({ token: user.auth_token, medico_id, anno })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryCambiaStatoDichiarazione = async (
  user,
  medico_id,
  anno,
  nuovoStato,
) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/cambia-stato-dichiarazione`,
      )
      .send({ token: user.auth_token, medico_id, anno, nuovo_stato: nuovoStato })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const querySwitchMedico = async (user, direction, medico_id) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/switch-medico`,
      )
      .send({ token: user.auth_token, direction, current_medico_id: medico_id })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryCambiaEmail = async (user, email, medico_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/gestionale-utente/cambia-email`)
      .send({ token: user.auth_token, email, medico_id })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryUploadUnico = async (user, file, medico_id, anno) => {
  const formData = new FormData()
  formData.append('file_to_upload', file)
  formData.append('medico_id', medico_id)
  formData.append('token', user.auth_token)
  formData.append('anno', anno)
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/gestionale-utente/upload-unico`)
      .send(formData)
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryDeleteUnico = async (user, medico_id, anno) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/gestionale-utente/delete-unico`)
      .send({ token: user.auth_token, medico_id, anno })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}

export const queryCambiaFiscoStatus = async (user, stato, medico_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/medico-data/cambia-fiscostatus`)
      .send({ token: user.auth_token, fisco_status: stato, medico_id })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return
  }
}

export const queryToggleActive = async (user, medico_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/medico-data/toggle-active`)
      .send({ token: user.auth_token, medico_id })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return
  }
}

export const querySincronizzaCassetto = async (user, cf, sections) => {
  try {
    const response = await request
      .patch(`${process.env.REACT_APP_API_URL}/ade/${cf}/sincronizza`)
      .send({ token: user.auth_token, cf, sections })
    console.log('response', response)
    return response.body
  } catch (e) {
    alert("Errore sincronizzazione cassetto, segnalalo all'assistenza")
    return
  }
}

export const queryToggleCu = async (user, cf, nomeFile) => {
  try {
    const response = await request
      .patch(`${process.env.REACT_APP_API_URL}/ade/${cf}/mark-controllato`)
      .send({ token: user.auth_token, section: 'cu', identifier: nomeFile })
    return response.body
  } catch (e) {
    alert('Errore generico')
    return emptyState
  }
}
