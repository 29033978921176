import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import classNames from 'classnames'
import {
  cambiaFiscoStatus,
  toggleActive,
} from '../../../../../app/store/gestionaleUtente/actions'
import { selectIsCommercialista } from '../../../../../app/store/user/selectors'

const StatoPagamento = () => {
  const dati = useSelector(selectGestionaleUtente)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const isCommercialista = useSelector(selectIsCommercialista)
  const dispatch = useDispatch()

  const cambiaStatus = (stato) => {
    dispatch(cambiaFiscoStatus(stato))
  }

  const cambiaActive = () => {
    dispatch(toggleActive())
  }

  return (
    <>
      <Button
        color="secondary"
        outline
        disabled={isCommercialista}
        onClick={() => setModalIsOpen(!modalIsOpen)}
        size="sm"
        className="ms-2"
      >
        <i
          className={classNames(
            'bi bi-circle-fill',
            { 'text-warning': dati.anagrafica.stato === 'pending' },
            { 'text-success': dati.anagrafica.stato === 'active' },
            { 'text-danger': dati.anagrafica.stato === 'inactive' },
          )}
        ></i>{' '}
        <i
          className={classNames(
            'bi bi-person-fill',
            { 'text-warning': dati.anagrafica.active && dati.anagrafica.moroso },
            { 'text-success': dati.anagrafica.active && !dati.anagrafica.moroso },
            { 'text-danger': !dati.anagrafica.active },
          )}
        ></i>
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Stato utente
        </ModalHeader>
        <ModalBody>
          Piattaforma business: {dati.anagrafica.stato}
          <br />
          {dati.anagrafica.stato === 'pending' ||
          dati.anagrafica.stato === 'active' ? (
            <Button
              color="secondary"
              outline
              onClick={() => cambiaStatus('inactive')}
              className="mt-2"
              size="sm"
            >
              Disattiva
            </Button>
          ) : (
            <>
              <Button
                color="secondary"
                outline
                onClick={() => cambiaStatus('pending')}
                className="mt-2"
                size="sm"
              >
                Pending
              </Button>
              <Button
                color="secondary"
                outline
                onClick={() => cambiaStatus('active')}
                className="mt-2 ms-2"
                size="sm"
              >
                Attiva
              </Button>
            </>
          )}
          <hr />
          Dashboard cliente: {dati.anagrafica.active ? 'attiva' : 'disattivata'}
          <br />
          <Button
            color="secondary"
            className="mt-2"
            outline
            onClick={() => cambiaActive()}
            size="sm"
          >
            {dati.anagrafica.active ? 'Disattiva' : 'Attiva'}
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default StatoPagamento
