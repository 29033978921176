import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  queryIdrata,
  querySalvaImportoModelloD,
  queryCompletaSaldoENotifica,
  queryCompletaAccontoENotifica,
  queryCambiaStatoDichiarazione,
  querySwitchMedico,
  queryCambiaEmail,
  queryUploadUnico,
  queryDeleteUnico,
  queryCambiaFiscoStatus,
  queryToggleActive,
  querySincronizzaCassetto,
  queryToggleCu,
} from '../../utility/QueryGestionaleUtente'
import {
  queryCambiaStatoFatto,
  queryToggleControllato,
} from '../../utility/QueryShared'

export const idrata = createAsyncThunk(
  'gestionaleUtente/idrata',
  async ({ anno, medico_id }, { getState }) => {
    const state = getState()
    const responseBody = await queryIdrata(state.user.user, anno, medico_id)
    return { responseBody, anno }
  },
)

export const salvaImportoModelloD = createAsyncThunk(
  'gestionaleUtente/salvaImportoModelloD',
  async (importo, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const anno = state.gestionaleUtente.anno
    querySalvaImportoModelloD(state.user.user, medico_id, importo, anno)
    return importo
  },
)

export const completaSaldoENotifica = createAsyncThunk(
  'gestionaleUtente/completaSaldoENotifica',
  async (_, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const anno = state.gestionaleUtente.anno
    const responseBody = await queryCompletaSaldoENotifica(
      state.user.user,
      medico_id,
      anno,
    )
    return responseBody
  },
)

export const completaAccontoENotifica = createAsyncThunk(
  'gestionaleUtente/completaAccontoENotifica',
  async (_, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const anno = state.gestionaleUtente.anno
    const responseBody = await queryCompletaAccontoENotifica(
      state.user.user,
      medico_id,
      anno,
    )
    return responseBody
  },
)

export const cambiaStatoDichiarazione = createAsyncThunk(
  'gestionaleUtente/cambiaStatoDichiarazione',
  async (nuovoStato, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const anno = state.gestionaleUtente.anno
    queryCambiaStatoDichiarazione(state.user.user, medico_id, anno, nuovoStato)
    return nuovoStato
  },
)

export const cambiaStatoFatto = createAsyncThunk(
  'gestionaleUtente/cambiaStatoFatto',
  async (_, { getState }) => {
    const state = getState()
    const dichiarazione_id = state.gestionaleUtente.dichiarazione.id
    queryCambiaStatoFatto(state.user.user, dichiarazione_id)
  },
)

export const cambiaUtente = createAsyncThunk(
  'gestionaleUtente/cambiaUtente',
  async (direction, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const response = await querySwitchMedico(state.user.user, direction, medico_id)
    return response
  },
)

export const cambiaEmail = createAsyncThunk(
  'gestionaleUtente/cambiaEmail',
  async (email, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    queryCambiaEmail(state.user.user, email, medico_id)
    return email
  },
)

export const uploadUnico = createAsyncThunk(
  'gestionaleUtente/uploadUnico',
  async (file, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const anno = state.gestionaleUtente.anno
    const response = await queryUploadUnico(state.user.user, file, medico_id, anno)
    return response
  },
)

export const deleteUnico = createAsyncThunk(
  'gestionaleUtente/deleteUnico',
  async (_, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    const anno = state.gestionaleUtente.anno
    const response = await queryDeleteUnico(state.user.user, medico_id, anno)
    return response
  },
)

export const cambiaFiscoStatus = createAsyncThunk(
  'gestionaleUtente/cambiaFiscoStatus',
  async (stato, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    queryCambiaFiscoStatus(state.user.user, stato, medico_id)
    return stato
  },
)

export const toggleActive = createAsyncThunk(
  'gestionaleUtente/toggleActive',
  async (_, { getState }) => {
    const state = getState()
    const medico_id = state.gestionaleUtente.medico_id
    await queryToggleActive(state.user.user, medico_id)
    return null
  },
)

export const sincronizzaCassetto = createAsyncThunk(
  'gestionaleUtente/sincronizzaCassetto',
  async ({ cf, sections }, { getState }) => {
    const state = getState()
    await querySincronizzaCassetto(state.user.user, cf, sections)
    return sections
  },
)

export const toggleControllato = createAsyncThunk(
  'gestionaleUtente/toggleControllato',
  async ({ id, tipologia }, { getState }) => {
    const state = getState()
    queryToggleControllato(state.user.user, id)
    return { id, tipologia }
  },
)

export const toggleCu = createAsyncThunk(
  'gestionaleUtente/toggleCu',
  async (nomeFile, { getState }) => {
    const state = getState()
    const cf = nomeFile.split('_')[2].split('.')[0]
    queryToggleCu(state.user.user, cf, nomeFile)
    return nomeFile
  },
)
