import request from 'superagent'

export const listMediciPrimaConsulenza = async (user) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/commercialista/prima-consulenza/list-medici`,
      )
      .send({ token: user.auth_token })

    return response.body.medici
  } catch (e) {
    return []
  }
}

export const codiceFiscaleInverso = (user, codiceFiscale) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/commercialista/prima-consulenza/codice-fiscale-inverso`,
    )
    .send({ token: user.auth_token, codiceFiscale })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

export const inviaPDFRiepilogo = (user, medico_id) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/commercialista/prima-consulenza/invia-pdf-riepilogo`,
    )
    .send({ token: user.auth_token, medico_id })
    .then((response) => {
      return response.body
    })
    .catch((e) => console.error(e.message))

export const attivaMedico = async (user, medico_id) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/commercialista/prima-consulenza/attiva-medico`,
      )
      .send({ token: user.auth_token, medico_id })

    return response.body
  } catch (e) {
    return
  }
}

export const listGuadagni = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/commercialista/guadagni/list`)
      .send({ token: user.auth_token })

    return response.body.dati
  } catch (e) {
    return
  }
}

export const queryIdrataDatiTappe = (user, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/commercialista/tappe/idrata-dati`)
    .send({ token: user.auth_token, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryIdrataDownload = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/commercialista/download/idrata`)
    .send({ token: user.auth_token })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const listCuNonControllate = (user, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/commercialista/cu/list-non-controllate`)
    .send({ token: user.auth_token, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))
