import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Navbar,
  Collapse,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Container,
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import { logout } from '../../../app/store/user/slice'

const CommercialistaNav = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const logoutAction = () => {
    dispatch(logout())
    history.push('/')
  }

  return (
    <Navbar
      light
      style={{
        zIndex: '1000',
      }}
      expand="md"
      fixed="none"
      className="navbar navbar-expand-md navbar-dark bg-dark"
    >
      <Container
        fluid
        style={{
          display: 'flex',
        }}
      >
        <NavbarBrand tag={Link} to="/commercialista">
          <img
            src="/img_landing/logo_bianco.png"
            alt="Fiscomed"
            style={{ height: '42px' }}
          />
        </NavbarBrand>
        <NavbarToggler style={{ backgroundColor: 'white' }} />
        <Collapse isOpen={true} navbar>
          <Nav navbar>
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/commercialista">
                  <i className="bi bi-person"></i> Clienti
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tappe">
                  <i className="bi bi-bar-chart-steps"></i> Tappe
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/prima-consulenza">
                  <i className="bi bi-person-video"></i> Prima consulenza
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cu">
                  <i className="bi bi-layers-half"></i> CU
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dati">
                  <i className="bi bi-database-fill-down"></i> Dati
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/guadagni">
                  <i className="bi bi-piggy-bank"></i> Guadagni
                </Link>
              </li>
            </ul>
          </Nav>
          <Nav navbar className="ms-auto">
            <ul className="navbar-nav">
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => logoutAction()}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="bi bi-door-open"></i> Logout
                </span>
              </li>
            </ul>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default CommercialistaNav
