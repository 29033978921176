import React from 'react'
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import classNames from 'classnames'
import { cambiaStatoFatto } from '../../../../app/store/gestionaleUtente/actions'

const Fatto = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()

  return (
    <Button
      color={classNames(
        { success: dati?.dichiarazione?.fatto },
        { danger: !dati?.dichiarazione?.fatto },
      )}
      size="sm"
      className="ms-2 mt-2"
      onClick={() => dispatch(cambiaStatoFatto())}
    >
      {dati?.dichiarazione?.fatto ? 'Fatto' : 'Da fare'}
    </Button>
  )
}

export default Fatto
