import currency from 'currency.js'
import moment from 'moment'
import request from 'superagent'
import { groupBy } from './array'

export const totaleImporto = (prestazioni, addebitoMarcaDaBollo) => {
  let importoPerQuantita = currency(0)
  let somma = currency(0)
  prestazioni.forEach((pres) => {
    importoPerQuantita = currency(pres.importo).multiply(pres.quantita)
    somma = somma.add(importoPerQuantita)
  })
  if (addebitoMarcaDaBollo) somma = somma.add(2)
  return somma.value
}

export const totaleImportoAutofattura = (prestazioni) => {
  let somma = currency(0)
  let sommaIva = currency(0)
  prestazioni.forEach((pres) => {
    somma = somma.add(pres.importoTotale)
    sommaIva = sommaIva.add(pres.importoIVA)
  })
  return { somma: somma.value, sommaIva: sommaIva.value }
}

export const downloadInformazioniFiscali = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/informazioni-fiscali/list`)
      .send({ token: user.auth_token })

    return response.body
  } catch (e) {
    return []
  }
}

export const sortIncassatoBySimilarity = (incassato) => {
  incassato?.fattura_fiscomed?.sort((a, b) => {
    if (a.p_iva !== '' && b.p_iva !== '') {
      return a.p_iva.localeCompare(b.p_iva)
    }
    if (a.cf !== '' && b.cf !== '') {
      return a.cf.localeCompare(b.cf)
    }
    return a.nome.localeCompare(b.nome)
  })
  incassato?.fattura?.sort((a, b) => {
    if (a.p_iva !== '' && b.p_iva !== '') {
      return a.p_iva.localeCompare(b.p_iva)
    }
    if (a.cf !== '' && b.cf !== '') {
      return a.cf.localeCompare(b.cf)
    }
    return a.nome.localeCompare(b.nome)
  })
  incassato?.cedolino?.sort((a, b) => {
    if (a.p_iva !== '' && b.p_iva !== '') {
      return a.p_iva.localeCompare(b.p_iva)
    }
    if (a.cf !== '' && b.cf !== '') {
      return a.cf.localeCompare(b.cf)
    }
    return a.nome.localeCompare(b.nome)
  })
  incassato?.borsa_mmg?.sort((a, b) => {
    if (a.p_iva !== '' && b.p_iva !== '') {
      return a.p_iva.localeCompare(b.p_iva)
    }
    if (a.cf !== '' && b.cf !== '') {
      return a.cf.localeCompare(b.cf)
    }
    return a.nome.localeCompare(b.nome)
  })
  incassato?.borsa_specializzazione?.sort((a, b) => {
    if (a.p_iva !== '' && b.p_iva !== '') {
      return a.p_iva.localeCompare(b.p_iva)
    }
    if (a.cf !== '' && b.cf !== '') {
      return a.cf.localeCompare(b.cf)
    }
    return a.nome.localeCompare(b.nome)
  })
  incassato?.prestazione_occasionale?.sort((a, b) => {
    if (a.p_iva !== '' && b.p_iva !== '') {
      return a.p_iva.localeCompare(b.p_iva)
    }
    if (a.cf !== '' && b.cf !== '') {
      return a.cf.localeCompare(b.cf)
    }
    return a.nome.localeCompare(b.nome)
  })
  return incassato
}

export const preposizioni = (uomo) => {
  if (uomo) {
    return {
      il: 'il',
      ilTuo: 'il tuo',
      alTuo: 'al tuo',
      dalTuo: 'dal tuo',
      lui: 'lui',
      luiStesso: 'lui stesso',
      ilDottore: 'il Dottore',
    }
  } else {
    return {
      il: 'la',
      ilTuo: 'la tua',
      alTuo: 'alla tua',
      dalTuo: 'dalla tua',
      lui: 'lei',
      luiStesso: 'lei stessa',
      ilDottore: 'la Dottoressa',
    }
  }
}

// le sezioni commentate sono da rimuovere dopo che si testa in produzione la nuova funzione
// groupBy che si trova in src/app/utility/array.js. La modifica è stata fatta per problemi di
// retrocompatibilità con i browser più vecchi.
export const groupDocuments = (documents) => {
  const groupedDocuments = {
    fattura: [],
    cedolino: [],
    borsa_mmg: [],
    borsa_specializzazione: [],
    prestazione_occasionale: [],
  }
  if (documents.fattura) {
    documents.fattura.sort((a, b) =>
      moment(b.data_incasso).diff(moment(a.data_incasso)),
    )

    //groupedDocuments.fattura = Object.groupBy(
    //  documents.fattura,
    //  ({ data_incasso }) => moment(data_incasso).format('YYYY'),
    //)
    groupedDocuments.fattura = groupBy(documents.fattura, ({ data_incasso }) =>
      moment(data_incasso).format('YYYY'),
    )
  }
  if (documents.cedolino) {
    documents.cedolino.sort((a, b) =>
      moment(b.data_incasso).diff(moment(a.data_incasso)),
    )
    //groupedDocuments.cedolino = Object.groupBy(
    //  documents.cedolino,
    //  ({ data_incasso }) => moment(data_incasso).format('YYYY'),
    //)
    groupedDocuments.cedolino = groupBy(documents.cedolino, ({ data_incasso }) =>
      moment(data_incasso).format('YYYY'),
    )
  }
  if (documents.borsa_mmg) {
    documents.borsa_mmg.sort((a, b) =>
      moment(b.data_incasso).diff(moment(a.data_incasso)),
    )
    //groupedDocuments.borsa_mmg = Object.groupBy(
    //  documents.borsa_mmg,
    //  ({ data_incasso }) => moment(data_incasso).format('YYYY'),
    //)
    groupedDocuments.borsa_mmg = groupBy(documents.borsa_mmg, ({ data_incasso }) =>
      moment(data_incasso).format('YYYY'),
    )
  }
  if (documents.borsa_specializzazione) {
    documents.borsa_specializzazione.sort((a, b) =>
      moment(b.data_incasso).diff(moment(a.data_incasso)),
    )
    //groupedDocuments.borsa_specializzazione = Object.groupBy(
    //  documents.borsa_specializzazione,
    //  ({ data_incasso }) => moment(data_incasso).format('YYYY'),
    //)
    groupedDocuments.borsa_specializzazione = groupBy(
      documents.borsa_specializzazione,
      ({ data_incasso }) => moment(data_incasso).format('YYYY'),
    )
  }
  if (documents.prestazione_occasionale) {
    documents.prestazione_occasionale.sort((a, b) =>
      moment(b.data_incasso).diff(moment(a.data_incasso)),
    )
    groupedDocuments.prestazione_occasionale = groupBy(
      documents.prestazione_occasionale,
      ({ data_incasso }) => moment(data_incasso).format('YYYY'),
    )
  }
  return groupedDocuments
}
