import React from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col, Badge } from 'reactstrap'

const ModalStorico = ({ storico, isOpen, closeStorico }) => {
  const prettyPrint = (risposte) => {
    if (!risposte) return
    if (risposte.length === 0)
      return 'Il cliente ha saltato questa fase prima di prenotare la consulenza'
    return risposte.map((elemento, index) => {
      return (
        <Row key={index} className="mb-4">
          <Col xs={9}>
            <span className="text-muted">{elemento.domanda}</span>:
          </Col>
          <Col xs={3}>
            <span>
              {elemento.risposta === true && <Badge color="success">Sì</Badge>}
              {elemento.risposta === false && <Badge color="danger">No</Badge>}
              {elemento.risposta === null && (
                <Badge color="secondary">Non risposto</Badge>
              )}
            </span>
          </Col>
        </Row>
      )
    })
  }

  const modal = (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader toggle={() => closeStorico()}>Storico fiscale</ModalHeader>
      <ModalBody>{prettyPrint(storico?.risposte)}</ModalBody>
    </Modal>
  )

  return <>{modal}</>
}

export default ModalStorico
