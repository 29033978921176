import { createAsyncThunk } from '@reduxjs/toolkit'
import request from 'superagent'
import { saveUserInfo, logout, setLoading, setError } from './slice'
import { loginGA } from '../../utility/GA'
import {
  superUserLoginQuery,
  logoutSuperUserQuery,
} from '../../utility/QueryLoginEPagamento'

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    try {
      const response = await request
        .post(`${process.env.REACT_APP_API_URL}/signin`)
        .set({ 'Content-Type': 'application/json' })
        .send({ email, password })

      const res = JSON.parse(response.text)

      if (!res.error) {
        loginGA(res)
        dispatch(saveUserInfo(res))
        return res
      } else {
        dispatch(setError(res.message_error))
        return rejectWithValue(res.message_error)
      }
    } catch {
      const errorMessage = 'Errore di connessione, riprova più tardi'
      dispatch(setError(errorMessage))
      return rejectWithValue(errorMessage)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const loginCommercialista = createAsyncThunk(
  'user/loginCommercialista',
  async ({ uuidUtente, token }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    try {
      const response = await request
        .post(`${process.env.REACT_APP_API_URL}/signinCommercialista`)
        .set({ 'Content-Type': 'application/json' })
        .send({ uuidUser: uuidUtente, token })

      const res = JSON.parse(response.text)

      if (!res.error) {
        dispatch(saveUserInfo(res))
        return res
      } else {
        dispatch(setError(res.message_error))
        return rejectWithValue(res.message_error)
      }
    } catch {
      const errorMessage = 'Errore di connessione, riprova più tardi'
      dispatch(setError(errorMessage))
      return rejectWithValue(errorMessage)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (_, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true))
    const state = await getState()
    try {
      const response = await request
        .get(`${process.env.REACT_APP_API_URL}/user-info`)
        .set({
          token: state.user.user.auth_token,
          superusertoken: state.user.user.super_user_token,
        })
      const res = response.body
      if (!res.error) {
        dispatch(saveUserInfo(res))
        return res
      } else {
        dispatch(logout())
        dispatch(setError(res.message_error))
        return rejectWithValue(res.message_error)
      }
    } catch {
      dispatch(logout())
      const errorMessage = 'Errore di connessione, riprova più tardi'
      dispatch(setError(errorMessage))
      return rejectWithValue(errorMessage)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const superUserLoginAction = createAsyncThunk(
  'user/superUserLoginAction',
  async (medico_id, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true))
    const state = await getState()

    try {
      const response = await superUserLoginQuery(state.user.user, medico_id)
      dispatch(saveUserInfo(response))
    } catch {
      const errorMessage = 'Errore di connessione, riprova più tardi'
      dispatch(setError(errorMessage))
      return rejectWithValue(errorMessage)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const logoutSuperUserAction = createAsyncThunk(
  'user/logoutSuperUserAction',
  async (_, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true))
    const state = await getState()

    try {
      const response = await logoutSuperUserQuery(state.user.user)
      dispatch(saveUserInfo(response))
    } catch {
      const errorMessage = 'Errore di connessione, riprova più tardi'
      dispatch(setError(errorMessage))
      return rejectWithValue(errorMessage)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
