import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import { listEsiti, downloadZip } from '../../app/utility/QueryContabile'
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  Card,
  Input,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap'
import { apriXMLBusiness } from '../../app/store/fileViewer/actions'
import classNames from 'classnames'
import { selectLoadingFile } from '../../app/store/fileViewer/selectors'
import { useDispatch } from 'react-redux'
import FatturaDaGestire from './component/FatturaDaGestire'
import CentredLoading from '../component/CentredLoading'

const emptyZip = {
  data_inizio: '',
  data_fine: '',
  loading: false,
}

const Fatture = () => {
  const user = useSelector(selectUser)
  const [fatture, setFatture] = useState({
    tutteLeFatture: [],
    fattureDaGestire: [],
    datiPopolati: false,
    decodificaEseguita: false,
    sorted: false,
    checkedToShow: false,
  })
  const [suggerimento, setSuggerimento] = useState('')
  const [modaleScartoOpen, setModaleScartoOpen] = useState(false)
  const loading = useSelector(selectLoadingFile)
  const dispatch = useDispatch()
  const [zip, setZip] = useState(emptyZip)

  useEffect(() => {
    async function popolaDati() {
      let valori = await listEsiti(user)
      setFatture({
        ...fatture,
        tutteLeFatture: valori.tutte_le_fatture,
        fattureDaGestire: valori.fatture_da_gestire,
        datiPopolati: true,
      })
    }
    popolaDati()
  }, [])

  useEffect(() => {
    if (fatture.datiPopolati === true) {
      let fattureDecodificate = fatture?.tutteLeFatture?.map((fattura) => {
        return {
          ...fattura,
          parametriDecodificati: JSON.parse(
            atob(fattura.parametri).replaceAll('=>', ':').replaceAll('nil', 'null'),
          ),
        }
      })
      let fattureDeGestireDecodificate = fatture?.fattureDaGestire?.map(
        (fattura) => {
          return {
            ...fattura,
            parametriDecodificati: JSON.parse(
              atob(fattura.parametri)
                .replaceAll('=>', ':')
                .replaceAll('nil', 'null'),
            ),
          }
        },
      )
      setFatture({
        ...fatture,
        decodificaEseguita: true,
        tutteLeFatture: fattureDecodificate,
        fattureDaGestire: fattureDeGestireDecodificate,
      })
    }
  }, [fatture.datiPopolati])

  useEffect(() => {
    if (fatture.decodificaEseguita === true) {
      let fattureSoreted = fatture.tutteLeFatture?.sort((a, b) => {
        return (
          b.parametriDecodificati?.data_fattura
            ?.toString()
            .localeCompare(a.parametriDecodificati?.data_fattura?.toString()) ||
          b.parametriDecodificati?.numero_fattura
            ?.toString()
            .localeCompare(a.parametriDecodificati?.numero_fattura?.toString())
        )
      })
      setFatture({ ...fatture, tutteLeFatture: fattureSoreted, sorted: true })
    }
  }, [fatture.decodificaEseguita])

  useEffect(() => {
    if (fatture.sorted === true) {
      let fattureRiviste = fatture.tutteLeFatture?.map((fattura, index) => {
        if (index > 99) {
          return { ...fattura, toShow: false }
        } else {
          return { ...fattura, toShow: true }
        }
      })
      setFatture({ ...fatture, tutteLeFatture: fattureRiviste, checkedToShow: true })
    }
  }, [fatture.sorted])

  const fattureVisibili = fatture.tutteLeFatture?.filter(
    (fattura) => fattura.toShow === true,
  )

  const apriModaleScarto = (suggerimento) => {
    setSuggerimento(suggerimento)
    setModaleScartoOpen(true)
  }

  const caricaAltreFatture = () => {
    const numero = fatture.tutteLeFatture?.filter((f) => f.toShow === true).length
    let fattureRiviste = fatture.tutteLeFatture?.map((fattura, index) => {
      if (index > numero + 99) {
        return { ...fattura, toShow: false }
      } else {
        return { ...fattura, toShow: true }
      }
    })
    setFatture({ ...fatture, tutteLeFatture: fattureRiviste })
  }

  const scaricaZip = async () => {
    setZip({ ...zip, loading: true })
    let fine = downloadZip(user, zip.data_inizio, zip.data_fine)
    if (await fine) {
      setZip(emptyZip)
    }
  }

  const rimuoviFatturaDaGestire = (id) => {
    let nuoveFatture = fatture.fattureDaGestire?.filter((fattura) => {
      return fattura.id !== id
    })
    setFatture({ ...fatture, fattureDaGestire: nuoveFatture })
  }

  return (
    <Container fluid className="mt-4">
      <Modal isOpen={modaleScartoOpen} toggle={() => setModaleScartoOpen(false)}>
        <ModalHeader
          className="font-regular"
          toggle={() => setModaleScartoOpen(false)}
        >
          Errore scarto
        </ModalHeader>
        <ModalBody>{suggerimento}</ModalBody>
      </Modal>
      {fatture.checkedToShow === true ? (
        <Row className="mb-5">
          <Col xs={6}>
            {fatture.fattureDaGestire.length === 0 ? (
              <div className="alert alert-success" role="alert">
                Non ci sono fatture da gestire 🎊
              </div>
            ) : (
              <>
                <div className="alert alert-danger" role="alert">
                  Fatture da gestire: {fatture.fattureDaGestire.length}
                </div>
                {fatture.fattureDaGestire?.map((fattura, index) => {
                  return (
                    <FatturaDaGestire
                      fattura={fattura}
                      key={index}
                      rimuovi={(id) => rimuoviFatturaDaGestire(id)}
                      user={user}
                    />
                  )
                })}
              </>
            )}
          </Col>

          <Col xs={6}>
            <Card body className="mb-3">
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="exampleSelect">Primo giorno</Label>
                    <Input
                      type="date"
                      value={zip.data_inizio}
                      onChange={(e) =>
                        setZip({ ...zip, data_inizio: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="exampleSelect">Ultimo giorno</Label>
                    <Input
                      type="date"
                      value={zip.data_fine}
                      onChange={(e) => setZip({ ...zip, data_fine: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Button
                      id="scarica"
                      color="primary"
                      onClick={() => scaricaZip()}
                      style={{ marginTop: '32px' }}
                      disabled={
                        zip.loading || zip.data_inizio === '' || zip.data_fine === ''
                      }
                    >
                      {zip.loading ? <Spinner size="sm" /> : 'Scarica'}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Card>
            <table className="table table-striped table-sm table-bordered">
              <thead>
                <tr>
                  <th>Numero</th>
                  <th>Data</th>
                  <th>Importo</th>
                  <th>Cliente</th>
                  <th>Esito</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fattureVisibili?.map((fattura, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {fattura.parametriDecodificati?.numero_fattura}
                        {fattura.parametriDecodificati?.sezionale}
                      </td>
                      <td>{fattura.parametriDecodificati?.data_fattura}</td>
                      <td>
                        {parseFloat(fattura.parametriDecodificati?.totale) / 100.0}€
                      </td>
                      <td>
                        {fattura.parametriDecodificati?.nome}{' '}
                        {fattura.parametriDecodificati.cognome}
                      </td>
                      <td>
                        {fattura.esito === 'scarto' ? (
                          <span
                            onClick={() => apriModaleScarto(fattura.suggerimento)}
                            className="text-danger"
                            style={{ cursor: 'pointer' }}
                          >
                            scarto
                          </span>
                        ) : (
                          fattura.esito
                        )}
                      </td>
                      <td>
                        <Badge
                          color={classNames(
                            { primary: !loading },
                            { secondary: loading },
                          )}
                          onClick={() =>
                            dispatch(
                              apriXMLBusiness(
                                fattura.progressivo_unico_fattura + '.xml',
                              ),
                            )
                          }
                          style={{ cursor: 'pointer' }}
                          disabled={loading}
                        >
                          <i className="bi bi-eye"></i>
                        </Badge>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <center>
              <Button color="primary" onClick={() => caricaAltreFatture()} size="sm">
                Carica altre fatture
              </Button>
            </center>
          </Col>
        </Row>
      ) : (
        <CentredLoading />
      )}
    </Container>
  )
}

export default Fatture
