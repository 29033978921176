import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import UserRoleDetector from './UserRoleDetector'

const OnlyBigScreen = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 1023)

  useEffect(() => {
    if (smallScreen) {
      history.push('/disponibile-sul-desktop')
    }
  }, [path, smallScreen])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1023) {
        setSmallScreen(true)
      } else {
        setSmallScreen(false)
      }
    })
  }, [])

  return <UserRoleDetector />
}

export default OnlyBigScreen
