import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Input, Label } from 'reactstrap'
import { useSelector } from 'react-redux'
import {
  selectGestionaleUtente,
  selectDichiarazione,
} from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import Completato from './Completato'
import { salvaImportoModelloD } from '../../../../app/store/gestionaleUtente/actions'

const ModelloD = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dichiarazione = useSelector(selectDichiarazione)
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [importo, setImporto] = useState(parseInt(dichiarazione?.importo_modello_d))

  useEffect(() => {
    setImporto(parseInt(dichiarazione?.importo_modello_d))
  }, [dichiarazione])

  return (
    <>
      <Button
        className="mt-2 ms-2"
        color="secondary"
        outline
        size="sm"
        onClick={() => setModalIsOpen(true)}
      >
        Modello D <Completato completato={dichiarazione?.step_enpam_completato} />
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)}>
        <ModalHeader className="font-regular" toggle={() => setModalIsOpen(false)}>
          Modello D
        </ModalHeader>
        <ModalBody>
          {dichiarazione?.step_enpam_completato ? (
            <>
              <span className="font-bold">Hai comunicato</span> l'importo che il
              cliente deve inserire nel suo modello D. L'importo comunicato è{' '}
              <span className="font-bold">{parseInt(importo)}€</span>
            </>
          ) : (
            <>
              Scrivi in questo campo l'importo che il cliente dovrà inserire nel suo
              modello D
              <ul>
                <li>
                  Se inserisci <span className="font-bold">zero</span>: il cliente
                  verrà avvertito per email di non{' '}
                  <span className="font-bold">dover inviare il modello</span>
                </li>
                <li>
                  Se inserisci un{' '}
                  <span className="font-bold">importo maggiore di zero</span> il
                  cliente riceverà un'email con l'importo da inserire e sulla sua{' '}
                  <span className="font-bold">piattaforma</span> si attiverà un{' '}
                  <span className="font-bold">percorso</span> per la corretta
                  compilazione del modello.
                </li>
              </ul>
              <br />
              <Label>Importo</Label>
              <Input
                type="number"
                min="0"
                step="1"
                value={importo}
                onChange={(e) => setImporto(e.target.value)}
              />
              <br />
              <Button
                color="primary"
                size="sm"
                className="float-end"
                onClick={() => dispatch(salvaImportoModelloD(importo))}
              >
                Salva
              </Button>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModelloD
