import React from 'react'

export const Tooltip = ({ children, placement }) => {
  return (
    <span
      className="d-inline-block"
      tabIndex="0"
      data-bs-toggle="tooltip"
      title={children}
      data-bs-placement={placement}
    >
      <i className="bi bi-question-circle"></i>
    </span>
  )
}

export default Tooltip
