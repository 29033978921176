import request from 'superagent'

// Crea link di pagamento
export const creaLinkPagamento = (dati) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/abbonamento/link-pagamento`)
    .send({ ...dati })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// ritorna i dati del medico con il token superuser
export const superUserLoginQuery = async (user, medico_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/signin-super-user`)
      .send({ token: user.auth_token, medico_id })
    return response.body
  } catch (e) {
    return null
  }
}

// ritorna i dati del medico con il token superuser
export const logoutSuperUserQuery = async (user) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/logout-super-user`)
      .send({ token: user.auth_token, super_user_token: user.super_user_token })
    return response.body
  } catch (e) {
    return null
  }
}
