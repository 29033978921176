import React, { useState } from 'react'
import request from 'superagent'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
  Tooltip,
  Row,
  Col,
} from 'reactstrap'
import swal from '@sweetalert/with-react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const Cancellazione = () => {
  const user = useSelector(selectUser)
  const [loadingButtonCancella, setLoadingButtonCancella] = useState(false)
  const [dataEmissione, setDataEmissione] = useState(moment().format('YYYY-MM-DD'))
  const [numDocumentoFiscale, setNumDocumentoFiscale] = useState('')
  const [tooltipDataEmissioneOpen, setTooltipDataEmissioneOpen] = useState(false)
  const [tooltipNumDocumentoFiscaleOpen, setTooltipNumDocumentoFiscaleOpen] =
    useState(false)

  const cancellaDocumento = () => {
    if (loadingButtonCancella) return
    setLoadingButtonCancella(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/cancella-documento`)
      .send({
        token: user.auth_token,
        dataEmissione,
        numDocumentoFiscale,
      })
      .then((response) => {
        setLoadingButtonCancella(false)
        mostraEsito(response.body)
      })
      .catch((e) => {
        console.error(e.message)
        setLoadingButtonCancella(false)
      })
  }

  const mostraEsito = (esito) => {
    let icon = 'success'
    if (esito.error || esito.esito === '1') {
      icon = 'error'
    }
    if (esito.esito === '2') {
      icon = 'warning'
    }

    let intestazione
    let messaggio
    intestazione =
      'Il documento non è stato cancellato. Il Sistema Tessera Sanitaria dà questo errore:'
    messaggio = esito.error_message

    if (!esito.error) {
      if (esito.esito === '0') {
        intestazione = 'Il documento è stato cancellato'
        messaggio = null
      }
      if (esito.esito === '1') {
        intestazione =
          'Il documento non è stato cancellato. Il Sistema Tessera Sanitaria dà questo errore:'
        if (typeof esito.messaggi === 'string') {
          messaggio = esito.messaggi
        } else {
          messaggio = esito.messaggi.join(' ')
        }
      }
      if (esito.esito === '2') {
        intestazione = 'Documento cancellato, ma con dei messaggi di errore:'
        if (typeof esito.messaggi === 'string') {
          messaggio = esito.messaggi
        } else {
          messaggio = esito.messaggi.join(' ')
        }
      }
    }

    swal({
      icon,
      title: 'Esito cancellazione',
      content: (
        <div>
          {intestazione}
          <br />
          <br />
          {messaggio}
        </div>
      ),
      button: 'OK',
    })
  }

  return (
    <div>
      <br />
      <h6 className="font-regular interlinea-30">
        Da questa scheda puoi facilmente cancellare un documento inviato. Potresti
        aver necessità di farlo se, ad esempio, hai inviato per errore i dati della
        stessa fattura due volte.
      </h6>
      <br />
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>
              Data di emissione{' '}
              <i className="bi bi-question-circle" id="tooltip-dataemissione"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipDataEmissioneOpen}
                target="tooltip-dataemissione"
                toggle={() => setTooltipDataEmissioneOpen(!tooltipDataEmissioneOpen)}
              >
                È il giorno in cui hai emesso la fattura
              </Tooltip>
            </Label>
            <Input
              type="date"
              value={dataEmissione}
              onChange={(e) => setDataEmissione(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>
              Numero documento fiscale{' '}
              <i className="bi bi-question-circle" id="tooltip-numdocfisc"></i>
              <Tooltip
                placement="top"
                isOpen={tooltipNumDocumentoFiscaleOpen}
                target="tooltip-numdocfisc"
                toggle={() =>
                  setTooltipNumDocumentoFiscaleOpen(!tooltipNumDocumentoFiscaleOpen)
                }
              >
                È il numero della fattura
              </Tooltip>
            </Label>
            <Input
              type="text"
              value={numDocumentoFiscale}
              onChange={(e) => setNumDocumentoFiscale(e.target.value)}
            />
          </FormGroup>
          <Button
            onClick={() => cancellaDocumento()}
            className="bottone-blu float-end mt-4"
          >
            {loadingButtonCancella ? (
              <Spinner size="sm" type="grow" />
            ) : (
              'CANCELLA IL DOCUMENTO'
            )}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default Cancellazione
