import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'

const Report = () => {
  const dati = useSelector(selectGestionaleUtente)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Button
        color="secondary"
        outline
        size="sm"
        className="me-2 position-relative"
        onClick={() => setModalIsOpen(true)}
        disabled={dati.loading}
      >
        <i className="bi bi-file-earmark-bar-graph"></i> Report
      </Button>
      <Modal
        isOpen={modalIsOpen}
        size="xl"
        toggle={() => setModalIsOpen(!modalIsOpen)}
      >
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>Report</ModalHeader>
        <ModalBody>
          <h4 className="font-regular">
            Dati e previsioni {dati?.report_tassazione?.anno_precedente?.anno}
          </h4>
          <ul>
            <li>
              Incassato lordo CA:{' '}
              {dati?.report_tassazione?.anno_precedente?.fatturato?.lordo_ca}
            </li>
            <li>
              Incassato libera professione:{' '}
              {
                dati?.report_tassazione?.anno_precedente?.fatturato
                  ?.fatturato_libera_professione
              }
            </li>
            <li>
              Incassato Borsa MMG:{' '}
              {dati?.report_tassazione?.anno_precedente?.fatturato?.borsa_mmg}{' '}
            </li>
          </ul>
          <h5 className="font-regular">Contributi</h5>
          <pre>
            {JSON.stringify(
              dati?.report_tassazione?.anno_precedente?.contributi,
              null,
              2,
            )}
          </pre>
          <h5 className="font-regular">Imposta unica</h5>
          <pre>
            {JSON.stringify(
              dati?.report_tassazione?.anno_precedente?.imposta_unica,
              null,
              2,
            )}
          </pre>
          <h5 className="font-regular">Incasso mensile</h5>
          <pre>
            {JSON.stringify(
              dati?.report_tassazione?.anno_precedente?.incasso_mensile,
              null,
              2,
            )}
          </pre>
          <h5 className="font-regular">Limiti incassato</h5>
          <pre>{JSON.stringify(dati.limiti?.anno_precedente, null, 2)}</pre>
          <br />
          <h4 className="font-regular">
            Dati e previsioni {dati?.report_tassazione?.anno_corrente?.anno}
          </h4>
          <ul>
            <li>
              Incassato lordo CA:{' '}
              {dati?.report_tassazione?.anno_corrente?.fatturato?.lordo_ca}
            </li>
            <li>
              Incassato libera professione:{' '}
              {
                dati?.report_tassazione?.anno_corrente?.fatturato
                  ?.fatturato_libera_professione
              }
            </li>
            <li>
              Incassato Borsa MMG:{' '}
              {dati?.report_tassazione?.anno_corrente?.fatturato?.borsa_mmg}{' '}
            </li>
          </ul>
          <h5 className="font-regular">Contributi</h5>
          <pre>
            {JSON.stringify(
              dati?.report_tassazione?.anno_corrente?.contributi,
              null,
              2,
            )}
          </pre>
          <h5 className="font-regular">Imposta unica</h5>
          <pre>
            {JSON.stringify(
              dati?.report_tassazione?.anno_corrente?.imposta_unica,
              null,
              2,
            )}
          </pre>
          <h5 className="font-regular">Incasso mensile</h5>
          <pre>
            {JSON.stringify(
              dati?.report_tassazione?.anno_corrente?.incasso_mensile,
              null,
              2,
            )}
          </pre>
          <h5 className="font-regular">Limiti incassato</h5>
          <pre>{JSON.stringify(dati.limiti?.anno_corrente, null, 2)}</pre>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Report
