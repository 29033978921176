import request from 'superagent'

export const listMediciAssegnati = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/shared/list-medici-assegnati`)
    .send({ token: user.auth_token })
    .then((response) => {
      return response.body.medici
    })
    .catch((e) => console.error(e.message))

export const listFatturePA = async (user) => {
  try {
    const response = await request
      .get(`${process.env.REACT_APP_API_URL}/admin/fattura-pa/list`)
      .set({ token: user.auth_token || '' })

    return response.body.fatture
  } catch (e) {
    return []
  }
}

export const inviaFatturaPa = async (user, signedInvoice, fatturaId) => {
  const formData = new FormData()
  formData.append('signed_invoice', signedInvoice)
  formData.append('fattura_id', fatturaId)
  formData.append('token', user.auth_token)

  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/admin/fattura-pa/invia`)
      .send(formData)
      .on('progress', (event) => {
        swal({
          title: 'Invio fattura',
          text: `${parseInt(event.percent)}%`,
          icon: 'info',
          button: false,
        })
      })
      .then(() => {
        swal({
          title: 'Fattura inviata',
          icon: 'success',
          button: 'Chiudi',
        })
      })

    return response
  } catch (e) {
    swal({
      title: 'Errore',
      text: 'Non è stato possibile inviare la fattura',
      icon: 'error',
      button: 'Chiudi',
    })
  }
}

export const scaricaFatturaPA = async (user, fattura) => {
  try {
    const response = await request
      .get(
        `${process.env.REACT_APP_API_URL}/admin/fattura-pa/download/${fattura.id}`,
      )
      .set({ token: user.auth_token })

    window.open(response.body.url, '_blank')
    return
  } catch (e) {
    console.error(e.message)
  }
}
