import React from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import moment from 'moment'
import { selectFileManager } from '../../../app/store/fileManager/selectors'
import { useDispatch } from 'react-redux'
import { deleteFileBlocchetto } from '../../../app/store/fileManager/actions'
import { apriFileBlocchetto } from '../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../app/store/fileViewer/selectors'
import classNames from 'classnames'

export const TabellaPrestazioniOccasionali = ({ setDocumentToUpdate }) => {
  const fileManager = useSelector(selectFileManager)
  const dispatch = useDispatch()
  const loadingFile = useSelector(selectLoadingFile)

  const tabellaPrestazioniOcc =
    fileManager?.grouped_documents?.prestazione_occasionale[fileManager.anno]?.map(
      (element, i) => (
        <tr key={i}>
          <td>Prestazione occasionale</td>
          <td>{moment(element.data_incasso).format('MM')}</td>
          <td>{element.importo}€</td>
          <td>{element.trattenuta_irpef}€</td>
          <td>
            <span
              className={classNames({ 'testo-blu': loadingFile === false })}
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(apriFileBlocchetto(element.id))}
            >
              {element.nome_originale_file.substring(0, 50)}
            </span>
          </td>
          <td className="font-regular">
            <center
              style={{ cursor: 'pointer' }}
              onClick={() => setDocumentToUpdate(element)}
            >
              <i className="bi bi-pencil"></i>
            </center>
          </td>
          <td className="text-danger">
            <center
              style={{ cursor: 'pointer' }}
              onClick={() =>
                dispatch(
                  deleteFileBlocchetto({
                    id_documento: element.id,
                    tipologia: 'prestazione_occasionale',
                  }),
                )
              }
            >
              <i className="bi bi-trash"></i>
            </center>
          </td>
        </tr>
      ),
    )

  return (
    <>
      {fileManager?.grouped_documents?.prestazione_occasionale[fileManager.anno]
        ?.length === undefined ? (
        <div className="alert alert-info mt-5" role="alert">
          <i className="bi bi-info-circle"></i> Non hai importato nessuna prestazione
          occasionale con questa data d'incasso
        </div>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca mt-5">
          <thead>
            <tr>
              <th className="h6 font-regular">Tipo</th>
              <th className="h6 font-regular">Mese</th>
              <th className="h6 font-regular">Importo</th>
              <th className="h6 font-regular">Trattenuta IRPEF</th>
              <th className="h6 font-regular">File</th>
              <th className="h6 font-regular"></th>
              <th className="h6 font-regular"></th>
            </tr>
          </thead>
          <tbody>{tabellaPrestazioniOcc}</tbody>
        </Table>
      )}
    </>
  )
}

export default TabellaPrestazioniOccasionali
