import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { getLast } from '../../../app/utility/array'

import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalScarto } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalScarto = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  const lastEsito = getLast(dati.fatturaSelezionata?.esito)
  return (
    <Modal
      isOpen={dati.modalScartoOpen}
      toggle={() => dispatch(chiudiModalScarto())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalScarto())}
        className="font-regular font-grigio-scuro"
      >
        Fattura scartata
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata è stata scartata perché lo SdI ha trovato degli errori
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Entro 5 giorni dovrai inviare una fattura di rettifica, con lo stesso
          numero univoco e la stessa data di emissione di quella scartata.
          <br /> Non preoccuparti: cliccando sul tasto &ldquo;Rettifica la
          fattura&ldquo;, andrai nella schermata di creazione della fattura dove
          troverai il numero e la data giusti già impostati.
          <br />
          <br /> Ecco gli errori che hanno fatto scartare la fattura:
        </h6>
        <br />
        <div className="alert alert-danger" role="alert">
          {lastEsito?.suggerimento}
        </div>
        <br />
        <h6 style={{ lineHeight: '25px' }}>
          Vuoi una mano nel rettificare la fattura?{' '}
          <Link to="/chat-commercialista">Contattaci</Link> e ti aiuteremo a farlo
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button
          tag={Link}
          to={
            '/fiscomed/nuova-fattura/rettifica-pr/' +
            dati.fatturaSelezionata?.fattura?.id
          }
          className="bottone-blu"
        >
          Rettifica la fattura
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalScarto
