import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import { useSelector } from 'react-redux'

import { selectUser } from '../../../app/store/user/selectors'
import { querySincronizzaCassetto } from '../../../app/utility/QueryTecnico'

const ModalSync = () => {
  const user = useSelector(selectUser)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()
  const [sezioniDaSincronizzare, setSezioniDaSincronizzare] = useState([])

  const toggleSezione = (sezione) => {
    if (sezioniDaSincronizzare.includes(sezione)) {
      setSezioniDaSincronizzare(sezioniDaSincronizzare.filter((s) => s !== sezione))
    } else {
      setSezioniDaSincronizzare([...sezioniDaSincronizzare, sezione])
    }
  }

  const sincronizza = async () => {
    setLoading(true)
    try {
      const response = await querySincronizzaCassetto({
        user,
        sections: sezioniDaSincronizzare,
      })
      setResponse(response)
    } catch (e) {
      console.error(e.message)
      setResponse({ error: e.message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        size="sm"
        color="secondary"
        outline
        className="ms-2"
        onClick={() => setModalIsOpen(true)}
        disabled={loading}
      >
        <i className="bi bi-stack"></i> Sincronizzazione massiva
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Scegli le sezioni da sincronizzare
        </ModalHeader>
        <ModalBody>
          <FormGroup check className="mt-3">
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('dichiarazioni')}
              onChange={() => toggleSezione('dichiarazioni')}
            />
            <Label>Dichiarazioni</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('sette_trenta')}
              onChange={() => toggleSezione('sette_trenta')}
            />
            <Label>730</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('cu')}
              onChange={() => toggleSezione('cu')}
            />
            <Label>CU</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('registro')}
              onChange={() => toggleSezione('registro')}
            />
            <Label>Registro</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('f24')}
              onChange={() => toggleSezione('f24')}
            />
            <Label>F24</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('fatture_elettroniche')}
              onChange={() => toggleSezione('fatture_elettroniche')}
            />
            <Label>Fatture elettroniche</Label>
          </FormGroup>
          <FormGroup check className="mb-3">
            <Input
              type="checkbox"
              checked={sezioniDaSincronizzare.includes('catasto')}
              onChange={() => toggleSezione('catasto')}
            />
            <Label>Catasto</Label>
          </FormGroup>

          <div>
            {response !== undefined &&
              (response.error ? (
                <p>Errore: {response.error}</p>
              ) : (
                <p>Utenti messi in sync: {response.to_sync}</p>
              ))}
          </div>
          <Button
            color="primary"
            onClick={() => sincronizza()}
            className="float-end"
            disabled={loading}
          >
            <i className="bi bi-stack"></i> Sincronizza
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalSync
