import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectIsMedico,
  selectIsMedicoMoroso,
  selectIsCommercialista,
  selectIsAdmin,
  selectIsContabile,
  selectIsOperation,
  selectIsTecnico,
} from '../../../app/store/user/selectors'
import MedicoRoutes from '../group/MedicoRoutes'
import CommercialistaRoutes from '../group/CommercialistaRoutes'
import AdminRoutes from '../group/AdminRoutes'
import ContabileRoutes from '../group/ContabileRoutes'
import OperationRoutes from '../group/OperationRoutes'
import TecnicoRoutes from '../group/TecnicoRoutes'
import MedicoMorosoRoutes from '../group/MedicoMorosoRoutes'

const UserRoleDetector = () => {
  const isMedico = useSelector(selectIsMedico)
  const isMedicoMoroso = useSelector(selectIsMedicoMoroso)
  const isCommercialista = useSelector(selectIsCommercialista)
  const isAdmin = useSelector(selectIsAdmin)
  const isContabile = useSelector(selectIsContabile)
  const isOperation = useSelector(selectIsOperation)
  const isTecnico = useSelector(selectIsTecnico)

  return (
    <>
      {isMedico && <>{isMedicoMoroso ? <MedicoMorosoRoutes /> : <MedicoRoutes />}</>}
      {isCommercialista && <CommercialistaRoutes />}
      {isAdmin && <AdminRoutes />}
      {isContabile && <ContabileRoutes />}
      {isOperation && <OperationRoutes />}
      {isTecnico && <TecnicoRoutes />}
    </>
  )
}

export default UserRoleDetector
