import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../../sharedView/home/Home'
import PrimaConsulenza from '../../commercialista/primaConsulenza/PrimaConsulenza'
import UserProfile from '../../sharedView/userProfile/UserProfile'
import Guadagni from '../../commercialista/Guadagni'
import Tappe from '../../sharedView/tappe/Tappe'
import Dati from '../../commercialista/Dati'
import ControlloCu from '../../commercialista/controlloCu/ControlloCu'

const CommercialistaRoutes = () => (
  <Switch>
    <Route path="/prima-consulenza">
      <PrimaConsulenza />
    </Route>
    <Route path="/shared/user-profile/:medicoId">
      <UserProfile />
    </Route>
    <Route path="/guadagni">
      <Guadagni />
    </Route>
    <Route exact path="/tappe">
      <Tappe />
    </Route>
    <Route exact path="/dati">
      <Dati />
    </Route>
    <Route exact path="/cu">
      <ControlloCu />
    </Route>
    <Route>
      <Home />
    </Route>
  </Switch>
)

export default CommercialistaRoutes
