import React, { useEffect, useState } from 'react'
import { Container, ButtonGroup, Button } from 'reactstrap'
import { listCuNonControllate } from '../../../app/utility/QueryCommercialista'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import CentredLoading from '../../component/CentredLoading'
import { Link } from 'react-router-dom'
import moment from 'moment'

const SuccessMessage = ({ anno }) => (
  <div className="alert alert-success mt-4" role="alert">
    Alla grande! Non ci sono medici con delle CU non controllate per l'anno {anno} 🎉
  </div>
)

const MediciList = ({ medici, anno }) => (
  <div>
    <div className="alert alert-info my-4" role="alert">
      Ci sono {medici.length} medici che hanno almeno una CU da controllate per
      l'anno {anno}
    </div>
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Nome</th>
          <th scope="col">Iscrizione</th>
          <th scope="col">Apertura Partita IVA</th>
        </tr>
      </thead>
      <tbody>
        {medici.map((medico, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              <Link to={'/shared/user-profile/' + medico.id}>
                {medico.cognome} {medico.nome}
              </Link>
            </td>
            <td>{moment(medico.created_at).format('MM/YYYY')}</td>
            <td>{moment(medico.data_apertura_p_iva).format('MM/YYYY')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

const ControlloCu = () => {
  const [medici, setMedici] = useState([])
  const [anno, setAnno] = useState(new Date().getFullYear() - 1)
  const [loading, setLoading] = useState(true)
  const user = useSelector(selectUser)

  useEffect(() => {
    richiediDati()
  }, [anno])

  const richiediDati = () => {
    setLoading(true)
    listCuNonControllate(user, anno).then((response) => {
      setMedici(response.medici)
      setLoading(false)
    })
  }

  return (
    <Container className="mt-3">
      <ButtonGroup className="mt-2" size="sm">
        <Button onClick={() => setAnno(anno - 1)} color="primary" disabled={loading}>
          <i className="bi bi-arrow-left-square"></i>
        </Button>
        <Button disabled color="light">
          Anno {anno}
        </Button>
        <Button onClick={() => setAnno(anno + 1)} color="primary" disabled={loading}>
          <i className="bi bi-arrow-right-square"></i>
        </Button>
      </ButtonGroup>
      <div className="mt-2">
        {loading ? (
          <CentredLoading />
        ) : medici.length === 0 ? (
          <SuccessMessage anno={anno} />
        ) : (
          <MediciList medici={medici} anno={anno} />
        )}
      </div>
    </Container>
  )
}

export default ControlloCu
