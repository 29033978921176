import React, { useState, useEffect } from 'react'
import { Card, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import AttivaMedico from './AttivaMedico'
import InviaPDFRiepilogo from './InviaPDFRiepilogo'
import StatoDichiarazione from './StatoDichiarazione'
import SalvatoSulServer from '../../../sharedComponent/SalvatoSulServer'
import CodiceFiscaleInverso from './CodiceFiscaleInverso'
import { salvaDatiMedicoDaAttivare } from '../../../../app/utility/QueryShared'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'
import moment from 'moment'

const SchedaMedico = ({
  selectedMedico,
  inviaPDF,
  aggiornaDatiMedico,
  rimuoviMedico,
  cambiaStatoDichiarazione,
}) => {
  const user = useSelector(selectUser)
  const [medico, setMedico] = useState(selectedMedico)
  const [datiSalvati, setDatiSalvati] = useState(true)
  const [firtsRun, setFirstRun] = useState(true)

  useEffect(() => {
    setMedico(selectedMedico)
    setFirstRun(true)
  }, [selectedMedico])

  useEffect(() => {
    if (firtsRun) {
      setFirstRun(false)
      return
    }

    setDatiSalvati(false)
    const debounceId = setTimeout(() => salvaSulServer(), 750)
    return () => {
      return clearTimeout(debounceId)
    }
  }, [medico])

  const salvaSulServer = () => {
    salvaDatiMedicoDaAttivare(user, medico)
    aggiornaDatiMedico(medico)
    setDatiSalvati(true)
  }

  return (
    <Card body data-testid="medico-item">
      <div className="d-flex justify-content-between mb-5">
        <SalvatoSulServer salvato={datiSalvati} />
        <div className="d-flex justify-content-end">
          <InviaPDFRiepilogo
            medico={medico}
            inviaPDF={(medico_id) => inviaPDF(medico_id)}
          />
          <StatoDichiarazione
            medico={medico}
            cambiaStatoDichiarazione={(medico_id, stato_dichiarazione) =>
              cambiaStatoDichiarazione(medico_id, stato_dichiarazione)
            }
          />
          <AttivaMedico
            medico={medico}
            rimuoviMedico={(medico_id) => rimuoviMedico(medico_id)}
          />
        </div>
      </div>
      <Row>
        <Col xs={4}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="text"
              name="email"
              id="email"
              value={medico.email}
              disabled
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="pec">
              PEC{' '}
              <small className="text-muted">(inserire pec@pec.it se mancante)</small>
            </Label>
            <Input
              type="text"
              name="pec"
              id="pec"
              value={medico.pec}
              onChange={(e) => setMedico({ ...medico, pec: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="telefono">Telefono</Label>
            <Input
              type="text"
              name="telefono"
              id="telefono"
              value={medico.telefono}
              onChange={(e) => setMedico({ ...medico, telefono: e.target.value })}
            />
          </FormGroup>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4}>
          <FormGroup>
            <Label for="nome">Nome</Label>
            <Input
              type="text"
              name="nome"
              id="nome"
              value={medico.nome}
              onChange={(e) => setMedico({ ...medico, nome: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="cognome">Cognome</Label>
            <Input
              type="text"
              name="cognome"
              id="cognome"
              value={medico.cognome}
              onChange={(e) => setMedico({ ...medico, cognome: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="data_di_nascita">Data di nascita</Label>
            <Input
              type="date"
              name="data_di_nascita"
              id="data_di_nascita"
              value={moment(medico.data_di_nascita).format('YYYY-MM-DD')}
              onChange={(e) =>
                setMedico({ ...medico, data_di_nascita: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="cf">Codice fiscale</Label>
            <Input
              type="text"
              name="cf"
              id="cf"
              value={medico.cf}
              onChange={(e) =>
                setMedico({ ...medico, cf: e.target.value.toUpperCase() })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <CodiceFiscaleInverso codiceFiscale={medico.cf} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4}>
          <FormGroup>
            <Label for="indirizzo">Indirizzo</Label>
            <Input
              type="text"
              name="indirizzo"
              id="indirizzo"
              value={medico.indirizzo}
              onChange={(e) => setMedico({ ...medico, indirizzo: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="comune">Comune</Label>
            <Input
              type="text"
              name="comune"
              id="comune"
              value={medico.comune}
              onChange={(e) => setMedico({ ...medico, comune: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="provincia">Provincia</Label>
            <Input
              type="text"
              name="provincia"
              id="provincia"
              value={medico.provincia}
              onChange={(e) =>
                setMedico({
                  ...medico,
                  provincia: e.target.value.toUpperCase().substring(0, 2),
                })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="cap">CAP</Label>
            <Input
              type="text"
              name="cap"
              id="cap"
              value={medico.cap}
              onChange={(e) => setMedico({ ...medico, cap: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="cap">Ordine di riferimento</Label>
            <Input
              type="text"
              name="ordine"
              id="ordine"
              value={medico.ordine}
              onChange={(e) => setMedico({ ...medico, ordine: e.target.value })}
            />
          </FormGroup>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4}>
          <FormGroup>
            <Label for="partita_iva">Numero partita IVA</Label>
            <Input
              type="text"
              name="partita_iva"
              id="partita_iva"
              value={medico.partita_iva}
              onChange={(e) => setMedico({ ...medico, partita_iva: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="codice_ateco">Codice ATECO</Label>
            <Input
              type="text"
              name="codice_ateco"
              id="codice_ateco"
              value={medico.ateco}
              onChange={(e) => setMedico({ ...medico, ateco: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="data_apertuta_p_iva">Data apertura partita IVA</Label>
            <Input
              type="date"
              name="data_apertura_p_iva"
              id="data_apertura_p_iva"
              value={moment(medico.data_apertura_p_iva).format('YYYY-MM-DD')}
              onChange={(e) =>
                setMedico({ ...medico, data_apertura_p_iva: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Label for="cap">Partita IVA aperta con il 5%?</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              defaultValue={medico.aliquota_ridotta ? 'si' : 'no'}
              onChange={(e) =>
                setMedico({ ...medico, aliquota_ridotta: e.target.value === 'si' })
              }
            >
              <option value="si">Si</option>
              <option value="no">No</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <br />
      <FormGroup>
        <Label for="note_admin">Note</Label>
        <Input
          type="textarea"
          name="note_admin"
          id="note_admin"
          value={medico.note_admin}
          onChange={(e) => setMedico({ ...medico, note_admin: e.target.value })}
          rows={7}
        />
      </FormGroup>
    </Card>
  )
}

export default SchedaMedico
