import React from 'react'
import { Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { apriFileCassetto } from '../../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../../app/store/fileViewer/selectors'

const Anagrafica = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingFile)

  return (
    <Button
      color="secondary"
      size="sm"
      outline
      onClick={() =>
        dispatch(apriFileCassetto('dati_generali/' + dati.cassetto.cf + '.pdf'))
      }
      disabled={dati?.cassetto?.last_sync === null || loading}
    >
      <i className="bi bi-eye"></i> Anagrafica AdE
    </Button>
  )
}

export default Anagrafica
