import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalAssegna = ({ operatori, assegna }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  return (
    <div className="d-inline">
      <Button
        color="secondary"
        outline
        className="ms-3"
        onClick={() => setModalIsOpen(true)}
        size="sm"
      >
        <i className="bi bi-arrow-right-short"></i> Assegna
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)}>
        <ModalHeader>Assegna a</ModalHeader>
        <ModalBody>
          {operatori.map((operatore) => (
            <Button
              block
              key={operatore.public_uuid}
              onClick={() => assegna(operatore.public_uuid)}
              color="secondary"
              outline
              className="mb-2 font-regular"
            >
              <i className="bi bi-arrow-right-short"></i> {operatore.email}
            </Button>
          ))}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalAssegna
