import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { listGuadagni } from '../../app/utility/QueryCommercialista'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import moment from 'moment'

const Guadagni = () => {
  const user = useSelector(selectUser)
  const [guadagni, setGuadagni] = useState([])

  useEffect(() => {
    async function popolaDati() {
      setGuadagni(await listGuadagni(user))
    }
    popolaDati()
  }, [])

  return (
    <Container className="mt-4">
      <div className="alert alert-light font-regular">
        <h3 className="font-regular">
          <i className="bi bi-bar-chart"></i> Dati fatturazione
        </h3>
        <br />
        Memosoft S.R.L
        <br />
        Partita IVA e codice fiscale 16612071007
        <br />
        Indirizzo Corso Guglielmo Marconi 33 Civitavecchia RM 00053
        <br />
        Codice SDI M5UXCR1
        <br />
        PEC memosoftsrl@legalmail.it
      </div>
      <div className="alert alert-light font-regular mt-4">
        <h3 className="font-regular">
          <i className="bi bi-graph-up-arrow"></i> Clienti
        </h3>
        <br />
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Mese</th>
              <th scope="col">Clienti da fatturare</th>
            </tr>
          </thead>
          <tbody>
            {guadagni.map((guadagno, index) => {
              return (
                <tr key={index}>
                  <td>{moment(guadagno.mese).format('MM/YYYY')}</td>
                  <td>{guadagno.utenti}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Container>
  )
}

export default Guadagni
