import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalEsitoPositivoPA } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalEsitoPositivoPA = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalEsitoPositivoPAOpen}
      toggle={() => dispatch(chiudiModalEsitoPositivoPA())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalEsitoPositivoPA())}
        className="font-regular font-grigio-scuro"
      >
        Esito positivo
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata accettata. La pubblica amministrazione ha verificato la
          fattura elettronica e ha stabilito la sua correttezza 🥳
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Ora possono succedere due cose. La pubblica amministrazione può pagarti
          oppure, se nota qualcosa che non va, inviarti delle comunicazioni tramite
          email
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalEsitoPositivoPA
