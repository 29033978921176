import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Button } from 'reactstrap'
import { queryIdrataDatiTappe } from '../../../app/utility/QueryCommercialista'
import { queryIdrataDatiTappeOperation } from '../../../app/utility/QueryOperation'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { Link } from 'react-router-dom'
import CentredLoading from '../../component/CentredLoading'
import classNames from 'classnames'
import { CSVLink } from 'react-csv'
import IlBottoneDelPercorsoDichiarativo from './IlBottoneDelPercorsoDichiarativo'

const Tappe = () => {
  const user = useSelector(selectUser)
  const [dati, setDati] = useState({
    da_capire: [],
    non_dichiaranti: [],
    quiz_da_completare: [],
    dichiarazione_da_preparare: [],
    in_attesa_di_pratiche: [],
  })
  const [loading, setLoading] = useState(true)
  const [sezioniAperte, setSezioniAperte] = useState({
    da_capire: false,
    non_dichiaranti: false,
    quiz_da_completare: false,
    dichiarazione_da_preparare: false,
    in_attesa_di_pratiche: false,
    completati: false,
  })

  useEffect(() => {
    if (user.role === 'commercialista') {
      queryIdrataDatiTappe(user, new Date().getFullYear()).then((response) => {
        setDati(response)
        setLoading(false)
      })
    } else {
      queryIdrataDatiTappeOperation(user, new Date().getFullYear()).then(
        (response) => {
          setDati(response)
          setLoading(false)
        },
      )
    }
  }, [])

  const toggleSezione = (sezione) => {
    setSezioniAperte({ ...sezioniAperte, [sezione]: !sezioniAperte[sezione] })
  }

  const csvDataAdapter = (data) =>
    data?.map((d) => ({
      Email: d.user.email,
      Telefono: d.telefono,
      UserID: d.id,
      Nome: d.nome,
      Cognome: d.cognome,
      Facile: d.dichiarazione?.facile ? 'Si' : 'No',
      Responsabile: d.dichiarazione?.responsabile,
    }))

  const da_capire = () =>
    dati?.da_capire?.map((d) => {
      if (d === null) return null
      return (
        <Col key={d.id} xs={2}>
          <Link to={'/shared/user-profile/' + d.id}>
            <span className="badge bg-light mb-3 font-regular">
              {d.nome?.substring(0, 1).toUpperCase()} {d.cognome}
            </span>
          </Link>
        </Col>
      )
    })

  const non_dichiaranti = () =>
    dati?.non_dichiaranti?.map((d) => {
      if (d === null) return null
      return (
        <Col key={d.id} xs={2}>
          <Link to={'/shared/user-profile/' + d.id}>
            <span className="badge bg-light mb-3 font-regular">
              {d.nome.substring(0, 1).toUpperCase()} {d.cognome}
            </span>
          </Link>
        </Col>
      )
    })

  const quiz_da_completare = () =>
    dati?.quiz_da_completare?.map((d) => {
      if (d === null) return null
      return (
        <Col key={d.id} xs={2}>
          <Link to={'/shared/user-profile/' + d.id}>
            <span className="badge bg-light mb-3 font-regular">
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  { 'text-success': d.dichiarazione?.facile },
                  { 'text-warning': !d.dichiarazione?.facile },
                )}
              ></i>{' '}
              {d.nome.substring(0, 1).toUpperCase()} {d.cognome}
              <br />
              {d.dichiarazione?.responsabile?.substring(0, 9)}
            </span>
          </Link>
        </Col>
      )
    })

  const dichiarazione_da_preparare = () =>
    dati?.dichiarazione_da_preparare?.map((d) => {
      if (d === null) return null
      return (
        <Col key={d.id} xs={2}>
          <Link to={'/shared/user-profile/' + d.id}>
            <span className="badge bg-light mb-3 font-regular">
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  { 'text-success': d.dichiarazione?.facile },
                  { 'text-warning': !d.dichiarazione?.facile },
                )}
              ></i>{' '}
              {d.nome.substring(0, 1).toUpperCase()} {d.cognome}
              <br />
              {d.dichiarazione?.responsabile?.substring(0, 9)}
            </span>
          </Link>
        </Col>
      )
    })

  const in_attesa_di_pratiche = () =>
    dati?.in_attesa_di_pratiche?.map((d) => {
      if (d === null) return null
      return (
        <Col key={d.id} xs={2}>
          <Link to={'/shared/user-profile/' + d.id}>
            <span className="badge bg-light mb-3 font-regular">
              {d.nome.substring(0, 1).toUpperCase()} {d.cognome}{' '}
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  { 'text-success': d.dichiarazione?.step_enpam_completato },
                  { 'text-warning': !d.dichiarazione?.step_enpam_completato },
                )}
              ></i>{' '}
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  {
                    'text-success': d?.tappaF24?.saldo_acconto_completata,
                  },
                  {
                    'text-warning': !d?.tappaF24?.saldo_acconto_completata,
                  },
                )}
              ></i>{' '}
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  { 'text-success': d?.tappaF24?.completata },
                  { 'text-warning': !d?.tappaF24?.completata },
                )}
              ></i>{' '}
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  { 'text-success': d?.tappaModelloRedditi?.completata },
                  {
                    'text-warning': !d?.tappaModelloRedditi?.completata,
                  },
                )}
              ></i>{' '}
              <br />
              {d.dichiarazione?.responsabile?.substring(0, 9)}
            </span>
          </Link>
        </Col>
      )
    })

  const completati = () =>
    dati?.completati?.map((d) => {
      if (d === null) return null
      return (
        <Col key={d.id} xs={2}>
          <Link className="font-regular" to={'/shared/user-profile/' + d.id}>
            <span className="badge bg-light mb-3 font-regular">
              <i
                className={classNames(
                  'bi bi-circle-fill',
                  { 'text-success': d.dichiarazione?.facile },
                  { 'text-warning': !d.dichiarazione?.facile },
                )}
              ></i>{' '}
              {d.nome.substring(0, 1).toUpperCase()} {d.cognome}
              <br />
              {d.dichiarazione?.responsabile?.substring(0, 9)}
            </span>
          </Link>
        </Col>
      )
    })

  const freccia = (
    <center>
      <span className="badge bg-light mb-3">
        <i className="bi bi-arrow-down-short font-regular"></i>
      </span>
    </center>
  )

  return (
    <Container fluid className="mt-4">
      {loading ? (
        <CentredLoading />
      ) : (
        <>
          <IlBottoneDelPercorsoDichiarativo
            percorsoAperto={dati?.percorso_dichiarativo_aperto}
          />
          <Card body className="mb-3">
            <div className="d-flex justify-content-between">
              <h5 className="font-regular text-warning">
                {dati?.da_capire?.length} Da capire
              </h5>
              <div>
                <CSVLink data={csvDataAdapter(dati?.da_capire)}>
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() => toggleSezione('da_capire')}
                >
                  {sezioniAperte.da_capire ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </Button>
              </div>
            </div>

            {sezioniAperte.da_capire && <Row className="mt-4">{da_capire()}</Row>}
          </Card>
          {freccia}
          <Card body className="mb-3">
            <div className="d-flex justify-content-between">
              <h5 className="font-regular text-danger">
                {dati?.non_dichiaranti?.length} Non dichiaranti
              </h5>
              <div>
                <CSVLink data={csvDataAdapter(dati?.non_dichiaranti)}>
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() => toggleSezione('non_dichiaranti')}
                >
                  {sezioniAperte.non_dichiaranti ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </Button>
              </div>
            </div>

            {sezioniAperte.non_dichiaranti && (
              <Row className="mt-4">{non_dichiaranti()}</Row>
            )}
          </Card>
          {freccia}
          <Card body className="mb-3">
            <div className="d-flex justify-content-between">
              <h5 className="font-regular">
                {dati?.quiz_da_completare?.length} Stanno compilando il quiz
              </h5>
              <div>
                <CSVLink data={csvDataAdapter(dati?.quiz_da_completare)}>
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() => toggleSezione('quiz_da_completare')}
                >
                  {sezioniAperte.quiz_da_completare ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </Button>
              </div>
            </div>
            {sezioniAperte.quiz_da_completare && (
              <Row className="mt-4">{quiz_da_completare()}</Row>
            )}
          </Card>
          {freccia}
          <Card body className="mb-3">
            <div className="d-flex justify-content-between">
              <h5 className="font-regular">
                {dati?.dichiarazione_da_preparare?.length} Dichiarazione da preparare
              </h5>
              <div>
                <CSVLink data={csvDataAdapter(dati?.dichiarazione_da_preparare)}>
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() => toggleSezione('dichiarazione_da_preparare')}
                >
                  {sezioniAperte.dichiarazione_da_preparare ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </Button>
              </div>
            </div>
            {sezioniAperte.dichiarazione_da_preparare && (
              <Row className="mt-4">{dichiarazione_da_preparare()}</Row>
            )}
          </Card>
          {freccia}
          <Card body className="mb-3">
            <div className="d-flex justify-content-between">
              <h5 className="font-regular">
                {dati?.in_attesa_di_pratiche?.length} In attesa di pratiche
              </h5>
              <div>
                <CSVLink data={csvDataAdapter(dati?.in_attesa_di_pratiche)}>
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() => toggleSezione('in_attesa_di_pratiche')}
                >
                  {sezioniAperte.in_attesa_di_pratiche ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </Button>
              </div>
            </div>
            {sezioniAperte.in_attesa_di_pratiche && (
              <Row className="mt-4">
                <Col xs={12}>
                  <i className="bi bi-circle-fill text-warning"></i>{' '}
                  <small className="text-muted">Modello D</small>{' '}
                  <i className="bi bi-circle-fill text-warning"></i>{' '}
                  <small className="text-muted">Saldo e primo acconto</small>{' '}
                  <i className="bi bi-circle-fill text-warning"></i>{' '}
                  <small className="text-muted">Secondo acconto</small>{' '}
                  <i className="bi bi-circle-fill text-warning"></i>{' '}
                  <small className="text-muted">Modello unico trasmesso</small>
                  <br />
                  <br />
                  <br />
                </Col>
                {in_attesa_di_pratiche()}
              </Row>
            )}
          </Card>
          {freccia}
          <Card body className="mb-3">
            <div className="d-flex justify-content-between">
              <h5 className="font-regular text-success">
                {dati?.completati?.length} Completati
              </h5>
              <div>
                <CSVLink data={csvDataAdapter(dati?.completati)}>
                  <Button color="secondary" outline size="sm" className="me-2">
                    <i className="bi bi-filetype-csv"></i> Scarica
                  </Button>
                </CSVLink>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() => toggleSezione('completati')}
                >
                  {sezioniAperte.completati ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </Button>
              </div>
            </div>
            {sezioniAperte.completati && <Row className="mt-4">{completati()}</Row>}
          </Card>
        </>
      )}
    </Container>
  )
}

export default Tappe
