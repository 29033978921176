import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import moment from 'moment'

export const DataIncassoBlocchetto = ({ dataIncasso, modificaData }) => (
  <FormGroup>
    <Label for="exampleEmail">Data di incasso*</Label>
    <Input
      type="date"
      value={moment(dataIncasso).format('YYYY-MM-DD')}
      onChange={(e) => modificaData(e.target.value)}
    />
  </FormGroup>
)

export default DataIncassoBlocchetto
