import React, { useEffect, useState } from 'react'
import { Container, Card, Button } from 'reactstrap'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { queryIdrataDownload } from '../../app/utility/QueryCommercialista'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import CentredLoading from '../component/CentredLoading'
import { csvAdapterCatasto, csvAdapterAppunti } from './csvAdapters'

const Dati = () => {
  const [loading, setLoading] = useState(true)
  const user = useSelector(selectUser)
  const [data, setData] = useState([])

  useEffect(() => {
    queryIdrataDownload(user).then((response) => {
      setData(response.dati)
      setLoading(false)
    })
  }, [])

  return (
    <Container>
      <Card body className="mt-4">
        <h2>
          <i className="bi bi-database-fill-down text-primary"></i> Dati disponibili
          per il download
        </h2>
      </Card>
      <Card body className="mt-3">
        {loading ? (
          <div style={{ minHeight: '200px' }}>
            <CentredLoading />
          </div>
        ) : (
          <div>
            <Button color="secondary" outline size="sm" className="me-2" disabled>
              <CSVLink
                data={csvAdapterCatasto([])}
                filename={
                  'terreni_fabbricati_' + moment().format('YYYY_MM_DD') + '.csv'
                }
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <i className="bi bi-filetype-csv"></i> Anagrafiche
              </CSVLink>
            </Button>
            <Button color="secondary" outline size="sm" className="me-2">
              <CSVLink
                data={csvAdapterCatasto(data)}
                filename={
                  'terreni_fabbricati_' + moment().format('YYYY_MM_DD') + '.csv'
                }
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <i className="bi bi-filetype-csv"></i> Terreni e fabbricati
              </CSVLink>
            </Button>
            <Button color="secondary" outline size="sm" className="me-2" disabled>
              <CSVLink
                data={csvAdapterCatasto([])}
                filename={
                  'terreni_fabbricati_' + moment().format('YYYY_MM_DD') + '.csv'
                }
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <i className="bi bi-filetype-csv"></i> Documenti fiscali
              </CSVLink>
            </Button>
            <Button color="secondary" outline size="sm" className="me-2">
              <CSVLink
                data={csvAdapterAppunti(data)}
                filename={'appunti_' + moment().format('YYYY_MM_DD') + '.csv'}
                style={{ color: 'inherit', textDecoration: 'none' }}
                separator={';'}
              >
                <i className="bi bi-filetype-csv"></i> Appunti
              </CSVLink>
            </Button>
          </div>
        )}
      </Card>
    </Container>
  )
}

export default Dati
