import React from 'react'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { superUserLoginAction } from '../../../../../app/store/user/actions'
import { useSelector } from 'react-redux'
import {
  useMedicoId,
  useAnagrafica,
} from '../../../../../app/store/gestionaleUtente/selectors'

const SwitchToUser = () => {
  const dispatch = useDispatch()
  const medico_id = useSelector(useMedicoId)
  const anagrafica = useSelector(useAnagrafica)

  const switchToUser = () => {
    dispatch(superUserLoginAction(medico_id))
  }

  return (
    <Button
      color="primary"
      size="sm"
      onClick={() => switchToUser()}
      disabled={anagrafica.stato === 'pending'}
    >
      <i className="bi bi-tv"></i> Profilo medico
    </Button>
  )
}

export default SwitchToUser
