import { createSlice } from '@reduxjs/toolkit'
import { emptyState } from './costants'
import * as actions from './actions'
import { sortIncassatoBySimilarity } from '../../utility/Miscellanea'
import currency from 'currency.js'

const initialState = {
  ...emptyState,
}

const gestionaleUtenteSlice = createSlice({
  name: 'gestionaleUtente',
  initialState,
  reducers: {
    modificaAnno(state, action) {
      state.anno = action.payload
    },
    modificaMedico(state, action) {
      state.medico_id = action.payload
    },
    modificaAnagrafica(state, action) {
      state.anagrafica = action.payload
    },
    modificaNote(state, action) {
      state.anagrafica.note_admin = action.payload
    },
    caricaCertificatoAttribuzione(state, action) {
      state.certificato_attribuzione = action.payload
    },
    caricaFileCondiviso(state, action) {
      state.file_condivisi.push(action.payload)
    },
    caricaFilePrivato(state, action) {
      state.file_privati.push(action.payload)
    },
    deleteFileCondiviso(state, action) {
      state.file_condivisi = state.file_condivisi.filter(
        (file) => file.id !== action.payload,
      )
    },
    deleteFilePrivato(state, action) {
      state.file_privati = state.file_privati.filter(
        (file) => file.id !== action.payload,
      )
    },
    selectDocumento(state, action) {
      if (action.payload.fattura.selected) {
        state.incassato[action.payload.tipologia].forEach((fattura) => {
          fattura.id === action.payload.fattura.id && (fattura.selected = false)
        })
        state.somma = currency(state.somma).subtract(
          action.payload.fattura.importo,
        ).value
        state.trattenuta_enpam = currency(state.trattenuta_enpam).subtract(
          action.payload.fattura.trattenuta_enpam,
        ).value
      } else {
        state.incassato[action.payload.tipologia].forEach((fattura) => {
          fattura.id === action.payload.fattura.id && (fattura.selected = true)
        })
        state.somma = currency(state.somma).add(action.payload.fattura.importo).value
        state.trattenuta_enpam = currency(state.trattenuta_enpam).add(
          action.payload.fattura.trattenuta_enpam,
        ).value
      }
    },
    sortByNumber(state) {
      state.incassato?.fattura_fiscomed?.sort((a, b) => a.numero - b.numero)
      state.incassato?.fattura?.sort((a, b) => a.numero - b.numero)
      state.incassato?.cedolino?.sort((a, b) => a.numero - b.numero)
      state.incassato?.borsa_mmg?.sort((a, b) => a.numero - b.numero)
      state.incassato?.borsa_specializzazione?.sort((a, b) => a.numero - b.numero)
      state.incassato?.prestazione_occasionale?.sort((a, b) => a.numero - b.numero)
    },
    sortBySimilarity(state) {
      state.incassato = sortIncassatoBySimilarity(state.incassato)
    },
    deseleziona(state) {
      state.incassato?.fattura_fiscomed?.forEach((fattura) => {
        fattura.selected = false
      })
      state.incassato?.fattura?.forEach((fattura) => {
        fattura.selected = false
      })
      state.incassato?.cedolino?.forEach((fattura) => {
        fattura.selected = false
      })
      state.incassato?.borsa_mmg?.forEach((fattura) => {
        fattura.selected = false
      })
      state.incassato?.borsa_specializzazione?.forEach((fattura) => {
        fattura.selected = false
      })
      state.incassato?.prestazione_occasionale?.forEach((fattura) => {
        fattura.selected = false
      })
      state.somma = 0
      state.trattenuta_enpam = 0
    },
    aggiornaDocumento(state, action) {
      state.incassato[action.payload.tipologiaDaRimuovere] = state.incassato[
        action.payload.tipologiaDaRimuovere
      ].filter((doc) => doc.id !== action.payload.documento.id)
      state.incassato[action.payload.documento.tipologia].push(
        action.payload.documento,
      )
      if (action.payload.sortedByNumber) {
        state.incassato[action.payload.documento.tipologia].sort(
          (a, b) => a.numero - b.numero,
        )
      } else {
        state.incassato = sortIncassatoBySimilarity(state.incassato)
      }
    },
    aggiornaF24(state, action) {
      state.dichiarazione.f24s = action.payload.f24
    },
    modificaNoteDichiarazione(state, action) {
      state.dichiarazione.note = action.payload
    },
    modificaResponsabile(state, action) {
      state.dichiarazione.responsabile = action.payload
    },
    modificaFacile(state, action) {
      state.dichiarazione.facile = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.idrata.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.idrata.fulfilled, (state, action) => {
      state.anno = action.payload.anno
      state.loading = false
      state.anagrafica = action.payload.responseBody.anagrafica
      state.certificato_attribuzione =
        action.payload.responseBody.certificato_attribuzione
      state.file_condivisi = action.payload.responseBody.file_condivisi
      state.file_privati = action.payload.responseBody.file_privati
      state.incassato = sortIncassatoBySimilarity(
        action.payload.responseBody.incassato,
      )
      state.dichiarazione = action.payload.responseBody.dichiarazione
      state.medico_id = action.payload.responseBody.medico_id
      state.cassetto = action.payload.responseBody.cassetto
      state.limiti = action.payload.responseBody.limiti
      state.report_tassazione = action.payload.responseBody.report_tassazione
    })
    builder.addCase(actions.salvaImportoModelloD.fulfilled, (state, action) => {
      state.dichiarazione.step_enpam_completato = true
      state.dichiarazione.importo_modello_d = action.payload
    })
    builder.addCase(actions.completaSaldoENotifica.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.completaSaldoENotifica.fulfilled, (state, action) => {
      state.dichiarazione = action.payload.dichiarazione
      state.loading = false
    })
    builder.addCase(actions.completaAccontoENotifica.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.completaAccontoENotifica.fulfilled, (state, action) => {
      state.dichiarazione = action.payload.dichiarazione
      state.loading = false
    })
    builder.addCase(actions.cambiaStatoDichiarazione.fulfilled, (state, action) => {
      state.dichiarazione.attivo = action.payload
    })
    builder.addCase(actions.cambiaStatoFatto.fulfilled, (state, action) => {
      state.dichiarazione.fatto = !state.dichiarazione.fatto
    })
    builder.addCase(actions.cambiaUtente.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.cambiaUtente.fulfilled, (state, action) => {
      state.loading = false
      state.anagrafica = action.payload.anagrafica
      state.certificato_attribuzione = action.payload.certificato_attribuzione
      state.file_condivisi = action.payload.file_condivisi
      state.file_privati = action.payload.file_privati
      state.incassato = sortIncassatoBySimilarity(action.payload.incassato)
      state.dichiarazione = action.payload.dichiarazione
      state.medico_id = action.payload.medico_id
      state.cassetto = action.payload.cassetto
    })
    builder.addCase(actions.cambiaEmail.fulfilled, (state, action) => {
      state.anagrafica.email = action.payload
    })
    builder.addCase(actions.uploadUnico.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.uploadUnico.fulfilled, (state, action) => {
      state.dichiarazione = action.payload.dichiarazione
      state.loading = false
    })
    builder.addCase(actions.deleteUnico.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.deleteUnico.fulfilled, (state, action) => {
      state.dichiarazione = action.payload.dichiarazione
      state.loading = false
    })
    builder.addCase(actions.cambiaFiscoStatus.fulfilled, (state, action) => {
      state.anagrafica.stato = action.payload
    })
    builder.addCase(actions.toggleActive.fulfilled, (state, action) => {
      state.anagrafica.active = !state.anagrafica.active
    })
    builder.addCase(actions.sincronizzaCassetto.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(actions.sincronizzaCassetto.fulfilled, (state, action) => {
      state.loading = false
      state.cassetto.to_sync = true
      state.cassetto.sections_to_sync = action.payload.sections
    })
    builder.addCase(actions.toggleControllato.fulfilled, (state, action) => {
      state.incassato[action.payload.tipologia].forEach((fattura) => {
        fattura.id === action.payload.id &&
          (fattura.controllato = fattura.controllato ? null : true)
      })
    })
    builder.addCase(actions.toggleCu.fulfilled, (state, action) => {
      state.cassetto.cu.forEach((cu) => {
        cu.nome_file === action.payload && (cu.controllato = !cu.controllato)
      })
    })
  },
})

export const {
  modificaAnno,
  modificaMedico,
  modificaAnagrafica,
  modificaNote,
  caricaCertificatoAttribuzione,
  caricaFileCondiviso,
  caricaFilePrivato,
  deleteFileCondiviso,
  deleteFilePrivato,
  selectDocumento,
  deseleziona,
  sortByNumber,
  sortBySimilarity,
  aggiornaDocumento,
  aggiornaF24,
  modificaNoteDichiarazione,
  modificaResponsabile,
  modificaFacile,
} = gestionaleUtenteSlice.actions

export default gestionaleUtenteSlice.reducer
