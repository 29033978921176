import React from 'react'
import classnames from 'classnames'

const SalvatoSulServer = ({ salvato, floatEnd }) => {
  return (
    <small className={classnames('text-muted', { 'float-end': floatEnd })}>
      {salvato ? (
        <>
          Dati salvati <i className="bi bi-check text-success"></i>
        </>
      ) : (
        <>
          Dati non salvati <i className="bi bi-x text-danger"></i>
        </>
      )}
    </small>
  )
}

export default SalvatoSulServer
