import React from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import moment from 'moment'
import { selectFileManager } from '../../../app/store/fileManager/selectors'
import { useDispatch } from 'react-redux'
import { deleteFileBlocchetto } from '../../../app/store/fileManager/actions'
import { apriFileBlocchetto } from '../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../app/store/fileViewer/selectors'
import classNames from 'classnames'

export const TabellaBorse = ({ setDocumentToUpdate }) => {
  const fileManager = useSelector(selectFileManager)
  const dispatch = useDispatch()
  const loadingFile = useSelector(selectLoadingFile)

  const tabellaBorseMMG = fileManager?.grouped_documents?.borsa_mmg[
    fileManager.anno
  ]?.map((element, i) => (
    <tr key={i}>
      <td>MMG</td>
      <td>{moment(element.data_incasso).format('MM')}</td>
      <td>
        <span
          className={classNames({ 'testo-blu': loadingFile === false })}
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(apriFileBlocchetto(element.id))}
        >
          {element.nome_originale_file.substring(0, 50)}
        </span>
      </td>
      <td className="font-regular">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() => setDocumentToUpdate(element)}
        >
          <i className="bi bi-pencil"></i>
        </center>
      </td>
      <td className="text-danger">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() =>
            dispatch(
              deleteFileBlocchetto({
                id_documento: element.id,
                tipologia: 'borsa_mmg',
              }),
            )
          }
        >
          <i className="bi bi-trash"></i>
        </center>
      </td>
    </tr>
  ))

  const tabellaBorseSpec = fileManager?.grouped_documents?.borsa_specializzazione[
    fileManager.anno
  ]?.map((element, i) => (
    <tr key={i}>
      <td>Specializzazione</td>
      <td>{moment(element.data_incasso).format('MM')}</td>
      <td>
        <span
          className={classNames({ 'testo-blu': loadingFile === false })}
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(apriFileBlocchetto(element.id))}
        >
          {element.nome_originale_file.substring(0, 50)}
        </span>
      </td>
      <td className="font-regular">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() => setDocumentToUpdate(element)}
        >
          <i className="bi bi-pencil"></i>
        </center>
      </td>
      <td className="text-danger">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() =>
            dispatch(
              deleteFileBlocchetto({
                id_documento: element.id,
                tipologia: 'borsa_specializzazione',
              }),
            )
          }
        >
          <i className="bi bi-trash"></i>
        </center>
      </td>
    </tr>
  ))

  return (
    <>
      {fileManager?.grouped_documents?.borsa_specializzazione[fileManager.anno]
        ?.length === undefined &&
      fileManager?.grouped_documents?.borsa_mmg[fileManager.anno]?.length ===
        undefined ? (
        <div className="alert alert-info mt-5" role="alert">
          <i className="bi bi-info-circle"></i> Non hai importato nessuna borsa con
          questa data d'incasso
        </div>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca mt-5">
          <thead>
            <tr>
              <th className="h6 font-regular">Tipo</th>
              <th className="h6 font-regular">Mese</th>
              <th className="h6 font-regular">File</th>
              <th className="h6 font-regular"></th>
              <th className="h6 font-regular"></th>
            </tr>
          </thead>
          <tbody>{tabellaBorseMMG}</tbody>
          <tbody>{tabellaBorseSpec}</tbody>
        </Table>
      )}
    </>
  )
}

export default TabellaBorse
