import request from 'superagent'

export const fileCondivisioPresignedURL = async (user, file_id) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/shared/file-manager/download`)
      .send({ token: user.auth_token, file_id })
    return response.body
  } catch (e) {
    return null
  }
}

export const fileBlocchettoPresignedURL = async (user, id_documento) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/download`)
      .send({ token: user.auth_token, id_documento })
    return response.body
  } catch (e) {
    return null
  }
}

export const fatturaCartaceaPresignedURL = async (user, id_documento) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/fattura-cartacea/presigned-url-pdf`)
      .send({ token: user.auth_token, idFattura: id_documento })
    return response.body
  } catch (e) {
    return null
  }
}

export const fatturaElettronicaBASE64 = async (user, id_documento) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/fattura-elettronica/fattura-di-cortesia`,
      )
      .send({ token: user.auth_token, idFattura: id_documento })
    return response.body
  } catch (e) {
    return null
  }
}

export const unicoPresignedURL = async (user, id_documento) => {
  try {
    const response = await request
      .post(`${process.env.REACT_APP_API_URL}/dichiarazione/scarica-modello`)
      .send({ token: user.auth_token, idModello: id_documento })
    return response.body
  } catch (e) {
    return null
  }
}

export const fileCassettoPresignedURL = async (user, aws_path) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/download-file-cassetto`,
      )
      .send({ token: user.auth_token, aws_path })
    return response.body
  } catch (e) {
    return null
  }
}

export const fatturaAdEPresignedURL = async (user, aws_path) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/shared/gestionale-utente/download-fattura-ade`,
      )
      .send({ token: user.auth_token, aws_path })
    return response.body
  } catch (e) {
    return null
  }
}

export const fatturaBusinessBASE64 = async (user, file_name) => {
  try {
    const response = await request
      .post(
        `${process.env.REACT_APP_API_URL}/contabile/gestore-fatture/download-fattura`,
      )
      .send({ token: user.auth_token, file_name })
    return response.body
  } catch (e) {
    return null
  }
}
