import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsyncWithServer } from '../../../app/store/user/selectors'
import { updateUserInfo } from '../../../app/store/user/actions'
import OnlyBigScreen from './OnlyBigScreen'

const SynchronizedUserDataComponent = () => {
  const syncWithServer = useSelector(selectIsyncWithServer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (syncWithServer === false) {
      dispatch(updateUserInfo())
    }
  }, [])

  return <>{syncWithServer && <OnlyBigScreen />}</>
}

export default SynchronizedUserDataComponent
