import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  fileCondivisioPresignedURL,
  fileBlocchettoPresignedURL,
  fatturaCartaceaPresignedURL,
  fatturaElettronicaBASE64,
  unicoPresignedURL,
  fileCassettoPresignedURL,
  fatturaAdEPresignedURL,
  fatturaBusinessBASE64,
} from '../../utility/QueryFileViewer'
import {
  scaricaFileAllegatoUtente,
  scaricaF24DaServer,
} from '../../utility/QueryDichiarazione'

export const apriFileCondiviso = createAsyncThunk(
  'fileViewer/apriFileCondiviso',
  async (fileID, { getState }) => {
    const state = getState()
    const responseBody = await fileCondivisioPresignedURL(state.user.user, fileID)
    return responseBody
  },
)

export const apriFileBlocchetto = createAsyncThunk(
  'fileViewer/apriFileBlocchetto',
  async (fileID, { getState }) => {
    const state = getState()
    const responseBody = await fileBlocchettoPresignedURL(state.user.user, fileID)
    return responseBody
  },
)

export const apriFatturaCartacea = createAsyncThunk(
  'fileViewer/apriFatturaCartacea',
  async (fileID, { getState }) => {
    const state = getState()
    const responseBody = await fatturaCartaceaPresignedURL(state.user.user, fileID)
    return responseBody
  },
)

export const apriFatturaElettronica = createAsyncThunk(
  'fileViewer/apriFatturaElettronica',
  async (fileID, { getState }) => {
    const state = getState()
    const responseBody = await fatturaElettronicaBASE64(state.user.user, fileID)
    return responseBody
  },
)

// capire quando viene usato perchè non mi è chiaro
export const apriFileDichiarazioneAllegatoDaUtente = createAsyncThunk(
  'fileViewer/apriFileDichiarazioneAllegatoDaUtente',
  async ({ nome, uuid }, { getState }) => {
    const state = getState()
    const responseBody = await scaricaFileAllegatoUtente(state.user.user, uuid)
    return {
      url: responseBody.url,
      content_type: responseBody.content_type,
      nome_file: nome,
    }
  },
)

export const apriF24 = createAsyncThunk(
  'fileViewer/apriF24',
  async (fileID, { getState }) => {
    const state = getState()
    const responseBody = await scaricaF24DaServer(state.user.user, fileID)
    return responseBody
  },
)

export const apriUnico = createAsyncThunk(
  'fileViewer/apriUnico',
  async (fileID, { getState }) => {
    const state = getState()
    const responseBody = await unicoPresignedURL(state.user.user, fileID)
    return responseBody
  },
)

export const apriFileCassetto = createAsyncThunk(
  'fileViewer/apriFileCassetto',
  async (aws_path, { getState }) => {
    const state = getState()
    const responseBody = await fileCassettoPresignedURL(state.user.user, aws_path)
    return responseBody
  },
)

export const apriFatturaAdE = createAsyncThunk(
  'fileViewer/apriFatturaAdE',
  async (aws_path, { getState }) => {
    const state = getState()
    const responseBody = await fatturaAdEPresignedURL(state.user.user, aws_path)
    return responseBody
  },
)

export const apriXMLBusiness = createAsyncThunk(
  'fileViewer/apriXMLBusiness',
  async (nomeFile, { getState }) => {
    const state = getState()
    const responseBody = await fatturaBusinessBASE64(state.user.user, nomeFile)
    return responseBody
  },
)
