import React, { useEffect } from 'react'
import { Container, Col, Row } from 'reactstrap'
import HeaderFattura from './header/HeaderFattura'
import PannelloContainer from './pannello/PannelloContainer'
import PreviewContainer from './preview/PreviewContainer'
import CardCaricamento from './componenti/CardCaricamento'
import { useParams } from 'react-router-dom'
import HeaderNDCpa from './header/HeaderNDCpa'
import HeaderNDCpr from './header/HeaderNDCpr'
import HeaderNDDpr from './header/HeaderNDDpr'
import HeaderRettificaPr from './header/HeaderRettificaPr'
import { Helmet } from 'react-helmet'

import {
  idrataNDVariazionePa,
  idrataNDVariazionePr,
  idrataFatturaDiRettifica,
  idrata,
} from '../../app/store/fiscomed/actions'
import { aggiornaTipologiaFattura } from '../../app/store/fiscomed/slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsLoadingFiscomed } from '../../app/store/fiscomed/selectors'

const FatturaContainer = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoadingFiscomed)
  const { tipologia, idFatturaDiRiferimento } = useParams()

  useEffect(() => {
    if (tipologia === 'nota-di-credito-pa') {
      dispatch(aggiornaTipologiaFattura('Nota di credito pa'))
      dispatch(idrataNDVariazionePa(idFatturaDiRiferimento))
      return
    }
    if (tipologia === 'nota-di-credito-pr') {
      dispatch(aggiornaTipologiaFattura('Nota di credito pr'))
      dispatch(idrataNDVariazionePr(idFatturaDiRiferimento))
      return
    }
    if (tipologia === 'nota-di-debito-pr') {
      dispatch(aggiornaTipologiaFattura('Nota di debito pr'))
      dispatch(idrataNDVariazionePr(idFatturaDiRiferimento))
      return
    }
    if (tipologia === 'rettifica-pr') {
      dispatch(aggiornaTipologiaFattura('Rettifica pr'))
      dispatch(idrataFatturaDiRettifica(idFatturaDiRiferimento))
      return
    }
    if (tipologia === 'nota-di-credito-pa') {
      dispatch(aggiornaTipologiaFattura('Nota di credito PA'))
      return
    }
    if (tipologia === 'nota-di-debito-pa') {
      dispatch(aggiornaTipologiaFattura('Nota di debito PA'))
      return
    }
    if (tipologia === 'autofattura') {
      dispatch(aggiornaTipologiaFattura('Autofattura'))
      return
    }

    dispatch(aggiornaTipologiaFattura('Fattura elettronica'))
    dispatch(idrata())
  }, [])

  return (
    <>
      <Helmet>
        <title>Crea fattura</title>
        <meta property="title" content="Crea fattura" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {isLoading ? (
        <CardCaricamento />
      ) : (
        <Container>
          {tipologia === 'fattura' && <HeaderFattura />}
          {tipologia === 'nota-di-credito-pa' && <HeaderNDCpa />}
          {tipologia === 'nota-di-credito-pr' && <HeaderNDCpr />}
          {tipologia === 'nota-di-debito-pr' && <HeaderNDDpr />}
          {tipologia === 'rettifica-pr' && <HeaderRettificaPr />}

          <Row>
            <Col md="4">
              <PannelloContainer />
            </Col>
            <Col md="8">
              <PreviewContainer />
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default FatturaContainer
