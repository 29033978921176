import React, { useEffect, useState } from 'react'
import {
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  FormGroup,
  Input,
  Spinner,
  Card,
  Row,
  Col,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import { selectUser } from '../../../../../app/store/user/selectors'
import {
  uploadDocumentoCondiviso,
  deleteDocumentoCondiviso,
} from '../../../../../app/utility/QueryShared'
import {
  caricaFileCondiviso,
  caricaFilePrivato,
  deleteFileCondiviso,
  deleteFilePrivato,
} from '../../../../../app/store/gestionaleUtente/slice'
import moment from 'moment'
import { apriFileCondiviso } from '../../../../../app/store/fileViewer/actions'

const OffacanvasFile = ({ tipologia }) => {
  const [offcanvasOpen, setOffcanvasOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [nota, setNota] = useState('')
  const dati = useSelector(selectGestionaleUtente)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const [fileCondivisi, setFileCondivisi] = useState([])

  useEffect(() => {
    if (tipologia === 'file_condiviso') {
      setFileCondivisi(dati.file_condivisi)
      return
    }
    setFileCondivisi(dati.file_privati)
  }, [dati, tipologia])

  const uploadFile = () => {
    setLoading(true)
    uploadDocumentoCondiviso(user, {
      file: fileToUpload,
      medico_id: dati.medico_id,
      nota: nota,
      file_type: tipologia,
    }).then((bodyResponse) => {
      const fileToAdd = {
        nome_file: bodyResponse.nome_file,
        id: bodyResponse.id,
        created_at: bodyResponse.created_at,
        owner: bodyResponse.owner,
        nota: bodyResponse.nota,
      }
      if (tipologia === 'file_condiviso') {
        dispatch(caricaFileCondiviso(fileToAdd))
      } else {
        dispatch(caricaFilePrivato(fileToAdd))
      }
    })
    setFileToUpload(null)
    setNota('')
    setLoading(false)
  }

  const deleteFile = (file) => {
    setLoading(true)
    if (confirm('Sei sicuro di voler eliminare il file?')) {
      deleteDocumentoCondiviso(user, file.id).then(() => {
        setLoading(false)
        if (tipologia === 'file_condiviso') {
          dispatch(deleteFileCondiviso(file.id))
          return
        }
        dispatch(deleteFilePrivato(file.id))
      })
    }
  }

  return (
    <>
      <Button
        color="secondary"
        size="sm"
        className="me-2 position-relative"
        outline
        onClick={() => setOffcanvasOpen(true)}
      >
        {tipologia === 'file_condiviso' ? (
          <>
            <i className="bi bi-files"></i> File condivisi{' '}
            {dati?.file_condivisi?.length}
          </>
        ) : (
          <>
            <i className="bi bi-file-earmark-lock"></i> File personali{' '}
            {dati?.file_privati?.length}
          </>
        )}
      </Button>
      <Offcanvas
        direction="start"
        scrollable
        isOpen={offcanvasOpen}
        className="w-50"
        toggle={() => setOffcanvasOpen(!offcanvasOpen)}
      >
        <OffcanvasHeader toggle={() => setOffcanvasOpen(!offcanvasOpen)}>
          {tipologia === 'file_condiviso' ? 'File condivisi' : 'File personali'}
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="alert alert-secondary mb-5">
            <FormGroup className="d-flex">
              <Input
                type="file"
                name="file"
                id="file"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <Button
                color="primary"
                className="ms-2"
                onClick={() => uploadFile()}
                disabled={fileToUpload === null || nota === ''}
              >
                {loading ? <Spinner size="sm" /> : 'Upload'}
              </Button>
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="nome"
                id="nome"
                placeholder="Descrizione del file"
                value={nota}
                onChange={(e) => setNota(e.target.value)}
              />
            </FormGroup>
          </div>
          {fileCondivisi.map((file) => (
            <Card body key={file.id} className="card-bordo-visibile mb-3">
              <Row className="font-regular">
                <Col xs={8}>
                  <span className="text-primary">{file.nome_file}</span> caricato dal{' '}
                  {file.owner} il {moment(file.created_at).format('DD/MM/YYYY')}
                  <br />
                  <br />
                  <span className="text-muted">{file.nota}</span>
                </Col>
                <Col xs={4}>
                  <Button
                    color="primary"
                    className="float-end"
                    onClick={() => dispatch(apriFileCondiviso(file.id))}
                  >
                    <i className="bi bi-eye"></i> Visualizza
                  </Button>
                  {file.owner === 'commercialista' && (
                    <Button
                      color="danger"
                      className="float-end me-2"
                      onClick={() => deleteFile(file)}
                      disabled={loading}
                    >
                      <i className="bi bi-trash3"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          ))}
        </OffcanvasBody>
      </Offcanvas>
    </>
  )
}

export default OffacanvasFile
