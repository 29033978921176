import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../../sharedView/home/Home'
import Fatture from '../../contabile/Fatture'
import NumeroClienti from '../../contabile/NumeroClienti'
import UserProfile from '../../sharedView/userProfile/UserProfile'
import ControlloDocumenti from '../../sharedView/controlloDocumentiImportati/ControlloDocumenti'

const ContabileRoutes = () => (
  <Switch>
    <Route exact path="/fatture">
      <Fatture />
    </Route>
    <Route exaxt path="/numero-clienti">
      <NumeroClienti />
    </Route>
    <Route path="/shared/user-profile/:medicoId">
      <UserProfile />
    </Route>
    <Route exact path="/checker">
      <ControlloDocumenti />
    </Route>
    <Route>
      <Home />
    </Route>
  </Switch>
)

export default ContabileRoutes
