import React from 'react'
import { Container, Card, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const SoloDesktop = () => (
  <Container>
    <Card body className="mt-5">
      Fiscomed è utilizzabile solo sui dispositivi con schermo grande come quello di
      un computer.
      <br />
      <br />
      <br />
      <Button tag={Link} to="/" color="primary">
        Torna alla home
      </Button>
    </Card>
  </Container>
)

export default SoloDesktop
