import React, { useState } from 'react'
import { Button, Card, Table, Badge } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import {
  selectDocumento,
  deseleziona,
  sortByNumber,
  sortBySimilarity,
  aggiornaDocumento,
} from '../../../../app/store/gestionaleUtente/slice'
import { apriFileBlocchetto } from '../../../../app/store/fileViewer/actions'
import { ModalUpdateBlocchettoGestionale } from '../../../sharedComponent/ModalUpdateBlocchettoGestionale'
import moment from 'moment'
import { documentFromBlocchettoToGestionaleAdapter } from '../../../../app/utility/Adapter'
import {
  apriFatturaCartacea,
  apriFatturaElettronica,
} from '../../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../../app/store/fileViewer/selectors'
import classNames from 'classnames'
import BadgeControllato from './BadgeControllato'

const Incassato = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const [sortedByNumber, setSortedByNumber] = useState(false)
  const [tipologiaVisualizzata, setTipologiaVisualizzata] =
    useState('fattura_fiscomed')
  const [modalUpdateIsOpen, setModalUpdateIsOpen] = useState(false)
  const [documentToUpdate, setDocumentToUpdate] = useState({})
  const loading = useSelector(selectLoadingFile)

  const visualizzaPDF = (e, documento, tipologia) => {
    e.stopPropagation()
    if (loading) return
    if (tipologia === 'fattura_fiscomed') {
      if (documento.fattura_elettronica) {
        dispatch(apriFatturaElettronica(documento.id))
      } else {
        dispatch(apriFatturaCartacea(documento.id))
      }
    } else {
      dispatch(apriFileBlocchetto(documento.id))
    }
  }

  const modificaFile = (e, fattura) => {
    e.stopPropagation()
    setDocumentToUpdate(fattura)
    setModalUpdateIsOpen(true)
  }

  const selezionaDocumento = (fattura, tipologia) => {
    dispatch(selectDocumento({ fattura, tipologia }))
  }

  const valori = (documenti) => (
    <tbody>
      {documenti?.map((fattura, i) => {
        return (
          <tr
            key={i}
            onClick={() => selezionaDocumento(fattura, fattura.tipologia)}
            className={fattura.selected ? 'table-success' : ''}
          >
            <td>
              {tipologiaVisualizzata === 'borse' ||
              fattura.tipologia === 'prestazione_occasionale'
                ? fattura.tipologia
                : fattura.numero}
            </td>
            <td>
              <small className="text-muted">
                {moment(fattura.data_emissione).format('MM-YY')} |{' '}
                {moment(fattura.data_incasso).format('MM-YY')}{' '}
              </small>
            </td>
            <td>
              <BadgeControllato fattura={fattura} />
            </td>
            <td>{fattura.nome}</td>
            <td>{fattura.p_iva}</td>
            <td>{fattura.cf}</td>
            <td>
              {fattura.importo}
              {fattura.importo > 0 && '€'}
            </td>
            {tipologiaVisualizzata === 'cedolino' && (
              <td>{fattura.trattenuta_enpam}€</td>
            )}
            {tipologiaVisualizzata === 'prestazione_occasionale' && (
              <td>{fattura.trattenuta_irpef}€</td>
            )}
            <td>
              <Badge
                color={classNames({ primary: !loading }, { secondary: loading })}
                onClick={(e) => visualizzaPDF(e, fattura, fattura.tipologia)}
                style={{ cursor: 'pointer' }}
                disabled={loading}
              >
                <i className="bi bi-eye"></i>
              </Badge>
              {fattura.tipologia !== 'fattura_fiscomed' && (
                <Badge
                  color="warning"
                  className="ms-2"
                  onClick={(e) => modificaFile(e, fattura)}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="bi bi-pencil"></i>
                </Badge>
              )}
            </td>
          </tr>
        )
      })}
    </tbody>
  )

  return (
    <>
      <ModalUpdateBlocchettoGestionale
        isOpen={modalUpdateIsOpen}
        close={() => setModalUpdateIsOpen(false)}
        inputDocument={documentToUpdate}
        medicoId={dati.medico_id}
        returnFunction={(doc) =>
          dispatch(
            aggiornaDocumento({
              tipologiaDaRimuovere: documentToUpdate.tipologia,
              documento: documentFromBlocchettoToGestionaleAdapter(doc),
              sortedByNumber,
            }),
          )
        }
      />
      <Card body className="mt-4">
        <div>
          <Button
            color="secondary"
            outline
            className="me-2 position-relative"
            size="sm"
            disabled={tipologiaVisualizzata === 'fattura_fiscomed'}
            onClick={() => setTipologiaVisualizzata('fattura_fiscomed')}
          >
            Fatture {dati.incassato?.fattura_fiscomed?.length}
          </Button>
          <Button
            color="secondary"
            outline
            className="me-2 position-relative"
            size="sm"
            disabled={tipologiaVisualizzata === 'fattura'}
            onClick={() => setTipologiaVisualizzata('fattura')}
          >
            Fatture importate {dati.incassato?.fattura?.length}
          </Button>
          <Button
            color="secondary"
            outline
            className="me-2 position-relative"
            size="sm"
            disabled={tipologiaVisualizzata === 'cedolino'}
            onClick={() => setTipologiaVisualizzata('cedolino')}
          >
            Cedolini {dati.incassato?.cedolino?.length}
          </Button>
          <Button
            color="secondary"
            outline
            className="me-2 position-relative"
            size="sm"
            disabled={tipologiaVisualizzata === 'prestazione_occasionale'}
            onClick={() => setTipologiaVisualizzata('prestazione_occasionale')}
          >
            Occasionali {dati.incassato?.prestazione_occasionale?.length}
          </Button>
          <Button
            color="secondary"
            outline
            className="me-2 position-relative"
            size="sm"
            disabled={tipologiaVisualizzata === 'borse'}
            onClick={() => setTipologiaVisualizzata('borse')}
          >
            Borse{' '}
            {dati.incassato?.borsa_mmg?.length +
              dati.incassato?.borsa_specializzazione?.length}
          </Button>
          <Button
            color="secondary"
            outline
            className="float-end"
            size="sm"
            onClick={() => dispatch(deseleziona())}
          >
            <i className="bi bi-eraser"></i> Deseleziona
          </Button>
          <Button
            color="secondary"
            outline
            className="me-2 float-end"
            size="sm"
            disabled
          >
            Trattenuta {dati.trattenuta_enpam}€
          </Button>
          <Button
            color="secondary"
            outline
            className="me-2 float-end"
            size="sm"
            disabled
          >
            Totale {dati.somma}€
          </Button>
          <Table className="mt-5 table-sm" hover>
            <thead>
              <tr>
                <th>
                  {tipologiaVisualizzata === 'borse' ||
                  tipologiaVisualizzata == 'prestazione_occasionale' ? (
                    'Tipo'
                  ) : (
                    <Button
                      color="secondary"
                      size="sm"
                      disabled={sortedByNumber}
                      onClick={() => {
                        dispatch(sortByNumber())
                        setSortedByNumber(true)
                      }}
                    >
                      Numero
                    </Button>
                  )}
                </th>
                <th>Emiss | Inc</th>
                <th>Verif</th>
                <th>
                  <Button
                    color="secondary"
                    size="sm"
                    disabled={!sortedByNumber}
                    onClick={() => {
                      dispatch(sortBySimilarity())
                      setSortedByNumber(false)
                    }}
                  >
                    Rag sociale
                  </Button>
                </th>
                <th>
                  <Button
                    color="secondary"
                    size="sm"
                    disabled={!sortedByNumber}
                    onClick={() => {
                      dispatch(sortBySimilarity())
                      setSortedByNumber(false)
                    }}
                  >
                    P IVA
                  </Button>
                </th>
                <th>
                  <Button
                    color="secondary"
                    size="sm"
                    disabled={!sortedByNumber}
                    onClick={() => {
                      dispatch(sortBySimilarity())
                      setSortedByNumber(false)
                    }}
                  >
                    CF
                  </Button>
                </th>
                <th scope="col">Totale</th>
                {tipologiaVisualizzata === 'cedolino' && (
                  <th scope="col">Tratt ENPAM</th>
                )}
                {tipologiaVisualizzata === 'prestazione_occasionale' && (
                  <th scope="col">Tratt IRPEF</th>
                )}
                <th></th>
              </tr>
            </thead>
            {tipologiaVisualizzata === 'fattura_fiscomed' && (
              <>{valori(dati.incassato?.fattura_fiscomed)}</>
            )}
            {tipologiaVisualizzata === 'fattura' && (
              <>{valori(dati.incassato?.fattura)}</>
            )}
            {tipologiaVisualizzata === 'cedolino' && (
              <>{valori(dati.incassato?.cedolino)}</>
            )}
            {tipologiaVisualizzata === 'prestazione_occasionale' && (
              <>{valori(dati.incassato?.prestazione_occasionale)}</>
            )}
            {tipologiaVisualizzata === 'borse' && (
              <>
                {valori(dati.incassato?.borsa_mmg)}
                {valori(dati.incassato?.borsa_specializzazione)}
              </>
            )}
          </Table>
          {tipologiaVisualizzata === 'preocc' && (
            <center>
              <h3>In sviluppo</h3>
            </center>
          )}
        </div>
      </Card>
    </>
  )
}

export default Incassato
