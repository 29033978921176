export const searchMedico = (query, medici, status) => {
  if (!Array.isArray(medici)) {
    return []
  }

  if (query === '') {
    return medici.filter((medico) => medico.stato === status)
  }
  const queryLower = query.toLowerCase()
  const corrispondenze = medici.filter((medico) => {
    return (
      medico.stato === status &&
      (medico.email?.toLowerCase().includes(queryLower) ||
        medico.nome?.toLowerCase().includes(queryLower) ||
        medico.cognome?.toLowerCase().includes(queryLower) ||
        medico.telefono?.includes(queryLower) ||
        (medico.nome?.toLowerCase() + ' ' + medico.cognome?.toLowerCase()).includes(
          queryLower,
        ) ||
        (medico.cognome?.toLowerCase() + ' ' + medico.nome?.toLowerCase()).includes(
          queryLower,
        ))
    )
  })

  return corrispondenze
}

export const contaTotali = (medici) => {
  if (!Array.isArray(medici)) {
    medici = []
  }
  return medici.reduce((acc, medico) => {
    acc[medico.stato] = (acc[medico.stato] || 0) + 1
    return acc
  }, {})
}

export const sortCorrispondenze = (corrispondenze, sortKey, sortDirection) => {
  const sorted = [...corrispondenze]
  sorted.sort((a, b) => {
    if (sortDirection === 'asc') {
      return a[sortKey] > b[sortKey] ? 1 : -1
    } else {
      return a[sortKey] < b[sortKey] ? 1 : -1
    }
  })
  return sorted
}
