import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import {
  destroyMedico,
  pendingViewData,
  assignMedicoToCommercialista,
} from '../../../app/utility/QueryOperation'
import CentredLoading from '../../component/CentredLoading'
import { Container, Card, Row, Col } from 'reactstrap'
import TableCommercialisti from './component/TableCommercialisti'
import CardMedicoDaAssegnare from './component/CardMedicoDaAssegnare'
import LeadChiusi from '../../sharedView/leadChiusi/LeadChiusi'

const Pending = () => {
  const user = useSelector(selectUser)
  const [data, setData] = useState({})
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    pendingViewData(user).then((dataFromServer) => {
      setData(dataFromServer)
      setLoadingData(false)
    })
  }, [])

  const removeMedico = (medico_id) => {
    if (
      confirm(
        "Sei sicuro di voler cancellare questo medico?\n\n Prima di cancellare l'account ricorda di:\n Riomuoverlo da MailChimp\n Disattivare gli abbonamenti Stripe",
      )
    ) {
      setLoadingData(true)
      destroyMedico(user, medico_id).then((dataFromServer) => {
        setData(dataFromServer)
        setLoadingData(false)
      })
    }
  }

  const assignMedico = (data) => {
    setLoadingData(true)
    assignMedicoToCommercialista(user, data)
      .then((dataFromServer) => {
        setData(dataFromServer)
        setLoadingData(false)
      })
      .catch(() => {
        alert("Errore durante l'assegnazione del medico al commercialista")
        setLoadingData(false)
      })
  }

  return (
    <Container fluid>
      {loadingData ? (
        <CentredLoading />
      ) : (
        <div>
          <Card body className="mt-3">
            <h6 className="font-regular">
              {data.medici.length} medici da assegnare
            </h6>
          </Card>

          <Row className="mt-4">
            <Col xs="2">
              <Card body>
                <TableCommercialisti commercialisti={data.commercialisti} />
              </Card>
            </Col>
            <Col xs="4">
              {data.medici.length === 0 && (
                <Card body>
                  <div className="alert alert-info mt-3" role="alert">
                    Non ci sono medici da assegnare
                  </div>
                </Card>
              )}
              {data.medici.map((medico) => (
                <CardMedicoDaAssegnare
                  key={medico.id}
                  medico={medico}
                  commercialisti={data.commercialisti}
                  deleteMedico={removeMedico}
                  assignMedico={assignMedico}
                />
              ))}
            </Col>
            <Col xs="6">
              <Card body>
                <LeadChiusi />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  )
}
export default Pending
