import React, { useState } from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../app/store/user/selectors'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import ModalStorico from './ModalStorico'
import { ricominciaDichiarazione } from '../../../../../app/utility/QueryDichiarazione'

const Altro = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dati = useSelector(selectGestionaleUtente)
  const [modaStoricoIsOpen, setModalStoricoIsOpen] = useState(false)
  const user = useSelector(selectUser)

  const closeStorico = () => {
    setModalStoricoIsOpen(false)
  }

  const riapriQuiz = () => {
    if (
      window.confirm(
        "Sei sicuro di voler riaprire il quiz? I dati del quiz non verranno persi ma l'utente tornerà alla prima tappa e potrà modificare le risposte",
      )
    ) {
      ricominciaDichiarazione(user, dati.medico_id).then(() => {
        window.location.reload()
      })
    }
  }

  return (
    <>
      <ButtonDropdown
        color="secondary"
        size="sm"
        className="me-2 position-relative"
        toggle={() => setDropdownOpen(!dropdownOpen)}
        isOpen={dropdownOpen}
      >
        <DropdownToggle caret>Altro</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setModalStoricoIsOpen(true)}>
            Storico fiscale
          </DropdownItem>
          <DropdownItem onClick={() => riapriQuiz()}>Riapri quiz</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <ModalStorico
        storico={dati?.anagrafica?.storico}
        isOpen={modaStoricoIsOpen}
        closeStorico={() => closeStorico()}
      />
    </>
  )
}

export default Altro
