import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import { inviaPDFRiepilogo } from '../../../../app/utility/QueryCommercialista'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'
import swal from 'sweetalert'

const InviaPDFRiepilogo = ({ medico, inviaPDF }) => {
  const user = useSelector(selectUser)

  const invia = () => {
    inviaPDFRiepilogo(user, medico.id)
    inviaPDF(medico.id)
    swal('PDF inviato', 'Il PDF di riepilogo è stato inviato ', 'success')
  }

  return (
    <>
      {medico.data_invio_pdf_riepilogativo === null && (
        <Button className="me-2" color="primary" onClick={() => invia()}>
          <i className="bi bi-file-earmark-pdf"></i> Invia PDF di riepilogo
        </Button>
      )}
    </>
  )
}

export default InviaPDFRiepilogo
