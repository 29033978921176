export const csvAdapterCatasto = (data) => {
  const csvData = []
  data.forEach((element) => {
    const codiceFiscale = element.codice_fiscale
    const nomeCompleto = element.nome + ' ' + element.cognome
    const terreni =
      element.catasto.anomalia_terreni === false ? element.catasto.terreni : []
    const fabbricati =
      element.catasto.anomalia_fabbricati === false ? element.catasto.fabbricati : []

    terreni.forEach((terreno) => {
      csvData.push({
        nome: nomeCompleto,
        dichiarante: testoDichiarante(element.dichiarante),
        codice_fiscale: codiceFiscale,
        tipologia: 'terreno',
        classe: terreno.classe,
        comune: terreno.comune,
        foglio: terreno.foglio,
        ha_are_ca: terreno.ha_are_ca,
        particella: terreno.particella,
        qualita: terreno.qualita,
        reddito_agrario: terreno.reddito_agrario,
        reddito_dominicale: terreno.reddito_dominicale,
        subalterno: terreno.subalterno,
        titolarita: terreno.titolarita,
      })
    })

    fabbricati.forEach((fabbricato) => {
      csvData.push({
        nome: nomeCompleto,
        dichiarante: testoDichiarante(element.dichiarante),
        codice_fiscale: codiceFiscale,
        tipologia: 'fabbricato',
        classe: fabbricato.classe,
        comune: fabbricato.comune,
        consistenza: fabbricato.consistenza,
        foglio: fabbricato.foglio,
        indirizzo: fabbricato.indirizzo,
        particella: fabbricato.particella,
        rendita: fabbricato.rendita,
        subalterno: fabbricato.subalterno,
        titolarita: fabbricato.titolarita,
        zona: fabbricato.zona,
      })
    })
  })
  return csvData
}

export const csvAdapterAppunti = (data) => {
  const csvData = []

  data.forEach((element) => {
    csvData.push({
      nome_completo: element.nome + ' ' + element.cognome,
      dichiarante: testoDichiarante(element.dichiarante),
      appunti: element.appunti,
      appunti_dichiarazione_corrente: element.appunti_dichiarazione_corrente,
      appunti_dichiarazione_scorsa: element.appunti_dichiarazione_precedente,
    })
  })

  return csvData
}

const testoDichiarante = (dichiarante) => {
  if (dichiarante === true) {
    return 'Dichiarante'
  }
  if (dichiarante === false) {
    return 'Non dichiarante'
  }
  return 'Da capire'
}
