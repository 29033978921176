import React from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { cambiaUtente } from '../../../../../app/store/gestionaleUtente/actions'
import { selectUser } from '../../../../../app/store/user/selectors'

const Nome = () => {
  const user = useSelector(selectUser)
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()

  return (
    <ButtonGroup size="sm">
      {user.role === 'commercialista' && (
        <Button onClick={() => dispatch(cambiaUtente('previous'))} color="primary">
          <i className="bi bi-arrow-left-square"></i>
        </Button>
      )}
      <Button disabled color="light">
        {dati.loading ? (
          'Caricamento...'
        ) : (
          <>
            {dati?.anagrafica?.nome} {dati?.anagrafica?.cognome}
          </>
        )}
      </Button>
      {user.role === 'commercialista' && (
        <Button onClick={() => dispatch(cambiaUtente('next'))} color="primary">
          <i className="bi bi-arrow-right-square"></i>
        </Button>
      )}
    </ButtonGroup>
  )
}

export default Nome
