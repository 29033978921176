import React, { useState, useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import swal from 'sweetalert'
import moment from 'moment'
import { apriFileDichiarazioneAllegatoDaUtente } from '../../../app/store/fileViewer/actions'
import { useDispatch } from 'react-redux'

const Contributi = () => {
  const context = useContext(DichiarazioneContext)
  const dispatch = useDispatch()
  const [ENPAMCaricato, setENPAMCaricato] = useState(null)

  const controllaEProsegui = () => {
    if (ENPAMCaricato !== null) {
      context.completaStepContributi(ENPAMCaricato)
    } else {
      swal({
        title: 'Manca qualcosa',
        text: 'Spunta una delle due caselle prima di andare avanti',
        icon: 'warning',
        button: 'CHIUDI',
      })
    }
  }

  const filesContributi = () => {
    const tappa = context.dati?.tappe.find((t) => t.nome === 'contributi')
    const domanda = tappa?.domande.find((d) => d.numero === 0)
    return domanda?.files?.map((f, index) => (
      <div key={index} className="mb-2">
        <i
          className="bi bi-trash3 testo-rosso"
          style={{ cursor: 'pointer' }}
          onClick={() => context.cancellaFile('contributi', 0, f.uuid)}
        ></i>{' '}
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            dispatch(
              apriFileDichiarazioneAllegatoDaUtente({
                nome: f.nome,
                uuid: f.uuid,
              }),
            )
          }
        >
          {f.nome}
        </span>
      </div>
    ))
  }

  return (
    <div>
      <h4 className="testo-verde font-bold mb-4">
        2️⃣ SECONDA TAPPA: CERTIFICAZIONE DEI CONTRIBUTI ENPAM
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Dato che {context.genere?.ilTuo} commercialista può vedere tutto ciò che si
        trova nel tuo cassetto fiscale, c'è solo un tipo di documento che ti
        chiediamo di caricare. Si tratta della{' '}
        <span className="font-bold">certificazione dei contributi ENPAM</span>.
        <br />
        <br /> La puoi trovare nella tua area riservata del sito dell'ENPAM, dopo
        aver fatto l'accesso. Ti basterà cliccare su <i>"Certificazioni"</i> nella
        barra a sinistra e scaricare il file{' '}
        <i>
          "Oneri Deducibili anno {moment().year()} versati nel {moment().year() - 1}"
        </i>{' '}
        sotto la voce <i>"Oneri Deducibili".</i>
        <br />
        <br />
        Prima di andare avanti,{' '}
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() => context.apriFileUpload('contributi', 0)}
        >
          <u>clicca qui</u>
        </span>{' '}
        per caricare la certificazione e poi spunta la casella qui sotto.
      </h6>

      {filesContributi()}
      <FormGroup check className="mt-4">
        <Input
          type="checkbox"
          onChange={(e) => setENPAMCaricato(e.target.checked)}
          checked={ENPAMCaricato}
          valid={ENPAMCaricato}
        />{' '}
        <Label check>Ho caricato la certificazione ENPAM</Label>
      </FormGroup>
      <br />
      <h6 className="font-regular mt-3 interlinea-30">
        ⚠️ <span className="font-bold">Nella tua area riservata non c’è nulla?</span>{' '}
        Non preoccuparti: se lo scorso anno non hai pagato l’ENPAM{' '}
        <i>(perché hai fatto l’iscrizione dopo che avevano generato i bollettini)</i>{' '}
        è normale che non ci sia nulla. In questo caso, spunta la casella qui sotto
        prima di andare avanti.
      </h6>
      <FormGroup check className="mb-4 mt-4">
        <Input
          type="checkbox"
          onChange={(e) => setENPAMCaricato(!e.target.checked)}
          checked={ENPAMCaricato !== null && !ENPAMCaricato}
          valid={ENPAMCaricato !== null && !ENPAMCaricato}
        />{' '}
        <Label check>
          Non ho caricato la certificazione perché nella mia area riservata non c'è
          nulla
        </Label>
      </FormGroup>
      <Button
        className="float-end bottone-blu mb-4"
        onClick={() => controllaEProsegui()}
      >
        PROSSIMA TAPPA
      </Button>
    </div>
  )
}

export default Contributi
