import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalImpossibilitaDiRecapito } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalImpossibilitaDiRecapito = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalImpossibilitaDiRecapitoOpen}
      toggle={() => dispatch(chiudiModalImpossibilitaDiRecapito())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalImpossibilitaDiRecapito())}
        className="font-regular font-grigio-scuro"
      >
        Impossibilità di recapito
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          L&lsquo;esito di questa fattura è indicato come &quot;impossibilità di
          recapito&quot;. Significa che la fattura elettronica è stata correttamente
          inviata allo SdI, che però non è riuscito a consegnare la fattura
          elettronica sulla PEC o sul codice destinatario del cliente. Per questo
          motivo l&apos;ha salvata nella sua Area Fatture e Corrispettivi. Non
          preoccuparti, la fattura è da considerarsi correttamente inviata
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Invia un&lsquo;email al tuo cliente e avvertilo che può leggere la fattura
          dalla sua Area Fatture e Corrispettivi, sul sito dell&lsquo;Agenzia delle
          Entrate
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalImpossibilitaDiRecapito
