import React, { useState } from 'react'
import {
  Button,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Tooltip,
} from 'reactstrap'
import PrestazioniAutofattura from '../componenti/PrestazioniAutofattura'
import { useHistory } from 'react-router-dom'
import ModalPagamento from '../componenti/ModalPagamento'
import ModalCliente from '../componenti/ModalCliente'
import { latin1Invalid } from '../../../app/utility/ValidatoreRegex'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFiscomedData,
  selectIsLoadingFiscomed,
} from '../../../app/store/fiscomed/selectors'
import {
  setLoading,
  aggiornaDataDocumentoAutofattura,
  aggiornaIdDocumentoAutofattura,
  aggiornaTipologiaAutofattura,
} from '../../../app/store/fiscomed/slice'
import { creaAutofattura } from '../../../app/store/fiscomed/actions'

// TODO: testare se creaAutofattura funziona
const PannelloAutofattura = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)
  const isLoading = useSelector(selectIsLoadingFiscomed)
  const history = useHistory()
  const [tooltip, setTooltip] = useState({
    tipologia: false,
    numero: false,
    data: false,
    fornitore: false,
    pagamento: false,
  })

  const salvaEStampa = () => {
    const ivaPresente = fiscomedData.IVATotale > 0
    if (!isLoading) {
      dispatch(setLoading(true))
      dispatch(creaAutofattura())
        .unwrap()
        .then((idFattura) => {
          dispatch(setLoading(false))
          if (idFattura) {
            if (ivaPresente) {
              history.push(
                '/fiscomed/nuova-fattura/esito-autofattura/trasmessa/1/' + idFattura,
              )
            } else {
              history.push(
                '/fiscomed/nuova-fattura/esito-autofattura/trasmessa/0/' + idFattura,
              )
            }
          } else {
            history.push(
              '/fiscomed/nuova-fattura/esito-autofattura/non-trasmessa/0/id',
            )
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <Card body className="mt-2 mb-5">
      <Form>
        <FormGroup>
          <Label>
            Tipologia autofattura{' '}
            <i className="bi bi-question-circle" id="tooltipTipologia"></i>
          </Label>
          <Tooltip
            placement="top"
            isOpen={tooltip.tipologia}
            target="tooltipTipologia"
            toggle={() => setTooltip({ ...tooltip, tipologia: !tooltip.tipologia })}
          >
            Se l&apos;autofattura che stai facendo è per l&apos;acquisto di un
            servizio, seleziona la tipologia TD17. Se si tratta di un bene
            intracomunitario, invece, la tipologia giusta è TD18. Per i beni comprati
            da un fornitore estero ma già presenti sul suolo italiano seleziona TD19.
          </Tooltip>
          <Input
            type="select"
            value={fiscomedData.tipologiaAutofattura}
            onChange={(e) => dispatch(aggiornaTipologiaAutofattura(e.target.value))}
          >
            <option value={'TD17'}>TD17 - Servizi</option>
            <option value={'TD18'}>TD18 - Beni intracomunitari</option>
            <option value={'TD19'}>TD19 - Beni ex art.17 c.2 DPR 663/72</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>
            Numero fattura di riferimento{' '}
            <i className="bi bi-question-circle" id="tooltipNumero"></i>
          </Label>
          <Tooltip
            placement="top"
            isOpen={tooltip.numero}
            target="tooltipNumero"
            toggle={() => setTooltip({ ...tooltip, numero: !tooltip.numero })}
          >
            Qui devi inserire il numero della fattura estera che hai ricevuto, per la
            quale stai facendo questa autofattura
          </Tooltip>
          <Input
            type="text"
            value={fiscomedData.idDocumentoAutofattura}
            onChange={(e) =>
              dispatch(aggiornaIdDocumentoAutofattura(e.target.value))
            }
            invalid={latin1Invalid('ok')}
          />
          <FormFeedback>
            Sembra che tu abbia usato dei caratteri non consentiti
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            Data fattura di riferimento{' '}
            <i className="bi bi-question-circle" id="tooltipData"></i>
          </Label>
          <Tooltip
            placement="top"
            isOpen={tooltip.data}
            target="tooltipData"
            toggle={() => setTooltip({ ...tooltip, data: !tooltip.data })}
          >
            Indica la data della fattura estera che hai ricevuto, per la quale stai
            emettendo l’autofattura
          </Tooltip>
          <Input
            type="date"
            value={fiscomedData.dataDocumentoAutofattura}
            onChange={(e) =>
              dispatch(aggiornaDataDocumentoAutofattura(e.target.value))
            }
          />
        </FormGroup>
      </Form>
      <div className="d-flex flex-row mt-4">
        <h4 className="font-medium">
          <i className="bi bi-person-video2"></i> Fornitore{' '}
          <i className="bi bi-question-circle" id="tooltipFornitore"></i>
        </h4>
        <Tooltip
          placement="top"
          isOpen={tooltip.fornitore}
          target="tooltipFornitore"
          toggle={() => setTooltip({ ...tooltip, fornitore: !tooltip.fornitore })}
        >
          Il fornitore è l&apos;azienda o il privato estero da cui hai comprato il
          bene o servizio. Puoi aggiungerlo nella &quot;Rubrica clienti&quot;
        </Tooltip>
      </div>
      <ModalCliente />
      <h4 className="font-medium mt-4">
        <i className="bi bi-clipboard2-pulse"></i> Bene o servizio
      </h4>{' '}
      <PrestazioniAutofattura />
      <div className="d-flex flex-row mt-4">
        <h4 className="d-inline align-bottom font-medium ">
          <i className="bi bi-credit-card"></i> Pagamento{' '}
          <i className="bi bi-question-circle" id="tooltipPagamento"></i>
        </h4>
        <Tooltip
          placement="top"
          isOpen={tooltip.pagamento}
          target="tooltipPagamento"
          toggle={() => setTooltip({ ...tooltip, pagamento: !tooltip.pagamento })}
        >
          Indica semplicemente come hai pagato il bene o servizio acquistato
        </Tooltip>
      </div>
      <ModalPagamento />
      <div className="mt-4 mb-1">
        <Button
          className="bottone-blu-scuro float-end"
          onClick={() => salvaEStampa()}
        >
          INVIA AUTOFATTURA
        </Button>
      </div>
    </Card>
  )
}

export default PannelloAutofattura
