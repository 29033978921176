import React, { useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'

const F24InLavorazione = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <>
      <h1 className="font-bold mt-3">Stiamo lavorando alla tua dichiarazione 👷‍♀️</h1>
      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Congratulazioni: hai completato tutti gli step. Il tuo lavoro, per ora,
        finisce qui 🎉
        <br />
        <br />
        Grazie ai <i>documenti caricati</i>, al <i>questionario compilato</i> e alla
        consultazione del <i>cassetto fiscale</i>, {context.genere?.ilTuo}{' '}
        commercialista può preparare la tua dichiarazione e gli eventuali facsimile
        degli <span className="font-bold">F24</span> che dovrai trasmettere
        all’Agenzia delle Entrate.
        <br />
        <br />
        Se avrà bisogno di chiarimenti o di qualche informazione in più, ti
        contatterà per email o con una chiamata telefonica.{' '}
        <span className="font-bold">Se non si farà sentire, meglio</span>: vuol dire
        che sta filando tutto liscio 😊
        <br />
        <br />
        <span className="font-bold">Cosa succede ora?</span>
        <br />
        <br />
        Se non ci saranno particolari cambiamenti o proroghe:
        <ul>
          <li>
            <span className="font-bold">Tra l’1 e il 14 giugno</span>, il tuo
            commercialista caricherà qui il/gli F24 del{' '}
            <span className="font-bold">saldo e del primo acconto</span>. A quel
            punto, <span className="font-bold">riceverai un’email</span> che ti
            avviserà di controllare questa schermata per scaricarli. Nell’email
            troverai tutte le istruzioni per trasmetterli e portare a termine questo
            adempimento.
            <br /> ⚠️{' '}
            <i>
              Attenzione: non è detto che tu abbia degli F24. In questo caso,
              riceverai comunque un’email di conferma e qui non troverai nulla.
            </i>
          </li>
          <br />
          <li>
            <span className="font-bold">Tra l’1 e il 14 novembre</span>, verrà
            caricato qui l’F24 del <span className="font-bold">secondo acconto</span>
            . Anche in questo caso,{' '}
            <span className="font-bold">riceverai un’email</span> che ti avviserà del
            fatto che puoi trovare gli F24 in questa sezione. Naturalmente, troverai
            le istruzioni per inviarlo all’Agenzia delle Entrate.
            <br />
            ⚠️{' '}
            <i>
              Se il secondo acconto non ti riguarda, riceverai comunque una email di
              conferma.
            </i>
          </li>
        </ul>
        <br />
        <br />
      </h6>
    </>
  )
}

export default F24InLavorazione
