import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectDichiarazione } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { apriFileDichiarazioneAllegatoDaUtente } from '../../../../app/store/fileViewer/actions'
import Completato from './Completato'

const CertificatoContributi = () => {
  const dichiarazione = useSelector(selectDichiarazione)
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Button
        className="mt-2 ms-2"
        color="secondary"
        outline
        size="sm"
        onClick={() => setModalIsOpen(true)}
      >
        Contributi scaricabili{' '}
        <Completato completato={dichiarazione?.dati?.tappe[1]?.completata} />
      </Button>
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)}>
        <ModalHeader className="font-regular" toggle={() => setModalIsOpen(false)}>
          Contributi
        </ModalHeader>
        <ModalBody>
          {dichiarazione?.dati?.tappe[1]?.completata ? (
            <>
              <span className="text-muted">
                Il cliente ha dato questa risposta nel quiz:
              </span>{' '}
              {dichiarazione?.dati?.tappe[1]?.domande[0]?.domanda}
              <br />
              <br />
              {dichiarazione?.dati?.tappe[1]?.domande[0]?.files.length > 0 ? (
                <>
                  <span className="text-muted">Ha anche caricato questo file</span>
                  <br />
                  <span
                    className="testo-blu"
                    onClick={() =>
                      dispatch(
                        apriFileDichiarazioneAllegatoDaUtente({
                          nome: dichiarazione?.dati?.tappe[1]?.domande[0]?.files[0]
                            .nome,
                          uuid: dichiarazione?.dati?.tappe[1]?.domande[0]?.files[0]
                            .uuid,
                        }),
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {dichiarazione?.dati?.tappe[1]?.domande[0]?.files[0].nome}
                  </span>
                </>
              ) : (
                <span className="text-muted">
                  Inoltre non ha caricato nessun file
                </span>
              )}
            </>
          ) : (
            <span className="text-muted">
              Il cliente non ha completato questa sezione
            </span>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default CertificatoContributi
