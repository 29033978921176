import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalMancataConsegnaAutofattura } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalMancataConsegnaAutofattura = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalMancataConsegnaAutofatturaOpen}
      toggle={() => dispatch(chiudiModalMancataConsegnaAutofattura())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalMancataConsegnaAutofattura())}
        className="font-regular font-grigio-scuro"
      >
        Mancata consegna
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          L&apos;autofattura è una fattura che una persona fa a se stessa per
          registrare una transazione. Nel caso delle autofatture, quindi, la persona
          che la emette e quella che la riceve è la stessa: sei tu 😁
          <br />
          <br />
          Per questo motivo, è normale che l&apos;esito dell&apos;autofattura sia
          &quot;Mancata consegna&quot;.
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Puoi rilassarti e tornare a fare quello che stavi facendo.
          L&apos;autofattura è stata emessa correttamente ed è tutto ok 🎉
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalMancataConsegnaAutofattura
