import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { listMediciAssegnati } from '../../../app/utility/QueryAdmin'
import { searchMedico, contaTotali, sortCorrispondenze } from './utility'
import CentredLoading from '../../component/CentredLoading'
import { Container, Input, Button, Card, Row, Col } from 'reactstrap'
import TablePreviewMedico from './component/TablePreviewMedico'
import { CSVLink } from 'react-csv'

const Home = () => {
  const user = useSelector(selectUser)
  const [medici, setMedici] = useState([])
  const [totali, setTotali] = useState({
    active: 0,
    pending: 0,
    inactive: 0,
  })
  const [corrispondenze, setCorrispondenze] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [numeroVisualizzati, setNumeroVisualizzati] = useState(100)
  const [status, setStatus] = useState('active')
  const [sortDirection, setSortDirection] = useState({
    nome: 'asc',
    cognome: 'asc',
    email: 'asc',
    active: 'asc',
    telefono: 'asc',
    created_at: 'asc',
    data_apertura_p_iva: 'asc',
  })

  const handleSort = (sortKey) => {
    const sorted = sortCorrispondenze(
      corrispondenze,
      sortKey,
      sortDirection[sortKey],
    )
    setCorrispondenze(sorted)
    setSortDirection({
      ...sortDirection,
      [sortKey]: sortDirection[sortKey] === 'asc' ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    listMediciAssegnati(user).then((mediciFromServer) => {
      setMedici(mediciFromServer)
      setLoadingData(false)
      setCorrispondenze(searchMedico('', mediciFromServer, status))
      setTotali(contaTotali(mediciFromServer))
    })
  }, [])

  useEffect(() => {
    setCorrispondenze(searchMedico('', medici, status))
  }, [medici])

  const search = (query) => {
    setCorrispondenze(searchMedico(query, medici, status))
  }

  useEffect(() => {
    setCorrispondenze(searchMedico('', medici, status))
  }, [status])

  return (
    <Container fluid>
      <Card body className="my-4">
        <center>
          <Input
            type="text"
            onChange={(e) => search(e.target.value)}
            placeholder="🔍 Cerca per nome, cognome, telefono o email"
            disabled={loadingData}
            style={{ maxWidth: '550px' }}
            className="ms-5 mt-3"
            bsSize="lg"
          />
        </center>
        <br />

        {loadingData ? (
          <div style={{ minHeight: '200px' }}>
            <CentredLoading />
          </div>
        ) : (
          <div>
            <Button
              color="success"
              className="mb-2 me-2"
              size="sm"
              onClick={() => setStatus('active')}
            >
              Attivi {totali.active}
            </Button>
            <Button
              color="warning"
              className="mb-2 me-2"
              size="sm"
              onClick={() => setStatus('pending')}
            >
              In attivazione {totali.pending}
            </Button>
            <Button
              color="danger"
              className="mb-2 me-2"
              size="sm"
              onClick={() => setStatus('inactive')}
            >
              Inattivi {totali.inactive}
            </Button>
            {Array.isArray(medici) && medici.length > 0 && (
              <CSVLink data={medici}>
                <Button color="secondary" outline className="mb-2" size="sm">
                  <i className="bi bi-filetype-csv"></i> Scarica
                </Button>
              </CSVLink>
            )}
            <TablePreviewMedico
              medici={corrispondenze.slice(0, numeroVisualizzati)}
              user={user}
              handleSort={(sortKey) => handleSort(sortKey)}
            />
            {numeroVisualizzati < totali[status] && (
              <center>
                <Button
                  color="secondary"
                  outline
                  className="mb-3"
                  onClick={() => setNumeroVisualizzati(numeroVisualizzati + 100)}
                >
                  Vedi altri clienti
                </Button>
              </center>
            )}
          </div>
        )}
      </Card>
    </Container>
  )
}
export default Home
