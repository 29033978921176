import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { currentYear } from '../../app/utility/ArrayScadenze'

const ModaleQuotaA = ({ testo, colore, underline, fontStyle }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <span
        className={colore}
        onClick={() => setModalIsOpen(true)}
        style={{
          cursor: 'pointer',
          textDecoration: underline,
          fontStyle: fontStyle,
        }}
      >
        {testo}
      </span>

      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          Quota A ENPAM: perché l'importo può essere più alto di quello che pensi
        </ModalHeader>
        <ModalBody>
          <span className="font-regular">
            La Quota A è un contributo fisso che devi pagare ogni anno, di solito ad
            aprile, dal momento dell’iscrizione all’ENPAM. Il suo importo non dipende
            dai tuoi guadagni, ma varia in base alla fascia d’età.
          </span>
          <br />
          <br />
          <span className="font-regular">
            🔹{' '}
            <span className="font-bold">
              Se lo scorso anno hai fatto l'iscrizione dopo che l’ENPAM ha già
              calcolato le quote
            </span>{' '}
            e preparato i bollettini, la Quota A {currentYear - 1} non l’hai ancora
            versata.
          </span>
          <br />
          <br />
          <span className="font-regular">
            <i>🤌 Cosa succede quest'anno?</i>
            <br />
            Nel {currentYear} pagherai sia la Quota A {currentYear} che il recupero
            della Quota A {currentYear - 1}, calcolato solo sui mesi effettivi di
            iscrizione (quindi inferiore alla quota intera).
          </span>
          <br />
          <br />
          <span className="font-regular">
            📌{' '}
            <span className="font-bold">
              In breve: l’importo da pagare sarà più alto di quello indicato sulla
              piattaforma
            </span>
            , perché comprenderà anche i mesi del {currentYear - 1} in cui eri
            iscritta o iscritto e che non hai ancora pagato.
          </span>
          <br />
          <br />
          <span className="font-regular">
            💡 <i>Attenzione</i>: la nostra piattaforma non conosce la data della tua
            iscrizione e mostra sempre la quota intera prevista per un giovane medico
            della tua età. Ecco perché potresti vedere un importo diverso da quello
            atteso. Dal prossimo anno, potrai affidarti senza pensieri alla
            previsione che ti facciamo noi 😊
          </span>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModaleQuotaA
