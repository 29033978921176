import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
} from 'reactstrap'
import DataIncassoBlocchetto from '../../sharedComponent/DataIncassoBlocchetto'
import { Tooltip } from '../../sharedComponent/Tooltip'
import currency from 'currency.js'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { preposizioni } from '../../../app/utility/Miscellanea'
import { selectFileManager } from '../../../app/store/fileManager/selectors'
import { useDispatch } from 'react-redux'
import {
  setUserUploadModalIsOpen,
  changeDocumento,
  changeDataDocumento,
} from '../../../app/store/fileManager/slice'
import {
  uploadFileBlocchetto,
  uploadFileCondiviso,
} from '../../../app/store/fileManager/actions'
import {
  tipologiaAdapterFromTextToTipologia,
  tipologiaAdapterFromTipologiaToText,
} from '../../../app/utility/Adapter'
import swal from 'sweetalert'
import { dataIncassoSezionaleBlocchettoValida } from '../../../app/utility/controllerApi'

export const ModalUpload = () => {
  const fileManager = useSelector(selectFileManager)
  const user = useSelector(selectUser)
  const genere = preposizioni(user.commercialista_uomo)
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState(null)

  const trySetFile = (fileToSet) => {
    if (fileToSet === undefined) {
      setSelectedFile(null)
      return
    }
    if (fileToSet.size > 20000000) {
      setSelectedFile(null)
      swal({
        title: 'Oops...',
        text: 'Il file che stai cercando di caricare è veramente gigante, ti va di provare con un file più piccolo? Possiamo gestire file fino a 20MB',
        icon: 'warning',
      })
      return
    }
    setSelectedFile(fileToSet)
  }

  const carica = () => {
    if (fileManager.documento.tipologia === 'altro') {
      dispatch(uploadFileCondiviso({ selectedFile, medico_id: null }))
    } else {
      if (dataIncassoSezionaleBlocchettoValida(fileManager.documento.data_incasso)) {
        dispatch(uploadFileBlocchetto({ selectedFile, medico_id: null }))
      } else {
        swal({
          title: 'OOOPS...',
          text: 'La data di incasso sembra trovarsi in un tempo lontano o indefinito. Se viaggi nel tempo è tutto okay, ma se così non fosse prova a ricontrollarla 🕰️',
          icon: 'error',
          button: 'OK',
        })
      }
    }
  }

  return (
    <>
      <Button
        className="bottone-blu float-end"
        onClick={() => dispatch(setUserUploadModalIsOpen(true))}
      >
        <i className="bi bi-cloud-arrow-up"></i> IMPORTA
      </Button>
      <Modal
        isOpen={fileManager.userUploadModalIsOpen}
        toggle={() => dispatch(setUserUploadModalIsOpen(false))}
      >
        <ModalHeader
          className="font-medium"
          toggle={() => dispatch(setUserUploadModalIsOpen(false))}
        >
          Importa documento
        </ModalHeader>
        <ModalBody>
          <h6 className="font-regular interlinea-30">
            Prova a compilare i campi qui sotto. Se dovessi incontrare qualche
            difficoltà, scrivici su WhatsApp: faremo la procedura insieme 😊
          </h6>
          <br />
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">Tipologia</Label>
                <Input
                  onChange={(e) =>
                    dispatch(
                      changeDocumento({
                        ...fileManager.documento,
                        tipologia: tipologiaAdapterFromTextToTipologia(
                          e.target.value,
                        ),
                      }),
                    )
                  }
                  type="select"
                  value={tipologiaAdapterFromTipologiaToText(
                    fileManager.documento.tipologia,
                  )}
                >
                  <option>Fattura</option>
                  <option>Cedolino</option>
                  <option>Borsa MMG</option>
                  <option>Borsa specializzazione</option>
                  <option>Prestazione occasionale</option>
                  <option>Altro</option>
                </Input>
              </FormGroup>
            </Col>
            {fileManager.documento.tipologia !== 'altro' && (
              <>
                <Col xs={6}>
                  <DataIncassoBlocchetto
                    dataIncasso={fileManager.documento.data_incasso}
                    modificaData={(data) => dispatch(changeDataDocumento(data))}
                  />
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Partita IVA del tuo cliente{' '}
                      <Tooltip placement="top">
                        Qui ci va la Partita IVA del cliente verso cui hai fatto la
                        fattura o dell'ente che ha emesso per te il documento da
                        importare. Questo campo non è obbligatorio, ma ci aiuta a
                        mettere ordine fra i tuoi documenti
                      </Tooltip>
                    </Label>
                    <Input
                      type="text"
                      placeholder=""
                      value={fileManager.documento.partita_iva}
                      onChange={(e) =>
                        dispatch(
                          changeDocumento({
                            ...fileManager.documento,
                            partita_iva: e.target.value,
                          }),
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Codice fiscale del cliente{' '}
                      <Tooltip placement="top">
                        Qui ci va il codice fiscale del cliente verso cui hai fatto
                        la fattura o dell'ente che ha emesso per te il documento da
                        importare. Spesso coincide con la Partita IVA. Questo campo
                        non è obbligatorio, ma ci aiuta a mettere ordine fra i tuoi
                        documenti
                      </Tooltip>
                    </Label>
                    <Input
                      type="text"
                      placeholder=""
                      value={fileManager.documento.codice_fiscale}
                      onChange={(e) =>
                        dispatch(
                          changeDocumento({
                            ...fileManager.documento,
                            codice_fiscale: e.target.value,
                          }),
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            {(fileManager.documento.tipologia === 'fattura' ||
              fileManager.documento.tipologia === 'cedolino') && (
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleEmail">Numero*</Label>
                  <Input
                    type="text"
                    placeholder="1s"
                    value={fileManager.documento.numero}
                    onChange={(e) =>
                      dispatch(
                        changeDocumento({
                          ...fileManager.documento,
                          numero: e.target.value,
                        }),
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}
            {[
              'fattura',
              'cedolino',
              'borsa_mmg',
              'prestazione_occasionale',
            ].includes(fileManager.documento.tipologia) && (
              <Col xs={6}>
                <FormGroup>
                  <Label for="importo">
                    Importo lordo*{' '}
                    <Tooltip placement="top">
                      Se stai caricando una fattura, in questo campo devi inserire
                      l&apos;importo della fattura più la marca da bollo. Esempio:
                      l&apos;importo della tua fattura è 1200 € e la marca da bollo
                      che hai applicato è di 2€. In questo campo scriverai 1202 €.
                      Se, vuoi importare un cedolino, sempre in questo campo devi
                      riportare il numero che trovi sotto la dicitura totale
                      competenze. Se invece stai importando una borsa MMG, in questo
                      campo devi inserire l&apos;importo IRPEF lordo
                    </Tooltip>
                  </Label>
                  <Input
                    type="number"
                    placeholder="120,23"
                    value={fileManager.documento.importo}
                    onChange={(e) =>
                      dispatch(
                        changeDocumento({
                          ...fileManager.documento,
                          importo: currency(e.target.value).value,
                        }),
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}
            {fileManager.documento.tipologia === 'cedolino' && (
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    Trattenute ENPAM*{' '}
                    <Tooltip placement="top">
                      In questo campo, inserisci il numero che trovi sotto la voce
                      &quot;oneri previdenziali&quot;. È importante per il calcolo
                      delle tasse
                    </Tooltip>
                  </Label>
                  <Input
                    type="number"
                    placeholder="12,23"
                    value={fileManager.documento.trattenuta_enpam}
                    onChange={(e) =>
                      dispatch(
                        changeDocumento({
                          ...fileManager.documento,
                          trattenuta_enpam: currency(e.target.value).value,
                        }),
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}
            {fileManager.documento.tipologia === 'prestazione_occasionale' && (
              <Col xs={6}>
                <FormGroup>
                  <Label for="trattenuteIrpef">
                    Trattenute IRPEF*{' '}
                    <Tooltip placement="top">
                      In questo campo, inserisci il numero che trovi sotto la voce
                      &quot;oneri previdenziali&quot;. È importante per il calcolo
                      delle tasse
                    </Tooltip>
                  </Label>
                  <Input
                    name="trattenuteIrpef"
                    type="number"
                    placeholder="12,23"
                    value={fileManager.documento.trattenuta_irpef}
                    onChange={(e) =>
                      dispatch(
                        changeDocumento({
                          ...fileManager.documento,
                          trattenuta_irpef: currency(e.target.value).value,
                        }),
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          {fileManager.documento.tipologia === 'fattura' && (
            <FormGroup>
              <Label for="exampleEmail">
                È stata trasmessa a Sistema Tessera Sanitaria
              </Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    checked={fileManager.documento.inviata_ts === true}
                    onChange={() =>
                      dispatch(
                        changeDocumento({
                          ...fileManager.documento,
                          inviata_ts: true,
                        }),
                      )
                    }
                    value="Si"
                  />
                  Sì
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio2"
                    checked={fileManager.documento.inviata_ts === false}
                    onChange={() =>
                      dispatch(
                        changeDocumento({
                          ...fileManager.documento,
                          inviata_ts: false,
                        }),
                      )
                    }
                    value="No"
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          )}
          {fileManager.documento.tipologia === 'altro' && (
            <Col xs={12}>
              <FormGroup>
                <Label for="nota" className="font-regular">
                  Aggiungi una nota per spiegare {genere.alTuo} commercialista che
                  tipo di file stai caricando
                </Label>
                <Input
                  type="text"
                  name="nota"
                  id="nota"
                  placeholder="Questo è la ricevuta dell'f24 del 06/2022"
                  value={fileManager.documento.nota}
                  onChange={(e) =>
                    dispatch(
                      changeDocumento({
                        ...fileManager.documento,
                        nota: e.target.value,
                      }),
                    )
                  }
                />
              </FormGroup>
            </Col>
          )}
          <FormGroup>
            <Label for="exampleEmail">Foto o pdf del documento</Label>
            <Input
              type="file"
              onChange={(e) => trySetFile(e.target.files[0])}
              accept=".pdf, .jpg, .jpeg, .png, .heic"
            />
          </FormGroup>
          <br />
          {fileManager.documento.uploading ? (
            <Button className="float-end bottone-blu" disabled>
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button
              className="bottone-blu float-end"
              onClick={() => carica()}
              disabled={fileManager.uploadButtonDisabled || !selectedFile}
            >
              <i className="bi bi-cloud-arrow-up"></i> IMPORTA
            </Button>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalUpload
