import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { getCommercialisti } from '../../../app/utility/QueryOperation'
import CentredLoading from '../../component/CentredLoading'
import { Button, Container } from 'reactstrap'
import Commercialista from './Commercialista'

const Commercialisti = () => {
  const user = useSelector(selectUser)

  const [data, setData] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [showNewCommercialista, setShowNewCommercialista] = useState(false)

  useEffect(() => {
    getCommercialisti(user).then((dataFromServer) => {
      setData(dataFromServer)
      setLoadingData(false)
    })
  }, [])

  return (
    <Container>
      {loadingData ? (
        <CentredLoading />
      ) : (
        <div className="d-flex flex-column mt-4 mb-5">
          <div>
            <Button
              className="float-end mb-4 bottone-verde"
              onClick={() => setShowNewCommercialista(true)}
            >
              Aggiungi Commercialista
            </Button>
          </div>
          {showNewCommercialista && (
            <Commercialista
              isNew
              setShowNewCommercialista={setShowNewCommercialista}
            />
          )}
          {data.commercialisti.map((commercialista) => (
            <Commercialista
              key={commercialista.id}
              commercialista={commercialista}
            />
          ))}
        </div>
      )}
    </Container>
  )
}
export default Commercialisti
