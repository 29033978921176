import React from 'react'
import { Card, Badge } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { apriFileCassetto } from '../../../../app/store/fileViewer/actions'

const ModelloUnico = () => {
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()

  return (
    <Card body className="mt-4">
      <span className="h5">Modello unico/redditi persone fisiche</span>
      {dati.cassetto?.last_sync !== null && (
        <>
          {dati.cassetto?.dichiarazioni?.length > 0 ? (
            <table className="table table-striped table-bordered mt-3">
              <tbody>
                {dati.cassetto?.dichiarazioni?.map((dichiarazione, index) => {
                  return (
                    <tr key={index}>
                      <td>Anno {dichiarazione.anno}</td>
                      <td>
                        <Badge
                          color="primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            dispatch(
                              apriFileCassetto(
                                'dichiarazioni/' + dichiarazione.nome_file,
                              ),
                            )
                          }
                        >
                          <i className="bi bi-eye"></i>
                        </Badge>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-secondary mt-3" role="alert">
              Non risultano dichiarazioni nei 3 anni precedenti
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default ModelloUnico
