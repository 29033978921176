import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { listImporti } from '../../app/utility/QueryContabile'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'
import moment from 'moment'

const NumeroClienti = () => {
  const user = useSelector(selectUser)
  const [guadagni, setGuadagni] = useState([])

  useEffect(() => {
    async function popolaDati() {
      setGuadagni(await listImporti(user))
    }
    popolaDati()
  }, [])

  return (
    <Container className="mt-4">
      <div className="alert alert-light font-regular mt-4">
        <h3 className="font-regular">
          <i className="bi bi-graph-up-arrow"></i> Clienti
        </h3>
        <br />
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Mese</th>
              <th scope="col">Commercialista</th>
              <th scope="col">Clienti</th>
            </tr>
          </thead>
          <tbody>
            {guadagni.map((guadagno, index) => {
              return (
                <tr key={index}>
                  <td>{moment(guadagno.mese).format('MM/YYYY')}</td>
                  <td>
                    {guadagno.commercialistum?.nome}{' '}
                    {guadagno.commercialistum?.cognome}
                  </td>
                  <td>{guadagno.utenti}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Container>
  )
}

export default NumeroClienti
