import React, { useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Card, Row, Col, Progress, CardTitle } from 'reactstrap'

const Medaglie = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <Card body className="mt-4 p-4">
      <CardTitle className="h4 font-grigio-scuro">Traguardi</CardTitle>
      <Row>
        <Col xs={3}>
          <center>
            <img
              src="/img/dichiarazione/chirurgo.webp"
              className="mt-1"
              style={{ width: '100%' }}
            />
          </center>
        </Col>
        <Col xs={9}>
          <h6 className="font-bold mb-2 interlinea-30">L&apos;Allegro Chirurgo</h6>
          <Progress
            value={
              context.dati?.tappe.find((tappa) => tappa.nome === 'fatture')
                .completata
                ? 100
                : 0
            }
            className="progress-gialla"
          />
          <h6 className="font-regular mt-2 interlinea-25 text-secondary">
            Controlla le fatture e i cedolini con la precisione con cui giocavi al
            gioco da tavola
          </h6>
          <br />
        </Col>
        <Col xs={3}>
          <center>
            <img
              src="/img/dichiarazione/hacker.png"
              className="mt-1"
              style={{ width: '100%' }}
            />
          </center>
        </Col>
        <Col xs={9}>
          <h6 className="font-bold mb-2 interlinea-30">Hacker</h6>
          <Progress
            value={
              context.dati?.tappe.find((tappa) => tappa.nome === 'contributi')
                .completata
                ? 100
                : 0
            }
            className="progress-gialla"
          />
          <h6 className="font-regular mt-2 interlinea-25 text-secondary">
            Naviga nei meandri del sito dell&apos;ENPAM e ruba le tue certificazioni
            dei contributi
          </h6>
          <br />
        </Col>
        <Col xs={3}>
          <center>
            <img
              src="/img/dichiarazione/vip.webp"
              className="mt-1"
              style={{ width: '100%' }}
            />
          </center>
        </Col>
        <Col xs={9}>
          <h6 className="font-bold mb-2 interlinea-30">VIP</h6>
          <Progress
            value={
              context.dati?.tappe.find((tappa) => tappa.nome === 'quiz').completata
                ? 100
                : 0
            }
            className="progress-gialla"
          />
          <h6 className="font-regular mt-2 interlinea-25 text-secondary">
            È il tuo momento: rispondi alle domande sulla tua vita privata
          </h6>
          <br />
        </Col>
        <Col xs={3}>
          <center>
            <img
              src="/img/dichiarazione/watson.png"
              className="mt-1"
              style={{ width: '100%' }}
            />
          </center>
        </Col>
        <Col xs={9}>
          <h6 className="font-bold mb-2 interlinea-30">Elementare, Watson</h6>
          <Progress
            value={
              context.dati?.tappe.find((tappa) => tappa.nome === 'f24inlavorazione')
                .completata
                ? 100
                : 0
            }
            className="progress-gialla"
          />
          <h6 className="font-regular mt-2 interlinea-25 text-secondary">
            Caso risolto: grazie a te siamo riusciti a preparare la tua dichiarazione
            dei redditi
          </h6>
          <br />
        </Col>
      </Row>
    </Card>
  )
}

export default Medaglie
