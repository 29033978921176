import React, { useEffect, useState } from 'react'
import { FormGroup, Label, Button, Spinner } from 'reactstrap'
import { codiceFiscaleInverso } from '../../../../app/utility/QueryCommercialista'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'
import { codiceFiscaleValid } from '../../../../app/utility/ValidatoreRegex'

const CodiceFiscaleInverso = ({ codiceFiscale }) => {
  const user = useSelector(selectUser)
  const [dataDiNascita, setDataDiNascita] = useState('Roma')
  const [luogoDiNascita, setLuogoDiNascita] = useState('21/12/1993')
  const [calcolato, setCalcolato] = useState(false)
  const [loading, setLoading] = useState(false)

  const calcola = () => {
    setLoading(true)
    codiceFiscaleInverso(user, codiceFiscale).then((response) => {
      setDataDiNascita(response.data_di_nascita)
      setLuogoDiNascita(response.luogo_di_nascita)
      setCalcolato(true)
      setLoading(false)
    })
  }

  useEffect(() => {
    setCalcolato(false)
  }, [codiceFiscale])

  return (
    <>
      {loading ? (
        <Spinner color="primary" className="mt-5" size="sm" />
      ) : (
        <FormGroup>
          {calcolato ? (
            <div className="mt-4">
              <small className="text-muted">
                Data di nascita inversa: {dataDiNascita}
              </small>
              <br />
              <small className="text-muted">
                Luogo di nascita inverso: {luogoDiNascita}
              </small>
            </div>
          ) : (
            <>
              <Label for="cf">Codice fiscale inverso</Label>
              <br />

              <Button
                color="secondary"
                outline
                onClick={() => calcola()}
                disabled={!codiceFiscaleValid(codiceFiscale)}
              >
                <i className="bi bi-journal-code"></i> Calcola
              </Button>
            </>
          )}
        </FormGroup>
      )}
    </>
  )
}

export default CodiceFiscaleInverso
