import React from 'react'
import { Table } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectGestionaleUtente } from '../../../../app/store/gestionaleUtente/selectors'
import { useDispatch } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'
import { apriF24 } from '../../../../app/store/fileViewer/actions'
import { selectLoadingFile } from '../../../../app/store/fileViewer/selectors'
import classNames from 'classnames'
import { deleteF24 } from '../../../../app/utility/QueryGestionaleUtente'
import { aggiornaF24 } from '../../../../app/store/gestionaleUtente/slice'

const TabellaF24 = () => {
  const user = useSelector(selectUser)
  const dati = useSelector(selectGestionaleUtente)
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingFile)

  const cancellaF24 = (f24) => {
    if (confirm('Sei sicuro di voler eliminare il file?')) {
      deleteF24(user, f24.id, dati.medico_id, dati.anno).then((bodyResponse) => {
        dispatch(aggiornaF24(bodyResponse))
      })
    }
  }

  const visualizzaFile = (f24) => {
    if (loading === true) return
    dispatch(apriF24(f24.id))
  }

  return (
    <>
      {dati?.dichiarazione.f24s.length > 0 ? (
        <Table className="my-5">
          <thead>
            <tr>
              <th className="font-regular"></th>
              <th className="font-regular">Tipo</th>
              <th className="font-regular">Importo</th>
              <th className="font-regular">Scadenza</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dati.dichiarazione.f24s.map((f24, index) => (
              <tr key={index}>
                <td>
                  <span className="font-regular">{index + 1}</span>
                </td>
                <td>
                  <span className="font-regular">{f24.tipologia}</span>
                </td>
                <td>
                  <span className="font-regular">{f24.importo}€</span>
                </td>
                <td>
                  <span className="font-regular">{f24.data_scadenza}</span>
                </td>
                <td>
                  <span
                    className={classNames(
                      { 'font-regular': loading === true },
                      { 'testo-blu': loading === false },
                    )}
                    style={{ cursor: 'pointer' }}
                    onClick={() => visualizzaFile(f24)}
                  >
                    <u>file_f24.pdf</u>
                  </span>
                </td>
                <td>
                  <i
                    className="bi bi-trash3 text-danger"
                    onClick={() => cancellaF24(f24)}
                    style={{ cursor: 'pointer' }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning" role="alert">
          Non hai caricano nessun F24 per questa dichiarazione
        </div>
      )}
    </>
  )
}

export default TabellaF24
